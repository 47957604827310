import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, EyeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Button from '../helpers/Button'
import flasher from '@flasher/flasher'
import ViewModel from '../helpers/ViewModel'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import LinkButton from '../helpers/LinkButton'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { CSVLink } from 'react-csv'
import { TableCellsIcon } from '@heroicons/react/24/outline'
import PleaseWait from '../Common/PleaseWait'

function CalledList() {
    document.title = 'SiteSmart - Called List'
    const tableHeading = ['Sr.No', 'Enquiry ID', 'Client Name', 'Mobile', 'Site Name', 'Called Date', 'Client Status', 'Called Status', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [calledList, setCalledList] = useState();

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    }

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getCalledLists = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        axiosClient.post(`datewise_called_data`, formData)
            .then(({ data }) => {
                setLoader(false)
                setCalledList(data.called_data)
                flasher.success(data.msg)
            })
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Client Name', key: 'client_name' },
        { label: 'Mobile No.', key: 'mobile_no' },
        { label: 'Work Name', key: 'work_name' },
        { label: 'Wing Name', key: 'wing_name' },
        { label: 'Level Name', key: 'level_name' },
        { label: 'Nature of Flat', key: 'nature_of_flat' },
        { label: 'Contact Mode', key: 'contact_mode' },
        { label: 'Client Response', key: 'clients_response' },
        { label: 'Previous Call Date', key: 'created_at' },
        { label: 'Reminder Date', key: 'next_reminder_date' },
        { label: 'Client Status', key: 'cus_status' },
        { label: 'Remark', key: 'remark' },
    ];

    const csvData = Array.isArray(calledList)
        ? calledList.map((item, index) => ({
            index: index + 1,
            client_name: item.client_name,
            mobile_no: item.mobile_no,
            work_name: item.work_name,
            wing_name: item.wing_name,
            level_name: item.level_name,
            nature_of_flat: item.nature_of_flat,
            contact_mode: item.contact_mode,
            clients_response: item.clients_response,
            created_at: item.created_at,
            next_reminder_date: item.next_reminder_date,
            cus_status: item.cus_status,
            remark: item.remark,
        }))
        : [];

    const getAllCalledListLists = () => {
        setLoader(true)
        axiosClient.get(`called_data`)
            .then(({ data }) => {
                setLoader(false)
                setCalledList(data.called_data)
                flasher.success(data.msg)
            })
    }


    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_followup_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }

    useEffect(() => {
        getAllCalledListLists()

    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Client Called List'} button={
                    <LinkButton to={'/follow_up'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To Follow Up</span>
                    </LinkButton>
                }>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                        <div className='mb-3'>
                            <Label htmlFor={'date'} labelText={'Start Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={startDate} onChange={handleStartDate} />
                        </div>
                        <div className="mb-3">
                            <Label htmlFor={'date'} labelText={'End Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={endDate} onChange={handleEndDate} />
                        </div>
                        <div className="items-center flex justify-start mb-3"><Button className='px-4  mt-4' onClick={getCalledLists}><MagnifyingGlassIcon className='w-5 h-5' /> </Button></div>
                        <div className='flex justify-end pt-8 gap-3 mb-3'>
                            <CSVLink data={csvData} headers={headers} filename={'Called List.csv'}>
                                <button className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-2 py-2 text-sm font-medium text-white focus-visible:ring-offset-2' ><TableCellsIcon className='w-5 h-5 mr-1' /> Export CSV</button>
                            </CSVLink>
                        </div>
                    </div>
                    {calledList && calledList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {calledList.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.enq_id}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{tableData.mobile_no}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.next_reminder_date && moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">{tableData.cus_status && ((tableData.cus_status).split('_')).join(' ')}</td>
                                        <td className="px-4 py-3">
                                            {tableData.call_status === null ? (
                                                <button title={'Forward'} className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Pending</button>
                                            ) : (
                                                <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'> Called</button>
                                            )}
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Button title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></Button>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Follow Up Data Not Found</p>
                        </div>
                    )}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef}>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Call Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 mt-4 '>
                                                <div className="">
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Client Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Email Id</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6 break-words'>{modalData.email_id}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Mobile No</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.mobile_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Address</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.address}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Enquiry For</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.enquiry_for}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Client status</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.cus_status && ((modalData.cus_status).split('_')).join(' ')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>No. of Reminder</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.no_of_reminders}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Work Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Wing Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Level Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.level_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Nature of Flat</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Area (sq / ft)</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Previous Call Date</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{moment(modalData.created_at).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Reminder Date</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{moment(modalData.next_reminder_date).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Contact Mode</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.contact_mode}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Client Response</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.clients_response}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-1 md:py-2'>
                                                        <h1 className='w-36 font-bold'>Remark</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.remark}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center mr-3 rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                </PageComponent>
            )}
        </Fragment>
    )
}

export default CalledList
