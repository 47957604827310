import React, { Fragment } from 'react'

function CustomSelect({children,id,name,value,readOnly,disabled,className,onChange}) {
    return (
        <Fragment>
            <select id={id} name={name} value={value} readOnly={readOnly} disabled={disabled} onChange={onChange} className={"block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset text-sm ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color"+(className)}>
                {children}
            </select>
        </Fragment>
    )
}

export default CustomSelect