// ReportsMenu.js

import React, {Fragment} from 'react';
import { ChevronUpIcon, ChevronDownIcon, } from '@heroicons/react/24/solid'
import * as Icons from '@heroicons/react/24/solid'; 

function Toggle({ open, toggleFun, handleToggleFun, moduleName,Icon,Title }) {
    const SelectedIcon = Icons[Icon];
    return (
        <Fragment>
             <div>
            <button onClick={() => handleToggleFun(moduleName, !toggleFun[moduleName])} className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg group mt-1">
                {open ? (
                    <Fragment>
                        <SelectedIcon  className='h-6 w-6 mx-auto' title={Title} />
                    </Fragment>
                ) : (
                    <Fragment>
                        <SelectedIcon  className='h-6 w-6' />
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">{Title}</span>
                        {toggleFun[moduleName] ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                    </Fragment>
                )}
            </button>
        </div>
        </Fragment>   
    );
}

export default Toggle;
