import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'

function BankMasterAddEdit() {
  const { id } = useParams()
  // eslint-disable-next-line no-lone-blocks
  {
    id ? (
      document.title = 'SiteSmart - Edit Bank Master'
    ) : (
      document.title = 'SiteSmart - Add New Bank Master'
    )
  }

  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const [formInfo, setFormInfo] = useState({
    bank_name:'',
    bank_branch: '',
    bank_address: '',
    bank_account_no: '',
    ifsc_code: '',
    opening_balance: '',
  })
  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    const formData = new FormData() 
    formData.append('bank_name', formInfo.bank_name)
    formData.append('bank_branch', formInfo.bank_branch)
    formData.append('bank_address', formInfo.bank_address)
    formData.append('bank_account_no', formInfo.bank_account_no)
    formData.append('description', formInfo.description)
    formData.append('ifsc_code', formInfo.ifsc_code)
    formData.append('opening_balance', formInfo.opening_balance)

    axiosClient.post(id ? `update_Bank_master/${id}` : '/store_Bank_master', formData)
      .then(({ data }) => {
        setLoader(false)
        flasher.success(data.msg)
        navigate('/accounting/bank-master')
      })
      .catch(({ response }) => {
        setLoader(false)
        setErrors(response.data.errors)
      })
  }

  const getSingleAccountLedgerData = () => {
    setLoader(true)
    axiosClient.get(`/single_Bank_master_data/${id}`)
      .then(({ data }) => {
        setLoader(false)
        setFormInfo(data.single_data)
      })
  }

  useEffect(() => {
    if (id) {
      getSingleAccountLedgerData()
    }
  }, [])

  return (
    <Fragment>

      {loader ? (<Loader />) : (
        <PageComponent title={'Account Master'} button={
          <LinkButton to={'/accounting/bank-master'}>
            <ArrowLeftCircleIcon className='w-5 h-5' />
            <span>Back To List</span>
          </LinkButton>
        }>
          <div className='flex items-center justify-center '>
            <Card className={'w-96 md:w-[70%] px-6 py-4 bg-default-color'}>
              <form onSubmit={handleSubmit}>
                <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div>
                    <Label htmlFor={'bank_name'} labelText={'Bank Name / Cash'} className={'mb-1'} />
                    <Input id={'bank_name'} type={'text'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange}  readOnly={id ? true : false} />
                    <Validation error={errors.bank_name} />
                  </div>
                  <div>
                    <Label htmlFor={'bank_branch'} labelText={'Bank Branch'} className={'mb-1'} />
                    <Input id={'bank_branch'} type={'text'} name={'bank_branch'} value={formInfo.bank_branch} onChange={handleChange}  />
                    <Validation error={errors.bank_branch} />
                  </div>
                  <div>
                    <Label htmlFor={'bank_address'} labelText={'Bank Address'} className={'mb-1'} />
                    <Input id={'bank_address'} type={'text'} name={'bank_address'} value={formInfo.bank_address} onChange={handleChange} />
                    <Validation error={errors.bank_address} />
                  </div>
                  <div>
                    <Label htmlFor={'bank_account_no'} labelText={'Bank Account No'} className={'mb-1'} />
                    <Input id={'bank_account_no'} type={'text'} name={'bank_account_no'} value={formInfo.bank_account_no} onChange={handleChange} readOnly={id ? true : false} />
                    <Validation error={errors.bank_account_no} />
                  </div>
                  <div>
                    <Label htmlFor={'ifsc_code'} labelText={'IFSC Code'} className={'mb-1'} />
                    <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                    <Validation error={errors.ifsc_code} />
                  </div>
                  <div>
                    <Label htmlFor={'opening_balance'} labelText={'Opening Balance'} className={'mb-1'} />
                    <Input id={'opening_balance'} type={'text'} name={'opening_balance'} value={formInfo.opening_balance} onChange={handleChange} readOnly={id ? true : false}/>
                    <Validation error={errors.opening_balance} />
                  </div>
                </div>
                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                  <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                    <span>Save Now</span>
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        </PageComponent>
      )}
    </Fragment>
  )
}


export default BankMasterAddEdit
