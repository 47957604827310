import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon, } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ViewModel from '../helpers/ViewModel'
import Loader from '../Common/Loader'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import ActionButton from '../helpers/ActionButton'
import { CSVLink } from 'react-csv'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function Enquiry() {
    document.title = 'SiteSmart - Enquiry Master'

    const tableHeading = ['Sr.No', 'Client ID', 'Name', 'Mobile No.', 'Source', 'Work Name', 'Enquiry For', 'Reminder Date', 'Action']
    const [loader, setLoader] = useState(false)
    const [enquiryLists, setEnquiryLists] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const getEnquiryLists = () => {
        setLoader(true)
        axiosClient.get('/enquiry_lists')
            .then(({ data }) => {
                setLoader(false)
                setEnquiryLists(data.lists)
            })
    }

    const deleteEnquiry = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_enquiry/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getEnquiryLists()
                    })
            }
        })
    }

    function print(printpage) {
        var printContents = document.getElementById(printpage).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        var printButton = document.getElementById("printButton");
        printButton.style.visibility = 'hidden';

        var gotitButton = document.getElementById("gotit");
        gotitButton.style.visibility = 'hidden';

        window.print();

        gotitButton.style.visibility = 'visible';
        printButton.style.visibility = 'visible';
        printpage.style.visibility = 'hidden';
        document.body.innerHTML = originalContents;
        window.close();
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Client Name', key: 'client_name' },
        { label: 'Mobile No.', key: 'mobile_no' },
        { label: 'Email ID', key: 'email_id' },
        { label: 'Address', key: 'address' },
        { label: 'Source', key: 'source' },
        { label: 'Exquiry For', key: 'enquiry_for' },
        { label: 'Work Name', key: 'work_name' },
        { label: 'Wing Name', key: 'wing_name' },
        { label: 'Level Name', key: 'level_name' },
        { label: 'Nature of Flat', key: 'nature_of_flat' },
        { label: 'Parking', key: 'parking' },
        { label: 'Area', key: 'area' },
        { label: 'Enquiry Date', key: 'enquiry_date' },
        { label: 'Contact Mode', key: 'contact_mode' },
        { label: 'Client Response', key: 'clients_response' },
        { label: 'Reminder Date', key: 'reminder_date' },
        { label: 'Lead By', key: 'lead_by' },
        { label: 'Remark', key: 'remark' },
    ];

    const csvData = Array.isArray(enquiryLists)
        ? enquiryLists.map((item, index) => ({
            index: index + 1,
            client_name: item.client_name,
            mobile_no: item.mobile_no,
            email_id: item.email_id,
            address: item.address,
            source: item.source,
            enquiry_for: item.enquiry_for,
            work_name: item.work_name,
            wing_name: item.wing_name,
            level_name: item.level_name,
            nature_of_flat: item.nature_of_flat,
            parking: item.parking,
            area: item.area,
            enquiry_date: item.enquiry_date,
            contact_mode: item.contact_mode,
            clients_response: item.clients_response,
            reminder_date: item.reminder_date,
            lead_by: item.lead_by,
            remark: item.remark,
        }))
        : [];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    function openModal(id) {
        setModalLoader(true)
        setViewModel(true)
        axiosClient.get(`/single_enquiry_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }

    useEffect(() => {
        getEnquiryLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Customer Enquiry'} button={
                    <LinkButton to={'/enquiry/add-enquiry'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add New Enquiry</span>
                    </LinkButton>
                }>
                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                        <div className='grid grid-cols-2 gap-2'></div>
                        <div className=' flex justify-end gap-3'>
                            <CSVLink data={csvData} headers={headers} filename={'Enquiry List.csv'}>
                                <button className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-2 py-2 text-sm font-medium text-white focus-visible:ring-offset-2' ><TableCellsIcon className='w-5 h-5 mr-1' /> Export CSV</button>
                            </CSVLink>
                        </div>
                    </div>
                    <br />
                    {enquiryLists && enquiryLists.length > 0 ? (
                        <Table id='print' tableHeading={tableHeading}>
                            {enquiryLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.id}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{tableData.mobile_no}</td>
                                        <td className="px-4 py-3">{tableData.source}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.enquiry_for}</td>
                                        <td className="px-4 py-3">{moment(tableData.reminder_date).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton
                                                    title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/enquiry/edit-enquiry/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteEnquiry(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Enquires Data Not Found</p>
                        </div>
                    )}
                    {enquiryLists && enquiryLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={enquiryLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && modalData && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Enquiry Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-3'>
                                                <div className="">
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold '>Client Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Mobile No</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.mobile_no}</h1>
                                                    </div>
                                                    <div className=' grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Email Id</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className='break-words'>{modalData.email_id}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Source</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.source}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Enquiry For</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.enquiry_for}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Enquiry Date</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{moment(modalData.enquiry_date).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Work Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Wing Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.wing_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Level Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.level_name}</h1>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Nature of Flat</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Area (sq / ft)</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.area}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Parking</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.parking && ((modalData.parking).split('_')).join(' ')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Contact Mode</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.contact_mode}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Clients Response</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.clients_response}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Reminder Date</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{moment(modalData.reminder_date).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Address</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className='break-words'>{modalData.address}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                        <h1 className='font-bold'>Remark</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.remark}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )
            }
        </Fragment >
    )
}

export default Enquiry
