import React, { Fragment } from 'react'
import { useStateContext } from '../context/ContextProvider'


function PageComponent({ children, title, button, className }) {
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()

    return (
        <Fragment>
            <header>
                <div className={"mx-auto px-4 py-3 sm:px-6 lg:px-8 flex justify-between items-center" + (className)}>
                    <h1 className="text-[4vw] md:text-2xl font-bold tracking-tight text-gray-900">{title}</h1>
                        {button}
                </div>
            </header>
            <main>
                {
                    children ? (
                        <div className="mx-auto px-4 md:px-8">
                            {children}
                        </div>
                    ) : (
                        <div className="bg-default-color h-[100vh]">
                            <div className="h-24 md:h-48"></div>
                            <h1 className='flex justify-center items-center text-3xl font-bold'> </h1>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <div></div>
                                <img src={FRONTEND_URL + '/assets/images/not_found.gif'} className='w-full' alt="loader" />
                                <div></div>
                            </div>
                            <h1 className='flex justify-center text-[#1d475b] items-center text-3xl font-bold'> Page Not Found !</h1>
                        </div>
                    )
                }

            </main>
        </Fragment>
    )
}

export default PageComponent