import React, { useRef, Fragment, useEffect, useState, } from 'react'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import { useReactToPrint } from "react-to-print";
import ActionButton from '../helpers/ActionButton'
import { useStateContext } from '../../context/ContextProvider'
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function TaskDetails() {
    document.title = 'SiteSmart - Task Details'
    const tableHeading = ['Sr.No', 'Work Name', 'Task Name', 'Contractor Name', 'Start Date ', 'Due Date', 'Uploaded Photos', 'Action']
    const tableHeading1 = ['Sr.No', 'Activity Name', ' Deacription', 'Start Date', 'Due Date']
    const [loader, setLoader] = useState(false)
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalData, setModalData] = useState([])
    const [modalData1, setModalData1] = useState([])
    const [taskList, setTaskList] = useState('')
    const [workName, setWorkName] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handleChangeWorkName = (e) => {
        setWorkName(e.target.value)
    }
    const getTaskList = () => {
        setLoader(true)
        axiosClient.get('/task_Details/')
            .then(({ data }) => {
                setLoader(false)
                setTaskList(data.lists)
            })
    }

    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setImgModalLoader(false)
    }

    const getWorkWiseData = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_taskdata/${workName}`)
            .then(({ data }) => {
                setLoader(false)
                setTaskList(data.work_wise_taskdata)
            })
    }
    const deleteTask = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_task/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getTaskList()
                    })
            }
        })
    }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_task_data/${id}`)
            .then(({ data }) => {
                setModalData(data.view_data)
                setModalData1(data.Mulitisubtasks)
                setModalLoader(false)
            })
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    const getAllData = () => {
        workName && setLoader(true)
        workName && workName == 'All' ? (getTaskList()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWorks(data.lists)
                setLoader(false)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getWorkWiseData()
    }, [workName])

    useEffect(() => {
        getAllData();
    }, [workName])

    useEffect(() => {
        getTaskList();
        getWorkData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Task Details'} button={
                    <LinkButton to={'/task-details/add-task-details'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add New Task</span>
                    </LinkButton>}>
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-3'>
                        <div >
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={workName} onChange={handleChangeWorkName} className={'w-full'}>
                                <option disabled value={''}>--- Choose Work Name ---</option>
                                <option value={"All"}>All</option>
                                {works.map((workData, workId) => {
                                    return (<Fragment key={workId}>
                                        <option value={workData.id}>{workData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {taskList && taskList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {taskList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.task_name}</td>
                                        <td className="px-4 py-3">{tableData.contractor_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.start_date).format('DD-MM-YYYY')}</td>
                                        <td className=" py-3">{moment(tableData.due_date).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3 justify-center flex">
                                            {
                                                <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage()} >
                                                    <img src={tableData.upload_bill_copy ? (
                                                        tableData.upload_bill_copy.name ? URL.createObjectURL(tableData.upload_bill_copy) : IMG_URL + "/assets/images/BillcopyFile/" + tableData.upload_bill_copy
                                                    ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="bill-img" className='w-12 h-12 rounded' />
                                                </Link>
                                            }
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link onClick={() => openModal(tableData.id)}><ActionButton className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton></Link>
                                                <Link title='Edit' to={`/task-details/edit-task-details/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteTask(tableData.id)} title={'Delete'} className={'p-1.5  text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[15vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Task Data Not Found</p>
                        </div>
                    )}
                    {taskList && taskList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={taskList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-2 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Task Details</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-1'>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold  '>Work Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.work_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Wing Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.wing_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Level Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.level_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Task Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.task_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Start Date</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{moment(modalData.start_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Due Date</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{moment(modalData.due_date).format('DD-MM-YYYY')}</h1>
                                                </div>

                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold whitespace-nowrap'>Contractor Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.contractor_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Assign To</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Description</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.description}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Status</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.status}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Remarks</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.remark}</h1>
                                                </div>
                                            </div>
                                                <h1 className='font-bold text-lg md:text-xl'>Sub Tasks</h1>
                                        </div>
                                                {modalData1 && modalData1.length > 0 ? (
                                                    <div className='w-[50vw] md:w-full'>
                                                    <Table tableHeading={tableHeading1}>
                                                        {modalData1.map((tableData, tableDataID) => {
                                                            return (<Fragment key={tableDataID}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                    <td className="px-4 py-3">{tableData.activity_name}</td>
                                                                    <td className="px-4 py-3">{tableData.activity_description}</td>
                                                                    <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.activity_start_date).format('DD-MM-YYYY')}</td>
                                                                    <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.activity_due_date).format('DD-MM-YYYY')}</td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                    </div>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[20vh]'>
                                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Sub Tasks Not Found</p>
                                                    </div>
                                                )}
                                        
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-[60%] max-w-2xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Bill Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={modalData.upload_bill_copy ? (
                                                modalData.upload_bill_copy.name ? URL.createObjectURL(modalData.upload_bill_copy) : IMG_URL + `/assets/images/BillcopyFile/` + modalData.upload_bill_copy
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                        }
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}>
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default TaskDetails