import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowDownCircleIcon, EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Dialog, Link, Transition } from '@headlessui/react'
import Button from '../helpers/Button'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ViewModel from '../helpers/ViewModel'

function ClientPaymentReports() {
    document.title = 'SiteSmart - Client Payment '
    const tableHeading = ['Sr.No', 'Date', 'Invoice No.', 'Client Name', 'Total Amount.', 'Due Date', 'Balance Due  ', 'Payment Date', 'Payment 1', ' Payment Date.', 'Payment 2', ' Payment Date', ' Payment 3  ', ' Payment Date.', 'Payment 4', ' Payment Date', ' Payment 5', 'Payment Date']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [agencyLists, setAgencyLists] = useState('')
    const [totalGrossPayment, setTotalGrossPayment] = useState()
    const [modalData, setModalData] = useState([])
    
    const getAgencyLists = () => {
        setLoader(true)
        axiosClient.get('/Contractorpayment_lists')
            .then(({ data }) => {
                setLoader(false)
                setAgencyLists(data.lists)
            })
    }
    const deleteAgency = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Contractorpayment/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getAgencyLists()
                    })
            }
        })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Contractorpayment_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
            })
    }
    useEffect(() => {
        getAgencyLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Client Payment'}>
                    <div className="grid grid-cols-1 md:grid-cols-4">
                        <div className='pr-5'>
                            <Label htmlFor={'date'} labelText={'From Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} />
                            {/* <Validation error={errors.date} /> */}
                        </div>
                        <div className="pl-5">
                            <Label htmlFor={'date'} labelText={'To Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} />
                        </div>
                        <div className="justify-end flex py-5 ml-3 mt-2">
                            <Button className='ml-2' to={'/accounting/add-client-payment'}>
                                <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                                <span>Sort By Site Name</span>
                            </Button>
                        </div>
                        <div className="justify-end flex py-5 ml-3 mt-2">
                            <Button className='ml-2' to={'/accounting/add-client-payment'}>
                                <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                                <span>Sort By Client Name</span>
                            </Button>
                        </div>
                    </div>
                    <br />
                    {agencyLists && agencyLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {agencyLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'24/05/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'1'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'Shweta Patil'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'2500000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'28/09/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'2000000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'24/05/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'500000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'10/06/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'1000000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'15/07/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'200000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'14/08/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'100000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'4/09/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'200000'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'22/09/2023'}</td>
                                    </tr>
                                </Fragment>)
                            })}
                            {/* <tr className="bg-default-color text-gray-900 border-b text-center">
                            <th className="px-4 py-3 whitespace-nowrap">Total</th>
                            <th className="px-4 py-3 font-bold whitespace-nowrap"></th>
                            <th className="px-4 py-3 whitespace-nowrap"></th>
                            <th className="px-4 py-3 whitespace-nowrap"></th>
                            <th className="px-4 py-3 whitespace-nowrap"></th>
                            <th className="px-4 py-3 whitespace-nowrap">3041</th>
                            <th className="px-4 py-3">
                            </th>
                        </tr> */}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Contractor Payment Data Not Found</p>
                        </div>
                    )}
                    {/* View Modal */}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                                    <Fragment>
                                        <div className='flex justify-between items-center gap-4'>
                                            <h1 className='text-center uppercase font-bold text-xl tracking-widest'>Contractor Payment Information</h1>
                                        </div>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Work Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{''}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Work Type</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.work_type}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bill No.</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bill_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bill Date</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bill_date}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bill Gross Amount</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bill_gross_amount}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Tax Amount</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.tax_amount}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>TDS Amount</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.tds_amount}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bill Payable Amount</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bill_payable_amount}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>beneficiary</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.beneficiary}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Unit Based Rate Total Qty</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.unit_based_rate_total_qty}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Unit Based Rate Est Amount</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.unit_based_rate_est_amount}</h1>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}


export default ClientPaymentReports
