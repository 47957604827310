import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../Axios'
import { useStateContext } from '../../context/ContextProvider';
import Swal from 'sweetalert2'
import { LockClosedIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import Button from '../helpers/Button';
import Input from '../helpers/Input';
import Label from '../helpers/Label';
import Validation from '../helpers/Validation';
import CheckBox from '../helpers/CheckBox';
import Loader from '../Common/Loader';
import Cookies from 'js-cookie'

function Login() {
    document.title = 'SiteSmart - Login'
    const [formInfo, setFormInfo] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState([]);
    const { setCurrentUser, setUserToken, FRONTEND_URL } = useStateContext()
    const [loader, setLoader] = useState(false)
    const [remeberMe, setRemeberMe] = useState(false);
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        if (remeberMe === true) {
            Cookies.set('email', formInfo.email)
            Cookies.set('password', formInfo.password)
            Cookies.set('remember', true)
        }
        const formData = new FormData();
        formData.append('email', formInfo.email);
        formData.append('password', formInfo.password);
        axiosClient.post('/login', formData)
            .then(({ data }) => {
                setLoader(false)
                setCurrentUser(data.user)
                setUserToken(data.token)
                Swal.fire({
                    icon: 'success',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
                localStorage.setItem('CURRENT_USERID', data.user_id)
                localStorage.setItem('CURRENT_USERNAME', data.user_name)
                localStorage.setItem('CURRENT_EMAIL', data.user_email)
                localStorage.setItem('CURRENT_MOBILE', data.user_mobile)
                localStorage.setItem('CURRENT_ADDRESS', data.user_address)
                localStorage.setItem('USER_ROLE', data.user.role)
                localStorage.setItem('CURRENT_PROFILEIMAGE', data.profile_image)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    useEffect(() => {
        if (Cookies.get('email') !== null) {
            setFormInfo(Cookies.get())
            setRemeberMe(Cookies.get('remember'))
        }
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <div className="flex w-full md:w-[35%] items-center px-4 py-12 sm:px-6 lg:px-8 absolute">
                    <div className="w-full space-y-2 pt-4 pb-6 px-6 rounded-lg">
                        <div>
                            <img className="mx-auto w-auto" src={FRONTEND_URL + "/assets/logos/logo1.png"} alt="Your Company" />
                            <h2 className="text-center text-2xl text-white uppercase font-extrabold tracking-widest">Login</h2>
                            {/* <p className="mt-2 text-center text-white">
                                Or{' '}
                                <Link to="/register" className="font-medium text-white hover:text-indigo-500">
                                    Create new account
                                </Link>
                            </p> */}
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="rounded-md shadow-sm">
                                <div className='mb-3'>
                                    <Label labelText={'Email Addess'} htmlFor='email' className={'mb-1 text-white font-medium'} />
                                    <Input type={'email'} value={formInfo.email} name={'email'} id={'email'} onChange={handleChange} />
                                    <Validation error={errors.email} />
                                </div>
                                <div className='mb-3'>
                                    <Label labelText={'Password'} htmlFor='password' className={'mb-1 text-white font-medium'} />
                                    <div className='relative'>
                                        <Input type={passwordType} value={formInfo.password} name={'password'} id={'password'} onChange={handleChange} />
                                        {passwordType === 'password' ? (
                                            <EyeIcon className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword} />
                                        ) : (
                                            <EyeSlashIcon className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword} />
                                        )}
                                    </div>
                                    <Validation error={errors.password} />
                                </div>
                            </div>
                            <div className="flex items-center justify-between pb-6">
                                <div className="flex items-center">
                                    <CheckBox id={'remeber-me'} name={'remember_me'} type={'checkbox'} onClick={(e) => setRemeberMe(e.target.checked)} checked={remeberMe} />
                                    <Label labelText={'Remember Me'} htmlFor={'remeber-me'} className={'text-white font-medium'} />
                                </div>
                                <div>
                                    <Link to="/forget-password" className="font-medium text-white">
                                        Forgot your password?
                                    </Link>
                                </div>
                            </div>
                            <Button className={'w-full'}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-default-color" aria-hidden="true" />
                                </span>
                                Login Now
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default Login