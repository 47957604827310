import React, { Fragment, useRef, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Menu, Transition } from '@headlessui/react'
import Button from '../helpers/Button'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import { CSVLink } from 'react-csv'
import ViewModel from '../helpers/ViewModel'
import Input from '../helpers/Input'
import Label from '../helpers/Label'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function QAQCMaster() {
    document.title = 'SiteSmart - Task Master'
    const tableHeading = ['Sr.No', 'Task Name', 'Action']
    const [loader, setLoader] = useState(false)
    const [qaqcLists, setQaqcLists] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [csvFile, setCsvFile] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [ViewCsv, setViewCsv] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const getQaqcLists = () => {
        setLoader(true)
        axiosClient.get('/show_task_master')
            .then(({ data }) => {
                setLoader(false)
                setQaqcLists(data.lists.sort((a, b) => a.task_name.localeCompare(b.task_name)));
                // setQaqcLists(data.lists)
            })
    }

    const deleteQaqc = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_qaqc_master/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getQaqcLists()
                    })
            }
        })
    }
    const [isOpen, setIsOpen] = useState(false)
    const [modalDataSingle, setModalDataSingle] = useState([])
    const [modalDataMulti, setModalDataMulti] = useState([])
    function closeModal() { setIsOpen(false) }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_qaqc_master/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalDataSingle(data.single_data)
                setModalDataMulti(data.Mulitiqaqc_checkpoints)
            })
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Task Name', key: 'task_name' },
    ];

    const handleCsvFile = () => {
        setLoader(true)
        axiosClient.post(`import_material`, csvFile)
            .then(({ data }) => {
                flasher.success(data.msg)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
        setViewCsv(false)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        getQaqcLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'QA/QC'} >
                    <div className="flex justify-end mb-2 md:text-[1vw] text-[2vw]">
                        <Menu as="div" className="">
                            <div className="flex justify-end pt-2">
                                <Menu.Button>
                                    <span className="sr-only">Open user menu</span>
                                    <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                </Menu.Button>
                            </div>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink onClick={() => setViewCsv(true)} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="px-2 py-2">
                                            <CSVLink data={qaqcLists} headers={headers} filename={'Level List.csv'}>
                                                <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                            </CSVLink>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {qaqcLists && qaqcLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {qaqcLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.task_name}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/qaqc-master/edit-qaqc-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                {/* <ActionButton onClick={() => deleteQaqc(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton> */}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! QA-QC Data Not Found</p>
                        </div>
                    )}
                    {qaqcLists && qaqcLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={qaqcLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>QA/QC Master</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-2  py-2'>
                                            <h1 className='w-36 font-bold'>Task Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalDataSingle.task_name}</h1>
                                        </div>
                                        <div className='flex items-center gap-4  py-2'>
                                            <h1 className='w-36 font-bold'>QAQC Check Points</h1>
                                            <h1>-</h1>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                                            {
                                                modalDataMulti.map((data) => {
                                                    return (
                                                        <h1 className='bg-primary-color text-white p-2 mb-2 rounded-md'>{data.qaqc_checkpoints}</h1>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className=" rounded-md bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel()} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        ViewCsv && <ViewModel viewModel={ViewCsv} setViewModel={setViewCsv} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Import CSV File</h1>
                                    <hr className='my-4 border border-primary-color' />
                                    <h1 className='text-lg font-bold'>Note :-</h1>
                                    <div className='m-y-4 pl-5'>
                                        <code className='block'><span className='font-semibold'>1.</span>  The Excel File must include 1 column</code>
                                        <code className='block'><span className='font-semibold'>2.</span>  Column heading should be "task_name"</code>
                                        <code className='block'><span className='font-semibold'>3.</span>  QA/QC Checkpoints need to be add manually</code>
                                        <code className='block'><span className='font-semibold'> 4.</span> Serial No. column is optional.</code>
                                        <code className='block'><span className='font-semibold'> 5.</span> The file format should be .csv or .xls</code>
                                    </div>
                                    <Input id={'csvFile'} type={'file'} name={'csvFile'} onChange={(e) => setCsvFile(e.target.files[0])} className={'p-[0px!important] mt-2 mb-2'} />
                                    <div className="mt-4 grid grid-cols-3">
                                        <Button onClick={() => handleCsvFile()} className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default QAQCMaster