import React, { Fragment } from 'react'

function Button({children,className,title,onClick}) {
    return (
        <Fragment>
            <button type="submit" className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "+(className)} title={title} onClick={onClick}>
                {children}
            </button>
        </Fragment>
    )
}

export default Button