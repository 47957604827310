// ReportsMenu.js

import React, { Fragment } from "react";
import * as Icons from "@heroicons/react/24/solid";
import { NavLink } from 'react-router-dom';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

const Nav = ({to, open,Icon,Title }) => {
  const SelectedIcon = Icons[Icon];
  return (
    <Fragment>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(
            isActive && "bg-primary-color text-default-color",
            "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 " +
              (open ? "pl-2 md:pl-4" : "pl-4")
          )
        }
      >
        {open ? (
          <SelectedIcon className="w-5 h-5" title={Title} />
        ) : (
          <Fragment>
            <SelectedIcon className="w-5 h-5" />
            <span>{Title}</span>
          </Fragment>
        )}
      </NavLink>
    </Fragment>
  );
}

export default Nav;
