import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Transition } from '@headlessui/react'
import ActionButton from '../../helpers/ActionButton'
import PleaseWait from '../../Common/PleaseWait'

function AccountLedgerMaster() {
    document.title = 'SiteSmart - Ledger'
    const tableHeading = ['Sr.No', 'Ledger Name', 'Sub Ledger', 'Description',  'Action']
    const [loader, setLoader] = useState(false)
    const [voucherList, setVoucherList] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [modalDataMulti, setModalDataMulti] = useState([])

    const getVoucherList = () => {
        setLoader(true)
        axiosClient.get('/Accountledger_lists')
            .then(({ data }) => {
                setLoader(false)
                setVoucherList(data.lists)
            })
    }

    const deleteVoucher = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_vouchermaster/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getVoucherList()
                    })
            }
        })
    }

    const [isOpen, setIsOpen] = useState(false)
    const [modalData, setModalData] = useState([])
    function closeModal() { setIsOpen(false) }
    function openModal(id) {
        setIsOpen(true)
        setModalLoader(true)
        axiosClient.get(`/single_Accountledger_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
                setModalDataMulti(data.Multigroups)
            })
    }

    useEffect(() => {
        getVoucherList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Ledger Master'} button={
                    <LinkButton to={'/accounting/account-ledger/add-account-ledger'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Ledger</span>
                    </LinkButton>
                }>
                    <h1>{voucherList.sub_task}</h1>
                    {voucherList && voucherList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {voucherList.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.ledger_name}</td>
                                        <td className="px-4 py-3">{tableData.sub_ledger}</td>
                                        <td className="px-4 py-3">{tableData.description}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/accounting/account-ledger/edit-account-ledger/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteVoucher(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Ledger Master Data Not Found</p>
                        </div>
                    )}
                    <Transition appear show={isOpen} as={Fragment} >
                        <Dialog as="div" className="relative z-50" onClose={closeModal}>
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>
                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                        <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                            {modalLoader ? (<PleaseWait />) : (
                                                <Fragment>
                                                        <h1 className='text-center uppercase font-bold text-xl tracking-widest'>Ledger Information</h1>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <div className='flex items-center gap-2  py-2'>
                                                        <h1 className='w-36 font-bold'>Ledger Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.ledger_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2  py-2'>
                                                        <h1 className='w-36 font-bold'>Sub Ledger</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.sub_ledger}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2  py-2'>
                                                        <h1 className='w-36 font-bold'>Description</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.description}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-2'> 
                                                        <h1 className='w-36 font-bold'>Group List</h1> 
                                                        <h1>-</h1> 
                                                    </div> 
                                                    <div className='grid grid-cols-1 md:grid-cols-3 gap-3'> 
                                                        { 
                                                            modalDataMulti.map((data) => { 
                                                                return ( 
                                                                    <h1 className='bg-primary-color text-white p-2 rounded-md flex justify-center'>{data.group_name}</h1> 
                                                                ) 
                                                            }) 
                                                        } 
                                                    </div>
                                                    <div className="mt-3">
                                                        <button
                                                            type="button"
                                                            className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={closeModal} >
                                                            Got it, thanks!
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default AccountLedgerMaster
