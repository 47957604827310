import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, Bars4Icon } from '@heroicons/react/24/outline'
import { ChartPieIcon, ChevronUpIcon, ChevronDownIcon, Squares2X2Icon, ScaleIcon, UsersIcon, WrenchScrewdriverIcon, ShoppingBagIcon, BanknotesIcon, CircleStackIcon, UserGroupIcon, BuildingOffice2Icon, UserCircleIcon, UserPlusIcon, Square3Stack3DIcon, ClipboardDocumentCheckIcon, ClipboardIcon, CurrencyRupeeIcon, ClipboardDocumentListIcon, ArrowsUpDownIcon } from '@heroicons/react/24/solid'
import { ListBulletIcon, PhoneArrowUpRightIcon, PlusCircleIcon, StarIcon, ClipboardDocumentIcon, BuildingLibraryIcon, TruckIcon, XCircleIcon, PencilSquareIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link, NavLink, Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'
import axiosClient from '../Axios'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import Toggle from './Toggle'
import Nav from './Nav'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainLayout() {
    const { userToken, setCurrentUser, setUserToken, FRONTEND_URL, IMG_URL } = useStateContext()
    const [accountingmasterToggle, setAccountingmasterToggle] = useState(false);
    const [accountingtransactionToggle, setAccountingtransactionToggle] = useState(false);
    const [preSalesToggle, setPreSalesToggle] = useState(false);
    const [postSalesToggle, setPostSalesToggle] = useState(false);
    const [contractorPaymentToggle, setContractorPaymentToggle] = useState(false);
    const [contractorreportToggle, setContractorreportToggle] = useState(false);
    const [userRole, SetUserRole] = useState(localStorage.getItem('USER_ROLE'));
    const [userImg, SetUserImg] = useState(localStorage.getItem('CURRENT_PROFILEIMAGE'));
    const [loader, setLoader] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [notification, setNotification] = useState([]);
    const [toggleFun, setToggleFun] = useState({
        master: false,
        workcontract: false,
        qaqc: false,
        taskmanagement: false,
        contractormanagent: false,
        purchase: false,
        stock: false,
        crm: false,
        hrandpayroll: false,
        accounting: false,
        reports: false,
    });

    const handleToggleFun = (e, v) => {
        setToggleFun({
            [e]: v,
        });
    };

    const ToggleNotification = (status) => {
        setShowNotification(status);
    };

    const accountingmasterToggleFun = () => {
        setAccountingmasterToggle(current => !current)
        setAccountingtransactionToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            accounting: true
        }));
    }
    const accountingtransactionToggleFun = () => {
        setAccountingtransactionToggle(current => !current)
        setAccountingmasterToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            accounting: true
        }));
    }

    const postSalesToggleFun = () => {
        setPostSalesToggle(current => !current)
        setPreSalesToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            crm: true
        }));
    }
    const preSalesToggleFun = () => {
        setPreSalesToggle(current => !current)
        setPostSalesToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            crm: true
        }));
    }

    const contractorPaymentToggleFun = () => {
        setContractorPaymentToggle(current => !current)
        setContractorreportToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            contractormanagent: true
        }));

    }
    const contractorreporToggleFun = () => {
        setContractorreportToggle(current => !current)
        setContractorPaymentToggle(false)
        setToggleFun(prevState => ({
            ...prevState,
            contractormanagent: true
        }))
    }
    // const Date = new Date();
    // const [date, setDate] = useState(Date.getDate());
    // setDate(Date.getDate())


    const logout = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure to logout?',
            showCancelButton: true,
            confirmButtonText: 'Yes! Sure',
            confirmButtonColor: 'red'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/logout')
                    .then(({ data }) => {
                        setCurrentUser({})
                        setUserToken(null)
                        Swal.fire({
                            icon: 'success',
                            title: data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        localStorage.clear()
                    })
            }
        })
    }

    const getNotificationsList = () => {
        setLoader(true)
        axiosClient.get(`/userwise_notification_data/${localStorage.getItem('CURRENT_USERID')}`)
            .then(({ data }) => {
                setLoader(false)
                setNotification(data.userwise_notification_data)
                setNotificationList(data.letestuserwise_notification_data)
            })
    }


    const userNavigation = [
        { name: 'Your Profile', href: '/your-profile' },
        { name: 'Change Password', href: '/change-password' },
        { name: 'Sign out', clickFuntion: logout },
    ]

    useEffect(() => {
        getNotificationsList();
    }, [])

    let unread = []

    useEffect(() => {
        const intervalId = setInterval(() => {
            notification.map((data) => {
                return (
                    data.status == 'unread' && (
                        flasher.success(data.message),
                        unread.push(data.id)
                    )
                )
            })
            let payload = {
                unread: unread
            }
            axiosClient.post(`update_unreadNotification/${localStorage.getItem('CURRENT_USERID')}`, payload)
                .then(({ data }) => {
                    data.status && getNotificationsList();
                    unread = []
                })
                .catch(({ response }) => {
                    console.log(response.data.errors)
                })
        }, 10000);
        return () => {
            clearInterval(intervalId);
        };
    }, [notification]);

    if (!userToken) { return (<Navigate to="login" />) }

    return (
        <Fragment>
            <Disclosure as="nav">
                {({ open }) => (
                    <Fragment>
                        <div onClick={() => ToggleNotification(false)} className='flex h-screen relative text-sm'>
                            <div className='flex h-screen relative text-sm'>
                                <div className={'bg-gray-800 border-r border-gray-300 overflow-y-auto text-white h-full fixed z-50  ' + (open ? 'w-[13%]  md:w-[5%]' : 'w-[50%] md:w-[40%] lg:w-[17%]')}>
                                    <div className='sticky top-0 z-10 bg-gray-800 '>
                                        <NavLink to={'/'} >
                                            {open ? (<ChartPieIcon className='h-6 w-6 mx-auto' title='Dashboard' />) : (
                                                <div className='flex items-center gap-2'>
                                                    {open ? (
                                                        <img className="mx-auto h-10 w-auto" src={FRONTEND_URL + "/assets/logos/smalllogo.png"} alt="Your Company" />
                                                    ) : (
                                                        <img className="mx-auto h-24 w-auto" src={FRONTEND_URL + "/assets/logos/logo1.png"} alt="Your Company" />
                                                    )}
                                                </div>
                                            )}
                                        </NavLink>
                                        <div className='flex justify-center '>
                                            <hr className='w-[85%] border border-white' />
                                        </div>
                                    </div>

                                   {/*Start masters */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager' || userRole === 'Purchase_Officer') && (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="master" Icon="Squares2X2Icon" Title={"Masters"} />
                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.master ? 'block' : 'hidden')}>
                                                    <li>
                                                        <Nav to={"/material-master"} open={open} Icon={"WrenchScrewdriverIcon"} Title={'Material Master'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/worktype-master"} open={open} Icon={"WrenchScrewdriverIcon"} Title={'Work Type Master'} />
                                                    </li>
                                                    {
                                                        (userRole === 'Admin' || userRole === 'Purchase_Officer') &&
                                                        (<li>
                                                            <Nav to={"/contractor-master"} open={open} Icon={"TruckIcon"} Title={'Contractor Master'} />
                                                        </li>)
                                                    }
                                                    <li>
                                                        <Nav to={"/task-master"} open={open} Icon={"ClipboardDocumentIcon"} Title={'Task Master'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/qaqc-master"} open={open} Icon={"ClipboardDocumentCheckIcon"} Title={'QA/QC'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/level-master"} open={open} Icon={"Bars4Icon"} Title={'Level Master'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/company-master"} open={open} Icon={"BuildingLibraryIcon"} Title={'Company Master'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/supplier-master"} open={open} Icon={"UserCircleIcon"} Title={'Supplier Master'} />
                                                    </li>
                                                    {
                                                        (userRole === 'Admin') && (
                                                            <li>
                                                                <Nav to={"/user-master"} open={open} Icon={"UsersIcon"} Title={'Users Master'} />
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Masters */}

                                    {/* Start Work Contracts */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager') &&
                                        (<div>
                                            <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="workcontract" Icon="ListBulletIcon" Title={"Work Contracts"} />
                                            <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.workcontract ? 'block' : 'hidden')}>
                                                <li>
                                                    <Nav to={"/work-management"} open={open} Icon={"BuildingOffice2Icon"} Title={'Work Management'} />
                                                </li>
                                            </ul>
                                        </div>)
                                    }
                                    {/* End Work Contracts */}

                                    {/* Start QA/QC */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="qaqc" Icon="ClipboardDocumentCheckIcon" Title={"QA/QC Management"} />
                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.qaqc ? 'block' : 'hidden')}>
                                                    <li>
                                                        <Nav to={"/qa-qc"} open={open} Icon={"CheckCircleIcon"} Title={'QA/QC'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/qa-qc-report"} open={open} Icon={"ClipboardIcon"} Title={'QA/QC Report'} />
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End QA/QC */}

                                    {/* Start Task Management */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="taskmanagement" Icon="UserPlusIcon" Title={"Task Management"} />
                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.taskmanagement ? 'block' : 'hidden')}>
                                                    <li>
                                                        <Nav to={"/task-details"} open={open} Icon={"WrenchScrewdriverIcon"} Title={'Task Details'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/task-activity/dprdetails"} open={open} Icon={"WrenchScrewdriverIcon"} Title={'DPR Details'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/task-reports"} open={open} Icon={"ClipboardIcon"} Title={'Task Report'} />
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                     {/* End Task Management */}

                                     {/* Start Contractor Management  */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager' || userRole === 'Accountant') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="contractormanagent" Icon="UserCircleIcon" Title={"Contractor Management"} />

                                                <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.contractormanagent ? 'block' : 'hidden')}>
                                                    {
                                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager') &&
                                                        (
                                                            <li>
                                                                <Nav to={"/contractorwork"} open={open} Icon={"PlusCircleIcon"} Title={'Contractor Work'} />
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        (userRole === 'Admin' || userRole === 'Accountant') &&
                                                        (
                                                            <li>
                                                                <div>
                                                                    <button onClick={() => contractorPaymentToggleFun()} className="flex items-center w-full p-2  text-white transition duration-75 rounded-lg group mt-1">
                                                                        {open ? (
                                                                            <Fragment>
                                                                                <CurrencyRupeeIcon className='h-6 w-6 mx-auto' title='Contractor Payment' />
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <CurrencyRupeeIcon className='h-6 w-6' />
                                                                                <span className="flex-1 ml-3 text-left whitespace-nowrap">Contractor Payment</span>
                                                                                {contractorPaymentToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                            </Fragment>
                                                                        )}
                                                                    </button>
                                                                    <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (contractorPaymentToggle ? 'block' : 'hidden')}>
                                                                        <li>
                                                                            <Nav to={"/Contractorbillcreation"} open={open} Icon={"PlusCircleIcon"} Title={'Bill Creation'} />
                                                                        </li>
                                                                        <li>
                                                                            <Nav to={"/transactiondetails"} open={open} Icon={"PlusCircleIcon"} Title={'Transaction Details'} />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    {
                                                        (userRole === 'Admin') &&
                                                        (
                                                            <div className="">
                                                                <button onClick={() => contractorreporToggleFun()} className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg group mt-1">
                                                                    {open ? (
                                                                        <Fragment>
                                                                            <ClipboardIcon className='h-6 w-6 mx-auto' title='Contractor Reports' />
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <ClipboardIcon className='h-6 w-6' />
                                                                            <span className="flex-1 ml-3 text-left whitespace-nowrap">Contractor Reports</span>
                                                                            {contractorreportToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                        </Fragment>
                                                                    )}
                                                                </button>
                                                                <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (contractorreportToggle ? 'block' : 'hidden')}>
                                                                    <li>
                                                                        <Nav to={"/contractorbill-report"} open={open} Icon={"ClipboardIcon"} Title={'Contractor Bill Report'} />
                                                                    </li>
                                                                    <li>
                                                                        <Nav to={"/contractorpayment-report"} open={open} Icon={"ClipboardIcon"} Title={'Contractor Payment Report'} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Contractor Management */}

                                    {/* Start Purchase */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Purchase_Officer' || userRole === 'Project_Manager' || userRole === 'Accountant') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="purchase" Icon="ShoppingBagIcon" Title={"Purchase"} />
                                                <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.purchase ? 'block' : 'hidden')}>
                                                    {
                                                        (userRole === 'Admin' || userRole === 'Purchase_Officer' || userRole === 'Project_Manager') &&
                                                        (
                                                            <div className='space-y-2'>
                                                                <li>
                                                                    <Nav to={"/purchase-requisition"} open={open} Icon={"ShoppingBagIcon"} Title={'Purchase Requisition'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/quotation"} open={open} Icon={"ShoppingBagIcon"} Title={'Quotation'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/purchase-order"} open={open} Icon={"ShoppingBagIcon"} Title={'Purchase Order'} />
                                                                </li>
                                                            </div>
                                                        )
                                                    }

                                                    {/* {
                                                        (userRole === 'Admin' || userRole === 'Purchase_Officer' || userRole === 'Accountant') &&
                                                        (
                                                            <li>
                                                                <Nav to={"/purchase-bill"} open={open} Icon={"ShoppingCartIcon"} Title={'Purchase Bill Payment'}/>
                                                            </li>
                                                        )
                                                    } */}
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Purchase */}

                                    {/* Start Stock */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Supervisor' || userRole === 'Project_Manager') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="stock" Icon="CircleStackIcon" Title={"Stock"} />

                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.stock ? 'block' : 'hidden')}>
                                                    <li>
                                                        <Nav to={"/stock-inward"} open={open} Icon={"Square3Stack3DIcon"} Title={'Stock Inward'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/inward/pending-stock"} open={open} Icon={"Square3Stack3DIcon"} Title={'Pending Stock'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/stock-consumed"} open={open} Icon={"Square3Stack3DIcon"} Title={'Stock Consumed'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/current-stock"} open={open} Icon={"Square3Stack3DIcon"} Title={'Current Stock'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/stock-transfer"} open={open} Icon={"Square3Stack3DIcon"} Title={'Stock Transfer'} />
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Stock */}

                                    {/* Start CRM */}
                                    {
                                        (userRole === 'Admin' || userRole === 'CRM_Manager' || userRole === 'Project_Manager') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="crm" Icon="StarIcon" Title={"CRM"} />
                                                <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.crm ? 'block' : 'hidden')}>
                                                    <li>
                                                        <div>
                                                            <button onClick={() => preSalesToggleFun()} className="flex items-center w-full p-1 md:p-2 text-white transition duration-75 rounded-lg group mt-1">
                                                                {open ? (
                                                                    <Fragment>
                                                                        <CurrencyRupeeIcon className='h-6 w-6 mx-auto' title='Pre Sales' />
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <CurrencyRupeeIcon className='h-6 w-6' />

                                                                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Pre Sales</span>
                                                                        {preSalesToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                    </Fragment>
                                                                )}
                                                            </button>
                                                            <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (preSalesToggle ? 'block' : 'hidden')}>
                                                                <li>
                                                                    <Nav to={"/enquiry"} open={open} Icon={"ClipboardIcon"} Title={'Enquiry'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/follow_up"} open={open} Icon={"PhoneArrowUpRightIcon"} Title={'Followup Details'}/>
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/post-sales/deal-registration"} open={open} Icon={"PlusCircleIcon"} Title={'Deal Registration'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/crm/lead-closed"} open={open} Icon={"XCircleIcon"} Title={'Lead Closed'} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <button onClick={() => postSalesToggleFun()} className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg group mt-1">
                                                                {open ? (
                                                                    <Fragment>
                                                                        <CurrencyRupeeIcon className='h-6 w-6 mx-auto' title='Post Sales' />
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <CurrencyRupeeIcon className='h-6 w-6' />
                                                                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Post Sales</span>
                                                                        {postSalesToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                    </Fragment>
                                                                )}
                                                            </button>
                                                            <ul className={"py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (postSalesToggle ? 'block' : 'hidden')}>
                                                                <li>
                                                                    <Nav to={"/accounting/client-payment"} open={open} Icon={"BanknotesIcon"} Title={'Payment'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/post-sales/add-legal-agreement"} open={open} Icon={"PencilSquareIcon"} Title={'Legal Agreement'} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End CRM */}

                                    {/* Start HR & Payroll */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Accountant') &&
                                        (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="hrandpayroll" Icon="UserGroupIcon" Title={"HR & Payroll"} />
                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.hrandpayroll ? 'block' : 'hidden')}>
                                                    {
                                                        (userRole === 'Admin') &&
                                                        (
                                                            <li>
                                                                <Nav to={"/employee-details"} open={open} Icon={"UsersIcon"} Title={'Employee Details'} />
                                                            </li>
                                                        )
                                                    }
                                                    <li>
                                                        <Nav to={"/advance-details"} open={open} Icon={"CurrencyRupeeIcon"} Title={'Employee Advance'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/salarymanagement"} open={open} Icon={"CurrencyRupeeIcon"} Title={'Salary Management'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/labour-advance-details"} open={open} Icon={"CurrencyRupeeIcon"} Title={'Labour Advance'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/daily-wages"} open={open} Icon={"ClipboardDocumentListIcon"} Title={'Daily Wages Details'} />
                                                    </li>
                                                    {
                                                        (userRole === 'Admin') &&
                                                        (
                                                            <li>
                                                                <Nav to={"/daily-attendance-report"} open={open} Icon={"ClipboardDocumentListIcon"} Title={'Daily Attendance Report'} />
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End HR & Payroll */}

                                    {/* Start Accounting */}
                                    {
                                        (userRole === 'Admin' || userRole === 'Accountant') &&
                                        (
                                            <div>
                                                {/* <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="accounting" Icon="CurrencyRupeeIcon" Title={"Accounting"} /> */}
                                                <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.accounting ? 'block' : 'hidden')}>
                                                    <li>
                                                        <div>
                                                            <button onClick={() => accountingmasterToggleFun()} className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg group mt-1">
                                                                {open ? (
                                                                    <Fragment>
                                                                        <Squares2X2Icon className='h-6 w-6 mx-auto' title='Accounting Master' />
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <Squares2X2Icon className='h-6 w-6' />
                                                                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Accounting Masters</span>
                                                                        {accountingmasterToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                    </Fragment>
                                                                )}
                                                            </button>
                                                            <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (accountingmasterToggle ? 'block' : 'hidden')}>
                                                                <li>
                                                                    <Nav to={"/accounting/bank-master"} open={open} Icon={"UserGroupIcon"} Title={'Account Master'} />
                                                                </li>
                                                                {/* <li>
                                                                    <Nav to={"/supplier-master"} open={open} Icon={"UserGroupIcon"} Title={'Cash Master '}/>
                                                                </li> */}
                                                                <li>
                                                                    <Nav to={"/accounting/cash-master"} open={open} Icon={"UserGroupIcon"} Title={'Groups Master'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/accounting/account-ledger"} open={open} Icon={"PlusCircleIcon"} Title={'Ledgers Master'} />
                                                                </li>
                                                                {/* <li>
                                                                    <Nav to={"/accounting/account-group"} open={open} Icon={"UserGroupIcon"} Title={'Profit/Loss Master'}/>
                                                                </li> */}
                                                                <li>
                                                                    <Nav to={"/accounting/voucher-master"} open={open} Icon={"UserGroupIcon"} Title={'Voucher Master'} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <button onClick={() => accountingtransactionToggleFun()} className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg group mt-1">
                                                                {open ? (
                                                                    <Fragment>
                                                                        <ArrowsUpDownIcon className='h-6 w-6 mx-auto' title='Transactions' />
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <ArrowsUpDownIcon className='h-6 w-6' />
                                                                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Transactions</span>
                                                                        {accountingtransactionToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                                                    </Fragment>
                                                                )}
                                                            </button>
                                                            <ul className={"md:py-1.5 md:px-2 space-y-2 text-white bg-gray-600 rounded-md " + (accountingtransactionToggle ? 'block' : 'hidden')}>
                                                                <li>
                                                                    <Nav to={"/accounting/bank-transaction"} open={open} Icon={"UserGroupIcon"} Title={'Account Transaction'} />
                                                                </li>
                                                                {/* <li>
                                                                    <Nav to={"/accounting/cash-transaction"} open={open} Icon={"UserGroupIcon"} Title={'Cash Transaction'}/>
                                                                </li> */}
                                                                <li>
                                                                    <Nav to={"/accounting/account-voucher"} open={open} Icon={"UserGroupIcon"} Title={'Voucher'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/accounting/account-ledgerreport"} open={open} Icon={"UserGroupIcon"} Title={'Ledger Report'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/balancesheet"} open={open} Icon={"ClipboardIcon"} Title={'Balance Sheet'} />
                                                                </li>
                                                                <li>
                                                                    <Nav to={"/profitlossreport"} open={open} Icon={"ClipboardIcon"} Title={'Profit Loss Report'} />
                                                                </li>
                                                                {/* <li>
                                                                    <Nav to={"/accounting/account-rawfieldone"} open={open} Icon={"UserGroupIcon"} Title={'Indirect Income'}/>
                                                                </li> */}
                                                                {/* <li>
                                                                    <Nav to={"/accounting/account-rawfieldtwo"} open={open} Icon={"UserGroupIcon"} Title={'Sales'}/>
                                                                </li> */}
                                                                {/* <li>
                                                                    <Nav to={"/accounting/account-rawfieldthree"} open={open} Icon={"UserGroupIcon"} Title={'Direct Expense'}/>
                                                                </li> */}
                                                                <li>
                                                                    <Nav to={"/accounting/client-payment"} open={open} Icon={"BanknotesIcon"} Title={'Client Payment'} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Accounting */}

                                    {/* Start Reports */}
                                    {
                                        (userRole === 'Admin') && (
                                            <div>
                                                <Toggle open={open} toggleFun={toggleFun} handleToggleFun={handleToggleFun} moduleName="reports" Icon="ClipboardIcon" Title={"Reports"} />
                                                <ul className={"py-1.5 px-2 space-y-2 text-white bg-gray-700 rounded-md " + (toggleFun.reports ? 'block' : 'hidden')}>
                                                    <li>
                                                        <Nav to={"/employee-details"} open={open} Icon={"ClipboardIcon"} Title={' Employee Details Reports'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/salarymanagement"} open={open} Icon={"ClipboardIcon"} Title={' Salary Management Reports'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/daily-wages"} open={open} Icon={"ClipboardIcon"} Title={'Daily Wages Reports'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/qa-qc-report"} open={open} Icon={"ClipboardIcon"} Title={'QA/QC Report'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/task-reports"} open={open} Icon={"ClipboardIcon"} Title={'Task Report'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/contractorbill-report"} open={open} Icon={"ClipboardIcon"} Title={'Contractor Bill Report'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/contractorpayment-report"} open={open} Icon={"ClipboardIcon"} Title={'Contractor Payment Report'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/purchase-order"} open={open} Icon={"ClipboardIcon"} Title={'Purchase Order Reports'} />
                                                    </li>
                                                    {/* <li>
                                                        <Nav to={"/PurchaseBillPaymentReports"} open={open} Icon={"ClipboardIcon"} Title={'Purchase Bill Payment Reports'}/>
                                                    </li>  */}
                                                    <li>
                                                        <Nav to={"/stock-consumed"} open={open} Icon={"ClipboardIcon"} Title={'Stock Consumed Reports'} />
                                                    </li>
                                                    <li>
                                                        <Nav to={"/stock-transfer"} open={open} Icon={"ClipboardIcon"} Title={'Stock Transfer Reports'} />
                                                    </li>
                                                    {/* <li>
                                                        <Nav to={"/contractorpayment-report"} open={open} Icon={"ClipboardIcon"} Title={'Contractor Payment Reports'}/>
                                                    </li> */}
                                                    <li>
                                                        <Nav to={"/clientPayment"} open={open} Icon={"ClipboardIcon"} Title={'Client Payment Reports'} />
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                    {/* End Reports */}
                                </div>
                            </div>
                            <div className={open ? 'w-[87%] md:w-[95%] ml-[13%] md:ml-[5%]' : 'w-[100%] md:w-[83%] md:ml-[17%]'}>
                                <div className="mx-auto px-4 sm:px-6 lg:px-8 bg-white shadow-xl">
                                    <div className="flex h-16 items-center justify-end md:justify-between">
                                        <div className="md:-ml-4 hidden md:flex">
                                            <Disclosure.Button className="inline-flex items-center justify-center rounded bg-gray-800 p-1.5 text-white">
                                                <span className="sr-only">Open main menu</span>
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            </Disclosure.Button>
                                        </div>
                                        <div className='flex items-center'>
                                            <div className="flex justify-end">
                                                <button onMouseOver={() => ToggleNotification(true)} id="dropdownNotificationButton" data-dropdown-toggle="dropdownNotification" class="inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400" type="button">
                                                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                                                        <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                                                    </svg>
                                                    <div class="relative flex">
                                                        <div class="relative inline-flex w-3 h-3 bg-red-500 border-2 border-white rounded-full -top-2 right-3 dark:border-gray-900"></div>
                                                    </div>
                                                </button>
                                            </div>
                                            {/* <div className="flex  justify-end mr-2">
                                                <button onMouseOver={() => ToggleNotification(true)} id="dropdownNotificationButton" data-dropdown-toggle="dropdownNotification" class="inline-flex relative p-1 items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400" type="button">
                                                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                                                        <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                                                    </svg>
                                                    <div class="">
                                                        <div class="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold  text-white bg-red-500 border-2 border-white rounded-full top-0 right-0 dark:border-gray-900">5</div>
                                                    </div>
                                                </button>
                                            </div> */}
                                            <p className='text-gray-900'>{localStorage.getItem('CURRENT_USERNAME')}</p>
                                            <br />
                                            <Menu as="div" className="relative mx-3">
                                                <div>
                                                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-primary-color text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-color">
                                                        <span className="sr-only">Open user menu</span>
                                                        <img src={userImg ? (
                                                            userImg.name ? URL.createObjectURL(userImg) : IMG_URL + "/assets/images/UserImages/" + userImg
                                                        ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="user-img" className='w-12 h-12 rounded-full' />
                                                    </Menu.Button>
                                                </div>
                                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({ active }) => (
                                                                    <NavLink to={item.href} onClick={item.clickFuntion} className={classNames(active ? 'bg-gray-200' : '', 'block px-4 py-2 text-sm text-gray-700')}>{item.name}</NavLink>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>

                                        <div className="-ml-1 flex md:hidden">
                                            <Disclosure.Button className="inline-flex items-center justify-center rounded bg-gray-800 p-1.5 text-white">
                                                <span className="sr-only">Open main menu</span>
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                    {
                                        showNotification && (
                                            <div id="dropdownNotification" class="absolute right-1 mt-1 z-20 w-full max-w-xs bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700" aria-labelledby="dropdownNotificationButton">
                                                <div class="block px-4 py-2 font-bold tracking-widest text-center text-white rounded-t-lg bg-gray-600 dark:bg-gray-800 dark:text-white">
                                                    Notifications
                                                </div>
                                                {
                                                    notificationList ? (
                                                        notificationList && notificationList.map((data, id) => {
                                                            return (<Fragment key={id}>
                                                                <div class="divide-y divide-gray-400 dark:divide-gray-700">
                                                                    <div className=" hover:bg-gray-100 px-2 py-2 dark:hover:bg-gray-700">
                                                                        <div className="flex pl-2 font-semibold text-gray-700 justify-center"> {data.module} <span className=' px-1'> : </span> <span class="font-semibold text-gray-900 dark:text-white"> {data.from_user}</span></div>
                                                                        <Link to={data.link} class="flex py-1">
                                                                            <div class="flex-shrink-0  items-center">
                                                                                <img src={data.user_image ? (
                                                                                    data.user_image.name ? URL.createObjectURL(data.user_image) : IMG_URL + "/assets/images/UserImages/" + data.user_image
                                                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded-full' />
                                                                            </div>
                                                                            <div class="w-full pl-3">
                                                                                <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">{data.message}
                                                                                </div>
                                                                                <div class="text-xs text-blue-600 dark:text-blue-500">{data.timeDifference}</div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Fragment>)
                                                        })
                                                    ) : (
                                                        <h1 className='font-semibold text-sm flex justify-center'> 0 Notifications</h1>
                                                    )
                                                }

                                                <Link to={'/notifications'} class="block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                                                    <div class="inline-flex items-center ">
                                                        <svg class="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                                                            <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                                                        </svg>
                                                        View all
                                                    </div>
                                                </Link>
                                                {/* <div>
                                                    <button onClick={showAlertPopUp}>Show Alert</button>

                                                    {showAlert && (
                                                        <div className="bg-primary-color text-white p-10">
                                                            This is your timed alert!
                                                        </div>
                                                    )}
                                                </div> */}
                                            </div>
                                        )
                                    }
                                </div>
                                <Outlet />
                            </div>
                        </div>
                    </Fragment>
                )}
            </Disclosure>
        </Fragment>
    )
}
