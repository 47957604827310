import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, EyeIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Menu, Transition } from '@headlessui/react'
import Button from '../helpers/Button'
import ActionButton from '../helpers/ActionButton'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import ViewModel from '../helpers/ViewModel'
import Input from '../helpers/Input'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ContractorMaster() {
    document.title = 'SiteSmart - Contractor Master'
    const tableHeading = ['Sr.No', 'Contractor Name', 'Work Type', 'Phone', 'Action']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [contractorCsv, setContractorCsv] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [viewCsv, setViewCSv] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [contractorLists, setContractorLists] = useState('')
    const getContractorLists = () => {
        setLoader(true)
        axiosClient.get('/Contractormaster_lists')
            .then(({ data }) => {
                setLoader(false)
                setContractorLists(data.lists.sort((a, b) => a.contractor_name.localeCompare(b.contractor_name)));
                // setContractorLists(data.lists)
            })
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Contractor Name', key: 'contractor_name' },
        { label: 'Mobile No', key: 'mobile_no' },
        { label: 'Email', key: 'email' },
        { label: 'Aadhar No', key: 'aadhar_card' },
        { label: 'Pan No', key: 'pan_card' },
        { label: 'Work Type', key: 'work_type' },
        { label: 'Firm Name', key: 'firm_name' },
        { label: 'Bank A/c Name', key: 'bank_account_name' },
        { label: 'Bank Branch', key: 'bank_branch' },
        { label: 'Bank Account No', key: 'bank_account_no' },
        { label: 'IFSC Code', key: 'ifsc_code' },
        { label: 'GST No', key: 'gst_no' },
        { label: 'Address', key: 'address' },
        { label: 'Work Name', key: 'work_name' },
        { label: 'Created Date', key: 'created_at' },
    ];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    
    const handleContractorCsv = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('contractorCsv', contractorCsv)
        axiosClient.post(`import_material`, formData)
            .then(({ data }) => {
                flasher.success(data.msg)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
        setViewModel(false)
    }

    const deleteContractor = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Contractormaster/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getContractorLists()
                    })
            }
        })
    }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Contractormaster_data/${id}`)
            .then(({ data }) => {
                setModalData(data.view_data)
                setModalLoader(false)
            })
    }
    useEffect(() => {
        getContractorLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Contractor Master'} button={
                    <LinkButton to={'/contractor-master/add-contractor-master'}>
                        <PlusCircleIcon className='w-6 h-6' />
                        <span>Add New Contractor</span>
                    </LinkButton>
                }>
                    <div className="flex justify-end mb-2">
                        <Menu as="div">
                            <div className="flex justify-end">
                                <Menu.Button >
                                    <span className="sr-only">Open user menu</span>
                                    <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                </Menu.Button>
                            </div>

                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={() => setViewCSv(true)} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="px-2 py-2">
                                            <CSVLink data={contractorLists} headers={headers} filename={'Contractor List.csv'}>
                                                <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                            </CSVLink>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {contractorLists && contractorLists.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {contractorLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.contractor_name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.work_type}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.mobile_no}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                    <Link title='Edit' to={`/contractor-master/edit-contractor-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                    <ActionButton onClick={() => deleteContractor(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Contractor Data Not Found</p>
                        </div>
                    )}
                    {contractorLists && contractorLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={contractorLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Contractor Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Contractor Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.contractor_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Mobile No.</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.mobile_no}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Firm Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.firm_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Work Type</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.work_type}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Address</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.address}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Email</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.email}</h1>
                                            </div>

                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Aadhar Card</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.aadhar_card}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Pan Card</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.pan_card}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Gst No.</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.gst_no}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Bank Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.bank_account_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Bank Branch</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.bank_branch}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>Bank Account No</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.bank_account_no}</h1>
                                            </div>
                                            <div className='flex items-center gap-4 py-1 md:py-2'>
                                                <h1 className='w-36 font-bold'>IFSC Code</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.ifsc_code}</h1>
                                            </div>
                                            <div className="mt-3">
                                                <button
                                                    type="button" className=" rounded-md bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel(false)} > Got it, thanks!
                                                </button>
                                            </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        viewCsv && <ViewModel viewModel={viewCsv} setViewModel={setViewCSv} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <div className='m-5' ref={componentRef}>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Import CSV File</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <h1 className='text-lg font-bold'>Note :-</h1>
                                        <div className='m-y-2 pl-5'>
                                            <code className='block'><span className='font-semibold'>1.</span>The Excel File must include required column's</code>
                                            <code className='block mb-1'><span className='font-semibold'>2.</span>Column heading's should be...</code>
                                            <code className='grid grid-cols-3 gap-x-5 mb-1'>
                                                <span>contractor_name</span>
                                                <span>mobile_no</span>
                                                <span>email</span>
                                                <span>aadhar_no</span>
                                                <span>pan_no</span>
                                                <span>work_type</span>
                                                <span>firm_name</span>
                                                <span>bank_name</span>
                                                <span>bank_branch</span>
                                                <span>account_no</span>
                                                <span>ifsc_code</span>
                                                <span>gst_no</span>
                                                <span>bank_branch</span>
                                                <span>address</span>
                                                <span>work_name</span>
                                                <span>created_date</span>
                                            </code>
                                            <code className='block'><span className='font-semibold'>4.</span>Serial No. column is optional.</code>
                                            <code className='block'><span className='font-semibold'>5.</span>The file format should be .csv or .xls</code>
                                        </div>
                                    </div>
                                    <Input id={'materialCsv'} type={'file'} name={'materialCsv'} onChange={(e) => setContractorCsv(e.target.files[0])} className={'p-[0px!important] mt-2 mb-2'} />
                                    <div className="mt-4 grid grid-cols-3">
                                        <Button onClick={() => handleContractorCsv()} className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit"> Save
                                        </Button>
                                    </div>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default ContractorMaster