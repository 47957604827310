import PageComponent from '../../../components/PageComponent'
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { Fragment, useEffect, useState } from 'react'
import ViewModel from '../../helpers/ViewModel'
import { Dialog } from '@headlessui/react'
import { EyeIcon } from '@heroicons/react/20/solid'
import Button from '../../helpers/Button'

function BankMaster() {
    document.title = 'SiteSmart - Bank Master'
    const tableHeading = ['Sr.No', 'Bank Name', 'Bank Branch', 'Bank Account No', 'Opening Balance', 'Current Balance', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])

    const [bankLists, setBankLists] = useState('')
    const getBankLists = () => {
        setLoader(true)
        axiosClient.get('/Bank_master_lists')
        .then(({data})=>{
            setLoader(false)
            setBankLists(data.lists)
        })
    }
    function openModal(id) {
      setViewModel(true)
      setModalLoader(true)
      axiosClient.get(`/single_Bank_master_data/${id}`)
          .then(({ data }) => {
              setModalLoader(false)
              setModalData(data.single_data)
          })
  }
    const deleteBank = (id) =>{
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Bank_master/${id}`)
                .then(({data})=>{
                    flasher.success(data.msg)
                    getBankLists()
                })
            }
        })
    }
    useEffect(()=>{
        getBankLists()
    },[])
  return (
    <Fragment>
            {loader ? (<Loader/>) : (
                <PageComponent title={'Account Master'} button={
                    <LinkButton to={'accounting/bank-master/add-bank-master'}>
                        <PlusCircleIcon className='w-5 h-5'/>
                        <span>Add Bank Details </span>
                    </LinkButton>
                }>
                    {bankLists && bankLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {bankLists.map((tableData, tableDataID)=>{
                                return(<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID+1}</td>
                                        <td className="px-4 py-3">{tableData.bank_name}</td>
                                        <td className="px-4 py-3">{tableData.bank_branch}</td>
                                        <td className="px-4 py-3">{tableData.bank_account_no}</td>
                                        <td className="px-4 py-3">{Number(tableData.opening_balance).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{Number(tableData.current_balance).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                            <Link onClick={() => openModal(tableData.id)}><Button className={'p-1.5'}><EyeIcon className='w-5 h-5' /></Button></Link>
                                                <Link title='Edit' to={`/accounting/bank-master/edit-bank-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5'/></ActionButton></Link>
                                                <ActionButton onClick={()=>deleteBank(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5'/></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Account Master Data Not Found</p>
                        </div>
                    )}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">

                                {modalLoader ? ('Please Wait') : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-xl tracking-wide'>Bank Information</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bank Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_name}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bank Branch</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_branch}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-40 font-bold'>Bank Address</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6 break-words'>{modalData.bank_address}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Bank Account No</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_account_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Ifsc Code</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.ifsc_code}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-36 font-bold'>Opening Balance.</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.opening_balance}</h1>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
  )
}

export default BankMaster
