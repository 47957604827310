import React, { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Bars3Icon, BuildingOffice2Icon, BuildingOfficeIcon, DocumentTextIcon, InformationCircleIcon, TableCellsIcon } from '@heroicons/react/20/solid'
import { ChevronUpIcon, ChevronDownIcon, BanknotesIcon, ClipboardIcon, ViewColumnsIcon, GlobeAltIcon, } from '@heroicons/react/24/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const WorkInfoSideMenu = ({ work_id }) => {

    // const [purchaseToggle, setPurchaseToggle] = useState(false);
    // const purchaseToggleFun = () => { setPurchaseToggle(current => !current) }

    // const [stockToggle, setStockToggle] = useState(false);
    // const stockToggleFun = () => { setStockToggle(current => !current) }

    // const [contractorToggle, setContractorToggle] = useState(false);
    // const contractorToggleFun = () => { setContractorToggle(current => !current) }

    const [workdetailToggle, setWorkDetailToggle] = useState(false);
    const workdetailToggleFun = () => { setWorkDetailToggle(current => !current) }

    const [salemanagementToggle, setSalemanagementToggle] = useState(false);
    const salemanagementToggleFun = () => { setSalemanagementToggle(current => !current) }

    const [userRole, SetUserRole] = useState(localStorage.getItem('USER_ROLE'));

    // const workdetailToggleFun = () => { 
    //     setWorkDetailToggle(current => !current) 
    //     setSalemanagementToggle(false);
    // }
    // const salemanagementToggleFun = () => {
    //      setSalemanagementToggle(current => !current) 
    //     setWorkDetailToggle(false);
    // }

    return (
        <Fragment>
            {
                (userRole === 'Admin' || userRole === 'Project_Manager') && (
                    <NavLink to={`/work-management/view-work-management/${work_id}`} className={({ isActive }) => classNames(isActive ? 'bg-primary-color  text-default-color' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                        <div className='flex items-center gap-2'>
                            <InformationCircleIcon className='h-5 w-5' title='Work Overview' />
                            <span className='ml-1 hidden md:block'>Work Overview</span>
                        </div>
                    </NavLink>
                )
            }
            {
                (userRole === 'Admin' || userRole === 'Project_Manager') && (
                    <div className='w-auto'>
                        <button onClick={() => workdetailToggleFun()} className={"flex items-center w-full p-2 bg-gray-400 text-black transition duration-75 rounded-lg group mt-1 " + (workdetailToggle && 'rounded-b-none')}>
                            <Fragment>
                                <BuildingOfficeIcon className='h-5 w-5 mx-auto' title='Add Work Details' />
                            </Fragment>
                            <Fragment>
                                <span className="flex-1 ml-3 text-left whitespace-nowrap hidden md:block ">Add Work Details</span>
                                {/* <span className='hidden md:block'> */}
                                {workdetailToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                {/* </span> */}
                            </Fragment>
                        </button>
                        <ul className={"md:py-1.5 md:px-2 space-y-2 text-black bg-gray-300 rounded-t-none " + (workdetailToggle ? 'block rounded' : 'hidden')}>
                            <li>
                                <NavLink to={`/work-management/view-wing-details/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-00', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                                    <Fragment>
                                        <BuildingOffice2Icon className='w-5 h-5' title='Wing Details' />
                                        <span className='ml-1 hidden md:block'>Wing Details</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/work-management/view-floor-details/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                                    <Fragment>
                                        <Bars3Icon className='w-5 h-5' title='Floor Details' />
                                        <span className='ml-1 hidden md:block'>Floor Details</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/work-management/view-flat-details/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                                    <Fragment>
                                        <TableCellsIcon className='w-5 h-5' title='Flat Details' />
                                        <span className='ml-1 hidden md:block'>Flat Details</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/work-management/view-payment-details/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                                    <Fragment>
                                        <BanknotesIcon className='w-5 h-5' title='Payment Stages' />
                                        <span className='ml-1 hidden md:block'>Payment Stages</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/work-management/view-materialestimation-details/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                                    <Fragment>
                                        <DocumentTextIcon className='w-5 h-5' title='Material Estimation' />
                                        <span className='ml-1 hidden md:block'>Material Estimation</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                )
            }
            {
                (userRole === 'Admin' || userRole === 'Project_Manager') && (
                    <NavLink to={`/work-management/view-wing-info/${work_id}`} className={({ isActive }) => classNames(isActive ? 'bg-primary-color  text-default-color' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                        <div className='flex items-center gap-2' >  
                            <InformationCircleIcon className='h-5 w-5' title='Wing Info' />
                            <span className='ml-1 hidden md:block'>Wing Info</span>
                        </div>
                    </NavLink>
                )
            }
            {
                (userRole === 'Admin' || userRole === 'Project_Manager') && (
                    <div className='w-auto'>
                        <button onClick={() => salemanagementToggleFun()} className={"flex items-center w-full p-2 bg-gray-400 text-black transition duration-75 rounded-lg group mt-1 " + (salemanagementToggle && 'rounded-b-none')}>
                            <Fragment>
                                <ViewColumnsIcon className='h-5 w-5 mx-auto' title='Sale Management ' />
                            </Fragment>
                            <Fragment>
                                <span className="flex-1 ml-3 text-left whitespace-nowrap hidden md:block">Sale Management</span>
                                {/* <span className='hidden md:block'> */}
                                {salemanagementToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                                {/* </span> */}
                            </Fragment>
                        </button> 
                        <ul className={"md:py-1.5 md:px-2 space-y-2 text-black bg-gray-300 rounded-t-none  " + (salemanagementToggle ? 'block rounded' : 'hidden')}>
                            <li>
                                <NavLink to={`/work-management/view-sale-dashboard/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                                    <Fragment>
                                        <GlobeAltIcon className='w-5 h-5' title='Sale Dashboard' />
                                        <span className='ml-1 hidden md:block'>Sale Dashboard</span>
                                    </Fragment>
                                </NavLink>
                            </li>
                            { (userRole === 'Admin'|| userRole === 'Project_Manager') && (
                                    <li>
                                        <NavLink to={`/work-management/view-sale-report/${work_id}`} className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                                            <Fragment>
                                                <ClipboardIcon className='w-5 h-5' title='Sale Report' />
                                                <span className='ml-1 hidden md:block'>Sale Report</span>
                                            </Fragment>
                                        </NavLink>
                                    </li> )
                            }
                        </ul>
                    </div>
                )
            }

            {/* <NavLink to={`/`} className={({ isActive }) => classNames(isActive ? 'bg-primary-color text-default-color ' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                <div className='flex items-center gap-2'>
                    <UserCircleIcon className='h-6 w-6' title='Task Management' />
                    <span className='ml-1 hidden md:block'>Task Management</span>
                </div>
            </NavLink>
            <NavLink to={`/`} className={({ isActive }) => classNames(isActive ? 'bg-primary-color text-default-color ' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                <div className='flex items-center gap-2'>
                    <InformationCircleIcon className='h-6 w-6' title='Work payments' />
                    <span className='ml-1 hidden md:block'>Client payments</span>
                </div>
            </NavLink>
            <div className='w-auto'>
                <button onClick={() => purchaseToggleFun()} className={"flex items-center w-full p-2 bg-gray-400 text-black transition duration-75 rounded-lg group mt-1 "+(purchaseToggle && 'rounded-b-none')}>
                    <Fragment>
                        <ListBulletIcon className='h-6 w-6 mx-auto' title='Purchase ' />
                    </Fragment>
                    <Fragment>
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Purchase</span>
                        {purchaseToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                    </Fragment>

                </button>
                <ul className={"py-1.5 px-2 space-y-2 text-black bg-gray-300 rounded-t-none  " + (purchaseToggle ? 'block rounded' : 'hidden')}>
                    <li>
                        <NavLink to="/" className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                            <Fragment>
                                <ShoppingBagIcon className='w-5 h-5' />
                                <span>Purchase Order </span>
                            </Fragment>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/" className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                            <Fragment>
                                <ShoppingCartIcon className='w-5 h-5' />
                                <span>Purchase Bill </span>
                            </Fragment>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className='w-auto '>
                <button onClick={() => stockToggleFun()} className={"flex items-center w-full p-2 bg-gray-400 text-black transition duration-75 rounded-lg group mt-1 "+(stockToggleFun && 'rounded-b-none')}>
                    <Fragment>
                        <CircleStackIcon className='h-6 w-6 mx-auto' title='Stock Management ' />
                    </Fragment>
                    <Fragment>
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Stock Management</span>
                        {stockToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                    </Fragment>

                </button>
                <ul className={"py-1.5 px-2 space-y-2 text-black bg-gray-300 rounded-lg rounded-t-none  " + (stockToggle ? 'block' : 'hidden')}>
                    <li>
                        <NavLink to="/" className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                            <Fragment>
                                <Square3Stack3DIcon className='w-5 h-5'/>
                                <span>Stock Register </span>
                            </Fragment>
                        </NavLink>
                    </li>
                    <li >
                        <NavLink to="/" className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                            <Fragment>
                                <Square3Stack3DIcon className='w-5 h-5' />
                                <span>Stock Transfer </span>
                            </Fragment>

                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/" className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color  gap-2 ")}>
                            <Fragment>
                                <PlusCircleIcon className='w-5 h-5' />
                                <span>Stock Consumed  </span>
                            </Fragment>

                        </NavLink>
                    </li>
                </ul>
            </div>
            <NavLink to={`/work-management/view-work-info/${id}`} className={({ isActive }) => classNames(isActive ? 'bg-gray-300 w-auto  text-black' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                <div className='flex items-center gap-2'>
                    <BanknotesIcon className='h-6 w-6' title='Daily Wage Details' />
                    <span className='ml-1 hidden md:block'>Daily wage Details </span>
                </div>
            </NavLink>
            <NavLink to={`/work-management/view-work-clientpayment/:slug`} className={({ isActive }) => classNames(isActive ? 'bg-gray-300 w-auto  text-black' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                <div className='flex items-center gap-2'>
                    <BanknotesIcon className='h-6 w-6' title='Salary Management' />
                    <span className='ml-1 hidden md:block'>Salary Management</span>
                </div>
            </NavLink>
            <NavLink to={`/work-management/view-work-clientpayment/:slug`} className={({ isActive }) => classNames(isActive ? 'bg-gray-300 w-auto  text-black' : 'bg-gray-400 hover:bg-primary-color hover:text-default-color', 'rounded-md p-2 w-auto ')}>
                <div className='flex items-center gap-2'>
                    <NewspaperIcon className='h-6 w-6' title='Expense Bills' />
                    <span className='ml-1 hidden md:block'>Expense Bills </span>
                </div>
            </NavLink>
             <div className='w-auto '>
                <button onClick={() => contractorToggleFun()} className={"flex items-center w-full p-2 bg-gray-400 text-black transition duration-75 rounded-lg group mt-1 "+(contractorToggle && 'rounded-b-none')}>
                    <Fragment>
                        <ListBulletIcon className='h-6 w-6 mx-auto' title='Contractor ' />
                    </Fragment>
                    <Fragment>
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Contractor Management</span>
                        {contractorToggle ? (<ChevronUpIcon className='h-5 w-5' />) : (<ChevronDownIcon className='h-5 w-5' />)}
                    </Fragment>

                </button>
                <ul className={"py-1.5 px-2 space-y-2 text-black bg-gray-300 rounded-lg rounded-t-none  " + (contractorToggle ? 'block' : 'hidden')}>
                    <li>
                        <NavLink to="/contractorwork-master" className={({ isActive }) => classNames(isActive && 'bg-gray-300', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                            <Fragment>
                                <Square3Stack3DIcon className='w-5 h-5' />
                                <span>Contractor Work  </span>
                            </Fragment>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contractorPayment" className={({ isActive }) => classNames(isActive && 'bg-gray-300 text-black', "flex items-center w-full p-2 transition duration-75 rounded-md group hover:bg-primary-color hover:text-default-color gap-2 ")}>
                            <Fragment>
                                <Square3Stack3DIcon className='w-5 h-5' />
                                <span>Contractor Payment </span>
                            </Fragment>

                        </NavLink>
                    </li>
                </ul>
            </div> */}
        </Fragment>
    )
}

export default WorkInfoSideMenu
