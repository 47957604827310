import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import Table from '../helpers/Table'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'
import moment from 'moment'
import flasher from '@flasher/flasher'
import Pagination from '../helpers/Pagination'

function PendingStock() {
    document.title = 'SiteSmart - Pending Stock'
    const tableHeading = ['Sr.No', 'Work', 'Wing', 'Level', 'Supplier', 'Order Date', 'Material', 'Unit', 'Status', 'Pending Stock']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [pendingList, setPendingList] = useState('')
    const getPendingList = () => {
        setLoader(true)
        axiosClient.get('/Remainingstock_Inward_lists')
            .then(({ data }) => {
                setLoader(false)
                setPendingList(data.lists)
            })
    }
    const handlePendingStock = (id) => {
        setLoader(true)
        axiosClient.post(`inward_remaining_material/${id}`)
            .then(({ data }) => {
                data.status == true ? flasher.success(data.msg) : flasher.warning(data.msg)
                // data.status == true ? navigate('/stock-consumed') : console.log();
                getPendingList()
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
                // setErrors(response.data.errors)
            })
    }

    useEffect(() => {
        getPendingList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Pending Stock'}>
                    {pendingList && pendingList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {pendingList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.wing_name}</td>
                                        <td className="px-4 py-3">{tableData.level_name}</td>
                                        <td className="px-4 py-3">{tableData.supplier_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3 break-words w-[10%]">{tableData.material_name}</td>
                                        <td className="px-4 py-3">{tableData.material_unit}</td>
                                        <td className="py-3 w-[15%]">
                                            {
                                                tableData.status === null ?
                                                    <Link title='Edit' onClick={() => handlePendingStock(tableData.id)} className={'border-2  bg-red-600 border-red-600 text-white text-center px-3 py-1.5 rounded-md'}><span className='py-1.5 px-3'>Pending</span></Link> :
                                                    <Link title='Approved' className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1.5 rounded-md'><span className='py-1.5 px-1.5'>Received</span></Link>
                                            }
                                        </td>
                                        <td className="px-4 py-3">{parseInt(tableData.remaining_qty).toLocaleString('en-IN')}</td>

                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Inward Data Not Found</p>
                        </div>
                    )}
                    {pendingList && pendingList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={pendingList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default PendingStock