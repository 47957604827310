import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, ArrowUpCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import CustomSelect from '../../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import ViewModel from '../../helpers/ViewModel'
import { Dialog } from '@headlessui/react'
import Table from '../../helpers/Table'
import CheckBox from '../../helpers/CheckBox'
import TextArea from '../../helpers/TextArea'
import PleaseWait from '../../Common/PleaseWait'

function ClientPaymentAddEdit() {
    const { id } = useParams()
    // eslint-disable-next-line no-lone-blocks
    {
        id ? (
            document.title = 'SiteSmart - Edit Client Payment'
        ) : (
            document.title = 'SiteSmart - Add New Client Payment'
        )
    }

    const [loader, setLoader] = useState(false)
    const [workNameLists, setWorkNameLists] = useState([])
    const tableHeading = ['Sr.No', 'Stage Name', 'Percentage %', 'Action']
    const [errors, setErrors] = useState([])
    const [viewModel, setViewModel] = useState(false)
    const [updateModel, setUpdateModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [workId, setWorkId] = useState(false)
    const [wingId, setWingId] = useState(false)
    const [percentage, setPercentage] = useState(0)
    const [paymentLists, setPaymentLists] = useState()
    const [temp, setTemp] = useState()
    const [totalsum, setTotalSum] = useState()
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        client_name: '',
        work_name: '',
        wing_name: '',
        level_name: '',
        flat_no: '',
        nature_of_flat: '',
        carpet_area: '',
        parking_no: '',
        lead_by: '',
        total_amount: '',
        advance: '',
        amount_payable: 0,
        remaining_balance: 0,
        received_date: '',
        received_to_acc: '',
        responsible_person: '',
        remarks: '',
        stage_button: '',
        payment_stage_list: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handlePayAble = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            remaining_balance: temp,
            amount_payable: e.target.value
        }))
    }

    const handleBalance = () => {
        if (parseInt(formInfo.remaining_balance) >= parseInt(formInfo.amount_payable)) {
            formInfo.remaining_balance = formInfo.remaining_balance - formInfo.amount_payable
        } else {
            flasher.warning('Payable Amount Greater Than Total Amount')
            setFormInfo(formInfo => ({
                ...formInfo,
                amount_payable: '',
                remaining_balance: temp
            }))
        }
        // const amount = formInfo.amount_payable > formInfo.remaining_balance ? (handleFlasher()) : (formInfo.remaining_balance = formInfo.remaining_balance - formInfo.amount_payable);
    }

    const handleFlasher = () => {
        flasher.warning('Payable Amount Greater Than Total Amount')
        setFormInfo(formInfo => ({
            ...formInfo,
            amount_payable: '',
            remaining_balance: temp
        }))
    }

    const addPaymentArray = (check, stage_id) => {
        let array = JSON.parse(JSON.stringify(paymentLists));
        const updatedData = array.map(x => (x.stage_id === stage_id ? { ...x, check_mark: !check } : x));
        setPaymentLists(updatedData);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            enq_id: formInfo.enq_id,
            Deal_id: formInfo.Deal_id,
            final_amount: formInfo.final_amount,
            advance: formInfo.advance,
            amount_payable: formInfo.amount_payable,
            remaining_balance: formInfo.remaining_balance,
            received_date: formInfo.received_date,
            received_to_acc: formInfo.received_to_acc,
            responsible_person: formInfo.responsible_person,
            remarks: formInfo.remarks,
            stage_button: '',
            payment_stage_list: paymentLists,
        }

        axiosClient.post(`update_ClientPayment/${id}`, payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/accounting/client-payment')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const handleCloseModel = () => {
        setViewModel(false)
        setUpdateModel(false)
        setPercentage(0)

        paymentLists && paymentLists.map((data) => {
            data.check_mark == true && (setPercentage((percentage) => percentage + parseInt(data.payment_percentage)))
        })
    }

    const openModal = () => {
        setModalLoader(true)
        setViewModel(true)
        axiosClient.get(`wing_wise_stage_master_data/${workId}/${wingId}`)
            .then(({ data }) => {
                setModalLoader(false)
                let array = [];
                data.work_wise_paymentstagedata.map(i => {
                    array.push({
                        stage_id: i.id,
                        payment_stage_name: i.stage_name,
                        payment_percentage: i.percentage,
                        check_mark: false,
                    })
                });
                setPaymentLists(array)
                setTotalSum(data.total_percentage)
            })
    }

    const openNewModal = () => {
        setModalLoader(true)
        setViewModel(true)
        axiosClient.get(`clientPay_stage_master_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                let array = [];
                data.work_wise_paymentstagedata.map(i => {
                    array.push({
                        id: i.id,
                        stage_id: i.payment_stage_name,
                        payment_stage_name: i.stage_name,
                        payment_percentage: i.percentage,
                        check_mark: i.check_mark == '0' ? false : true,
                    })
                });
                setPaymentLists(array)
                setTotalSum(data.totalSum)
            })
    }

    const [user, setUser] = useState([]);
    const getUserData = () => {
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setLoader(false)
                setUser(data.lists)
            })
    }

    const getSingleAccountLedgerData = () => {
        setLoader(true)
        axiosClient.get(`/single_ClientPayment_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setTemp(data.single_data.remaining_balance)
                setWorkId(data.view_data.work_name)
                setWingId(data.view_data.wing_name)
            })
    }
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkNameLists(data.lists)
            })
    }
    useEffect(() => {
        if (id) {
            getSingleAccountLedgerData()
        }

        getUserData();
        getWorkData();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Received Payment From Client'} button={
                    <LinkButton to={'/accounting/client-payment'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-96 md:w-[90%] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div>
                                        <Label htmlFor={'client_name'} labelText={'Client Name'} className={'mb-1'} />
                                        <Input id={'client_name'} type={'text'} name={'client_name'} value={formInfo.client_name} readOnly={true} />
                                        <Validation error={errors.client_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <Input id={'work_name'} type={'text'} name={'work_name'} value={formInfo.work_name} readOnly={true} />
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                        <Input id={'wing_name'} type={'text'} name={'wing_name'} value={formInfo.wing_name} readOnly={true} />
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <Input id={'level_name'} type={'text'} name={'level_name'} value={formInfo.level_name} readOnly={true} />
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                        <Input id={'flat_no'} type={'text'} name={'flat_no'} value={formInfo.flat_no} readOnly={true} />
                                        <Validation error={errors.flat_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'nature_of_flat'} labelText={'Nature Of Flat'} className={'mb-1'} />
                                        <Input id={'nature_of_flat'} type={'text'} name={'nature_of_flat'} value={formInfo.nature_of_flat} readOnly={true} />
                                        <Validation error={errors.nature_of_flat} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'carpet_area'} labelText={'Carpet Area'} className={'mb-1'} />
                                        <Input id={'carpet_area'} type={'text'} name={'carpet_area'} value={parseInt(formInfo.carpet_area).toLocaleString('en-IN')} readOnly={true} />
                                        <Validation error={errors.carpet_area} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'parking_no'} labelText={'Parking No.'} className={'mb-1'} />
                                        <Input id={'parking_no'} type={'text'} name={'parking_no'} value={formInfo.parking_no} readOnly={true} />
                                        <Validation error={errors.parking_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'parking_area'} labelText={'Parking Area'} className={'mb-1'} />
                                        <Input id={'parking_area'} type={'text'} name={'parking_area'} value={formInfo.parking_area} readOnly={true} />
                                        <Validation error={errors.parking_area} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'lead_by'} labelText={'Lead Source'} className={'mb-1'} />
                                        <Input id={'lead_by'} type={'text'} name={'lead_by'} value={formInfo.lead_by} readOnly={true} />
                                        <Validation error={errors.lead_by} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'final_amount'} labelText={'Total Amount'} className={'mb-1'} />
                                        <Input id={'final_amount'} type={'text'} name={'final_amount'} value={parseInt(formInfo.final_amount).toLocaleString('en-IN')} onChange={handleChange} readOnly={true} />
                                        <Validation error={errors.final_amount} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'payment_stages'} labelText={'Payment Stages'} className={'mb-1'} />
                                        <Link onClick={() => { formInfo.stage_button == null ? openModal() : openNewModal() }} className={'group relative flex justify-center rounded-md bg-primary-color p-2.5 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '}>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><ArrowUpCircleIcon className="h-5 w-5 text-default-color" /></span>
                                            <span>Select Stages</span>
                                        </Link>
                                    </div>
                                    <div>
                                        <Label htmlFor={'advance'} labelText={'Advance (' + percentage + '% Token amount)'} className={'mb-1'} />
                                        <Input id={'advance'} type={'text'} name={'advance'} value={parseInt(formInfo.advance = ((parseInt(formInfo.final_amount) / 100) * parseInt(percentage))).toLocaleString('en-IN')} onChange={handleChange} readOnly={true} />
                                        <Validation error={errors.advance} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'amount_payable'} labelText={'Amount Payable'} className={'mb-1'} />
                                        <div className="flex">
                                            <Input id={'amount_payable'} className={'rounded-none rounded-l-lg'} type={'text'} name={'amount_payable'} value={formInfo.amount_payable} onChange={handlePayAble} />
                                            <Link onClick={() => handleBalance()} className="inline-flex items-center bg-blue-400 shadow-lg hover:bg-blue-500 px-3 border border-l-0 border-blue-500 rounded-r-md">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </Link>
                                        </div>
                                        <Validation error={errors.amount_payable} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'remaining_balance'} labelText={'Remaining Balance'} className={'mb-1'} />
                                        <Input id={'remaining_balance'} type={'text'} name={'remaining_balance'} value={parseInt(formInfo.remaining_balance).toLocaleString('en-IN')} onChange={handleChange} readOnly={true} />
                                        <Validation error={errors.remaining_balance} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'received_date'} labelText={'Received Date'} className={'mb-1'} />
                                        <Input id={'received_date'} type={'date'} name={'received_date'} value={formInfo.received_date} onChange={handleChange} />
                                        <Validation error={errors.received_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'received_to_acc'} labelText={'Received To A/c'} className={'mb-1'} />
                                        <CustomSelect id={'received_to_acc'} name={'received_to_acc'} className={''} value={formInfo.received_to_acc} onChange={handleChange}>
                                            <option value={''}>--- Choose ---</option>
                                            <option value={'Cash'}>Cash</option>
                                            <option value={'Bank'}>Bank</option>
                                        </CustomSelect>
                                        <Validation error={errors.received_to_acc} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'responsible_person'} labelText={'Responsible Person'} className={'mb-1'} />
                                        <CustomSelect id={'responsible_person'} name={'responsible_person'} value={formInfo.responsible_person} onChange={handleChange} className={'w-full'}>
                                            <option value={''}>--- Choose User Name ---</option>
                                            {user.map(data => {
                                                return (
                                                    <option value={data.id}>{data.name} ({((data.role).split('_')).join(' ')})</option>
                                                )
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.responsible_person} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'remarks'} labelText={'Remarks'} className={'mb-1'} />
                                        <TextArea id={'remarks'} type={'text'} name={'remarks'} rows={1} value={formInfo.remarks} onChange={handleChange} />
                                        <Validation error={errors.remarks} />
                                    </div>
                                    {/* {
                                        formInfo.stage_button == null ? (
                                            <Link onClick={() => openModal()} className={'group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '}>
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"><ArrowUpCircleIcon className="h-5 w-5 text-default-color" /></span>
                                                <span>Payment Stages First</span>
                                            </Link>
                                        ) : (
                                            <Link onClick={() => openNewModal()} className={'group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '}>
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"><ArrowUpCircleIcon className="h-5 w-5 text-default-color" /></span>
                                                <span>Payment Stages Second</span>
                                            </Link>
                                        )
                                    } */}

                                </div>
                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>

                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={() => setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Payment Stages List Module 1</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {paymentLists && paymentLists.length > 0 ? (
                                            <Table tableHeading={tableHeading}>
                                                {paymentLists.map((tableData, tableDataID) => {
                                                    return (<Fragment key={tableDataID}>
                                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                                            <td className="px-4 py-3">{tableData.payment_stage_name}</td>
                                                            <td className="px-4 py-3">{tableData.payment_percentage}</td>
                                                            <td>
                                                                <CheckBox id={'checkPoint'} type={'checkbox'} name={'checkbox'} checked={tableData.check_mark} value={tableData.check_mark} onClick={(e) => { addPaymentArray(tableData.check_mark, tableData.stage_id) }} className={'ml-3'} />
                                                            </td>
                                                        </tr>
                                                    </Fragment>)
                                                })}
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <th className="px-4 py-3 whitespace-nowrap">Total</th>
                                                    <th className="px-4 py-3 font-bold whitespace-nowrap"></th>
                                                    <th className="px-4 py-3 whitespace-nowrap">{totalsum}{'%'}</th>
                                                    <th className="px-4 py-3">
                                                    </th>
                                                </tr>
                                            </Table>
                                        ) : (
                                            <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                                <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Payment Data Not Found</p>
                                            </div>
                                        )}


                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => handleCloseModel()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )
            }
        </Fragment >
    )
}

export default ClientPaymentAddEdit

