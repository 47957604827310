import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../../Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { Dialog, Transition } from '@headlessui/react'
import Swal from 'sweetalert2'
import { useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Table from '../../helpers/Table'
import ActionButton from '../../helpers/ActionButton'
import ViewModel from '../../helpers/ViewModel'
import PleaseWait from '../../Common/PleaseWait'

function AddFlatDetails() {
    const { id, work_name, work_id } = useParams()
    // eslint-disable-next-line no-lone-blocks
    {
        id ? (
            document.title = 'SiteSmart - Edit Flat'
        ) : (
            document.title = 'SiteSmart - Add New Flat'
        )
    }
    const [loader, setLoader] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [modalData, setModalData] = useState([])
    const [viewModel, setViewModel] = useState(false)

    function closeModal() { setIsOpen(false) }
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)
    const navigate = useNavigate()

    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const tableHeading = ['Sr.No', 'Flat No.', ' Nature Of Flat', 'Area(Sq/Ft)', 'Parking No ', 'parking', 'Action']

    const [flatcadImage, setFlatCadImage] = useState('');
    const flatcadImageChange = (e) => {
        setFlatCadImage(e.target.files[0]);
    }

    // Api to Get Work List And Work Id  //
    const [workname, setWorkName] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
            })
    }

    // Api to Get wing List  //
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    // Api to Get Level List  //
    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        setLoader(false)
        work_id && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${work_id}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.work_wing_wise_leveldata)
            })
    }
    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        level_name: '',
        // name_of_floor: '',
    })

    const [tableData, setTableData] = useState([]);
    const flatdetails_id = id;
    const [inputs, setInputs] = useState({
        flatdetails_id: flatdetails_id,
        flat_no: '',
        nature_of_flat: '',
        estimate_area: '',
        flat_cad: '',
        parking: '',
        parking_no: '',
    });
    console.log('inputs :', inputs);

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    // OnChange When We Input Data //
    const handleChangesub = (e) => {
        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value
        }))
    }
    // Submit Modal Data //
    const handleSubmitsubtask = (e) => {
        e.preventDefault();
        setTableData([...tableData, inputs]);
        setInputs({
            flatdetails_id: flatdetails_id,
            flat_no: '',
            nature_of_flat: '',
            estimate_area: '',
            parking: '',
            flat_cad: flatcadImage,
            parking_no: ''
        }
        );
        setViewModel(false);
    };


    // Edit / Update Data //
    const handleUpdatesubtask = (e) => {
        e.preventDefault();
        const payload = {
            flatdetails_id: flatdetails_id,
            work_name: '' || work_id,
            wing_name: formInfo.wing_name,
            level_name: formInfo.level_name,
            flat_no: inputs.flat_no,
            nature_of_flat: inputs.nature_of_flat,
            estimate_area: inputs.estimate_area,
            parking: inputs.parking,
            flat_cad: inputs.flatcadImage,
            parking_no: inputs.parking_no
        }
        axiosClient.post('/store_flat', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate(`/work-management/edit-flat-details-form/${work_id}/${id}`)
                getSingleTaskData()

            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            });

        setInputs({
            flatdetails_id: flatdetails_id,
            flat_no: '',
            nature_of_flat: '',
            estimate_area: '',
            parking: '',
            parking_no: '',
            flat_cad: flatcadImage
        }
        );
        // setIsOpen(false)
        setViewModel(false);
    };

    // Created State to store data in Array //
    const [flatno, setFlatNo] = useState([])
    const [natureofflat, setNatureOfFlat] = useState([])
    const [estimatearea, setEstimateArea] = useState([])
    const [parking, setParking] = useState([])
    const [parkingno, setParkingNo] = useState([])
    const [flatimage, setflatimage] = useState([])

    // Function for Convert Data into Array  //
    const handlechangeflatdetails = (e) => {
        const flatno = tableData && tableData.map((data) => {
            return data.flat_no
        });
        setFlatNo(flatno)

        const natureofflat = tableData && tableData.map((data) => {
            return data.nature_of_flat
        });
        setNatureOfFlat(natureofflat)

        const estimatearea = tableData && tableData.map((data) => {
            return data.estimate_area
        });
        setEstimateArea(estimatearea)

        const parking = tableData && tableData.map((data) => {
            return data.parking
        });
        setParking(parking)

        const parkingno = tableData && tableData.map((data) => {
            return data.parking_no
        });
        setParkingNo(parkingno)

        const flatimage = tableData && tableData.map((data) => {
            return data.flat_cad
        });
        setflatimage(flatimage)
    }
    // To Submit All Form //
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            work_name: work_id,
            wing_name: formInfo.wing_name,
            level_name: formInfo.level_name,
            // name_of_floor: formInfo.name_of_floor,
            flat_no: flatno,
            nature_of_flat: natureofflat,
            estimate_area: estimatearea,
            flat_cad: flatimage,
            parking: parking,
            parking_no: parkingno,
        }
        console.log('payload :', payload);
        console.log(' flat_cad: flatimage, :', flatimage,);
        axiosClient.post(id ? `update_flat_details/${id}` : '/store_flat_details', payload)
            .then(({ data }) => {
                setLoader(false)
                data.status ? flasher.success(data.msg) :
                    flasher.warning(data.msg)
                data.status ? navigate(`/work-management/view-flat-details/${work_id}`) : navigate(`/work-management/add-flat-details-form/${work_id}`)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    // Api to Get Single Data To View In Model //

    const getSingleTaskData = () => {
        setLoader(true)
        axiosClient.get(`/single_flat_details_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.newmultiflat_details)
                setModalData(data.multiflat_details)
            })
    }


    // To Delete Material When We Add Static Data By Getting Index No. // 
    const handleDelete = (index) => {
        const filterData = tableData.filter((item, i) => i !== index)
        setTableData(filterData)
    };

    // Function To Delete Single Data when Edit/Update Details // 
    const deleteactivity = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_flat/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSingleTaskData()
                    })
            }
        })
    }
    // Modal //
    // function openModal() {
    //     setIsOpen(true)
    //     setModalLoader(true)
    //     setModalLoader(false)
    // }

    // const [floorName, setFloorName] = useState('');
    // const getFloorName = () => {
    //     var name_of_floor = '';
    //     formInfo.level_name && level.map((data) => {
    //         return (
    //             formInfo.level_name == data.id && (name_of_floor = data.name_of_floor)
    //         )
    //     })
    //     setFloorName(name_of_floor);
    // }

    // useEffect(() => {
    //     floorName == '' && getFloorName()
    // }, [formInfo.level_name])

    useEffect(() => {
        getWingData();
    }, [work_id])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        if (id) {
            getSingleTaskData()
        }
        getWorkDetails();
    }, [])

    useEffect(() => {
        handlechangeflatdetails();
    }, [tableData])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Flat' : 'Add New Flat'} button={
                    <LinkButton to={`/work-management/view-flat-details/${work_id}`}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                            {workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />

                            ) : (
                                workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />

                            )
                            }
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='flex items-center justify-center'>
                                <Card className={'w-full md:w-[70rem] p-6  bg-default-color'}>
                                    <form onSubmit={handleSubmit}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true} />
                                                <Validation error={errors.work_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose Wing Name ---</option>
                                                    {wing && wing.map((wingData, wingId) => {
                                                        return (<Fragment key={wingId}>
                                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.wing_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                                <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={(e) => { handleChange(e) }} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose level Name ---</option>
                                                    {level && level.map((levelData, levelId) => {
                                                        return (<Fragment key={levelId}>
                                                            <option value={levelData.level}>{levelData.level_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div>
                                            {/* <div className='mb-3'>
                                                <Label htmlFor={'name_of_floor'} labelText={'Name Of Floor'} className={'mb-1'} />
                                                <Input id={'name_of_floor'} type={'text'} name={'name_of_floor'} value={floorName} readOnly={true} />
                                                <Validation error={errors.name_of_floor} />
                                            </div> */}

                                            {/* <div className='mb-3'>
                                                <Label htmlFor={'name_of_floor'} labelText={'Name Of Floor'} className={'mb-1'} />
                                                <CustomSelect id={'name_of_floor'} name={'name_of_floor'} value={formInfo.name_of_floor} onChange={handleChange}>
                                                    <option value={''}>--- Choose Name Of Floor ---</option>
                                                    {level && level.map((levelData, levelId) => {
                                                        return (<Fragment key={levelId}>
                                                            <option value={levelData.name_of_floor}>{levelData.name_of_floor}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div> */}
                                        </div>
                                        <br></br>
                                        <h3 className='text-2xl font-bold mb-4'>Add Flat Details</h3>
                                        <button type='button' className={'w-[20%] group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color mb-4'} onClick={() => setViewModel(true)}>
                                            <span>Add Flats</span>
                                        </button>
                                        {
                                            id ? (
                                                modalData && modalData.length > 0 ? (
                                                    <Table tableHeading={tableHeading} >
                                                        {modalData.map((item, i) => {
                                                                return (<Fragment key={i}>
                                                                    <tr className='mt-4 border-b '>
                                                                        <td className="px-4 py-3 text-center">{i + 1}</td>
                                                                        <td className='px-4 py-3  text-center text-lg'>{item.flat_no}</td>
                                                                        <td className='px-4 py-3  text-center text-lg'>{((item.nature_of_flat).split('_')).join(' ')}</td>
                                                                        <td className='px-4 py-3  text-center text-lg'>{item.estimate_area}</td>
                                                                        <td className='px-4 py-3  text-center text-lg'>{item.parking_no}</td>
                                                                        <td className="px-4 py-3  text-center text-lg">{((item.parking).split('_')).join(' ')}</td>
                                                                        <td>
                                                                            <div className='flex justify-center '><ActionButton className={'text-white bg-red-600 rounded-lg cursor-pointer'}>< Link onClick={() => deleteactivity(item.id)} title={'Delete'} ><TrashIcon className='w-5 h-5'/></Link></ActionButton></div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>)
                                                            })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[15vh]'>
                                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                                    </div>
                                                )
                                            ) : (
                                                tableData && tableData.length > 0 ? (
                                                    <Table tableHeading={tableHeading} >
                                                        {tableData.map((item, i) => {
                                                            return (<Fragment key={i}>
                                                                <tr className='mt-4 border-b'>
                                                                    <td className="px-4 py-3 text-center">{i + 1}</td>
                                                                    <td className='px-4 py-3 text-center text-lg'>{item.flat_no}</td>
                                                                    <td className='px-4 py-3 text-center text-lg'>{((item.nature_of_flat).split('_')).join(' ')}</td>
                                                                    <td className='px-4 py-3 text-center text-lg'>{item.estimate_area}</td>
                                                                    <td className='px-4 py-3 text-center text-lg'>{item.parking_no}</td>
                                                                    <td className='px-4 py-3 text-center text-lg'>{((item.parking).split('_')).join(' ')}</td>
                                                                    <td>
                                                                        <div className='flex justify-center'><ActionButton className={'text-white bg-red-600 rounded-lg cursor-pointer'}><Link onClick={() => handleDelete(i)} title={'Delete'}><TrashIcon className='w-5 h-5 ' /></Link></ActionButton></div>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[15vh]'>
                                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                                    </div>
                                                )
                                            )
                                        }
                                        <Button className={'w-60 mx-auto mt-9'}>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                            <span className='ml-5'>Save Now</span>
                                        </Button>
                                    </form>
                                </Card>
                            </div>
                            {
                                viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                                    <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ? <PleaseWait/> : (
                                            <Fragment>
                                                <form>
                                                    <div className='grid grid-col-1 md:grid-cols-3 gap-4 mb-4'>
                                                        <div>
                                                            <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                                            <Input id={'flat_no'} type={'text'} name={'flat_no'} value={inputs.flat_no} onChange={handleChangesub} />
                                                            <Validation error={errors.flat_no} />
                                                        </div>
                                                        <div>
                                                            <Label htmlFor={'nature_of_flat'} labelText={'Nature Of Flat'} className={'mb-1'} />
                                                            <CustomSelect id={'nature_of_flat'} name={'nature_of_flat'} value={inputs.nature_of_flat} onChange={handleChangesub}>
                                                                <option value={''}>-- Choose Nature Of Flats --</option>
                                                                <option value={'1_BHK'}>1 BHK</option>
                                                                <option value={'2_BHK'}>2 BHK</option>
                                                                <option value={'3_BHK'}>3 BHK</option>
                                                                <option value={'4_BHK'}>4 BHK</option>
                                                                <option value={'5_BHK'}>5 BHK</option>
                                                            </CustomSelect>
                                                            <Validation error={errors.nature_of_flat} />
                                                        </div>
                                                        <div>
                                                            <Label htmlFor={'estimate_area'} labelText={'Estimate Area '} className={'mb-1'} />
                                                            <div className="flex">
                                                                <Input id={'estimate_area'} type={'text'} name={'estimate_area'} className={'rounded-none rounded-l-lg'} value={inputs.estimate_area} onChange={handleChangesub} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                                                                <span className="flex items-center font-bold px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                                    Sq.Ft.
                                                                </span>
                                                            </div>
                                                            <Validation error={errors.estimate_area} />
                                                        </div>
                                                    </div>
                                                    {/* <div className='mb-4'>
                                                   <div className='flex gap-5'>
                                                       <Label htmlFor={'flat_cad'} labelText={'Upload Flat CAD File'} />
                                                       <img src={flatcadImage ? (
                                                           flatcadImage.name ? URL.createObjectURL(flatcadImage) : IMG_URL + "/assets/images/FlatCADFile/" + flatcadImage
                                                       ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-40 h-40 rounded'/>
                                                   </div>
                                                   <Input id={'flat_cad'} type={'file'} name={'flat_cad'} onChange={flatcadImageChange} className={'p-[0px!important] '} />
                                                   <Validation error={errors.flat_cad} />
                                               </div> */}
                                                    <div className='grid grid-cols-1 md:grid-cols-3 mb-4 gap-x-6'>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'parking'} labelText={'Parking'} className={'mb-1'} />
                                                            <CustomSelect id={'parking'} name={'parking'} value={inputs.parking} onChange={handleChangesub}>
                                                                <option value={''}>--- Choose Parking Type ---</option>
                                                                <option value={'No'}>No</option>
                                                                <option value={'2_Wheeler'}>2 Wheeler</option>
                                                                <option value={'4_Wheeler'}>4 wheeler</option>
                                                                <option value={'2_&_4_Wheeler'}>2 & 4 Wheeler</option>
                                                            </CustomSelect>
                                                            <Validation error={errors.parking} />
                                                        </div>
                                                        <div>
                                                            <Label htmlFor={'parking_no'} labelText={'Parking No.'} className={'mb-1'} />
                                                            <Input id={'parking_no'} type={'text'} name={'parking_no'} value={inputs.parking_no} onChange={handleChangesub} />
                                                            <Validation error={errors.parking_no} />
                                                        </div>
                                                    </div>
                                                </form>
                                                {
                                                    id ? (
                                                        <div className='flex justify-center mt-4'>
                                                            <button type='submit' onClick={handleUpdatesubtask} className={'w-56 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color gap-3'}>
                                                                <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                                                                <span>Save Now</span>
                                                            </button>
                                                        </div>

                                                    ) : (
                                                        <div className='flex justify-center mt-4'>
                                                            <button type='submit' onClick={handleSubmitsubtask} className={'w-56 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color gap-3'}>
                                                                <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                                                                <span>Save Now</span>
                                                            </button>
                                                        </div>
                                                    )
                                                }

                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>

    )
}
export default AddFlatDetails

