import React, { Fragment, useEffect, useState } from 'react';
import { ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid';
import Card from '../../helpers/Card';
import Label from '../../helpers/Label';
import Input from '../../helpers/Input';
import Button from '../../helpers/Button';
import LinkButton from '../../helpers/LinkButton';
import Loader from '../../Common/Loader';
import axiosClient from '../../../Axios';
import Validation from '../../helpers/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import PageComponent from '../../../components/PageComponent';
import flasher from '@flasher/flasher';
import CustomSelect from '../../helpers/CustomSelect';
import ActionButton from '../../helpers/ActionButton';

function VoucherMasterAddEdit() {
    const { id } = useParams();
    if (id) {
        document.title = 'SiteSmart -Voucher Master';
    } else {
        document.title = 'SiteSmart - Add New Voucher Master';
    }
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [selectedLedgerFromBackend, setSelectedLedgerFromBackend] = useState([]);
    const [newlyAddedGroups, setNewlyAddedGroups] = useState([]);
    const [voucherInfo, setvoucherInfo] = useState({
        voucher_name: '',
        remark: ''
    });
    const handleChange = (e) => {
        setvoucherInfo(voucherInfo => ({
            ...voucherInfo,
            [e.target.name]: e.target.value,
        }));
    };

    const getSingleGroupData = () => {
        setLoader(true);
        axiosClient.get(`/single_vouchermaster_data/${id}`)
            .then(({ data }) => {
                setLoader(false);
                setvoucherInfo(data.single_data);
                setSelectedLedgerFromBackend(data.Multiledgers);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const ledgerIds = selectedLedgerFromBackend.map((ledger_name) => ledger_name.id);
        const payload = {
            voucher_name: voucherInfo.voucher_name,
            remark: voucherInfo.remark,
            ledger_name: ledgerIds
        };
        setLoader(true);
        axiosClient
            .post(id ? `update_vouchermaster/${id}` : '/store_vouchermaster', payload)
            .then(({ data }) => {
                setLoader(false);
                flasher.success(data.msg);
                navigate('/accounting/voucher-master')
            })
            .catch(({ response }) => {
                setLoader(false);
                setErrors(response.data.errors);
            });
    };

    const [ledger, setLedgerLists] = useState([]);
    function openModal() {
        axiosClient.get('/Accountledger_lists')
            .then(({ data }) => {
                setLoader(false);
                setLedgerLists(data.lists);
            });
    }

    const deleteLedger = (id) => {
        axiosClient.delete(`/delete_Mulitigrups_forvoucher/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
            })
    }

    const handleGroupSelection = (selectedOption) => {
        const selectedGroup = ledger.find((data) => data.ledger_name === selectedOption);
        if (selectedGroup) {
            if (selectedGroup.id) {
                setSelectedLedgerFromBackend([...selectedLedgerFromBackend, selectedGroup]);
            } else {
                setNewlyAddedGroups([...newlyAddedGroups, selectedGroup]);
            }
        }
    };

    const removeSelectedLedger = (ledgerToRemove) => {
        if (ledgerToRemove.id) {
            deleteLedger(ledgerToRemove.id);
            setSelectedLedgerFromBackend((prevLedger) => prevLedger.filter((group) => group.id !== ledgerToRemove.id));
        } else {
            setNewlyAddedGroups((prevLedger) => prevLedger.filter((group) => group !== ledgerToRemove));
        }
    };

    useEffect(() => {
        if (id) {
            getSingleGroupData();
        }
        openModal()
    },[]);

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Voucher Master' : 'Add New Voucher Master'} button={
                    <LinkButton to={'/accounting/voucher-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[50rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'voucher_name'} labelText={'Voucher Name'} className={'mb-1'} />
                                        <Input id={'voucher_name'} type={'text'} name={'voucher_name'} value={voucherInfo.voucher_name} onChange={handleChange} />
                                        <Validation error={errors.voucher_name} />
                                    </div>  
                                    <div>
                                        <Label htmlFor={''} labelText={'Ledger Name'} className={'mb-1'} />
                                        <CustomSelect
                                            onChange={(e) => {
                                                const selectedOption = e.target.value;
                                                if (selectedOption) {
                                                    handleGroupSelection(selectedOption);
                                                }
                                            }}>
                                            <option value=" ">--- Select a Ledger ---</option>
                                            {ledger.map((data, ID) => (
                                                <option key={ID} value={data.ledger_name}>
                                                    {data.ledger_name}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'remark'} labelText={'Remark'} className={'mb-1'} />
                                        <Input id={'remark'} type={'text'} name={'remark'} value={voucherInfo.remark} onChange={handleChange} />
                                        <Validation error={errors.remark} />
                                    </div>
                                </div>
                                <h1 className='text-xl font-bold mt-3'> Ledger List </h1>
                                <div className="mt-4 grid grid-cols-3 gap-4">
                                    {[...selectedLedgerFromBackend, ...newlyAddedGroups].map((name, index) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <button type='button' className={"group relative flex justify-center rounded-l-lg bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color w-full "}>
                                                {name.ledger_name}
                                            </button>
                                            <ActionButton onClick={() => removeSelectedLedger(name)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg rounded-l-none cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex justify-center mt-4'>
                                    <Button className={'w-[30%]  mt-6  hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    );
}


export default VoucherMasterAddEdit
