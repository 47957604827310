import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import CustomSelect from '../../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'

function RawFieldtwo() {
    const { id } = useParams()
    // eslint-disable-next-line no-lone-blocks
    {
        id ? (
            document.title = 'SiteSmart - Edit Account Raw'
        ) : (
            document.title = 'SiteSmart - Add New Account Raw'
        )
    }

    const [loader, setLoader] = useState(false)
    const [accountgroupLists, setAccountGroupLists] = useState()
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        accountLedger_name: '',
        account_group: '',
        op_balance: '',
        description: '',
        bank_name: '',
        bank_branch: '',
        bank_account_no: '',
        ifsc_code: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('accountLedger_name', formInfo.accountLedger_name)
        formData.append('account_group', formInfo.account_group)
        formData.append('op_balance', formInfo.op_balance)
        formData.append('description', formInfo.description)
        formData.append('bank_name', formInfo.bank_name)
        formData.append('bank_branch', formInfo.bank_branch)
        formData.append('bank_account_no', formInfo.bank_account_no)
        formData.append('ifsc_code', formInfo.ifsc_code)

        axiosClient.post(id ? `update_AccountLedger/${id}` : '/store_Accountledger', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/accounting/account-ledger')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getAccountGroupLists = () => {
        setLoader(true)
        axiosClient.get('/accountgroup_lists')
            .then(({ data }) => {
                setLoader(false)
                setAccountGroupLists(data.lists)
            })
    }

    const getSingleAccountLedgerData = () => {
        setLoader(true)
        axiosClient.get(`/single_Accountledger_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleAccountLedgerData()
        }
        getAccountGroupLists();
    }, [])

    return (
        <Fragment>

            {loader ? (<Loader />) : (
                <PageComponent title={'Sales'} button={
                    <LinkButton to={'/accounting/account-ledger'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-96 md:w-[70%] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div>
                                        <Label htmlFor={'accountLedger_name'} labelText={'Sales Invoice'} className={'mb-1'} />
                                        <Input id={'accountLedger_name'} type={'text'} name={'accountLedger_name'} value={formInfo.accountLedger_name} onChange={handleChange} />
                                        <Validation error={errors.accountLedger_name} />
                                    </div>
                                    {/* <div>
                                        <Label htmlFor={'account_group'} labelText={'Account Group'} className={'mb-1'} />
                                        <CustomSelect id={'account_group'} name={'account_group'} className={''} value={formInfo.account_group} onChange={handleChange}>
                                            <option value={''}>--- Choose ---</option>
                                            {
                                                Array.isArray(accountgroupLists) && accountgroupLists.map(data => {
                                                    return (
                                                        <option value={data.accountgroup_name}>{data.accountgroup_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.account_group} />
                                    </div> */}
                                    <div>
                                        <Label htmlFor={'op_balance'} labelText={'Customer Name'} className={'mb-1'} />
                                        <Input id={'op_balance'} type={'text'} name={'op_balance'} value={formInfo.op_balance} onChange={handleChange} />
                                        <Validation error={errors.op_balance} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'description'} labelText={'Customer Contact'} className={'mb-1'} />
                                        <Input id={'description'} type={'text'} name={'description'} value={formInfo.description} onChange={handleChange} />
                                        <Validation error={errors.description} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_name'} labelText={'Date Of Sale'} className={'mb-1'} />
                                        <Input id={'bank_name'} type={'text'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange} />
                                        <Validation error={errors.bank_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_branch'} labelText={'Product/Service Sold'} className={'mb-1'} />
                                        <Input id={'bank_branch'} type={'text'} name={'bank_branch'} value={formInfo.bank_branch} onChange={handleChange} />
                                        <Validation error={errors.bank_branch} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_account_no'} labelText={'Quantity Sold'} className={'mb-1'} />
                                        <Input id={'bank_account_no'} type={'text'} name={'bank_account_no'} value={formInfo.bank_account_no} onChange={handleChange} />
                                        <Validation error={errors.bank_account_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Unit Price'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Total Sale Amount'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Currency'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Payment Method'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Tax Amount'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'Notes'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                </div>
                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>

                            </form>









                        </Card>

                    </div>


                </PageComponent>
            )}
        </Fragment>
    )
}




export default RawFieldtwo
