import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import { useStateContext } from '../../context/ContextProvider'
import flasher from '@flasher/flasher'

function WorkTypeMasterAddEdit() {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart -Work Type Master'
    } else {
        document.title = 'SiteSmart - Add New Work Type'
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()

    const [formInfo, setFormInfo] = useState({
        work_type: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const getSingleWorkData = () => {
        setLoader(true)
        axiosClient.get(`/single_worktype_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })          
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            work_type: formInfo.work_type,
        }
        setLoader(true)
        axiosClient.post(id ? `/update_worktype/${id}` : '/store_worktype', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/worktype-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }


    useEffect(() => {
        if (id) {
            getSingleWorkData()
        }
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Work Type' : 'Add New Work Type'} button={
                    <LinkButton to={'/worktype-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center h-[80vh]'>
                        <Card className={'w-full md:w-80 px-6 py-4 bg-default-color'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_type'} labelText={'Work Type'} className={'mb-1'} />
                                        <Input id={'work_type'} type={'text'} name={'work_type'} value={formInfo.work_type} onChange={handleChange} />
                                        <Validation error={errors.work_type} />
                                    </div>
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default WorkTypeMasterAddEdit