import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../Common/Loader'
import TextArea from '../helpers/TextArea'

function EnquiryAddEdit() {
    const { id } = useParams()
    {
        id ? (document.title = 'SiteSmart - Edit Enquiry') : (document.title = 'SiteSmart - Add New Enquiry')
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    let currentDate = new Date().toJSON().slice(0, 10);
    const [formInfo, setFormInfo] = useState({
        client_name: '',
        mobile_no: '',
        email_id: '',
        source: '',
        enquiry_for: '',
        work_name: '',
        wing_name: '',
        level_name: '',
        nature_of_flat: '',
        parking: '',
        enquiry_date: currentDate,
        contact_mode: '',
        clients_response: '',
        lead_by: '',
        reminder_date: '',
        address: '',
        remark: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('client_name', formInfo.client_name)
        formData.append('mobile_no', formInfo.mobile_no)
        formData.append('email_id', formInfo.email_id)
        formData.append('source', formInfo.source)
        formData.append('enquiry_for', formInfo.enquiry_for)
        formData.append('work_name', formInfo.work_name)
        formData.append('wing_name', formInfo.wing_name)
        formData.append('level_name', formInfo.level_name)
        formData.append('nature_of_flat', formInfo.nature_of_flat)
        formData.append('area', area)
        formData.append('parking', formInfo.parking)
        formData.append('enquiry_date', formInfo.enquiry_date)
        formData.append('contact_mode', formInfo.contact_mode)
        formData.append('clients_response', formInfo.clients_response)
        formData.append('lead_by', formInfo.lead_by)
        formData.append('reminder_date', formInfo.reminder_date)
        formData.append('address', formInfo.address)
        formData.append('remark', formInfo.remark)
        axiosClient.post(id ? `update_enquiry/${id}` : '/store_enquiry', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/enquiry')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleEnquiryData = () => {
        setLoader(true)
        axiosClient.get(`/single_enquiry_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setArea(data.single_data.area);
            })
    }

    const [workNameLists, setWorkNameLists] = useState([])
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkNameLists(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [natureFlatList, setNatureFlatList] = useState([])
    const getNatureFlatLists = () => {
        formInfo.level_name && axiosClient.get(`/level_wise_flat/${formInfo.wing_name}/${formInfo.level_name}`)
            .then(({ data }) => {
                setNatureFlatList(data.level_wise_flat)
            })
    }

    const [area, setArea] = useState('');
    const getEstimateArea = () => {
        var estimate_area = '';
        formInfo.nature_of_flat && natureFlatList.map((data) => {
            return (
                formInfo.nature_of_flat == data.id && (estimate_area = data.estimate_area)
            )
        })
        setArea(estimate_area);
    }

    useEffect(() => {
        getEstimateArea()
    }, [formInfo.nature_of_flat])

    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getNatureFlatLists()
    }, [formInfo.level_name])

    useEffect(() => {
        if (id) {
            getSingleEnquiryData()
        }
        getWorkData()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Enquiry' : 'Add New Enquiry'} button={
                    <LinkButton to={'/enquiry'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-96 md:w-[90%] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-1 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div>
                                        <Label htmlFor={'client_name'} labelText={'Client Name'} className={'mb-1'} />
                                        <Input id={'client_name'} type={'text'} name={'client_name'} value={formInfo.client_name} onChange={handleChange} />
                                        <Validation error={errors.client_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'mobile_no'} labelText={'Mobile No.'} className={'mb-1'} />
                                        <Input id={'mobile_no'} type={'text'} name={'mobile_no'} value={formInfo.mobile_no} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                                        <Validation error={errors.mobile_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'source'} labelText={'Source'} className={'mb-1'} />
                                        <CustomSelect id={'source'} name={'source'} defaultValue={formInfo.source} value={formInfo.source} onChange={handleChange}>
                                            <option  value={''}>--- Select Source ---</option>
                                            <option value={'Social media'}>Social media</option>
                                            <option value={'Newspaper'}>Newspaper</option>
                                            <option value={'Hoardings'}>Hoardings</option>
                                            <option value={'Mouth publicity'}>Mouth publicity</option>
                                            <option value={'Other'}>Other</option>
                                        </CustomSelect>
                                        <Validation error={errors.source} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'email_id'} labelText={'Email ID'} className={'mb-1'} />
                                        <Input id={'email_id'} type={'email'} name={'email_id'} value={formInfo.email_id} onChange={handleChange} />
                                        <Validation error={errors.email_id} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'enquiry_for'} labelText={'Enquiry For'} className={'mb-1'} />
                                        <CustomSelect id={'enquiry_for'} name={'enquiry_for'} defaultValue={formInfo.enquiry_for} value={formInfo.enquiry_for} onChange={handleChange}>
                                            <option  value={''}>--- Select ---</option>
                                            <option value={'Commercial'}>Commercial</option>
                                            <option value={'Residential'}>Residential</option>
                                            <option value={'Bunglow'}>Bunglow</option>
                                            <option value={'Pent House'}>Pent House</option>
                                            <option value={'Other'}>Other</option>
                                        </CustomSelect>
                                        <Validation error={errors.enquiry_for} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                            <option  value={''}>--- Select Work ---</option>
                                            {
                                                workNameLists.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option  value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option  value={''}>--- Select level_name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'nature_of_flat'} labelText={'Nature Of Flat'} className={'mb-1'} />
                                        <CustomSelect id={'nature_of_flat'} name={'nature_of_flat'} value={formInfo.nature_of_flat} onChange={(e) => { handleChange(e); getEstimateArea() }}>
                                            <option  value={''}>--- Select Flat Nature ---</option>
                                            {natureFlatList && natureFlatList.map((natureData, natureId) => {
                                                return (<Fragment key={natureId}>
                                                    <option value={natureData.id}>{natureData.nature_of_flat}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.nature_of_flat} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'area'} labelText={'Area (sq / ft)'} className={'mb-1'} />
                                        <Input id={'area'} type={'text'} name={'area'} value={area} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} readOnly={true} />
                                        <Validation error={errors.area} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'parking'} labelText={'Parking'} className={'mb-1'} />
                                        <CustomSelect id={'parking'} name={'parking'} defaultValue={formInfo.parking} value={formInfo.parking} onChange={handleChange}>
                                            <option  value={''}>--- Select ---</option>
                                            <option value={'None'}>No</option>
                                            <option value={'2_Wheeler'}>2 Wheeler</option>
                                            <option value={'4_Wheeler'}>4 Wheeler</option>
                                            <option value={'2_&_4_Wheeler'}>2 & 4 Wheeler</option>
                                        </CustomSelect>
                                        <Validation error={errors.parking} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'contact_mode'} labelText={'Contact Mode'} className={'mb-1'} />
                                        <CustomSelect id={'contact_mode'} name={'contact_mode'} value={formInfo.contact_mode} onChange={handleChange}>
                                            <option  value={''}>--- Select Contact Mode ---</option>
                                            <option value={'Call'}>Call</option>
                                            <option value={'Site'}>Site</option>
                                            <option value={'Other'}>Other</option>
                                        </CustomSelect>
                                        {/* <Validation error={errors.contact_mode} /> */}
                                    </div>
                                    <div>
                                        <Label htmlFor={'clients_response'} labelText={"Client's Response"} className={'mb-1'} />
                                        <CustomSelect id={'clients_response'} name={'clients_response'} defaultValue={formInfo.clients_response} value={formInfo.clients_response} onChange={handleChange}>
                                            <option  value={''}>--- Select Response Level ---</option>
                                            <option value={'High'}>High</option>
                                            <option value={'Low'}>Low</option>
                                            <option value={'Moderate'}>Moderate</option>
                                        </CustomSelect>
                                        <Validation error={errors.clients_response} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'lead_by'} labelText={'Lead By'} className={'mb-1'} />
                                        <Input id={'lead_by'} type={'text'} name={'lead_by'} value={formInfo.lead_by} onChange={handleChange} />
                                        <Validation error={errors.lead_by} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'reminder_date'} labelText={'Reminder Date'} className={'mb-1'} />
                                        <Input id={'reminder_date'} type={'date'} name={'reminder_date'} value={formInfo.reminder_date} onChange={handleChange} />
                                        <Validation error={errors.reminder_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'address'} labelText={'Address'} className={'mb-1'} />
                                        <TextArea id={'address'} name={'address'} value={formInfo.address} rows={1} onChange={handleChange} />
                                        <Validation error={errors.address} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'remark'} labelText={'Remark'} className={'mb-1'} />
                                        <TextArea id={'remark'} name={'remark'} value={formInfo.remark} rows="1" cols="50" onChange={handleChange} />
                                        <Validation error={errors.remark} />
                                    </div>
                                </div>
                                <div className="mt-1">
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default EnquiryAddEdit
