import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon } from '@heroicons/react/24/solid'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import ViewModel from '../../helpers/ViewModel'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination'
import PleaseWait from '../../Common/PleaseWait'

function ClientPayment() {
    document.title = 'SiteSmart - Client Payment'
    const tableHeading = ['Sr.No', 'Work Name', 'Client Name', 'Final Amount', 'Remaining Balance', 'Status', 'Action']
    const tableHeading2 = ['Sr.No', 'Total Amount', 'Advance', 'Amount payable', 'Remaining Balance', 'Received Date', 'Mode Of Payment', 'Responsible Person', 'Remark']
    const [loader, setLoader] = useState(false)
    const [clientPaymentLists, setClientPaymentLists] = useState([])
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [tableData, setTableData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const getClientPaymentLists = () => {
        setLoader(true)
        axiosClient.get('/ClientPayment_lists')
            .then(({ data }) => {
                setLoader(false)
                setClientPaymentLists(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function openModal(id) {
        setModalLoader(true)
        setViewModel(true)
        axiosClient.get(`/single_ClientPayment_data/${id}`)
            .then(({ data }) => {
                setModalData(data.single_data)
            })
        axiosClient.get(`/client_payment_installments_list/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setTableData(data.client_wise_installments_data)
            })
    }

    useEffect(() => {
        getClientPaymentLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Client Payment'} >
                    {/* <div className="justify-end flex">
                        <Button to={'/accounting/client-payment/add-client-payment'}>
                            <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                            <span>Sort By Client name</span>
                        </Button>
                        <Button className='ml-2' to={'/accounting/client-payment/add-client-payment'}>
                            <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                            <span>Sort By Work name</span>
                        </Button>
                    </div> */}
                    <br />
                    {clientPaymentLists && clientPaymentLists.length > 0 ? (

                        <Table tableHeading={tableHeading}>
                            {clientPaymentLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.final_amount).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.remaining_balance).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            {
                                                tableData.final_amount == tableData.remaining_balance ? <Link title='Edit' to={`/accounting/client-payment/edit-client-payment/${tableData.id}`} className='border-2  bg-red-600 border-red-600 text-white text-center px-5 py-1.5 rounded-lg'>Advance</Link> :
                                                    tableData.remaining_balance == '0' ? <button className='border-2  bg-green-600 border-green-600 text-white w-32 text-center px-3 py-1 rounded-lg'> Completed </button> :
                                                        <Link title='Edit' to={`/accounting/client-payment/edit-client-payment/${tableData.id}`} className='border-2  bg-orange-400 border-orange-400 text-white w-32 text-center px-3 py-1 rounded-lg'>InProcess</Link>
                                            }
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Client Payment Data Not Found</p>
                        </div>
                    )}
                    {clientPaymentLists && clientPaymentLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={clientPaymentLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <div className='p-0 md:p-5' ref={componentRef} >
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Client Payment Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 mb-2 '>
                                                <div >
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Client Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Work Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Level Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.level_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Nature of Flat</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Parking</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.parking && ((modalData.parking).split('_')).join(' ')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Parking Area</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.parking_area}</h1>
                                                    </div>
                                                </div>
                                                <div >
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Mobile No</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.mobile_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Wing Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Flat No.</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.flat_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Carpet Area</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{parseInt(modalData.carpet_area).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Parking No</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.parking_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Lead By</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.lead_by}</h1>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='w-[50vw] md:w-full'>
                                                {tableData && tableData.length > 0 ? (
                                                    <Table tableHeading={tableHeading2}>
                                                        {tableData.map((data, dataID) => {
                                                            return (<Fragment key={dataID}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td className="px-4 py-3">{dataID + 1}</td>
                                                                    <td className="px-4 py-3">{parseInt(data.final_amount).toLocaleString('en-IN')}</td>
                                                                    <td className="px-4 py-3">{parseInt(data.advance).toLocaleString('en-IN')}</td>
                                                                    <td className="px-4 py-3">{parseInt(data.amount_payable).toLocaleString('en-IN')}</td>
                                                                    <td className="px-4 py-3">{parseInt(data.remaining_balance).toLocaleString('en-IN')}</td>
                                                                    <td className="px-4 py-3">{moment(data.received_date).format('DD-MM-YYYY')}</td>
                                                                    <td className="px-4 py-3">{data.received_to_acc}</td>
                                                                    <td className="px-4 py-3">{data.name}</td>
                                                                    <td className="px-4 py-3">{data.remarks}</td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[30vh]'>
                                                        <p className='md:text-xl text-[3vw] font-bold'>Sorry! Client Installment Data Not Found</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div ></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )
            }
        </Fragment >
    )
}

export default ClientPayment