import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { PhotoIcon } from '@heroicons/react/24/solid'
import Label from '../../helpers/Label'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import {useNavigate, useParams } from 'react-router-dom'
import {useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Button from '../../helpers/Button'
import ViewModel from '../../helpers/ViewModel'
import { Dialog } from '@headlessui/react'
import PleaseWait from '../../Common/PleaseWait'


function SaleDashboard() {
    const { id, work_name, work_id } = useParams()
        document.title = 'SiteSmart -  Sales Dashboard'
    
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [modalData, setModalData] = useState([])
    const [modelImage, setModelImage] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)

    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setImgModalLoader(false)
    }
    // Api to Get Work Name And Id   //
    const [workname, setWorkName] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
            })
    }
    // Api to Get Wing List  //
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    // Api to Get Level List  //
    const [level, setLevel] = useState([]);
    const getLevelData = () => {
        work_id && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${work_id}/${formInfo.wing_name}/`)
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.work_wing_wise_leveldata)
            })
    }
    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        no_of_levels: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    // Api to fetch data of Flats //
    const [flatLists, setFlatLists] = useState('')
    const [floorImage, setFloorImage] = useState('')
    const getFlatLists = () => {
        setLoader(true)
      work_id && formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_level_wise_flat/${work_id}/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setLoader(false)
                setFlatLists(data.level_wise_flat)
                setFloorImage(data.floor_details.upload_floor_file)
            })
    }
    useEffect(() => {
        getWorkDetails();
    }, [])

    useEffect(() => {
        getWingData();
    }, [work_id])

    useEffect(() => {
        getFlatLists();
    }, [work_id && formInfo.wing_name && formInfo.level])

    useEffect(() => {
        getLevelData()
    }, [work_id && formInfo.wing_name])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent className={'bg-white'} title={'Sale Dashboard'} >
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='rounded flex content-center items-center  justify-center bg-white '>
                                <div className='grid grid-cols-1 md:grid-cols-2  bg-white rounded p-5 gap-x-28 ' onSubmit={getFlatLists}>
                                    {/* <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true} />
                                        <Validation error={errors.work_name} />
                                    </div> */}
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'flex justify-center '} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Choose wing Status ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                    </div>
                                    <div>
                                        <Label htmlFor={'level'} labelText={'Level'} className={'flex justify-center'} />
                                        <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={(e) => { handleChange(e); getFlatLists(e) }}>
                                            <option value={''}>--- Choose level Name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level} />
                                    </div>
                                </div>
                            </div>
                            <div className='grid items-center justify-center mt-5 bg-white rounded-xl'>
                                {flatLists && flatLists.length > 0 ? (
                                    <div>
                                        <div className='flex justify-end mb-4'>
                                            <Button className='w-35 mt-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300 pr-8 pl-8' onClick={() => handleModelImage()}>
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"><PhotoIcon className="h-5 w-5 text-default-color" /></span>
                                                <span className='ml-5'>Floor Plan</span>
                                            </Button>
                                        </div>
                                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-7'>
                                            {flatLists.map((tableData) => {
                                                return (
                                                    <div className="w-[200px] h-[200px] bg-transparent cursor-pointer group perspective mb-4">
                                                        <div className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000 bg-gradient-to-t from-blue-300 mb-8">
                                                            {/* // First screen Start // */}
                                                            <div className="absolute backface-hidden w-full h-full border-spacing-2 rounded">
                                                                <div className="p-3">
                                                                    <div className='justify-center text-center font-extrabold p-3'>{((tableData.nature_of_flat).split('_')).join(' ')}</div>
                                                                    <hr className="h-px bg-gray-200 border-1 dark:bg-gray-900 mb-1" />
                                                                    <div className='border-solid border-2 border-gray-500 grid justify-center content-center font-bold text-3xl space-x-3 p-4 m-2 mb-1 '>{tableData.flat_no}
                                                                    </div>
                                                                    <hr className="h-px bg-gray-200 border-1 dark:bg-gray-900" />
                                                                    <div className=' justify-center text-center font-bold p-3 break-words'>
                                                                        {tableData.estimate_area} Sq/Ft
                                                                    </div>
                                                                </div>
                                                            </div> {/* // First screen End // */}
                                                            {/* // Second screen Start // */}
                                                            <div className="absolute my-rotate-y-180 backface-hidden shadow-xl  w-full h-full duration-1000 bg-gradient-to-t from-blue-300 overflow-hidden">
                                                                <div className="text-center flex flex-col justify-center h-full text-gray-800">
                                                                    {flatLists.map((tableData2) => {
                                                                        return (
                                                                            tableData.flat_no == tableData2.flat_no && (
                                                                                <div className='mt-10'>
                                                                                    <div className='flex gap-1 p-2 '>
                                                                                        <h1 className='w-auto  font-bold'>Flat Status</h1>
                                                                                        <h1>-</h1>
                                                                                        <h1 className='ml-2'>{tableData2.sold_status}</h1>
                                                                                    </div>
                                                                                    <div className='flex gap-1 p-2'>
                                                                                        <h1 className='w-auto font-bold'>Owner Name</h1>
                                                                                        <h1>-</h1>
                                                                                        <h1 className='ml-2'>{tableData2.owner_name}</h1>
                                                                                    </div>
                                                                                    <div className='flex items-center gap-1 p-2'>
                                                                                        <h1 className='w-auto font-bold'>Carpet Area</h1>
                                                                                        <h1>-</h1>
                                                                                        <h1 className='ml-2'>{tableData2.carpet_area}</h1>
                                                                                    </div>
                                                                                    {/* <div className='flex justify-start m-3' >
                                                                                        <Button className='w-35 mt-6 transition rounded-3xl ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300 pr-5 pl-8' onClick={() => handleModelImage(floorImage, 'Floor Image')}>
                                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2"><PhotoIcon className="h-5 w-5 text-color" /></span>
                                                                                            <span className='ml-2'>Flat Plan</span>
                                                                                        </Button>
                                                                                    </div> */}
                                                                                </div>
                                                                            )
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-[35vh]'>
                                        <p className='text-3xl font-bold'>Please select Wing and Level </p>
                                    </div>
                                )}
                            </div>
                            {
                                imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                                    <Dialog.Panel className="w-[60%] max-w-4xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {imgmodalLoader ? <PleaseWait/> : (
                                            <Fragment>
                                                <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Floor Plan</h1>
                                                <hr className='my-4 border border-primary-color' />
                                                {
                                                    <img src={floorImage ? (
                                                        floorImage.name ? URL.createObjectURL(floorImage) : IMG_URL + `/assets/images/FloorCADFile/` + floorImage
                                                    ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                                }
                                                <div className="mt-4">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => setImgModel(false)}
                                                    > Got it, thanks!
                                                    </button>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default SaleDashboard
