import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'

function AccountGroupAddEdit() {
    const { id } = useParams()
    if(id){
        document.title = 'SiteSmart - Edit Unit'
    }else{
        document.title = 'SiteSmart - Add New Unit'
    }
    
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        accountgroup_name : '',
        group_under : '',
        narration : '',
    })
    const handleChange = (e) =>{
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name] : e.target.value
        }))
    }
    const [groupmasterLists, setGroupMasterLists] = useState('')
    const getGroupMasterlist = () => {
        setLoader(true)
       axiosClient.get('/groupmaster_list')
            .then(({ data }) => {
                setLoader(false)
                setGroupMasterLists(data.lists)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoader(true)
        const formData = new FormData()
        formData.append('accountgroup_name', formInfo.accountgroup_name)
        formData.append('group_under', formInfo.group_under)
        formData.append('narration', formInfo.narration)
        axiosClient.post(id ? `update_accountgroup/${id}` : '/store_accountgroup', formData)
        .then(({data})=>{
            setLoader(false)
            flasher.success(data.msg)
            navigate('/accounting/account-group')
        })
        .catch(({response})=>{
            setLoader(false)
            setErrors(response.data.errors)
        })
    }

    const getSingleUnitData = () => {
        setLoader(true)
        axiosClient.get(`/single_accountgroup_data/${id}`)
        .then(({data})=>{
            setLoader(false)
            setFormInfo(data.single_data)
        })
    }
    
    useEffect(()=>{
        if(id){
            getSingleUnitData()
        }
        getGroupMasterlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <PageComponent title={id ? 'Edit Account Group' : 'Add New Account Group'} button={
                    <LinkButton to={'/accounting/account-group'}>
                        <ArrowLeftCircleIcon className='w-6 h-6'/>
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center h-[70vh]'>
                        <Card className={'w-96 md:w-80 px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <Label htmlFor={'accountgroup_name'} labelText={'Account Group Name'} className={'mb-1'}/>
                                    <Input id={'accountgroup_name'} type={'text'} name={'accountgroup_name'} value={formInfo.accountgroup_name} onChange={handleChange}/>
                                    <Validation error={errors.accountgroup_name}/>
                                </div>
                                <div className='mb-3'>
                                        <Label htmlFor={'group_under'} labelText={'Group Name'} className={'mb-1'} />
                                        <CustomSelect id={'group_under'} name={'group_under'} value={formInfo.group_under} onChange={handleChange}>
                                            <option value={''}>--- Select Group Name ---</option>
                                            {groupmasterLists && groupmasterLists.map((groupmasterdata, groupmasterID) => {
                                                return (<Fragment key={groupmasterID}>
                                                    <option value={groupmasterdata.id}>{groupmasterdata.group_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.group_under} />
                                    </div>
                                {/* <div className='mb-3'>
                                    <Label htmlFor={'group_under'} labelText={'Group Under'} className={'mb-1'}/>
                                    <CustomSelect id={'group_under'} name={'group_under'} value={formInfo.group_under} onChange={handleChange}>
                                        <option value={''}>--- Choose Status ---</option>
                                        <option value={'Assets'}>Assets</option>
                                        <option value={'Expenses'}>Expenses</option>
                                        <option value={'Income'}>Income</option>
                                        <option value={'Liabilities'}>Liabilities</option>
                                    </CustomSelect>
                                    <Validation error={errors.group_under}/>
                                </div> */}
                                <div className='mb-3'>
                                    <Label htmlFor={'narration'} labelText={'Narration'} className={'mb-1'}/>
                                    <Input id={'narration'} type={'text'} name={'narration'} value={formInfo.narration} onChange={handleChange}/>
                                    <Validation error={errors.narration}/>
                                </div>
                                <Button className={'w-full mt-6'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color"/></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default AccountGroupAddEdit