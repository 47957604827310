import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../helpers/ActionButton'

function AccountGroup() {
    document.title = 'SiteSmart - AccountGroup'
    const tableHeading = ['Sr.No', 'Account Group Name', 'Group Under', 'Narration', 'Action']
    const [loader, setLoader] = useState(false)
    const [accountgroupLists, setAccountGroupLists] = useState('')
    const getAccountGroupLists = () => {
        setLoader(true)
        axiosClient.get('/accountgroup_lists')
        .then(({data})=>{
            setLoader(false)
            setAccountGroupLists(data.lists)
        })
    }

    const deleteAccountGroup = (id) =>{
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_accountgroup/${id}`)
                .then(({data})=>{
                    flasher.success(data.msg)
                    getAccountGroupLists()
                })
            }
        })
    }
    useEffect(()=>{
        getAccountGroupLists()
    },[])
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <PageComponent title={'Account Group'} button={
                    <LinkButton to={'/accounting/add-account-group'}>
                        <PlusCircleIcon className='w-5 h-5'/>
                        <span>Add New Account Group</span>
                    </LinkButton>
                }>
                    {accountgroupLists && accountgroupLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {accountgroupLists.map((tableData, tableDataID)=>{
                                return(<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID+1}</td>
                                        <td className="px-4 py-3">{tableData.accountgroup_name}</td>
                                        <td className="px-4 py-3">{tableData.group_name}</td>
                                        <td className="px-4 py-3">{tableData.narration}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link title='Edit' to={`/accounting/edit-account-group/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5'/></ActionButton></Link>
                                                <ActionButton onClick={()=>deleteAccountGroup(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5'/></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Account Group Data Not Found</p>
                        </div>
                    )}
                </PageComponent>
            )}
        </Fragment>
    )
}

export default AccountGroup