import React, { Fragment, useState } from 'react'
import { useStateContext } from '../../context/ContextProvider'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Validation from '../helpers/Validation'
import Button from '../helpers/Button'
import { EyeIcon, EyeSlashIcon, KeyIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from "@flasher/flasher";
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../Common/Loader'

function ResetPassword() {
    document.title = 'SiteSmart - Reset Password'
    const [formInfo, setFormInfo] = useState({new_password: ''});
    const [errors, setErrors] = useState([]);
    const { FRONTEND_URL } = useStateContext()
    const [loader, setLoader] = useState(false)
    const { emailID } = useParams()
    const navigate = useNavigate()
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const formData = new FormData();
        formData.append('emailID', emailID);
        formData.append('new_password', formInfo.new_password);
        axiosClient.post('/reset_password', formData)
        .then(({ data }) => {
            setLoader(false)
            navigate('/login')
            flasher.success(data.msg)
        })
        .catch(({ response }) => {
            setLoader(false)
            setErrors(response.data.errors)
        })
    }
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () =>{
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <div className="flex w-full md:w-[35%] items-center px-4 py-12 sm:px-6 lg:px-8 absolute">
                    <div className="w-full space-y-2 pt-4 pb-6 px-6 rounded-lg">
                        <div>
                            <img className="mx-auto w-auto" src={FRONTEND_URL + "/assets/logos/logo1.png"} alt="Your Company" />
                            <h2 className="text-center text-3xl text-white uppercase font-extrabold tracking-widest mt-4">Reset Password</h2>
                            <p className="mt-2 text-center text-white">
                                Or{' '}
                                <Link to="/login" className="font-medium hover:text-indigo-500">
                                    Go To Login
                                </Link>
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="rounded-md shadow-sm">
                                <div className='mb-6'>
                                    <Label labelText={'New Password'} htmlFor='new_password' className={'mb-1 font-medium text-white'} />
                                    <div className='relative'>
                                        <Input type={passwordType} value={formInfo.new_password} name={'new_password'} id={'new_password'} onChange={handleChange}/>
                                        {passwordType === 'password' ? (
                                            <EyeIcon className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword}/>
                                        ) : (
                                            <EyeSlashIcon className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword}/>
                                        )}
                                    </div>
                                    <Validation error={errors.new_password} />
                                </div>
                            </div>
                            <Button className={'w-full'}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <KeyIcon className="h-5 w-5 text-default-color" aria-hidden="true" />
                                </span>
                                Reset Password
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default ResetPassword