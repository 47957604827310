import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PhotoIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import moment from 'moment'
import { useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import { Dialog } from '@headlessui/react'
import Button from '../../helpers/Button'
import ViewModel from '../../helpers/ViewModel'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'
import PleaseWait from '../../Common/PleaseWait'

function WingDetails() {
    document.title = 'SiteSmart - Wing Details'
    const tableHeading = ['Sr.No', 'Wing Name', 'Budget', 'Start Date', 'Complition Date', 'Status', 'Action']
    const [loader, setLoader] = useState(false)
    const { work_id } = useParams()
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [wingLists, setWingLists] = useState('')
    const [workname, setWorkName] = useState("")
    const getWingLists = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_wingdata/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWingLists(data.work_wise_wingdata)
                setWorkName(data.list)
            })
    }

    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [modalData, setModalData] = useState([])
    const [modelImage, setModelImage] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)

    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setImgModalLoader(false)
    }
    const [wingImage, setWingImage] = useState([])
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_wing_details_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
                setWingImage(data.single_data.upload_wingcad)

            })
    }
    const deleteWork = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_wing_details/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getWingLists()
                    })
            }
        })
    }
    useEffect(() => {
        getWingLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Wing Details'} button={
                    <LinkButton to={`/work-management/add-wing-details-form/${work_id}`}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Wing</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                          
                        )
                        }
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname && workname.work_name}</h1>
                            <h1 className='text-center mt-1 mb-2 hidden md:block'>
                                {
                                    workname && (workname.work_category === 'project_management' ? "Project Management"
                                        : workname.marketing_type === 'interior_designing' ? "Interior Designing"
                                            : workname.marketing_type === 'plan_and_designing' ? "Plan & Designing"
                                                : "Construction")
                                }
                            </h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            {wingLists && wingLists.length > 0 ? (
                                <Table tableHeading={tableHeading}>
                                     {wingLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                        return (<Fragment key={tableDataID}>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3 font-semibold">{tableData.wing_name}</td>
                                                <td className="px-4 py-3">{Number(tableData.wing_budget).toLocaleString('en-IN')}</td>
                                                <td className="px-4 py-3">{moment(tableData.wing_start_date).format('D-MM-YYYY')}</td>
                                                <td className="px-4 py-3">{moment(tableData.wing_completion_date).format('D-MM-YYYY')}</td>
                                                <td className="px-4 py-3">{tableData.wing_work_status}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                        <Link title='Edit' to={`/work-management/edit-wing-details-form/${work_id}/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                        <ActionButton onClick={() => deleteWork(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>)
                                    })}
                                </Table>
                            ) : (
                                <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Wing Data Not Found</p>
                                </div>
                            )}
                            {wingLists && wingLists.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={wingLists.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                            {
                                viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                                    <Dialog.Panel className="w-auto  max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ? (<PleaseWait />) : (
                                            <Fragment>
                                                <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Wing Information</h1>
                                                <hr className='my-4 border border-primary-color' />
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Wing Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Wing Budget</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{Number(modalData.wing_budget).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Wing Description</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.wing_description}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'> Start Date</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{moment(modalData.wing_start_date).format('D-MM-YYYY')}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'> End Date</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{moment(modalData.wing_completion_date).format('D-MM-YYYY')}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Assigned Project Incharge</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.assigned_project_incharge}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Work Status</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.wing_work_status}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>No. Of Levels </h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.no_of_levels}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Total No. Of Flats</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.total_no_of_flats}</h1>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-52 font-bold'>Total No. Of Parkings</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.total_no_of_parkings}</h1>
                                                </div>
                                                <div className='flex justify-between mt-3'>
                                                    <div>
                                                        <button type="button" className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel(false)} > Got it, thanks!
                                                        </button>
                                                    </div>                                                  
                                                    <div>
                                                        <Button className="px-4 py-2 text-sm font-medium" onClick={() => handleModelImage()}>
                                                            <PhotoIcon className="h-5 w-5 text-default-color" />
                                                            <h1 className='bg-primary-color text-white text-center   rounded-md pl-2'>Wing Image</h1>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-auto max-w-2xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Wing Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={wingImage ? (
                                                wingImage.name ? URL.createObjectURL(wingImage) : IMG_URL + `/assets/images/WingCADFile/` + wingImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                        }
                                         
                                         {/* Document file view Code  */}
                                         
                                        {/* <object data={IMG_URL + `/assets/images/WingCADFile/` + wingImage} type="application/pdf" width="100%" height="500px">
                                            <p>Alternative text - include a link <a href={IMG_URL + `/assets/images/WingCADFile/` + wingImage}>to the PDF!</a></p>
                                        </object> */}
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}


export default WingDetails
