import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { PrinterIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import Pagination from '../helpers/Pagination';

function ContractorPaymentReport() {
    document.title = 'SiteSmart - Contractor Payment Report '
    const tableHeading = ['Sr.No','Paid Date', 'Work Name', 'Contractor Name','Description', 'Task',  'Payable Amount', 'Paid Amount', 'Bill Balance']
    const [loader, setLoader] = useState(false)
    const [transactionReportList, setTransactionReportList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [formInfo, setFormInfo] = useState({
        work_name: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const getPaymentList = () => {
        setLoader(true)
        axiosClient.get('/Contractorpayment_reportlists')
            .then(({ data }) => {
                setLoader(false)
                setTransactionReportList(data.lists)
            })
    }

    const getWorkWiseData = () => {
        setLoader(true)
        formInfo.work_name == '' ? (getPaymentList()) : axiosClient.get(`/workwise_Contractorpayment_reportlists/${formInfo.work_name}`)
            .then(({ data }) => {
                setLoader(false)
                setTransactionReportList(data.lists)
            })
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }
    const getAllData = () => {
        formInfo.work_name && setLoader(true)
        formInfo.work_name && formInfo.work_name == 'All' ? (getPaymentList()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getWorkWiseData()
    }, [formInfo.work_name])

    useEffect(() => {
        getAllData();
    }, [formInfo.work_name])

    useEffect(() => {
        getPaymentList()
        getWorkData()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Contractor Payment Report'}>
                    <div className="grid grid-cols-1 md:grid-cols-2 ">
                        <div className='grid grid-cols-2 gap-2'>
                            <div >
                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange} className={'w-full'}>
                                    <option disabled value={''}>--- Choose Work Name ---</option>
                                    <option value={"All"}>All</option>
                                    {works.map((userData, userId) => {
                                        return (<Fragment key={userId}>
                                            <option value={userData.id}>{userData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                        </div>
                        <div className=' flex  justify-end pt-8 gap-3'>
                            <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                        </div>
                    </div>
                    <br />
                    <div ref={componentRef} id='print'>
                        {transactionReportList && transactionReportList.length > 0 ? (
                            <Table tableHeading={tableHeading}>
                                {transactionReportList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.paid_date).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.work_name}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.contractor_name}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.bill_description}</td>
                                            <td className="px-4 py-3  whitespace-nowrap">{tableData.task_name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{parseInt(tableData.bill_payable_amount).toLocaleString('en-IN')}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{parseInt(tableData.paid_amount).toLocaleString('en-IN')}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{parseInt(tableData.bill_balance_amount).toLocaleString('en-IN')}</td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        ) : (
                            <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Payment Not Found</p>
                            </div>
                        )}
                        {transactionReportList && transactionReportList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={transactionReportList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                    </div>
                </PageComponent >
            )
            }
            
        </Fragment >
    )
}
export default ContractorPaymentReport