import React, { Fragment, useEffect, useState } from 'react';
import { ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid';
import Card from '../../helpers/Card';
import Label from '../../helpers/Label';
import Input from '../../helpers/Input';
import Button from '../../helpers/Button';
import LinkButton from '../../helpers/LinkButton';
import Loader from '../../Common/Loader';
import axiosClient from '../../../Axios';
import Validation from '../../helpers/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import PageComponent from '../../../components/PageComponent';
import flasher from '@flasher/flasher';
import CustomSelect from '../../helpers/CustomSelect';
import ActionButton from '../../helpers/ActionButton';

function AccountLedgerMasterAddEdit() {
    const { id } = useParams();
    if (id) {
        document.title = 'SiteSmart - Edit Ledger Master';
    } else {
        document.title = 'SiteSmart - Add New Ledger Master';
    }
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [selectedGroupFromBackend, setSelectedGroupsFromBackend] = useState([]);
    const [newlyAddedGroups, setNewlyAddedGroups] = useState([]);
    const [formInfo, setFormInfo] = useState({
        ledger_name: '',
        sub_ledger: '',
        description: '',

    });
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value,
        }));
    };

    const getSingleGroupData = () => {

        setLoader(true);
        axiosClient.get(`/single_Accountledger_data/${id}`)
            .then(({ data }) => {
                setLoader(false);
                setFormInfo(data.single_data);

                setSelectedGroupsFromBackend(data.Multigroups);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const groupIds = selectedGroupFromBackend.map((group_name) => group_name.id);
        const payload = {
            ledger_name: formInfo.ledger_name,
            sub_ledger: formInfo.sub_ledger,
            description: formInfo.description,
            group_name: groupIds
        };
        setLoader(true);
        axiosClient
            .post(id ? `update_AccountLedger/${id}` : '/store_Accountledger', payload)
            .then(({ data }) => {
                setLoader(false);
                flasher.success(data.msg);
                navigate('/accounting/account-ledger')
            })
            .catch(({ response }) => {
                setLoader(false);
                setErrors(response.data.errors);
            });
    };

    const [group, setGroupLists] = useState([]);
    function openModal() {
        axiosClient.get('/groupmaster_list')
            .then(({ data }) => {
                setLoader(false);
                setGroupLists(data.lists);
            });
    }

    const deletegroup = (id) => {
            axiosClient.delete(`/delete_Mulitigrups_forvoucher/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
            })
    }

    const handleGroupSelection = (selectedOption) => {
        const selectedGroup = group.find((data) => data.group_name === selectedOption);
        if (selectedGroup) {
            if (selectedGroup.id) {
                setSelectedGroupsFromBackend([...selectedGroupFromBackend, selectedGroup]);
            } else {
                setNewlyAddedGroups([...newlyAddedGroups, selectedGroup]);
            }
        }
    };

    const removeSelectedGroup = (groupToRemove) => {
        if (groupToRemove.id) {
            deletegroup(groupToRemove.id);
            setSelectedGroupsFromBackend((prevGroup) => prevGroup.filter((group) => group.id !== groupToRemove.id));
        } else {
            setNewlyAddedGroups((prevGroup) => prevGroup.filter((group) => group !== groupToRemove));
        }
    };

    useEffect(() => {
        if (id) {
            getSingleGroupData();
        }
        openModal()
    }, []);

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Ledger Master' : 'Add Ledger Master'} button={
                    <LinkButton to={'/accounting/account-ledger'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[50rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'ledger_name'} labelText={'Ledger Name'} className={'mb-1'} />
                                        <Input id={'ledger_name'} type={'text'} name={'ledger_name'} value={formInfo.ledger_name} onChange={handleChange} />
                                        <Validation error={errors.ledger_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'sub_ledger'} labelText={'Sub Ledger'} className={'mb-1'} />
                                        <Input id={'sub_ledger'} type={'text'} name={'sub_ledger'}  value={formInfo.sub_ledger} onChange={handleChange} />
                                        <Validation error={errors.sub_ledger} />
                                    </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mb-3 '>
                                    <div>
                                        <Label htmlFor={''} labelText={'Group Name'} className={'mb-1'} />
                                        <CustomSelect
                                            onChange={(e) => {
                                                const selectedOption = e.target.value;
                                                if (selectedOption) {
                                                    handleGroupSelection(selectedOption);
                                                }
                                            }}>
                                            <option  value= " ">--- Select a Group ---</option>
                                            {group.map((data, ID) => (
                                                <option key={ID} value={data.group_name}>
                                                    {data.group_name}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'description'} labelText={'Description'} className={'mb-1'} />
                                        <Input id={'description'} type={'text'} name={'description'}  value={formInfo.description} onChange={handleChange} />
                                        <Validation error={errors.description} />
                                    </div>
                                </div>
                                <h1 className='text-xl font-bold  '> Account Group List </h1>
                                <div className="mt-4 grid grid-cols-3 gap-4">
                                    {[...selectedGroupFromBackend, ...newlyAddedGroups].map((group_name, index) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <button type='button' className={"group relative flex justify-center rounded-l-lg bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color w-full "}>
                                                {group_name.group_name}
                                            </button>
                                            <ActionButton onClick={() => removeSelectedGroup(group_name)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg rounded-l-none cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex justify-center mt-4'>
                                    <Button className={'w-[30%]  mt-6  hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    );
}




export default AccountLedgerMasterAddEdit

