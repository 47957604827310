import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon, } from '@heroicons/react/24/solid'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import Card from '../helpers/Card'
import axiosClient from '../../Axios'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Validation from '../helpers/Validation'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { useStateContext } from '../../context/ContextProvider'
import { Dialog } from '@headlessui/react'
import TextArea from '../helpers/TextArea'
import Swal from 'sweetalert2'
import Currency from '../helpers/Currency'
import PleaseWait from '../Common/PleaseWait'



function DealRegistrationAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Deal Registration'
        ) : (
            document.title = 'SiteSmart - Add New Deal Registration'
        )
    }

    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [feesModel, setFeesModel] = useState(false)
    const [modelImage, setModelImage] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [totalFeesAmount, setTotalFeesAmount] = useState()
    const tableHeading = ['Sr.No', 'Document Name', 'Upload', 'Preview Img']
    const feestableHeading = ['Sr.No', 'Fees Type', 'Fees Amount', 'Remark', 'Action']
    const [feeList, setFeeList] = useState([])

    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()


    const [formInfo, setFormInfo] = useState({
        client_name: '',
        work_name: '',
        wing_name: '',
        level_name: '',
        flat_no: '',
        nature_of_flat: '',
        area: '',
        carpet_area: '',
        flat_amount_without_gst: '',
        gst: 0,
        flat_amount_with_gst: 0,

        parking: '',
        parking_no: '',
        parking_area: '',

        parking_amount_without_gst: 0,
        parking_gst: 0,
        parking_amount_with_gst: 0,

        grandDealTotal: '',

        co_owner_name: '',
        co_owner_contact: '',
        co_owner_email: '',
        co_owner_address: '',
    })

    const [modalInfo, setModalInfo] = useState({
        fees_type: '',
        fees_amount: '',
        fees_remark: '',
    })


    const [o_adharCard, setO_AdharCard] = useState()
    const [o_panCard, setO_PanCard] = useState()
    const [o_bankPassbook, setO_BankPassbook] = useState()
    const [o_salarySlips, setO_SalarySlips] = useState()
    const [o_idPhoto, setO_IdPhoto] = useState()
    const [o_voterCard, setO_VoterCard] = useState()
    const [o_bankLetter, setO_BankLetter] = useState()
    const [o_electricityBill, setO_ElectricityBill] = useState()

    const [co_adharCard, setCo_AdharCard] = useState()
    const [co_panCard, setCo_PanCard] = useState()
    const [co_bankPassbook, setCo_BankPassbook] = useState()
    const [co_salarySlips, setCo_SalarySlips] = useState()
    const [co_idPhoto, setCo_IdPhoto] = useState()
    const [co_voterCard, setCo_VoterCard] = useState()
    const [co_bankLetter, setCo_BankLetter] = useState()
    const [co_electricityBill, setCo_ElectricityBill] = useState()

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleFlatAmount = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            flat_amount_without_gst: e
        }))
    }

    const handleParkingAmount = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            parking_amount_without_gst: e
        }))
    }

    const handleFlatAmountWithGst = (e) => {
        var div = (formInfo.flat_amount_without_gst / 100) * formInfo.gst;
        var sum = parseInt(formInfo.flat_amount_without_gst) + div;
        setFormInfo(formInfo => ({
            ...formInfo,
            flat_amount_with_gst: sum
        }))
    }

    const handleModel = (e) => {
        setModalInfo(modalInfo => ({
            ...modalInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleModelImage = (image, foldername) => {
        setModalLoader(true)
        setViewModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setModalLoader(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        Swal.fire({
            title: 'Are you sure to save this?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                const formData = new FormData()
                formData.append('client_name', formInfo.client_name)
                formData.append('work_name', formInfo.work_name)
                formData.append('wing_name', formInfo.wing_name)
                formData.append('level_name', formInfo.level_name)
                formData.append('flat_no', formInfo.flat_no)
                formData.append('area', formInfo.area)
                formData.append('nature_of_flat', formInfo.nature_of_flat)
                formData.append('carpet_area', formInfo.carpet_area)
                formData.append('flat_amount_without_gst', formInfo.flat_amount_without_gst)
                formData.append('gst', formInfo.gst)
                formData.append('flat_amount_with_gst', formInfo.flat_amount_with_gst)
                formData.append('parking', formInfo.parking)
                formData.append('parking_no', formInfo.parking_no)
                formData.append('parking_area', formInfo.parking_area)
                formData.append('parking_amount_without_gst', formInfo.parking_amount_without_gst)
                formData.append('parking_gst', formInfo.parking_gst)
                formData.append('parking_amount_with_gst', formInfo.parking_amount_with_gst)
                formData.append('grandDealTotal', formInfo.grandDealTotal)
                formData.append('co_owner_name', formInfo.co_owner_name)
                formData.append('co_owner_contact', formInfo.co_owner_contact)
                formData.append('co_owner_email', formInfo.co_owner_email)
                formData.append('co_owner_address', formInfo.co_owner_address)

                formData.append('o_adharCard', o_adharCard)
                formData.append('o_panCard', o_panCard)
                formData.append('o_bankPassbook', o_bankPassbook)
                formData.append('o_salarySlips', o_salarySlips)
                formData.append('o_idPhoto', o_idPhoto)
                formData.append('o_voterCard', o_voterCard)
                formData.append('o_bankLetter', o_bankLetter)
                formData.append('o_electricityBill', o_electricityBill)

                formData.append('co_adharCard', co_adharCard)
                formData.append('co_panCard', co_panCard)
                formData.append('co_bankPassbook', co_bankPassbook)
                formData.append('co_salarySlips', co_salarySlips)
                formData.append('co_idPhoto', co_idPhoto)
                formData.append('co_voterCard', co_voterCard)
                formData.append('co_bankLetter', co_bankLetter)
                formData.append('co_electricityBill', co_electricityBill)

                axiosClient.post(`update_deal_registration/${id}`, formData)
                    .then(({ data }) => {
                        setLoader(false)
                        flasher.success(data.msg)
                    })
                    .catch(({ response }) => {
                        setLoader(false)

                        setErrors(response.data.errors)
                    })
                navigate('/post-sales/deal-registration')
            }
        })
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`delete_DealRegistrationFees/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSingleDealRegistrationData()
                    })
            }
        })
    }

    const getFeesData = () => {
        setLoader(true)
        axiosClient.get(`/DealRegistrationFees_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFeeList(data.DealRegistrationFees)
                setTotalFeesAmount(data.total_sum)
            })
    }

    const getSingleDealRegistrationData = () => {
        setLoader(true)
        axiosClient.get(`/single_deal_registration_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)

                setO_AdharCard(data.single_data.o_adharCard)
                setO_PanCard(data.single_data.o_panCard)
                setO_BankPassbook(data.single_data.o_bankPassbook)
                setO_SalarySlips(data.single_data.o_salarySlips)
                setO_IdPhoto(data.single_data.o_idPhoto)
                setO_VoterCard(data.single_data.o_voterCard)
                setO_BankLetter(data.single_data.o_bankLetter)
                setO_ElectricityBill(data.single_data.o_electricityBill)

                setCo_AdharCard(data.single_data.co_adharCard)
                setCo_PanCard(data.single_data.co_panCard)
                setCo_BankPassbook(data.single_data.co_bankPassbook)
                setCo_SalarySlips(data.single_data.co_salarySlips)
                setCo_IdPhoto(data.single_data.co_idPhoto)
                setCo_VoterCard(data.single_data.co_voterCard)
                setCo_BankLetter(data.single_data.co_bankLetter)
                setCo_ElectricityBill(data.single_data.co_electricityBill)
            })
    }

    const handleUpdateFees = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('fees_type', modalInfo.fees_type)
        formData.append('fees_amount', modalInfo.fees_amount)
        formData.append('fees_remark', modalInfo.fees_remark)
        formData.append('deal_id', id)

        axiosClient.post('/store_DealRegistrationFees', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                setFeesModel(false)
                setModalInfo({
                    fees_type: '',
                    fees_amount: '',
                    fees_remark: '',
                })
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
        getFeesData()
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [flatList, setFlatList] = useState([])
    const getFlatNoList = () => {
        formInfo.level_name && axiosClient.get(`/level_wise_flat/${formInfo.wing_name}/${formInfo.level_name}`)
            .then(({ data }) => {
                setFlatList(data.level_wise_flat)
                setTimeout(() => {
                    getFlatDetails()
                }, 1000);
            })
    }

    const getFlatDetails = () => {
        var estimate_area = '';
        var natureOfFlat = '';

        formInfo.flat_no && flatList.map((data) => {
            return (
                formInfo.flat_no == data.id && (estimate_area = data.estimate_area, natureOfFlat = data.nature_of_flat)
            )
        })

        formInfo.flat_no &&
            setFormInfo(formInfo => ({
                ...formInfo,
                area: estimate_area,
                nature_of_flat: natureOfFlat,
            }))
    }

    const handleGrandTotal = () => {
        formInfo.flat_amount_without_gst == null ? (
            setFormInfo(formInfo => ({
                ...formInfo,
                flat_amount_without_gst: 0,
                flat_amount_with_gst: 0,
            }))
        ) : console.log();

        formInfo.parking_amount_without_gst == null ? (
            setFormInfo(formInfo => ({
                ...formInfo,
                parking_amount_without_gst: 0,
                parking_amount_with_gst: 0,
            }))
        ) : console.log()

        formInfo.parking_amount_with_gst == null ? (
            setFormInfo(formInfo => ({
                ...formInfo,
                parking_amount_without_gst: 0,
                parking_amount_with_gst: 0,
            }))
        ) : console.log()

        totalFeesAmount ? console.log() : (
            setTotalFeesAmount(0)
        )

        let temp = parseInt(formInfo.flat_amount_with_gst) + parseInt(formInfo.parking_amount_with_gst) + parseInt(totalFeesAmount);
        setFormInfo(formInfo => ({
            ...formInfo,
            grandDealTotal: temp,
        }))

    }

    useEffect(() => {
        handleGrandTotal()
    }, [formInfo.flat_amount_without_gst, formInfo.flat_amount_with_gst, formInfo.gst, formInfo.parking_amount_without_gst, formInfo.parking_gst, totalFeesAmount])


    useEffect(() => {
        handleFlatAmountWithGst()
    }, [formInfo.flat_amount_without_gst, formInfo.gst])


    useEffect(() => {
        getFlatDetails()
    }, [formInfo.flat_no])

    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        getFlatNoList()
    }, [formInfo.level_name])

    useEffect(() => {
        if (id) {
            getSingleDealRegistrationData()
            getFeesData()
        }
        getWorkData();
        handleGrandTotal();
    }, [])

    return (
        <Fragment>

            {loader ? (<Loader />) : (
                <PageComponent title={'Post Sales'} button={
                    <LinkButton to={'/post-sales/deal-registration'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-96 md:w-[90%] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <h1 className="text-2xl mb-2 font-bold tracking-tight text-gray-900">Deal Registration</h1>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-4 gap-4'>
                                    <div>
                                        <Label htmlFor={'client_name'} labelText={'Client Name'} className={'mb-1'} />
                                        <Input id={'client_name'} type={'text'} name={'client_name'} value={formInfo.client_name} readOnly={true} />
                                        <Validation error={errors.client_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange}>
                                            <option value={''}>--- Choose Work Name ---</option>
                                            {Array.isArray(works) && works.map((work_data, u_id) => {
                                                return (<Fragment key={u_id}>
                                                    <option value={work_data.id}>{work_data.work_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        {/* <Validation error={errors.work_name} /> */}
                                    </div>

                                    <div>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option value={''}>--- Select level_name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                        <CustomSelect id={'flat_no'} name={'flat_no'} value={formInfo.flat_no} onClick={() => getFlatDetails()} onChange={(e) => { handleChange(e); getFlatDetails() }}>
                                            <option value={''}>--- Select Flat No ---</option>
                                            {flatList && flatList.map((flatData, flatId) => {
                                                return (<Fragment key={flatId}>
                                                    <option value={flatData.id}>{flatData.flat_no}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.flat_no} />
                                    </div>

                                    <div>
                                        <Label htmlFor={'nature_of_flat'} labelText={'Nature Of Flat'} className={'mb-1'} />
                                        <Input id={'nature_of_flat'} type={'text'} name={'nature_of_flat'} value={formInfo.nature_of_flat} readOnly={true} />
                                        <Validation error={errors.nature_of_flat} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'area'} labelText={'Area (sq / ft)'} className={'mb-1'} />
                                        <Input id={'area'} type={'text'} name={'area'} value={formInfo.area} readOnly={true} />
                                        <Validation error={errors.area} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'carpet_area'} labelText={'Carpet Area'} className={'mb-1'} />
                                        <Input id={'carpet_area'} type={'text'} name={'carpet_area'} value={formInfo.carpet_area} onChange={handleChange} />
                                        <Validation error={errors.carpet_area} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'flat_amount_without_gst'} labelText={'Flat Amount Without GST'} className={'mb-1'} />
                                        <div className="flex">
                                            <Currency
                                                className={"rounded-none rounded-l-lg"}
                                                id="flat_amount_without_gst"
                                                name="flat_amount_without_gst"
                                                decimalsLimit={2}
                                                defaultValue={formInfo.flat_amount_without_gst}
                                                onValueChange={handleFlatAmount}
                                            />
                                            <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <Validation error={errors.flat_amount_without_gst} />
                                    </div>


                                    <div>
                                        <Label htmlFor={'gst'} labelText={"GST %"} className={'mb-1'} />
                                        <CustomSelect id={'gst'} name={'gst'} defaultValue={formInfo.gst} value={formInfo.gst} onChange={handleChange}>
                                            <option value={''}>--- Choose GST ---</option>
                                            <option value={5}>5</option>
                                            <option value={18}>18</option>
                                            <option value={28}>28</option>
                                        </CustomSelect>
                                        <Validation error={errors.gst} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'flat_amount_with_gst'} labelText={'Flat Amount With GST'} className={'mb-1'} />
                                        <div className="flex">
                                            <Input id={'flat_amount_with_gst'} type={'text'} name={'flat_amount_with_gst'} className={'rounded-none rounded-l-lg'} value={parseInt(formInfo.flat_amount_with_gst).toLocaleString('en-IN')} onChange={handleChange} readOnly={true} />
                                            <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <Validation error={errors.flat_amount_with_gst} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'parking'} labelText={'Parking'} className={'mb-1'} />
                                        <CustomSelect id={'parking'} name={'parking'} defaultValue={formInfo.parking} value={formInfo.parking} onChange={handleChange}>
                                            <option value={''}>--- Select Parking Type ---</option>
                                            <option value={'None'}>No</option>
                                            <option value={'2_Wheeler'}>2 Wheeler</option>
                                            <option value={'4_Wheeler'}>4 Wheeler</option>
                                            <option value={'2_&_4_Wheeler'}>2 & 4 Wheeler</option>
                                        </CustomSelect>
                                        <Validation error={errors.parking} />
                                    </div>

                                </div>
                                {
                                    formInfo.parking && formInfo.parking !== 'None' && (
                                        <div className='mb-3 grid grid-cols-1 md:grid-cols-4 gap-4'>
                                            <div>
                                                <Label htmlFor={'parking_no'} labelText={'Parking No'} className={'mb-1'} />
                                                <Input id={'parking_no'} type={'text'} name={'parking_no'} value={formInfo.parking_no} onChange={handleChange} />
                                                <Validation error={errors.parking_no} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'parking_area'} labelText={'Parking Area'} className={'mb-1'} />
                                                <div className="flex">
                                                    <Input id={'parking_area'} type={'number'} className={'rounded-none rounded-l-lg'} name={'parking_area'} value={formInfo.parking_area} onChange={handleChange} />
                                                    <span className="flex items-center font-bold px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        Sq.Ft.
                                                    </span>
                                                </div>
                                                <Validation error={errors.parking_area} />
                                            </div>

                                            <div>
                                                <Label htmlFor={'parking_amount_without_gst'} labelText={'Parking Amount Without GST'} className={'mb-1'} />
                                                <div className="flex">
                                                    <Currency
                                                        className={"rounded-none rounded-l-lg"}
                                                        id="parking_amount_without_gst"
                                                        name="parking_amount_without_gst"
                                                        decimalsLimit={2}
                                                        defaultValue={formInfo.parking_amount_without_gst}
                                                        onValueChange={handleParkingAmount}
                                                    />
                                                    <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <Validation error={errors.parking_amount_without_gst} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'parking_gst'} labelText={"Parking GST %"} className={'mb-1'} />
                                                <CustomSelect id={'parking_gst'} name={'parking_gst'} defaultValue={formInfo.parking_gst} value={formInfo.parking_gst} onChange={handleChange}>
                                                    <option value={''}>--- Choose GST ---</option>
                                                    <option value={5}>5</option>
                                                    <option value={18}>18</option>
                                                    <option value={28}>28</option>
                                                </CustomSelect>
                                                <Validation error={errors.parking_gst} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'parking_amount_with_gst'} labelText={'Parking Amount With GST'} className={'mb-1'} />
                                                <div className="flex">
                                                    <Input id={'parking_amount_with_gst'} type={'text'} name={'parking_amount_with_gst'} className={'rounded-none rounded-l-lg'} value={parseInt(formInfo.parking_amount_with_gst = (parseInt(formInfo.parking_amount_without_gst) + ((formInfo.parking_amount_without_gst / 100) * formInfo.parking_gst))).toLocaleString('en-IN')} onChange={handleChange} />
                                                    <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <Validation error={errors.parking_amount_with_gst} />
                                            </div>
                                        </div>
                                    )
                                }


                                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-2.5 gap-x-[54%] mt-8'>
                                    <div className='font-bold text-xl '>Add Other Cost</div>
                                    <Link className='group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color' data-modal-target="defaultModal" onClick={() => setFeesModel(true)} data-modal-toggle="defaultModal" >
                                        <span>Add Other Cost</span>
                                    </Link>
                                </div>
                                <div className='mt-4 w-full'>
                                    {feeList && feeList.length > 0 ? (
                                        <Table tableHeading={feestableHeading}>
                                            {feeList.map((tableData, tableDataID) => {
                                                return (<Fragment key={tableDataID}>
                                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                                        <td>{tableDataID + 1}</td>
                                                        <td className="px-4 py-3 w-[20%]">{tableData.fees_type}
                                                        </td>
                                                        <td className="px-4 py-3 w-[20%]"> {parseInt(tableData.fees_amount).toLocaleString('en-IN')}
                                                        </td>
                                                        <td className="px-4 py-3 w-[20%]"> {tableData.fees_remark}
                                                        </td>
                                                        <td className='flex justify-center'>
                                                            <Link className='p-3' onClick={e => handleDelete(tableData.id)}><TrashIcon className='w-5 h-5' /></Link>
                                                        </td>
                                                    </tr>
                                                </Fragment>)
                                            })}

                                            <tr className="bg-default-color text-gray-900 border-b border-t-2 text-center">
                                                <th className="px-4 py-3 whitespace-nowrap">Total</th>
                                                <th className="px-4 py-3 whitespace-nowrap"></th>
                                                <th className="px-4 py-3 whitespace-nowrap">{parseInt(totalFeesAmount).toLocaleString('en-IN')}</th>
                                                <th className="px-4 py-3 whitespace-nowrap"></th>
                                                <th className="px-4 py-3 whitespace-nowrap"></th>
                                            </tr>
                                        </Table>
                                    ) : (
                                        <div className='flex justify-center items-center h-[20vh]'>
                                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Fees Data Not Found</p>
                                        </div>
                                    )}
                                </div>
                                <br />
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div className=""></div>
                                    <div className=""></div>
                                    <div className='flex justify-center bg-gray-200 p-3 rounded-lg mb-4'>
                                        <h1 className='text-xl font-extrabold'>Grand Total</h1>
                                        <span className='text-xl font-extrabold mr-2 ml-2'>-</span>
                                        <h1 className='text-xl font-extrabold'> {parseInt(formInfo.grandDealTotal).toLocaleString('en-IN')}</h1>
                                    </div>
                                </div>
                                <br />

                                <div className="mb-3">
                                    <Table tableHeading={tableHeading}>
                                        <Fragment>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">1</td>
                                                <td className="px-4 py-3">Adhar Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_adharCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_adharCard" name='o_adharCard' type="file" onChange={(e) => setO_AdharCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_adharCard, 'owneradharCard')}>
                                                            <img src={o_adharCard ? (
                                                                o_adharCard.name ? URL.createObjectURL(o_adharCard) : IMG_URL + "/assets/images/owneradharCard/" + o_adharCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">2</td>
                                                <td className="px-4 py-3">PAN Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_panCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_panCard" name='o_panCard' type="file" onChange={(e) => setO_PanCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_panCard, 'ownerpanCard')}>
                                                            <img src={o_panCard ? (
                                                                o_panCard.name ? URL.createObjectURL(o_panCard) : IMG_URL + "/assets/images/ownerpanCard/" + o_panCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">3</td>
                                                <td className="px-4 py-3">Bank Passbook</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_bankPassbook" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_bankPassbook" name={'o_bankPassbook'} type="file" onChange={(e) => setO_BankPassbook(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_bankPassbook, 'ownerbankPassbook')}>
                                                            <img src={o_bankPassbook ? (
                                                                o_bankPassbook.name ? URL.createObjectURL(o_bankPassbook) : IMG_URL + "/assets/images/ownerbankPassbook/" + o_bankPassbook
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">4</td>
                                                <td className="px-4 py-3">Salary Slips</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_salarySlips" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_salarySlips" name={'o_salarySlips'} type="file" onChange={(e) => setO_SalarySlips(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_salarySlips, 'ownersalarySlips')}>
                                                            <img src={o_salarySlips ? (
                                                                o_salarySlips.name ? URL.createObjectURL(o_salarySlips) : IMG_URL + "/assets/images/ownersalarySlips/" + o_salarySlips
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">5</td>
                                                <td className="px-4 py-3">Id Photo</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_idPhoto" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_idPhoto" name={'o_idPhoto'} type="file" onChange={(e) => setO_IdPhoto(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_idPhoto, 'owneridPhoto')}>
                                                            <img src={o_idPhoto ? (
                                                                o_idPhoto.name ? URL.createObjectURL(o_idPhoto) : IMG_URL + "/assets/images/owneridPhoto/" + o_idPhoto
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">6</td>
                                                <td className="px-4 py-3">Voter's Id Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_voterCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_voterCard" name={'o_voterCard'} type="file" onChange={(e) => setO_VoterCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_voterCard, 'ownervoterCard')}>
                                                            <img src={o_voterCard ? (
                                                                o_voterCard.name ? URL.createObjectURL(o_voterCard) : IMG_URL + "/assets/images/ownervoterCard/" + o_voterCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">7</td>
                                                <td className="px-4 py-3">Bank Letter</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_bankLetter" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_bankLetter" name={'o_bankLetter'} type="file" onChange={(e) => setO_BankLetter(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_bankLetter, 'ownerbankLetter')}>
                                                            <img src={o_bankLetter ? (
                                                                o_bankLetter.name ? URL.createObjectURL(o_bankLetter) : IMG_URL + "/assets/images/ownerbankLetter/" + o_bankLetter
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">9</td>
                                                <td className="px-4 py-3">Electricity Bill</td>
                                                <td className="px-4 py-3">
                                                    <label for="o_electricityBill" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="o_electricityBill" name={'o_electricityBill'} type="file" onChange={(e) => setO_ElectricityBill(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(o_electricityBill, 'ownerelectricityBill')}>
                                                            <img src={o_electricityBill ? (
                                                                o_electricityBill.name ? URL.createObjectURL(o_electricityBill) : IMG_URL + "/assets/images/ownerelectricityBill/" + o_electricityBill
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                        </Fragment>
                                    </Table>
                                    <br /><br />
                                </div>
                                <h1 className="text-xl mb-2 font-bold tracking-tight text-gray-900">Co-Owner Details</h1>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div>
                                        <Label htmlFor={'co_owner_name'} labelText={'Name'} className={'mb-1'} />
                                        <Input id={'co_owner_name'} type={'text'} name={'co_owner_name'} value={formInfo.co_owner_name} onChange={handleChange} />
                                        <Validation error={errors.co_owner_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'co_owner_contact'} labelText={'Contact'} className={'mb-1'} />
                                        <Input id={'co_owner_contact'} type={'text'} name={'co_owner_contact'} value={formInfo.co_owner_contact} onChange={handleChange} maxLength={10} />
                                        <Validation error={errors.co_owner_contact} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'co_owner_email'} labelText={'Email'} className={'mb-1'} />
                                        <Input id={'co_owner_email'} type={'text'} name={'co_owner_email'} value={formInfo.co_owner_email} onChange={handleChange} />
                                        <Validation error={errors.co_owner_email} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'co_owner_address'} labelText={'Address'} className={'mb-1'} />
                                        <TextArea id={'co_owner_address'} name={'co_owner_address'} value={formInfo.co_owner_address} rows={1} onChange={handleChange} />
                                        <Validation error={errors.co_owner_address} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Table tableHeading={tableHeading}>
                                        <Fragment>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">1</td>
                                                <td className="px-4 py-3">Adhar Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_adharCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_adharCard" name='co_adharCard' type="file" onChange={(e) => setCo_AdharCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_adharCard, 'co_adharCard')}>
                                                            <img src={co_adharCard ? (
                                                                co_adharCard.name ? URL.createObjectURL(co_adharCard) : IMG_URL + "/assets/images/co_adharCard/" + co_adharCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">2</td>
                                                <td className="px-4 py-3">PAN Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_panCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_panCard" name='co_panCard' type="file" onChange={(e) => setCo_PanCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_panCard, 'co_panCard')}>
                                                            <img src={co_panCard ? (
                                                                co_panCard.name ? URL.createObjectURL(co_panCard) : IMG_URL + "/assets/images/co_panCard/" + co_panCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">3</td>
                                                <td className="px-4 py-3">Bank Passbook</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_bankPassbook" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_bankPassbook" name={'co_bankPassbook'} type="file" onChange={(e) => setCo_BankPassbook(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_bankPassbook, 'co_bankPassbook')}>
                                                            <img src={co_bankPassbook ? (
                                                                co_bankPassbook.name ? URL.createObjectURL(co_bankPassbook) : IMG_URL + "/assets/images/co_bankPassbook/" + co_bankPassbook
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">4</td>
                                                <td className="px-4 py-3">Salary Slips</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_salarySlips" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_salarySlips" name={'co_salarySlips'} type="file" onChange={(e) => setCo_SalarySlips(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_salarySlips, 'co_salarySlips')}>
                                                            <img src={co_salarySlips ? (
                                                                co_salarySlips.name ? URL.createObjectURL(co_salarySlips) : IMG_URL + "/assets/images/co_salarySlips/" + co_salarySlips
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">5</td>
                                                <td className="px-4 py-3">Id Photo</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_idPhoto" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_idPhoto" name={'co_idPhoto'} type="file" onChange={(e) => setCo_IdPhoto(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_idPhoto, 'co_idPhoto')}>
                                                            <img src={co_idPhoto ? (
                                                                co_idPhoto.name ? URL.createObjectURL(co_idPhoto) : IMG_URL + "/assets/images/co_idPhoto/" + co_idPhoto
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">6</td>
                                                <td className="px-4 py-3">Voter's Id Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_voterCard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_voterCard" name={'co_voterCard'} type="file" onChange={(e) => setCo_VoterCard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_voterCard, 'co_voterCard')}>
                                                            <img src={co_voterCard ? (
                                                                co_voterCard.name ? URL.createObjectURL(co_voterCard) : IMG_URL + "/assets/images/co_voterCard/" + co_voterCard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">7</td>
                                                <td className="px-4 py-3">Bank Letter</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_bankLetter" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_bankLetter" name={'co_bankLetter'} type="file" onChange={(e) => setCo_BankLetter(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_bankLetter, 'co_bankLetter')}>
                                                            <img src={co_bankLetter ? (
                                                                co_bankLetter.name ? URL.createObjectURL(co_bankLetter) : IMG_URL + "/assets/images/co_bankLetter/" + co_bankLetter
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">9</td>
                                                <td className="px-4 py-3">Electricity Bill</td>
                                                <td className="px-4 py-3">
                                                    <label for="co_electricityBill" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="co_electricityBill" name={'co_electricityBill'} type="file" onChange={(e) => setCo_ElectricityBill(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(co_electricityBill, 'co_electricityBill')}>
                                                            <img src={co_electricityBill ? (
                                                                co_electricityBill.name ? URL.createObjectURL(co_electricityBill) : IMG_URL + "/assets/images/co_electricityBill/" + co_electricityBill
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>

                                            </tr>
                                        </Fragment>
                                    </Table>
                                    <br />
                                </div>

                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>

                            </form>

                        </Card>

                        {
                            viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                                <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                    {modalLoader ? <PleaseWait/> : (
                                        <Fragment>
                                            <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>View Full Image</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            {
                                                <img src={modelImage ? (
                                                    modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/${folderName}/` + modelImage
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-full rounded' />
                                            }
                                            <div className="mt-4">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModel(false)}
                                                >
                                                    Got it, thanks!
                                                </button>
                                            </div>
                                        </Fragment>
                                    )}
                                </Dialog.Panel>
                            </ViewModel>
                        }

                        {
                            feesModel && <ViewModel viewModel={feesModel} setViewModel={setFeesModel} >
                                <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Add Item</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <form onSubmit={(e) => handleUpdateFees(e)}>
                                            <div className='grid grid-cols-1 md:grid-cols-1 gap-4 mb-4'>
                                                <div>
                                                    <Label htmlFor={'fees_type'} labelText={'Fees Type'} className={'mb-1'} />
                                                    <Input id={'fees_type'} type={'text'} name={'fees_type'} value={modalInfo.fees_type} onChange={handleModel} />
                                                    <Validation error={errors.fees_type} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fees_amount'} labelText={'Fees Amount'} className={'mb-1'} />
                                                    <Input id={'fees_amount'} type={'text'} name={'fees_amount'} value={modalInfo.fees_amount} onChange={handleModel} />
                                                    <Validation error={errors.fees_amount} />
                                                </div>

                                                <div>
                                                    <Label htmlFor={'fees_remark'} labelText={'Fees Remark'} className={'mb-1'} />
                                                    <Input id={'fees_remark'} type={'text'} name={'fees_remark'} value={modalInfo.fees_remark} onChange={handleModel} />
                                                    <Validation error={errors.fees_remark} />
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        </form>
                                    </Fragment>
                                </Dialog.Panel>
                            </ViewModel>
                        }
                    </div>

                </PageComponent>
            )}
        </Fragment>
    )
}

export default DealRegistrationAddEdit

