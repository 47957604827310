import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Button from '../helpers/Button'
import ViewModel from '../helpers/ViewModel'
import { Link } from 'react-router-dom'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function LeadClosed() {
    document.title = 'SiteSmart - Lead Closed'
    const tableHeading = ['Sr.No', 'Client Name', 'Mobile', 'Site Name', "Response", 'Client Status', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [leadClosedList, setLeadClosedList] = useState();
    const getLeadClosedLists = () => {
        setLoader(true)
        axiosClient.get(`Lead_Closed_lists`)
            .then(({ data }) => {
                setLoader(false)
                setLeadClosedList(data.lists)
            })
    }

    const getWorkwiseLeadClosedLists = () => {
        setLoader(true)
        axiosClient.get(`workwise_Lead_Closed_lists/${work}`)
            .then(({ data }) => {
                setLoader(false)
                setLeadClosedList(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_followup_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }

    const [work, setWork] = useState([])
    const [workList, setWorkList] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkList(data.lists)
            })
    }


    useEffect(() => {
        work && getWorkwiseLeadClosedLists()
    }, [work])

    useEffect(() => {
        getLeadClosedLists()
        getWork()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Lead Closed List'}>
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-x-6 mt-4 mb-2 '>
                        <div className='ml-2'>
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={work} onChange={(e) => setWork(e.target.value)} className={'w-[25%]'}>
                                <option disabled value={''}>--- Select Work ---</option>
                                {workList.map((workData, workId) => {
                                    return (<Fragment key={workId}>
                                        <option value={workData.id}>{workData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                            {/* <Validation error={errors.work_name} /> */}
                        </div>
                        <div className='ml-2'>

                        </div>

                        <div className='ml-2'>

                        </div>
                        <div className='ml-2'>

                        </div>
                    </div>
                    {leadClosedList && leadClosedList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {leadClosedList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{tableData.mobile_no}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.clients_response}</td>
                                        <td className="px-4 py-3">{tableData.cus_status && ((tableData.cus_status).split('_')).join(' ')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Button title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></Button>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Follow Up Data Not Found</p>
                        </div>
                    )}
                    {leadClosedList && leadClosedList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={leadClosedList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef}>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Lead Closed Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-4 '>
                                                <div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Client Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Email Id</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.email_id}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Mobile No</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.mobile_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Address</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.address}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Enquiry For</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.enquiry_for}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Client status</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.cus_status && ((modalData.cus_status).split('_')).join(' ')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>No. of Reminder</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.no_of_reminders}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Work Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Wing Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Level Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.level_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Nature of Flat</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Area (sq / ft)</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Previous Call Date</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{moment(modalData.created_at).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Contact Mode</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.contact_mode}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Client Response</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.clients_response}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-2 py-1 md:py-2'>
                                                        <h1 className='w-20 md:w-36 font-bold'>Remark</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.remark}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default LeadClosed
