import React, { Fragment } from 'react'
import CurrencyInput from 'react-currency-input-field';

function Currency({ type, id, name, value, className, onValueChange, defaultValue, readOnly, required, disabled, onKeyPress, maxLength, placeHolder }) {
    return (
        <Fragment>
            <CurrencyInput type={type} name={name} value={value} id={id} autoComplete="given-name" className={"block w-full rounded-md border-0 text-sm py-2 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 " + (className)} readOnly={readOnly} required={required} defaultValue={defaultValue} disabled={disabled} onValueChange={onValueChange} onKeyPress={onKeyPress} maxLength={maxLength} placeholder={placeHolder} />
        </Fragment>
    )
}

export default Currency