import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid'
import { Link, useParams } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import moment from 'moment'
import { PrinterIcon } from '@heroicons/react/20/solid'
import { useReactToPrint } from 'react-to-print'

function ViewInward() {
    document.title = 'SiteSmart - View Inward Stock'
    const { id } = useParams()
    const tableHeading = ['Sr.No', 'Po ID', 'Date', 'Work', 'Wing', 'Level', 'Supplier', 'Material', 'Unit', 'PO Qty', 'Inward Qty']
    const [loader, setLoader] = useState(false)

    const [inwardList, setInwardList] = useState([])
    const getInwardList = () => {
        setLoader(true)
        axiosClient.get(`/single_Stock_Inward_data/${id}`)
            .then(({ data }) => {
                setInwardList(data.single_data)
                setLoader(false)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    useEffect(() => {
        getInwardList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'View Inward Stock'} button={
                    <LinkButton to={'/stock-inward'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className=' flex justify-end py-2'>
                        <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                    </div>
                    {inwardList && inwardList.length > 0 ? (
                        <div ref={componentRef}>
                            <Table tableHeading={tableHeading}>
                                {inwardList.map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                            <td className="px-4 py-3">{tableData.poID}</td>
                                            <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3">{tableData.work_name}</td>
                                            <td className="px-4 py-3">{tableData.wing_name}</td>
                                            <td className="px-4 py-3">{tableData.level_name}</td>
                                            <td className="px-4 py-3">{tableData.supplier_name}</td>
                                            <td className="px-4 py-3">{tableData.material_name}</td>
                                            <td className="px-4 py-3">{tableData.material_unit}</td>
                                            <td className="px-4 py-3">{parseInt(tableData.quantity).toLocaleString('en-IN')}</td>
                                            <td className="px-4 py-3">{parseInt(tableData.inward_qty).toLocaleString('en-IN')}</td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Inward Data Not Found</p>
                        </div>
                    )}
                </PageComponent>
            )}
        </Fragment>
    )
}

export default ViewInward
