import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ArrowLeftCircleIcon, PrinterIcon } from '@heroicons/react/24/solid'
import { Link, useParams } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import Card from '../helpers/Card'
import { useReactToPrint } from 'react-to-print'
import LinkButton from '../helpers/LinkButton'
import moment from 'moment'

function POStructure() {
    const { id } = useParams()
    document.title = 'Site Smart - Print PO'

    const tableHeading = ['Sr.No', 'Material', 'Quantity', 'Unit', 'Rate Per Unit', 'GST %', 'Total']
    const [loader, setLoader] = useState(false)

    const [tableTotal, setTableTotal] = useState()
    const [quotationData, setQuotationData] = useState([])
    const [quotationMaterial, setQuotationMaterial] = useState([])
    const [company, setCompany] = useState('')
    const [supplier, setSupplier] = useState('')
    const [work, setWork] = useState('')


    const getQuotataionData = () => {
        setLoader(false)
        axiosClient.get(`/Aproved_po_quotation_material_lists/${id}`)
            .then(({ data }) => {
                setQuotationMaterial(data.lists)
                setQuotationData(data.po_quotation);
                setTableTotal(data.totalSum)
                setSupplier(data.Supplier_data)
                setWork(data.work_data)
                setLoader(false)
            })
    }

    const getCompanylist = () => {
        setLoader(true)
        axiosClient.get('/company_lists')
            .then(({ data }) => {
                setCompany(data.lists[0])
                setLoader(false)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getQuotataionData()
        getCompanylist()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Print PO'} button={
                    <LinkButton to={'/purchase-order'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-x-1 mb-2'>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=' flex justify-center pt-2'>
                            <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                        </div>
                    </div>
                    <div ref={componentRef} className="flex justify-center">
                        <Card className={'w-full justify-center md:w-[90%] p-6  bg-default-color'}>
                            <div className='grid grid-cols-2 md:grid-cols-3 mt-4 mb-4'>
                                <div className="">
                                    <h1 className='flex justify-Start text-xl font-bold'>{company.name}</h1>
                                    <h1 className='flex justify-Start font-bold'>{company.address}</h1>
                                    <h1 className='flex justify-Start font-bold'>{company.contact_no}</h1>
                                    <h1 className='flex justify-Start font-bold'>{company.gst_no}</h1>
                                </div>
                                <div className="max-md:hidden md:visible">

                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-4 '>
                                    <div className=""></div>
                                    <div className="col-span-3">
                                        <div className='flex items-center gap-1 w-full py-2'>
                                            <h1 className='w-10 font-bold'>Date</h1>
                                            <h1 className='ml-6 flex justify-start '>{moment(quotationData.created_at).format('DD-MM-YYYY')}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-2'>
                                            <h1 className='w-10 font-bold'>PO#</h1>
                                            <h1 className='ml-6 flex justify-start'>{quotationData.id}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-5 mt-4 mb-4'>
                                {
                                    supplier && (
                                        <div className="col-span-2">
                                            <h1 className='flex justify-Start text-lg bg-primary-color pl-3 text-white font-bold'>{supplier.company_name}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_name}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_phone}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_email}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_gst_no}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_address}</h1>
                                            <h1 className=' flex justify-Start font-bold'>{supplier.supplier_pincode}</h1>
                                        </div>
                                    )
                                }
                                <div className="">
                                </div>
                                {
                                    work && (
                                        <div className="col-span-2">
                                            <h1 className='flex justify-Start text-lg bg-primary-color pl-3 text-white font-bold'>SHIP TO</h1>
                                            <h1 className='flex justify-Start text-base font-bold'>{work.work_name}</h1>
                                            <h1 className='flex justify-Start font-bold'>{work.work_location}</h1>
                                            <h1 className='flex justify-Start font-bold'>{work.name}</h1>
                                            <h1 className='flex justify-Start font-bold'>{work.mobile}</h1>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="mb-4">
                                {quotationMaterial && quotationMaterial.length > 0 ? (
                                    <Table tableHeading={tableHeading} className2={'shadow-none'} className={' bg-primary-color shadow-none border-x-2 border-primary-color'}>
                                        {quotationMaterial.map((tableData, tableDataID) => {
                                            return (<Fragment key={tableDataID}>
                                                <tr className="bg-default-color border-b text-center">
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{tableDataID + 1}</td>
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{tableData.material_name}</td>
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{parseInt(tableData.quantity).toLocaleString('en-IN')}</td>
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{tableData.material_unit}</td>
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{parseInt(tableData.unit_price).toLocaleString('en-IN')}</td>
                                                    <td className="px-4 py-2 border-x-2 border-primary-color">{tableData.purchase_gst}</td>
                                                    <td className="w-[12%] px-4 py-2 border-x-2 border-primary-color">{parseInt(tableData.total).toLocaleString('en-IN')}</td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                        <tr className="text-gray-900 border-t-2 border-primary-color text-center">
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1 font-bold">SUB TOTAL</td>
                                            <td className="px-4 py-1">{(tableTotal).toLocaleString('en-IN')}/-</td>
                                        </tr>
                                        <tr className="text-gray-900 text-center">
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1 font-bold">SHIPPING</td>
                                            <td className="px-4 py-1">{parseInt(quotationData.delivery).toLocaleString('en-IN')}/-</td>
                                        </tr>
                                        <tr className="text-gray-900 text-center">
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1 font-bold">LOADING</td>
                                            <td className="px-4 py-1">{(quotationData.loading).toLocaleString('en-IN')}/-</td>
                                        </tr>
                                        <tr className="text-gray-900 text-center">
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1 font-bold">OTHER</td>
                                            <td className="px-4 py-1">{(quotationData.other).toLocaleString('en-IN')}/-</td>
                                        </tr>

                                        
                                        <tr className="text-gray-900 text-center">
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1"></td>
                                            <td className="px-4 py-1 font-bold border-t-2 border-dotted border-primary-color">TOTAL</td>
                                            <td className="md:px-4 py-1 font-bold border-t-2 border-dotted border-primary-color"><span className='flex'>₹ {(quotationData.grand_total).toLocaleString('en-IN')} /-</span></td>
                                        </tr>
                                    </Table>
                                ) : (
                                    <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Purchase Order Data Not Found</p>
                                    </div>
                                )}
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-1 gap-x-1 mt-6 mb-2 '>
                                <h1 className='flex justify-start font-bold mb-2'>1. Payment to be made based on agreed terms.</h1>
                                <h1 className='flex justify-start font-bold mb-2'>2. Transaction information is confidential.</h1>
                                <h1 className='flex justify-start font-bold mb-2'>3. Goods must be delivered promptly to specified address.</h1>
                                <h1 className='flex justify-start font-bold mb-2'>4. Supplier guarantees quality and performance of goods.</h1>
                            </div>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default POStructure