import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon, PrinterIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import { useReactToPrint } from "react-to-print";
import ActionButton from '../helpers/ActionButton'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import ViewModel from '../helpers/ViewModel'
import moment from 'moment'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function Dpr() {
    document.title = 'SiteSmart - DPR Report'
    const tableHeading = ['Sr.No', 'Date', 'Work Name', ' Contractor Name', 'Assignee Name', 'Todays Task', 'Action']
    const tableHeading1 = ['Sr.No', 'Material Name', 'Unit', 'Quantity']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [dprList, setDprList] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [contractorName, setContractorName] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const handleChangeContractor = (e) => {
        setContractorName(e.target.value)
    }
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    }

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    }

    const getDprList = () => {
        setLoader(true)
        axiosClient.get('/Dprreport_list')
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.lists)
                setStartDate('')
                setEndDate('')
            })
    }
    const deleteDpr = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Dprreport/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getDprList()
                    })
            }
        })
    }
    const [tableData, setTableData] = useState([])
    const [modalData, setModalData] = useState([])
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Dprreport_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setTableData(data.viewmultiDPR_Materials)
            })
    }

    const [contractor, setContractor] = useState([]);
    const getContractorData = () => {
        setLoader(true)
        axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setLoader(false)
                setContractor(data.lists)
            })
    }
    const getContractorWiseData = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_taskdata/${contractorName}`)
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.work_wise_taskdata)
            })
    }

    const getAllData = () => {
        contractorName && setLoader(true)
        contractorName && contractorName == 'All' ? (getDprList()) : axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setContractor(data.lists)
                setLoader(false)
                setStartDate('')
                setEndDate('')
            })
    }
    const getSortedData = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('contractorName', contractorName);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        axiosClient.post(`datewise_dprreport`, formData)
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.lists)
                flasher.success(data.msg)
            })
    }

    const componentRef = useRef();
    const modalRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleModal = useReactToPrint({
        content: () => modalRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getContractorWiseData()
    }, [contractorName])

    useEffect(() => {
        getAllData();
    }, [contractorName])

    useEffect(() => {
        getDprList();
        getContractorData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Daily Progress Report'} button={
                    <LinkButton to={'/task-activity/dprdetails/add-dprdetails'}>
                        <PlusCircleIcon className='w-6 h-6' />
                        <span>Add DPR</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-5  gap-x-4 mt-4 mb-2 '>
                        <div >
                            <Label htmlFor={'contractorName'} labelText={'Contractor Name'} className={'mb-1'} />
                            <CustomSelect id={'contractorName'} name={'contractorName'} value={contractorName} onChange={handleChangeContractor} className={'w-full'}>
                                <option disabled value={''}>--- Choose Contractor ---</option>
                                <option value={"All"}>All</option>
                                {contractor.map((userData, userId) => {
                                    return (<Fragment key={userId}>
                                        <option value={userData.id}>{userData.contractor_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor={'date'} labelText={'From Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={startDate} onChange={handleStartDate} />
                        </div>
                        <div className="">
                            <Label htmlFor={'date'} labelText={'To Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={endDate} onChange={handleEndDate} />
                        </div>
                        <div className="items-center flex justify-start"><Button className='px-8  mt-4' onClick={getSortedData}><MagnifyingGlassIcon className='w-5 h-5' ></MagnifyingGlassIcon> </Button></div>
                        <div className="items-center flex justify-end"><Button className='px-10  mt-4' onClick={handlePrint}><PrinterIcon className='w-5 h-5' /></Button></div>
                    </div>
                    {/* {dprList && dprList.length > 0 ? (
                        <div ref={componentRef}>
                            <Table tableHeading={tableHeading}>
                                {dprList.map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                         <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3 ">{tableDataID + 1}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{moment(tableData.date).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3 ">{tableData.work_name}</td>
                                            <td className="px-4 py-3 ">{tableData.contractor_name}</td>
                                            <td className="px-4 py-3 ">{tableData.name}</td>
                                            <td className="px-4 w-auto py-3 ">{tableData.summary_of_completed_work}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                    <Link title='Edit' to={`/task-activity/edit-task-details-dprreport/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                    <ActionButton onClick={() => deleteDpr(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! DPR Data Not Found</p>
                        </div>
                    )} */}
                    {dprList && dprList.length > 0 ? (
                        <div ref={componentRef}>
                            <div className="grid grid-cols-1 gap-y-4 gap-x-6 sm:grid-cols-2 md:grid-cols-3 ">
                                {dprList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => (
                                    <div key={tableDataID} className="relative bg-white shadow-lg rounded-lg mb-4 transition-transform hover:scale-105">
                                        <div className="bg-primary-color text-white text-center py-2 rounded-t-lg font-semibold ">
                                            {moment(tableData.date).format('DD-MM-YYYY')}
                                        </div>
                                        <div className="p-4 grid grid-cols-2 gap-4">
                                            <div className=" font-bold">Work Name</div>
                                            <div className="text-gray-900">{tableData.work_name}</div>

                                            <div className=" font-bold">Contractor Name</div>
                                            <div className="text-gray-900">{tableData.contractor_name}</div>

                                            <div className=" font-bold">Task Name</div>
                                            <div className="text-gray-900">{tableData.task_name}</div>

                                            <div className=" font-bold">Assignee Name</div>
                                            <div className="text-gray-900">{tableData.name}</div>

                                            <div className=" font-bold  pb-12">Summary of Completed Work</div>
                                            <div className="text-gray-900 break-words pb-12">
                                                {tableData.summary_of_completed_work}
                                            </div>
                                            <div className="absolute bottom-0 grid grid-cols-1 md:grid-cols-3 p-2">
                                                <div className=""></div>
                                                <div className="flex justify-center items-end gap-2">
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-2 rounded-lg cursor-pointer text-white'}>
                                                        <EyeIcon className='w-5 h-5' />
                                                    </ActionButton>
                                                    <Link title='Edit' to={`/task-activity/dprdetails/edit-task-details-dprreport/${tableData.id}`}>
                                                        <ActionButton className={'p-2 bg-yellow-400 rounded-lg text-white'}>
                                                            <PencilSquareIcon className='w-5 h-5' />
                                                        </ActionButton>
                                                    </Link>
                                                    <ActionButton onClick={() => deleteDpr(tableData.id)} title={'Delete'} className={'p-2 bg-red-600 rounded-lg cursor-pointer text-white'}>
                                                        <TrashIcon className='w-5 h-5' />
                                                    </ActionButton>
                                                </div>
                                                <div className=""></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='md:text-3xl text-[4vw] font-bold'>Sorry! DPR Data Not Found</p>
                        </div>
                    )}
                    {dprList && dprList.length > 3 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={dprList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                       viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-2 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                <Fragment>
                                    <div ref={modalRef} className='m-5' id='print'>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Daily Progress Details</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-1'>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Date</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{moment(modalData.date).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            {/* <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Report Time</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.time_of_report}</h1>
                                            </div> */}
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Work Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.work_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Wing Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.wing_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Level Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.level_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold'>Contractor Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.contractor_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Task Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.task_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Task Status</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.task_status}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Assignee Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 >{modalData.name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Completed Work</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words'>{modalData.summary_of_completed_work}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Work for Tommarow</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words'>{modalData.work_planned_for_tommarow}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Issues and Concerns</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words'>{modalData.issues_and_concerns}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                <h1 className='font-bold '>Safety Incidents</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words'>{modalData.safety_incidents}</h1>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-2 w-full py-2 mt-3'>
                                            <h1 className='font-bold'>Material Used</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                        </div>
                                       
                                            {tableData && tableData.length > 0 ? (
                                                <div className='w-[50vw] md:w-full'>
                                                <Table tableHeading={tableHeading1}>
                                                    {tableData.map((tableData, tableDataID) => {
                                                        return (<Fragment key={tableDataID}>
                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                <td className="px-4 py-3">{tableData.material_name}</td>
                                                                <td className="px-4 py-3">{tableData.unit}</td>
                                                                <td className="px-4 py-3">{tableData.quantity}</td>
                                                            </tr>
                                                        </Fragment>)
                                                    })}
                                                </Table>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-center h-[20vh]'>
                                                    <p className='md:text-3xl text-[4vw] font-bold'>Sorry! Sub Tasks Not Found</p>
                                                </div>
                                            )}
                                    </div>
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handleModal} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                </Fragment>
                            )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default Dpr
