import React, { Fragment, useEffect, useState } from 'react';
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid';
import Card from '../helpers/Card';
import Label from '../helpers/Label';
import Input from '../helpers/Input';
import Button from '../helpers/Button';
import LinkButton from '../helpers/LinkButton';
import Loader from '../Common/Loader';
import axiosClient from '../../Axios';
import Validation from '../helpers/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import PageComponent from '../../components/PageComponent';
import flasher from '@flasher/flasher';
import { XMarkIcon } from '@heroicons/react/20/solid';
import CustomSelect from '../helpers/CustomSelect';

function LevelMasterAddEdit({ }) {
    const { id } = useParams();
    if (id) {
        document.title = 'SiteSmart - EditLevel Master';
    } else {
        document.title = 'SiteSmart - Add New Level';
    }
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [selectedTasksFromBackend, setSelectedTasksFromBackend] = useState([]);
    const [newlyAddedTasks, setNewlyAddedTasks] = useState([]);
    const [levelInfo, setLevelInfo] = useState({
        level_name: '',
    });
    const handleChange = (e) => {
        setLevelInfo(levelInfo => ({
            ...levelInfo,
            [e.target.name]: e.target.value,
        }));
    };

    const getSingleLevelData = () => {
        setLoader(true);
        axiosClient.get(`/single_levelmaster_data/${id}`)
            .then(({ data }) => {
                setLoader(false);
                setLevelInfo(data.single_data);
                setSelectedTasksFromBackend(data.Mulititasks);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const taskIds = selectedTasksFromBackend.map((task) => task.id);
        const payload = {
            level_name: levelInfo.level_name,
            task: taskIds
        };
        setLoader(true);
        axiosClient
            .post(id ? `update_levelmaster/${id}` : '/store_levelmaster', payload)
            .then(({ data }) => {
                setLoader(false);
                flasher.success(data.msg);
                navigate('/level-master');
            })
            .catch(({ response }) => {
                setLoader(false);
                setErrors(response.data.errors);
            });
    };

    const [task, setTaskLists] = useState([]);
    function openModal() {
        axiosClient.get(`/show_task_master`)
            .then(({ data }) => {
                setLoader(false);
                setTaskLists(data.lists);
            });
    }

    const deletetask = (id) => {
            axiosClient.delete(`/delete_mulititasks_forlavel/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
            })
    }

    const handleTaskSelection = (selectedOption) => {
        const selectedTask = task.find((data) => data.task_name === selectedOption);
        if (selectedTask) {
            if (selectedTask.id) {
                setSelectedTasksFromBackend([...selectedTasksFromBackend, selectedTask]);
            } else {
                setNewlyAddedTasks([...newlyAddedTasks, selectedTask]);
            }
        }
    };

    const removeSelectedTask = (taskToRemove) => {
        if (taskToRemove.id) {
            deletetask(taskToRemove.id);
            setSelectedTasksFromBackend((prevTasks) => prevTasks.filter((task) => task.id !== taskToRemove.id));
        } else {
            setNewlyAddedTasks((prevTasks) => prevTasks.filter((task) => task !== taskToRemove));
        }
    };


    useEffect(() => {
        if (id) {
            getSingleLevelData();
        }
        openModal()
    }, []);

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Level' : 'Add New Level'} button={
                    <LinkButton to={'/level-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[50rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <Input id={'level_name'} type={'text'} name={'level_name'} value={levelInfo.level_name} onChange={handleChange} />
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={''} labelText={'Task Name'} className={'mb-1'} />
                                        <CustomSelect
                                            onChange={(e) => {
                                                const selectedOption = e.target.value;
                                                if (selectedOption) {
                                                    handleTaskSelection(selectedOption);
                                                }
                                            }}>
                                            <option  value=" ">Select a task</option>
                                            {task.map((data, ID) => (
                                                <option key={ID} value={data.task_name}>
                                                    {data.task_name}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </div>
                                </div>
                                <h1 className='text-xl font-bold  mt-3'> Task List </h1>
                                <div className="mt-4 grid grid-cols-3 gap-4">
                                    {[...selectedTasksFromBackend, ...newlyAddedTasks].map((task, index) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <button type='button' className={"group relative flex justify-center rounded-l-lg bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color w-full "}>
                                                {task.task_name}
                                            </button>
                                                <button
                                                    type="button"
                                                    className={"group relative flex justify-center rounded-r-lg bg-red-600 p-2 tracking-wider font-semibold text-default-color  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  "}
                                                    onClick={() => removeSelectedTask(task)}>
                                                    <XMarkIcon className='w-5 h-5' />
                                                </button>
                                        </div>
                                    ))}

                                </div>
                                <div className='flex justify-center mt-4'>
                                    <Button className={'w-[30%]  mt-6  hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    );
}
export default LevelMasterAddEdit;

