import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../../helpers/Card'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import flasher from '@flasher/flasher'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import TextArea from '../../helpers/TextArea'
import CustomSelect from '../../helpers/CustomSelect'
import Button from '../../helpers/Button'

function PaymentDetailsAddEdit() {
    const { id, work_name, work_id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Payment'
    } else {
        document.title = 'SiteSmart - Add New Payment'
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { IMG_URL , FRONTEND_URL} = useStateContext()

    const [workname, setWorkName] = useState("")
    const [workid, setWorkId] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
                setWorkId(data.list.id)
            })
    }
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        workid && axiosClient.get(`/work_wise_wings/${workid}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        stage_name: '',
        percentage: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', workid)
        formData.append('wing_name', formInfo.wing_name)
        formData.append('stage_name', formInfo.stage_name)
        formData.append('percentage', formInfo.percentage)
        axiosClient.post(id ? `update_Payment_Stages_master/${id}` : '/store_Payment_Stages_master', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate(`/work-management/view-payment-details/${work_id}`)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleWorkData = () => {
        setLoader(true)
        axiosClient.get(`/single_Payment_Stages_Master/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }
    useEffect(() => {
        getWingData();
    }, [workid])

    useEffect(() => {
        if (id) {
            getSingleWorkData();
        }
        getWorkDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Payment' : 'Add New Payment'} button={
                    <LinkButton to={`/work-management/view-payment-details/${work_id}`}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='flex items-center justify-center'>
                                <Card className="w-[60rem] p-4 bg-default-color ">
                                    <form onSubmit={handleSubmit} className='mt-4'>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true} />
                                                <Validation error={errors.work_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose Wing Name ---</option>
                                                    {wing && wing.map((wingData, wingId) => {
                                                        return (<Fragment key={wingId}>
                                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'percentage'} labelText={'Percentage'} className={'mb-1'} />
                                                <div className="flex">
                                                    <Input id={'percentage'} type={'text'} name={'percentage'} className={'rounded-none rounded-l-lg'} value={formInfo.percentage} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                    <span className="flex items-center font-bold px-8 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                                                        %
                                                    </span>
                                                </div>
                                                <Validation error={errors.percentage} />
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <Label htmlFor={'stage_name'} labelText={'Stage Name'} className={'mb-1'} />
                                            <TextArea id={'stage_name'} type={'text'} name={'stage_name'} value={formInfo.stage_name} onChange={handleChange} rows={2} />
                                            <Validation error={errors.stage_name} />
                                        </div>
                                        <Button className={'w-60 mx-auto mt-9'}>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                            <span className='ml-5'>Save Now</span>
                                        </Button>
                                    </form>
                                </Card>
                            </div>
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default PaymentDetailsAddEdit
