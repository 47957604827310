import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import { useStateContext } from '../../context/ContextProvider'
import flasher from '@flasher/flasher'

function UsersMasterAddEdit(){
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Agency'
    } else {
        document.title = 'SiteSmart - Add New Agency'
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, BACKEND_URL , IMG_URL} = useStateContext()

    const [userImage, setUserImage] = useState('');
    const userImageChange = (e) => {
        setUserImage(e.target.files[0]);
    }

    const [formInfo, setFormInfo] = useState({
        name: '',
        email: '',
        mobile: '',
        role: '',
        user_status: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('name', formInfo.name)
        formData.append('email', formInfo.email)
        formData.append('mobile', formInfo.mobile)
        formData.append('role', formInfo.role)
        formData.append('user_status', formInfo.user_status)
        formData.append('user_image', userImage)
        axiosClient.post(id ? `update_user/${id}` : '/store_user', formData)

            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/user-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const getSingleUserData = () => {
        setLoader(true)
        axiosClient.get(`/single_user_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setUserImage(data.single_data.user_image)
            })
    }
    useEffect(() => {
        if (id) {
            getSingleUserData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit User' : 'Add New User'} button={
                    <LinkButton to={'/user-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[60rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'name'} labelText={'Name'} className={'mb-1'} />
                                        <Input id={'name'} type={'text'} name={'name'} value={formInfo.name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'email'} labelText={'Email'} className={'mb-1'} />
                                        <Input id={'email'} type={'text'} name={'email'} value={formInfo.email} onChange={handleChange} />
                                        <Validation error={errors.email} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'role'} labelText={'Role'} className={'mb-1'} />
                                        <CustomSelect id={'role'} name={'role'} value={formInfo.role} onChange={handleChange} className={'w-full'}>
                                            <option  value={''}>--- Choose role ---</option>
                                            <option value={'Project_Manager'}>Project Manager</option>
                                            <option value={'Accountant'}>Accountant</option>
                                            <option value={'Supervisor'}>Supervisor</option>
                                            <option value={'CRM_Manager'}>CRM Manager</option>
                                            <option value={'Purchase_Officer'}>Purchase Officer</option>
                                        </CustomSelect>
                                        <Validation error={errors.role} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'mobile'} labelText={'Mobile No'} className={'mb-1'} />
                                        <Input id={'mobile'} type={'text'} name={'mobile'} value={formInfo.mobile} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.mobile} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'user_status'} labelText={'User Status'} className={'mb-1'} />
                                        <CustomSelect id={'user_status'} name={'user_status'} value={formInfo.user_status} onChange={handleChange} className={'w-full'}>
                                            <option  value={''}>--- Choose user_Status ---</option>
                                            <option value={'active'}>Active</option>
                                            <option value={'deactive'}>Inactive</option>
                                        </CustomSelect>
                                        <Validation error={errors.user_status} />
                                    </div>
                                </div>
                                <div>
                                    <Label htmlFor={'user_image'} labelText={'User Image'} />
                                    <img src={userImage ? (
                                        userImage.name ? URL.createObjectURL(userImage) : IMG_URL + "/assets/images/UserImages/" + userImage
                                    ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-32 md:w-64 h-32 md:h-64 rounded' />
                                    <Input id={'user_image'} type={'file'} name={'user_image'} onChange={userImageChange} className={'p-[0px!important] mt-2'} />
                                    <Validation error={errors.user_image} />
                                </div>
                                <Button className={'w-full mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default UsersMasterAddEdit