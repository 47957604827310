import React, { Fragment } from 'react'

function Table({ className, className2, children, tableHeading }) {
    return (
        <Fragment>
            <div className={"relative overflow-x-auto rounded-lg shadow-md " + (className2)}>
                <table className="w-full text-left text-gray-500">
                    <thead className={"md:text-sm text-[2vw] tracking-wider text-default-color text-center uppercase bg-gray-800 " + (className)}>
                        <tr>
                            {tableHeading.map((heading, heading_id) => {
                                return (<Fragment key={heading_id}>
                                    <th scope="col" className="px-4 py-3 whitespace-nowrap">{heading}</th>
                                </Fragment>)
                            })}
                        </tr>
                    </thead>
                    <tbody className='md:text-sm text-[2vw]'>{children}</tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default Table