import React, { Fragment, useEffect, useRef, useState } from 'react'
import { EyeIcon, ForwardIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'


function PurchaseRequisitionOrder() {
    document.title = 'SiteSmart -Purchase Requisition Order'
    const tableHeading = ['Sr.No', 'PRO Date', 'Work Name', 'Wing Name', 'Level', 'Send To', 'Approval Status', 'Action']
    const tableHeading1 = ['Sr.No', 'Material Name', 'Material Unit', 'Quantity']
    const tableHeading2 = ['Sr.No', 'Material Name', 'Material Unit', 'Estimated Quantity']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [requisitionList, setRequisitionList] = useState('')
    const getRequisitionList = () => {
        setLoader(true)
        axiosClient.get('/purchaseRequisition_list')
            .then(({ data }) => {
                setRequisitionList(data.lists)
                setLoader(false)
            })
    }
    const getApprovalStatus = (id) => {
        setLoader(false)
        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: '42',
            message: 'Requisition Approved successfully...',
            module: 'Requisition',
            link: '/purchase-requisition',
        }
        axiosClient.get(`/update_status/${id}`)
            .then(({ data }) => {
                getRequisitionList();
                setLoader(false)
                flasher.success(data.msg)
            })
        axiosClient.post(`store_Notification`, notification)
            .then(({ data }) => {
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const deleteEmployee = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_purchaseRequisition/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getRequisitionList()
                    })
            }
        })
    }
    const [estmaterialList, setEstmaterialList] = useState('')
    const [materialList, setmaterialList] = useState('')
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_purchaseRequisition/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setmaterialList(data.material_lists)
                setEstmaterialList(data.view_Material_estimationdata)
            })
    }
    useEffect(() => {
        getRequisitionList();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Purchase Requisition Order'} button={
                    <LinkButton to={'/purchase-requisition/add-requisition'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add New Requisition</span>
                    </LinkButton>
                }>
                    {requisitionList && requisitionList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {requisitionList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        {/* <td className="px-4 py-3">{tableData.created_at}</td> */}
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.wing_name}</td>
                                        <td className="px-4 py-3">{tableData.level_name}</td>
                                        <td className="px-4 py-3">{tableData.name}</td>
                                        <td className="px-4 py-3">
                                            {
                                                tableData.status_aproved === null ? (
                                                    <button onClick={() => getApprovalStatus(tableData.id)} className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'> Not Approve</button>
                                                ) : (
                                                    <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Approve</button>
                                                )
                                            }
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link title='View' onClick={() => openModal(tableData.id)}><ActionButton className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton></Link>
                                                {
                                                    tableData.status_aproved === null ?
                                                        <Link title='Edit' to={`/purchase-requisition/edit-requisition/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link> :
                                                        <Link title='Forwarded' to={`/quotation`}> <ActionButton className={'p-1.5 text-white bg-teal-600 rounded-lg'}><ForwardIcon className='w-5 h-5' /></ActionButton></Link>
                                                }
                                                <Link title={'Delete'} onClick={() => deleteEmployee(tableData.id)} ><ActionButton className={'p-1.5  text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton></Link>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                        </div>
                    )}
                    {requisitionList && requisitionList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={requisitionList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Purchase Requisition Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div className='flex items-center gap-2 py-2'>
                                                    <h1 className='w-20 md:w-36 font-bold'>Work Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className=''>{modalData.work_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-2 py-2'>
                                                    <h1 className='w-20 md:w-36 font-bold'>Wing Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className=''>{modalData.wing_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-2 py-2'>
                                                    <h1 className='w-20 md:w-36 font-bold'>Level Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className=''>{modalData.level_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-2 py-2'>
                                                    <h1 className='w-20 md:w-36 font-bold'>Send To</h1>
                                                    <h1>-</h1>
                                                    <h1 className=''>{modalData.name}</h1>
                                                </div>
                                            </div>
                                            <h1 className='text-center uppercase font-bold md:text-lg text-[2vw] tracking-wide mt-4'>Estimated Material  List</h1>
                                            <div className='mt-3' >
                                                {estmaterialList && estmaterialList.length > 0 ? (
                                                    <Table tableHeading={tableHeading2}>
                                                        {estmaterialList.map((tableData, tableDataID) => {
                                                            return (<Fragment key={tableDataID}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                    <td className="px-4 py-3">{tableData.material_name}</td>
                                                                    <td className="px-4 py-3">{tableData.unit}</td>
                                                                    <td className="px-4 py-3">{parseInt(tableData.estimate_quantity).toLocaleString('en-IN')}</td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[15vh]'>
                                                        <p className='text-2xl font-bold'>Sorry! Estimated List Not Found</p>
                                                    </div>
                                                )}
                                            </div>
                                            <h1 className='text-center uppercase font-bold md:text-lg text-[2vw] tracking-wide mt-4'>Required Material  List</h1>
                                            <div className='mt-3' >
                                                {materialList && materialList.length > 0 ? (
                                                    <Table tableHeading={tableHeading1}>
                                                        {materialList.map((tableData, tableDataID) => {
                                                            return (<Fragment key={tableDataID}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                    <td className="px-4 py-3">{tableData.material_name}</td>
                                                                    <td className="px-4 py-3">{tableData.material_unit}</td>
                                                                    <td className="px-4 py-3">{parseInt(tableData.quantity).toLocaleString('en-IN')}</td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[20vh]'>
                                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Material List Not Found</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                </PageComponent>
            )
            }
        </Fragment >
    )
}

export default PurchaseRequisitionOrder