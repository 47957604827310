import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import Label from '../../helpers/Label'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import CustomSelect from '../../helpers/CustomSelect'
import { NavLink, useParams } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import { useReactToPrint } from 'react-to-print'
import Card from '../../helpers/Card'
import Table from '../../helpers/Table'
import { Menu, Transition } from '@headlessui/react'
import { CSVLink } from 'react-csv'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SaleReport() {
  const {work_id } = useParams()
  document.title = 'SiteSmart - Sales Report'
  const tableHeading = ['Sr.No', 'Flat No.', 'Carpet area', 'Nature of flat', 'Status', 'Sold date', ' Owner', 'Parking No.', 'Total amount']
  const [loader, setLoader] = useState(false)
  const { IMG_URL, FRONTEND_URL } = useStateContext()
  const [formInfo, setFormInfo] = useState({
    work_name: work_id,
    level: '',
    flat_status: '',
  })

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const [flatLists, setFlatLists] = useState('')
  const getFlatReport = () => {
    setLoader(true)
    axiosClient.get(`/work_wise_flatdata/${work_id}`)
      .then(({ data }) => {
        setLoader(false)
        setFlatLists(data.work_wise_flatdata)
      })
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const headers = [
    { label: 'Sr.No', key: 'index' },
    { label: 'QA/QC', key: 'qaqc_checkpoints' },
    { label: 'Remark', key: 'wing_name' },
    { label: 'Status', key: 'level_name' },
    { label: 'Checked Date', key: 'material_name' },
  ];

  const csvData = flatLists && flatLists.map((item, index) => ({
    index: index + 1,
    qaqc_checkpoints: item.qaqc_checkpoints,
    wing_name: item.remark,
    level_name: item.check_mark,
    material_name: item.updated_at,
  }))


  const getWorkWiseLists = () => {
    setLoader(true)
    work_id && axiosClient.get(`/work_wise_flatdata/${work_id}`)
      .then(({ data }) => {
        setLoader(false)
        setFlatLists(data.lists)
      })
  }

  const getWorkWingWiseLists = () => {
    setLoader(true)
    work_id && formInfo.wing_name && axiosClient.get(`/work_wing_wise_flatdata/${work_id}/${formInfo.wing_name}`)
      .then(({ data }) => {
        setLoader(false)
        setFlatLists(data.work_wing_wise_flatdata)
      })
  }

  const getWorkWingLevelWiseLists = () => {
    setLoader(true)
    work_id && formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_level_wise_flatdata/${work_id}/${formInfo.wing_name}/${formInfo.level}`)
      .then(({ data }) => {
        setLoader(false)
        setFlatLists(data.work_wing_level_wise_flatdata)
      })
  }

  // Api to Get Work Name And Id   //
  const [workname, setWorkName] = useState("")
  const getWorkDetails = () => {
    setLoader(true)
    axiosClient.get(`/get_work_details/${work_id}`)
      .then(({ data }) => {
        setLoader(false)
        setWorkName(data.list)
      })
  }

  const [wing, setWing] = useState([])
  const getWingLists = () => {
    work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
      .then(({ data }) => {
        setWing(data.work_wise_wingdata)
      })
  }

  const [level, setLevel] = useState([]);
  const getLevelData = () => {
    formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${work_id}/${formInfo.wing_name}/`)
      .then(({ data }) => {
        setLoader(false)
        setLevel(data.work_wing_wise_leveldata)
      })
  }

  useEffect(() => {
    getWingLists();
    getWorkWiseLists();
  }, [work_id])

  useEffect(() => {
    getWorkWingWiseLists()
  }, [formInfo.wing_name])


  useEffect(() => {
    getWorkWingLevelWiseLists();
  }, [formInfo.level])

  useEffect(() => {
    getLevelData();
  }, [work_id && formInfo.wing_name])

  useEffect(() => {
    getWorkDetails();
    getFlatReport();
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <PageComponent className={'bg-white'} title={'Sale Reports'} >
          <div className='flex gap-4'>
            <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%] mb-8'>
            { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
              {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
              {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
              <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
              <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                <WorkInfoSideMenu work_id={work_id}/>
              </div>
            </div>
            <div className='rounded md:w-[80%] mb-2'>
              <div className='grid grid-cols-1 md:grid-cols-5 gap-x-2 mt-4 mb-2'>
                <div>
                  <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'}/>
                  <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                    <option value={''}>--- Select Wing ---</option>
                    {wing && wing.map((wingData, wingId) => {
                      return (<Fragment key={wingId}>
                        <option value={wingData.id}>{wingData.wing_name}</option>
                      </Fragment>)
                    })}
                  </CustomSelect>
                </div>
                <div>
                  <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                  <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                    <option value={''}>--- Select Level ---</option>
                    {level && level.map((levelData, levelId) => {
                      return (<Fragment key={levelId}>
                        <option value={levelData.level}>{levelData.level_name}</option>
                      </Fragment>)
                    })}
                  </CustomSelect>
                </div>
                <div></div>
                <div></div>
              <div className="">
                <Menu as="div" className="">
                  <Label htmlFor={'date'} labelText={'Print'} className={'mb-1 opacity-0'} />
                  <div className="flex justify-end pt-1">
                    <Menu.Button >
                      <span className="sr-only">Open user menu</span>
                      <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                    </Menu.Button>
                  </div>
                  <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* <Menu.Item >
                        {({ active }) => (
                          <NavLink onClick={''} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                        )}
                      </Menu.Item> */}
                      <Menu.Item >
                        {({ active }) => (
                          <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        <div className="px-2 py-2">
                          <CSVLink data={csvData} headers={headers} filename={'QAQC Report.csv'}>
                            <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                          </CSVLink>
                        </div>
                      </Menu.Item> */}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              </div>
              <div ref={componentRef} className='flex items-center justify-center '>
                <Card className={' md:w-[100%] bg-default-color'}>
                  {
                    flatLists && flatLists.length > 0 ? (
                      <div>
                        {flatLists.map((data, ID) => {
                          return (<Fragment key={ID}>
                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] mt-4 tracking-widest'>{data.work_name}-Sales Report</h1>
                            <hr className='mt-4 mx-4 border border-primary-color' />
                            <div className='grid grid-cols-1 md:grid-cols-3 mt-4 gap-1 divide-x p-4'>
                              <div className="shadow-md">
                                <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                  <h1 className='flex justify-center font-bold'>Work Name</h1>
                                  <h1 className='col-span-2 flex justify-center'>{data.work_name}</h1>
                                </div>
                              </div>
                              <div className="shadow-md">
                                <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                  <h1 className='flex justify-center font-bold'>Wing Name</h1>
                                  <h1 className='col-span-2 flex justify-center'>{data.wing_name}</h1>
                                </div>
                              </div>
                              <div className="shadow-md">
                                <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                  <h1 className='flex justify-center font-bold'>Level Name</h1>
                                  <h1 className='col-span-2 flex justify-center'>{data.level_name}</h1>
                                </div>
                              </div>
                            </div>
                            <div>
                              {data.multiflat_details && data.multiflat_details.length > 0 ? (
                                <div className="px-4">
                                  {
                                    <Table className='rounded-lg shadow-md bg-primary-color' tableHeading={tableHeading}>
                                      {data.multiflat_details && data.multiflat_details.map((tableData2, tableDataID2) => {
                                        return (<Fragment key={tableDataID2}>
                                          <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="py-3">{(ID + 1) + ((tableDataID2 + 1) / 10)}</td>
                                            <td className="py-3">{tableData2.flat_no}</td>
                                            <td className="py-3">{tableData2.carpet_area}</td>
                                            <td className="py-3">{((tableData2.nature_of_flat).split('_')).join(' ')}</td>
                                            <td className="py-3">{tableData2.sold_status}</td>
                                            <td className="py-3">{''}</td>
                                            {/* <td className="py-3">{moment('').format('DD-MM-YYYY')}</td> */}
                                            <td className="py-3 text-wrap">{tableData2.owner_name}</td>
                                            <td className="py-3">{tableData2.parking_no}</td>
                                            <td>
                                              {tableData2.total_amount === null ?
                                                ( <label className="py-3">{''}</label> ) : ( <label className="py-3">{parseInt(tableData2.total_amount).toLocaleString('en-IN')}</label> )}
                                            </td>
                                          </tr>
                                        </Fragment>)
                                      })}
                                    </Table>
                                  }
                                </div>
                              ) : (
                                <p>Loading...</p>
                              )}
                            </div>
                            <hr className='mt-4 border-4 border-gray-200' />
                          </Fragment>)
                        })}
                      </div>
                    ) : (
                      <div className='flex justify-center items-center h-[45vh]'>
                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                      </div>
                    )
                  }
                </Card>
              </div>
              <br /> <br />
            </div>
          </div>
        </PageComponent>
      )
      }
    </Fragment >
  )
}


export default SaleReport
