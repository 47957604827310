import React, { Fragment, useRef, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Menu, Transition } from '@headlessui/react'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import { CSVLink } from 'react-csv'
import ViewModel from '../helpers/ViewModel'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function SupplierMaster() {
    document.title = 'SiteSmart - Supplier Master'
    const tableHeading = ['Sr.No', 'Supplier Name', 'Supplier Address', 'Contact No', 'Action']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [supplierLists, setSupplierLists] = useState('')
    const [csvFile, setCsvFile] = useState('')
    const [materiallist, setMateriallist] = useState([])
    const [viewModel, setViewModel] = useState(false)
    const [ViewCsv, setViewCsv] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getSupplierLists = () => {
        setLoader(true)
        axiosClient.get('/supplier_lists')
            .then(({ data }) => {
                setLoader(false)
                setSupplierLists(data.lists.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name)));
                // setSupplierLists(data.lists)
            })
    }

    const deleteSupplier = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_supplier/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSupplierLists()
                    })
            }
        })
    }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_supplier_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
                setMateriallist(data.Material_data)
            })
    }

    const material = [{ material_name: 'cement' }, { material_name: 'sand' }, { material_name: 'bricks' }]
    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Supplier Name', key: 'supplier_name' },
        { label: 'Company Name', key: 'company_name' },
        { label: 'Contact Number', key: 'supplier_phone' },
        { label: 'Email', key: 'supplier_email' },
        { label: 'Point of Contact', key: 'supplier_poc' },
        { label: 'Address', key: 'supplier_address' },
        { label: 'PinCode', key: 'supplier_pincode' },
        { label: 'GST No', key: 'supplier_gst_no' },
        { label: 'Bank Name', key: 'supplier_bank_name' },
        { label: 'Branck Name', key: 'supplier_bank_branch' },
        { label: 'Account Number', key: 'supplier_bank_account_no' },
        { label: 'IFSC Code', key: 'supplier_bank_ifsc_code' },
        { label: 'Materials', key: 'materials' },
    ];

    const csvData = supplierLists && supplierLists.map((item, index) => ({
        index: index + 1,
        supplier_name: item.supplier_name,
        company_name: item.company_name,
        supplier_phone: item.supplier_phone,
        supplier_email: item.supplier_email,
        supplier_poc: item.supplier_poc,
        supplier_address: item.supplier_address,
        supplier_pincode: item.supplier_pincode,
        supplier_gst_no: item.supplier_gst_no,
        supplier_bank_name: item.supplier_bank_name,
        supplier_bank_branch: item.supplier_bank_branch,
        supplier_bank_account_no: item.supplier_bank_account_no,
        supplier_bank_ifsc_code: item.supplier_bank_ifsc_code,
        materials: material && material.map((data) => {
            return data.material_name
        })
    }))
    const handleCsvFile = () => {
        setLoader(true)
        console.log('csvFile :', csvFile);
        axiosClient.post(`import_material`, csvFile)
            .then(({ data }) => {
                flasher.success(data.msg)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
        setViewCsv(false)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getSupplierLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Supplier Master'} button={
                    <LinkButton to={'/supplier-master/add-supplier-master'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Supplier</span>
                    </LinkButton>
                }>
                    <div className="flex justify-end mb-2">
                        <Menu as="div" className="">
                            <div className="flex justify-end pt-2">
                                <Menu.Button >
                                    <span className="sr-only">Open user menu</span>
                                    <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                </Menu.Button>
                            </div>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={() => setViewCsv(true)} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="px-2 py-2">
                                            <CSVLink data={csvData} headers={headers} filename={'Supplelier List.csv'}>
                                                <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                            </CSVLink>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {supplierLists && supplierLists.length > 0 ? (
                        <div ref={componentRef}>
                            <Table tableHeading={tableHeading}>
                                {supplierLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                            <td className="px-4 py-3">{tableData.supplier_name}</td>
                                            <td className="px-4 py-3">{tableData.supplier_address}</td>
                                            <td className="px-4 py-3">{tableData.supplier_phone}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 text-white bg-primary-color rounded-lg cursor-pointer'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                    <Link title='Edit' to={`/supplier-master/edit-supplier-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                    <ActionButton onClick={() => deleteSupplier(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Supplier Data Not Found</p>
                        </div>
                    )}
                    {supplierLists && supplierLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={supplierLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Supplier Information</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Name</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_name}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Company Name</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.company_name}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Material List</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>
                                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2'>
                                                    {materiallist.map((data) => {
                                                        return (
                                                            <h1 className='bg-primary-color text-white px-2 py-1 w-full rounded-md flex justify-center text-center'>{data.material_name}</h1>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Contact No.</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_phone}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Email</h1>
                                            <h1>-</h1>
                                            <div className='ml-6' style={{ wordWrap: 'break-word' }}>{modalData.supplier_email}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Point of Contact</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_poc}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'> Supplier Address</h1>
                                            <h1>-</h1>
                                            <div className='ml-6' style={{ wordWrap: 'break-word' }}>{modalData.supplier_address}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Pincode</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_pincode}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>GST No.</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_gst_no}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Bank Name</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_bank_name}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Bank Branch</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_bank_branch}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Bank Account No.</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_bank_account_no}</div>
                                        </div>
                                        <div className='flex items-center gap-4 py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>IFSC Code</h1>
                                            <h1>-</h1>
                                            <div className='ml-6'>{modalData.supplier_bank_ifsc_code}</div>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className=" rounded-md bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel()} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        ViewCsv && <ViewModel viewModel={ViewCsv} setViewModel={setViewCsv} >
                            <Dialog.Panel className="w-auto max-w-screen-md  transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Import CSV File</h1>
                                    <hr className='my-4 border border-primary-color' />
                                    <h1 className='text-lg font-bold'>Note :-</h1>
                                    <div className='m-y-4 pl-5'>
                                        <code className='block'><span className='font-semibold'>1.</span>The Excel File must include required column's</code>
                                        <code className='block mb-1'><span className='font-semibold'>2.</span>Column heading's should be...</code>
                                        <code className='grid grid-cols-3 gap-x-5 mb-1'>
                                            <span>supplier_name</span>
                                            <span>company_name</span>
                                            <span>contact_no</span>
                                            <span>email</span>
                                            <span>point_of_point</span>
                                            <span>address</span>
                                            <span>pincode</span>
                                            <span>gst_no</span>
                                            <span>bank_name</span>
                                            <span>account_no</span>
                                            <span>ifsc_code</span>
                                        </code>
                                        <code className='block'><span className='font-semibold'>3.</span>Serial No. column is optional.</code>
                                        <code className='block'><span className='font-semibold'>4.</span>The file format should be .csv or .xls</code>
                                    </div>
                                    <Input id={'csvFile'} type={'file'} name={'csvFile'} onChange={(e) => setCsvFile(e.target.files[0])} className={'p-[0px!important] mt-2 mb-2'} />
                                    <div className="mt-4 grid grid-cols-3">
                                        <Button onClick={() => handleCsvFile()} className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit" >
                                            Save
                                        </Button>
                                    </div>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default SupplierMaster