import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import moment from 'moment'
import Pagination from '../helpers/Pagination';


function DailyAttendanceReports() {
    document.title = 'SiteSmart - Daily Attendance Report'
    const tableHeading = ['Sr.No', 'Date', 'Work Name', 'Employee Name', 'Attendance Status', 'Sign In Time', 'Sign Out Time']
    const [loader, setLoader] = useState(false)
    const [attendanceList, setAttendanceList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const getAttendanceList = () => {
        setLoader(true)
        axiosClient.get('/Daily_attendance_list/')
            .then(({ data }) => {
                setLoader(false)
                setAttendanceList(data.lists)
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAttendanceList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Daily Attendance Report'} >
                    {attendanceList && attendanceList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {attendanceList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.date}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.employee_name}</td>
                                        <td className="px-4 py-3">{tableData.attendance_status}</td>
                                        <td className="px-4 py-3">{tableData.sign_in_time}</td>
                                        <td className="px-4 py-3">{tableData.sign_out_time}</td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='md:text-3xl text-[4vw] font-bold'>Sorry!Attendance Not Found</p>
                        </div>
                    )}
                    {attendanceList && attendanceList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={attendanceList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange} />
                    </div>) : (<div></div>)
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default DailyAttendanceReports