import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PhotoIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import moment from 'moment'
import { Dialog } from '@headlessui/react'
import Button from '../../helpers/Button'
import ViewModel from '../../helpers/ViewModel'
import { useStateContext } from '../../../context/ContextProvider'
import ActionButton from '../../helpers/ActionButton'
import PleaseWait from '../../Common/PleaseWait'

function AllWork() {
    document.title = 'SiteSmart - Work Management'
    const tableHeading = ['Sr.No', 'Created Date', 'Work Name', 'Location', 'Work Category', 'Action']
    const [loader, setLoader] = useState(false)
    const currentDate = new Date().toJSON().slice(0, 10);
    const [date, setDate] = useState(currentDate)
    const [workLists, setWorkLists] = useState('')
    const [workname, setWorkName] = useState('')
    const getWorkLists = () => {
        setLoader(true)
        axiosClient.get('/work_lists')
            .then(({ data }) => {
                setLoader(false)
                setWorkLists(data.lists)
                setWorkName(data.lists.id)
            })
    }
    console.log('workname :', workname);
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [modalData, setModalData] = useState([])
    const [viewModel, setViewModel] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const handleModelImage = (image,) => {
        setImgModalLoader(true)
        setImgModel(true)
        setImgModalLoader(false)
    }

    const deleteWork = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_work/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getWorkLists()
                    })
            }
        })
    }
    const warningWork = () => {
        Swal.fire({
            title: "You Won't Delete This?",
            text: "Completion Date And Today's date Does Not Match !",
            icon: 'error',
            //   showCancelButton: true,
            confirmButtonColor: '#d33',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'cancel '
        })
    }

    const [workImage, setWorkImage] = useState(false)
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_work_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setWorkImage(data.single_data.cad_image)
            })
    }
    useEffect(() => {
        getWorkLists();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Work Management'} button={
                    <LinkButton to={'/work-management/add-work-management'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Work</span>
                    </LinkButton>
                }>
                    {workLists && workLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {workLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('D-MM-YYYY')}</td>
                                        <td className="px-4 py-3 font-bold text-primary-color">
                                            <Link to={`/work-management/view-work-management/${tableData.id}`}>{tableData.work_name}</Link>
                                        </td>
                                        <td className="px-4 py-3 break-words w-[20%]">{tableData.work_location}</td>
                                        <td className="px-4 py-3">{((tableData.work_category).split('_')).join(' ')}</td>
                                        {/* <td className="px-4 py-3">
                                            {
                                                tableData.work_category === 'project_management' ? "Project Management" 
                                                : tableData.marketing_type === 'interior_designing' ? "Interior Designing"
                                                : tableData.marketing_type === 'plan_and_designing' ? "Plan & Designing"
                                                : "Construction"
                                            }
                                        </td> */}
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/work-management/edit-work-management/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                {date > tableData.completion_date ?
                                                    (<ActionButton onClick={() => deleteWork(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    ) : (<ActionButton title={'Delete'} onClick={() => warningWork(tableData.id)} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Works Data Not Found</p>
                        </div>
                    )}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto md:max-w-xl sm:max-w-sm  transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='flex justify-between items-center gap-2'>
                                            <h1 className='text-center uppercase font-bold text-xl tracking-widest'>Work Information</h1>
                                        </div>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Work Name</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.work_name}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Work Location</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.work_location}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Work Category</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.work_category}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'> Budget Amount</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{Number(modalData.budget_amount).toLocaleString('en-IN')}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Start Date</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{moment(modalData.start_date).format('D-MM-YYYY')}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'> completion Date</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{moment(modalData.completion_date).format('D-MM-YYYY')}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Work Status</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.work_status}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Assigned Supervisor</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.name}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Work Description </h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.work_description}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>RERA Registration</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.rera_registration}</h1>
                                        </div>
                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                            <h1 className='font-bold'>Amenities</h1>
                                            <h1 className='justify-center flex'>-</h1>
                                            <h1 className=''>{modalData.amenities}</h1>
                                        </div>
                                        <div className='flex justify-between mb-4 mt-3'>
                                            <div>
                                                <button type="button" className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel(false)} > Got it, thanks!
                                                </button>
                                            </div>
                                            <div>
                                                <Button className=" px-4 py-2 text-sm font-medium" onClick={() => handleModelImage()}>
                                                    <PhotoIcon className="h-5 w-5 text-default-color" />
                                                    <h1 className='bg-primary-color text-white text-center rounded-md pl-2'>Work Image</h1>
                                                </Button>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-[60%] max-w-2xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Work Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={workImage ? (
                                                workImage.name ? URL.createObjectURL(workImage) : IMG_URL + `/assets/images/CADFile/` + workImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                        }
                                        <div className="mt-4">
                                            <button type="button" className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>

            )}
        </Fragment>
    )
}

export default AllWork
