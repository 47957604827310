import React, { Fragment, useEffect, useState } from 'react'
import {PencilSquareIcon, ArrowLeftCircleIcon, InboxArrowDownIcon, TrashIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import TextArea from '../helpers/TextArea'
import ViewModel from '../helpers/ViewModel'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import Swal from 'sweetalert2'
import Table from '../helpers/Table'
import PleaseWait from '../Common/PleaseWait'

function DprAddEdit({ }) {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit DPR Report'
    } else {
        document.title = 'SiteSmart - Add New DPR Report'
    }
    const dprreport_id = id;
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const tableHeading = ['Sr.No', 'Material Name','Unit', 'Quantity', 'Action']
    const [material_name, setMaterial_name] = useState([])
    const [unit, setUnit] = useState([])
    const [quantity, setQuantity] = useState([])
    const [tableid, setTableid] = useState('')
    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        date: '',
        work_name: '',
        wing_name: '',
        level_name: '',
        contractor_name: '',
        name: '',
        // time_of_report: '',
        task_name: '',
        task_status: '',
        summary_of_completed_work: '',
        work_planned_for_tommarow: '',
        issues_and_concerns: '',
        safety_incidents: '',
    })

    const [materials, setMaterials] = useState({
        dprreport_id: dprreport_id,
        material_name: '',
        unit: '',
        quantity: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeMaterial = (e) => {
        setMaterials(materials => ({
            ...materials,
            [e.target.name]: e.target.value,
        }))
    }

    function openModal() {
        setMaterials({
            dprreport_id: dprreport_id,
            material_name: '',
            unit: '',
            quantity: '',
        }
        ); 
        setViewModel(true)
        setModalLoader(true)
        setModalLoader(false)
    }

    const handleSubmitMaterial = (e) => {
        e.preventDefault();
        if (!materials.material_name || !materials.unit || !materials.quantity) {
            flasher.error("All fields are mandatory")
            return;
        }
        if (isEditing && editingIndex !== null) {
            const updatedTableData = [...tableData];
            updatedTableData[editingIndex] = { ...materials };
            setTableData(updatedTableData);
        } else {
            setTableData([...tableData, { ...materials }]);
        }
        setViewModel(false)
        setIsEditing(false);
        setEditingIndex(null);
    };

    const handleSubmitWithBackend = (e) => {
        e.preventDefault();
        axiosClient.post(tableid ? `update_DPR_Material/${tableid}` : '/store_DPR_Material', materials)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                setViewModel(false)
                getSingledprdata()
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            });

    };

    const handleEdit = (index) => {
        openModal()
        const selectedSubtask = tableData[index];
        setMaterials({
            dprreport_id: selectedSubtask.dprreport_id,
            material_name: selectedSubtask.material_name,
            unit: selectedSubtask.unit,
            quantity: selectedSubtask.quantity,
        });
        setIsEditing(true);
        setEditingIndex(index);
    };


    function getsingleMaterialData(id) {
        axiosClient.get(`/single_DPR_Material_data/${id}`)
        .then(({ data }) => {
            setViewModel(true)
            setMaterials(data.single_data)
            setTableid(data.single_data.id)
            })
    }

    const handleDelete = (index) => {
        const filterData = tableData.filter((item, i) => i !== index)
        setTableData(filterData)
    };

    const deleteMaterial = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_DPR_Material/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSingledprdata()
                    })
            }
        })
    }

    const handleMaterials = (e) => {
        const materiallist = tableData && tableData.map((data) => {
            return data.material_name
        });
        setMaterial_name(materiallist)

        setUnit(materiallist)
        const unitlist = tableData && tableData.map((data) => {
            return data.unit
        });
        setUnit(unitlist)

        const quantities = tableData && tableData.map((data) => {
            return data.quantity
        });
        setQuantity(quantities)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            'date': formInfo.date,
            'wing_name': formInfo.wing_name,
            'work_name': formInfo.work_name,
            'level_name': formInfo.level_name,
            'name': formInfo.name,
            'task_name': formInfo.task_name,
            'contractor_name': formInfo.contractor_name,
            'task_status': formInfo.task_status,
            // 'time_of_report': formInfo.time_of_report,
            'summary_of_completed_work': formInfo.summary_of_completed_work,
            'work_planned_for_tommarow': formInfo.work_planned_for_tommarow,
            'issues_and_concerns': formInfo.issues_and_concerns,
            'safety_incidents': formInfo.safety_incidents,
            'material_name': material_name,
            'unit': unit,
            'quantity': quantity,
        }
        axiosClient.post(id ? `update_Dprreport/${id}` : '/store_Dprreport', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/task-activity/dprdetails')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const [workNameList, setWorkNameList] = useState([])
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkNameList(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingList = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelList = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [contractor, setContractor] = useState([]);
    const getContractorData = () => {
        axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setLoader(false)
                setContractor(data.lists)
            })
    }

    const [taskName, setTaskName] = useState([])
    const getTaskList = () => {
        axiosClient.get(`/show_task_master`)
            .then(({ data }) => {
                setTaskName(data.lists)
            })
    }

    const [material, setMaterial] = useState([]);
    const getMaterialList = () => {
        axiosClient.get(`/material_lists`)
            .then(({ data }) => {
                setLoader(false)
                setMaterial(data.lists)
            })

    }

    const [tableData, setTableData] = useState([]);
    const getUnitData = () => {
        materials.material_name && axiosClient.get(`/material_wise_unit_data/${materials.material_name}`)
        .then(({ data }) => {
            data.material_wise_unit_data.material_unit && (
                setMaterials(materials => ({
                    ...materials,
                    unit: data.material_wise_unit_data.material_unit,
                })))
        })
    }

    const getSingledprdata = () => {
        setLoader(true)
        axiosClient.get(`/single_Dprreport_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setTableData(data.multiDPR_Materials)
                
            })
    }
    useEffect(() => {
        getWingList()
    }, [formInfo.work_name])

    // useEffect(() => {
    //     getTaskData()
    // }, [formInfo.contractor_name])

    useEffect(() => {
        getUnitData()
    }, [materials.material_name])

    useEffect(() => {
        handleMaterials()
    }, [tableData])

    useEffect(() => {
        getLevelList()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        if (id) {
            getSingledprdata()
        }
        getWorkData();
        getTaskList();
        getMaterialList()
        getContractorData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit DPR ' : 'Add New DPR '} button={
                    <LinkButton to={'/task-activity/dprdetails'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[60rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'date'} labelText={'Date'} className={'mb-1'} />
                                        <Input id={'date'} type={'date'} name={'date'} value={formInfo.date} onChange={handleChange} />
                                        <Validation error={errors.date} />
                                    </div>
                                    {/* <div>
                                        <Label htmlFor={'time_of_report'} labelText={'Time of Report'} className={'mb-1'} />
                                        <Input id={'time_of_report'} type={'time'} name={'time_of_report'} value={formInfo.time_of_report} onChange={handleChange} />
                                        <Validation error={errors.time_of_report} />
                                    </div> */}
                                    <div >
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange}>
                                            <option value={''}>--- Select Work Name ---</option>
                                            {workNameList && workNameList.map(data => {
                                                return (
                                                    <option value={data.id}>{data.work_name}</option>
                                                )
                                            })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option value={''}>--- Select level_name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'contractor_name'} labelText={'Contractor Name'} className={'mb-1'} />
                                        <CustomSelect id={'contractor_name'} name={'contractor_name'} value={formInfo.contractor_name} onChange={handleChange} className={'w-full'}>
                                            <option value={''}>--- Choose Contractor Name ---</option>
                                            {contractor.map((contractorData, contractorID) => {
                                                return (<Fragment key={contractorID}>
                                                    <option value={contractorData.id}>{contractorData.contractor_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.contractor_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                        <CustomSelect id={'task_name'} name={'task_name'} value={formInfo.task_name} onChange={handleChange} className={'w-full'}>
                                            <option value={''}>--- Choose Task Name ---</option>
                                            {taskName && taskName.map((taskData, TaskId) => {
                                                    return (<Fragment key={TaskId}>
                                                        <option value={taskData.id}>{taskData.task_name}</option>
                                                    </Fragment>)
                                                })}
                                        </CustomSelect>
                                        <Validation error={errors.task_name} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'task_status'} labelText={'Task Status'} className={'mb-1'} />
                                        <CustomSelect id={'task_status'} name={'task_status'} value={formInfo.task_status} onChange={handleChange}>
                                            <option value={''}>--- Choose Status ---</option>
                                            <option value={'To-Do'}>To-Do</option>
                                            <option value={'Ongoing'}>Ongoing</option>
                                            <option value={'Completed'}>Completed</option>
                                        </CustomSelect>
                                        <Validation error={errors.task_status} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'name'} labelText={'Assignee Name'} className={'mb-1'} />
                                        <Input id={'name'} type={'text'} name={'name'} value={formInfo.name} onChange={handleChange} />
                                        <Validation error={errors.name} />
                                    </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-4'>
                                    <div >
                                        <Label htmlFor={'summary_of_completed_work'} labelText={'Summary of Work Completed'} className={'mb-1'} />
                                        <TextArea id={'summary_of_completed_work'} type={'text'} rows={1} name={'summary_of_completed_work'} value={formInfo.summary_of_completed_work} onChange={handleChange} maxLength={12} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.summary_of_completed_work} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'work_planned_for_tommarow'} labelText={'Work Planned for Tomorrow'} className={'mb-1'} />
                                        <TextArea id={'work_planned_for_tommarow'} type={'text'} rows={1} name={'work_planned_for_tommarow'} value={formInfo.work_planned_for_tommarow} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.work_planned_for_tommarow} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'issues_and_concerns'} labelText={'Issues and Concerns'} className={'mb-1'} />
                                        <TextArea id={'issues_and_concerns'} type={'text'} rows={1} name={'issues_and_concerns'} value={formInfo.issues_and_concerns} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.issues_and_concerns} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'safety_incidents'} labelText={'Safety Incidents'} className={'mb-1'} />
                                        <TextArea id={'safety_incidents'} type={'text'} rows={1} name={'safety_incidents'} value={formInfo.safety_incidents} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.safety_incidents} />
                                    </div>
                                </div><br></br>
                                <h3 className='md:text-2xl text-[4vw] font-bold mb-4'>Material Used</h3>
                                <button type='button' className={'w-[40%] md:w-[20%] group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color mb-4'} onClick={openModal}>
                                    <span>Add Material</span>
                                </button>
                                {tableData && tableData.length > 0 ? (
                                         <div className='w-[65vw] md:w-full'>
                                    <Table tableHeading={tableHeading}>
                                        {tableData.map((item, i) => {
                                            return (<Fragment key={i}>
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <td className="px-4 py-3">{i + 1}</td>
                                                    {
                                                        item.material_name && material.map((data) => {
                                                            return (
                                                                item.material_name == data.id && data.material_name &&
                                                                <td className=' px-4 py-3'> {data.material_name}</td>
                                                            )
                                                        })
                                                    }
                                                    <td className="px-4 py-3">{item.unit}</td>
                                                    <td className="px-4 py-3">{item.quantity}</td>
                                                    <td className="px-4 py-3">
                                                            <div className='flex justify-center gap-3'>
                                                                <button onClick={id?(() => getsingleMaterialData(item.id)) : (() => handleEdit(i))} className={" transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 group relative flex justify-center rounded-md bg-yellow-400 p-2 tracking-wider font-semibold text-default-color  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><PencilSquareIcon className='w-5 h-5' /></button>
                                                                <button onClick={id ? (() => deleteMaterial(item.id) ) : (() => handleDelete(i))} className={" transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 group relative flex justify-center rounded-md bg-red-700 p-2 tracking-wider font-semibold text-default-color  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><TrashIcon className='w-5 h-5' /></button>
                                                            </div>
                                                    </td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                    </Table>
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-[15vh]'>
                                        <p className='md:text-3xl text-[4vw] font-bold'>Sorry! Materials Not Found</p>
                                    </div>
                                )
                                }
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-[60%] max-w-xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <form>
                                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-4'>
                                                <div>
                                                    <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                    <CustomSelect id={'material_name'} name={'material_name'} value={materials.material_name} onChange={handleChangeMaterial}>
                                                        <option value={''}>--- Select Work Name ---</option>
                                                        {material && material.map(data => {
                                                            return (
                                                                <option value={data.id}>{data.material_name}</option>
                                                            )
                                                        })
                                                        }
                                                    </CustomSelect>

                                                    <Validation error={errors.material_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'unit'} labelText={'Unit'} className={'mb-1'} />
                                                    <Input id={'unit'} type={'text'} name={'unit'} value={materials.unit}  />
                                                    <Validation error={errors.unit} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'quantity'} labelText={'Quantity'} className={'mb-1'} />
                                                    <Input id={'quantity'} type={'text'} name={'quantity'} value={materials.quantity} onChange={handleChangeMaterial} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                    <Validation error={errors.quantity} />
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2 md:grid-cols-3 gap-x-3 mt-4 gap-y-2'>
                                                    <Button
                                                    type="submit"
                                                    className=" inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={id? (handleSubmitWithBackend) : (handleSubmitMaterial) }>
                                                        Save
                                                    </Button>
                                                 <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModel(false)} >
                                                    Cancel
                                                </button>
                                                        <div></div>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default DprAddEdit
