import React, { Fragment } from 'react'

function Card({children,className}) {
    return (
        <Fragment>     
            <div className={"block border border-gray-200 rounded-lg shadow "+(className)}>
                {children}
            </div>
        </Fragment>
    )
}

export default Card