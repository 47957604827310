import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import Table from '../helpers/Table'
import ViewModel from '../helpers/ViewModel'
import { useStateContext } from '../../context/ContextProvider'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import Swal from 'sweetalert2'
import CurrencyInput from 'react-currency-input-field'
import PleaseWait from '../Common/PleaseWait'

function QuotationAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Quotation'
        ) : (
            document.title = 'SiteSmart - Add Quotation'
        )
    }
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [viewModel2, setViewModel2] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [p_id, setP_id] = useState("")
    const [workName, setWorkName] = useState("")
    const [wingName, setWingName] = useState("")
    const [levelName, setLevelName] = useState("")
    const [supplierQuotationImg, setSupplierQuotationImg] = useState('');
    const [supplier, setSupplier] = useState([])
    const [requisitionList, setRequisitionList] = useState([])
    const [quotationList, setQuotationList] = useState([])
    const [quotationTable, setQuotationTable] = useState([]);
    const [modelImage, setModelImage] = useState(false)
    const [tableTotal, setTableTotal] = useState('')
    const [user, setUser] = useState([]);

    const tableHeading = ['Sr.No', 'Material', 'Unit', 'Quantity']
    const tableHeading2 = ['Sr.No', 'Supplier', 'Material', 'Unit', 'Quantity', 'Price', 'GST', 'Total', 'Action']
    const tableHeading3 = ['Sr.No', 'Supplier', 'Material', 'Unit', 'Quantity', 'Price', 'GST', 'Total', 'Action']


    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()

    const [formDetail, setFormDetail] = useState({
        supplier_name: '',
        send_to: '',
        loading: 0,
        delivery: 0,
        other: 0,
        date: '',
        grand_total: 0,
        material_name: '',
        material_unit: '',
        quantity: 0,
        unit_price: '',
        gst: 0,
        total: 0,
    })

    const handleChange = (e) => {
        setFormDetail(formDetail => ({
            ...formDetail,
            [e.target.name]: e.target.value
        }))
    }

    const handleQuantityUnit = () => {
        requisitionList && requisitionList.map((data) => {
            return (
                data.id == formDetail.material_name &&
                (setFormDetail(formDetail => ({
                    ...formDetail,
                    quantity: data.quantity,
                    material_unit: data.material_unit
                })))
            )
        })
    }


    const getSupplier = () => {
        setLoader(true)
        axiosClient.get(`/supplier_lists`)
            .then(({ data }) => {
                setSupplier(data.lists)
                setLoader(false)
            })
    }

    const getQuotationList = () => {
        setLoader(true)
        axiosClient.get(`/po_quotation_wise_supplier_lists/${id}`)
            .then(({ data }) => {
                setQuotationList(data.lists)
                setLoader(false)

            })
    }

    const getUserData = () => {
        setLoader(true)
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setUser(data.lists)
                setLoader(false)
            })
    }



    const handlesupplierQuotationImg = (e) => {
        setSupplierQuotationImg(e.target.files[0]);
    }

    const getSinglePurchaseOrder = () => {
        setLoader(true)
        axiosClient.get(`single_purchase_Order_data/${id}`)
            .then(({ data }) => {
                setRequisitionList(data.material_lists)
                setWorkName(data.view_data.work_name)
                setWingName(data.view_data.wing_name)
                setLevelName(data.view_data.level_name)
                setP_id(id)
                setLoader(false)
            })
    }


    const handleModalDelete = (index) => {
        const filterData = quotationTable.filter((item, i) => i !== index)
        setQuotationTable(filterData)
    }

    const handleModelImage = (image, foldername) => {
        setModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setModalLoader(false)
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_material_addto_card/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getQuotationList();
                    })
            }
        })
    }

    const handleAddToCart = (e) => {
        e.preventDefault();
        const payload = {
            supplier_name: formDetail.supplier_name,
            material_name: formDetail.material_name,
            material_unit: formDetail.material_unit,
            quantity: formDetail.quantity,
            unit_price: formDetail.unit_price,
            gst: formDetail.gst,
            total: formDetail.total,
        }
        formDetail.supplier_name == '' ? flasher.warning('Supplier Not Selected') : formDetail.total == 0 ? flasher.warning('Select Material & set Unit Price ') : setQuotationTable([...quotationTable, payload]);

        setFormDetail(formDetail => ({
            ...formDetail,
            material_name: '',
            material_unit: '',
            quantity: 0,
            unit_price: '',
            gst: 0,
            total: 0,
        }));
        setViewModel2(false);
    }

    const handleAddQuotation = (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData()
        formData.append('supplierQuotationImg', supplierQuotationImg)
        const payload =
        {
            supplier_name: formDetail.supplier_name,
            send_to: formDetail.send_to,
            loading: formDetail.loading,
            delivery: formDetail.delivery,
            other: formDetail.other,
            date: formDetail.date,
            grand_total: formDetail.grand_total,
            quotationMaterialList: quotationTable,
            supplierQuotationImg: supplierQuotationImg,
        }

        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: formDetail.send_to,
            message: 'You have a new Quotation approval request...',
            module: 'Quotation',
            link: '/quotation',
        }

        axiosClient.post(`store_pomaterial_addto_card/${id}`, payload)
            .then(({ data }) => {
                data.quotation_id && axiosClient.post(`updatepoQuotation_img/${data.quotation_id}`, formData)
                    .then(({ data }) => {
                        setLoader(false)
                    })
                    .catch(({ response }) => {
                        setLoader(false)
                        setErrors(response.data.errors)
                    })

                data.status ? flasher.success(data.msg) : flasher.warning(data.msg);
                data.status && (
                    axiosClient.post(`store_Notification`, notification)
                        .then(({ data }) => {
                            setLoader(false)
                        })
                        .catch(({ response }) => {
                            setLoader(false)
                        })
                )
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })

        getQuotationList();
        setViewModel(false)

        setQuotationTable([]);
        setSupplierQuotationImg('');
        setFormDetail({
            supplier_name: '',
            material_name: '',
            material_unit: '',
            quantity: 0,
            unit_price: '',
            gst: 0,
            total: 0,
        });
    }


    const handleTableTotal = () => {
        var total = 0;
        quotationTable.map((data) => {
            return (
                total = total + data.total
            )
        })
        setTableTotal(total);
    }

    const handleUnitPrice = (e) => {
        setFormDetail(formDetail => ({
            ...formDetail,
            unit_price: e
        }))
    }

    const handleTotal = () => {
        let num = parseInt(formDetail.quantity) * parseInt(formDetail.unit_price)
        let num2 = (num + ((num / 100) * parseInt(formDetail.gst)))
        setFormDetail(formDetail => ({
            ...formDetail,
            total: num2
        }))
    }

    useEffect(() => {
        handleTotal();
    }, [formDetail.gst, formDetail.unit_price, formDetail.quantity])

    useEffect(() => {
        handleTableTotal();
    }, [quotationTable])

    useEffect(() => {
        handleQuantityUnit();
    }, [formDetail.material_name])

    useEffect(() => {
        getSupplier();
        getUserData();
    }, [])

    useEffect(() => {
        getSinglePurchaseOrder();
        getQuotationList();
    }, [])


    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Add Quotation'} button={
                    <LinkButton to={'/quotation'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[95%] p-6  bg-default-color'}>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-3 mb-3'>
                                <div>
                                    <Label htmlFor={'purchase_id'} labelText={'Quotation Id'} className={'mb-1'} />
                                    <Input id={'purchase_id'} type={'text'} name={'purchase_id'} readOnly={true} value={parseInt(p_id).toLocaleString('en-IN')} onChange={handleChange} />
                                    <Validation error={errors.purchase_id} />
                                </div>
                                <div>
                                    <Label htmlFor={'ordered_to'} labelText={'Work Name'} className={'mb-1'} />
                                    <Input id={'ordered_to'} type={'text'} name={'ordered_to'} value={workName} readOnly={true} onChange={handleChange} />
                                    <Validation error={errors.ordered_to} />
                                </div>
                                <div className='mb-3'>
                                    <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                    <Input id={'wing_name'} type={'text'} name={'wing_name'} value={wingName} readOnly={true} />
                                    <Validation error={errors.wing_name} />
                                </div>
                                <div className='mb-3'>
                                    <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                    <Input id={'level_name'} type={'text'} name={'level_name'} value={levelName} readOnly={true} />
                                    <Validation error={errors.level_name} />
                                </div>

                            </div>
                        </Card>

                    </div >
                    <div className='flex items-center justify-center mt-4 mb-4'>
                        <Card className={'w-[95%] p-6  bg-default-color'}>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-2.5 gap-x-[54%]'>
                                <div className='font-bold text-xl '>Required Material List</div>
                                <Button className='text-default-color w-full ' data-modal-target="defaultModal" onClick={() => setViewModel(true)} data-modal-toggle="defaultModal" type="button">
                                    <span>Add Quotation</span>
                                </Button>
                            </div>
                            <div className='mt-4 w-full mb-4'>
                                {requisitionList && requisitionList.length > 0 ? (
                                    <Table tableHeading={tableHeading}>
                                        {requisitionList.map((tableData, tableDataID) => {
                                            return (<Fragment key={tableDataID}>
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <td>{tableDataID + 1}</td>
                                                    <td className="px-4 py-3">{tableData.material_name}</td>
                                                    <td className="px-4 py-3">{tableData.material_unit}</td>
                                                    <td className="px-4 py-3">{parseInt(tableData.quantity).toLocaleString('en-IN')}</td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                    </Table>
                                ) : (
                                    <div className='flex justify-center items-center h-[20vh]'>
                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Material Data Not Found</p>
                                    </div>
                                )}
                            </div>
                            {
                                quotationList && quotationList.map((data, id) => {
                                    return (
                                        <div className="mb-4">
                                            <div className='grid grid-cols-1 md:grid-cols-2 '>
                                                <div className='font-bold text-xl '>
                                                    {
                                                        supplier && supplier.map((data2, id) => {
                                                            return (
                                                                <Fragment key={id}>
                                                                    {data2.id == data.supplier_name && <h1>{data2.supplier_name} Quotation</h1>}
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2">
                                                    <div></div>
                                                    <div></div>

                                                </div>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-3 mt-4 gap-1 divide-x p-4'>
                                                <div className="shadow-md">
                                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                        <h1 className='flex justify-center font-bold'>Delivery Charges -</h1>
                                                        <h1 className=' flex justify-center'>{parseInt(data.delivery).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                        <h1 className='flex justify-center font-bold'>Loading Charges</h1>
                                                        <h1 className=' flex justify-center'>{parseInt(data.loading).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                </div>
                                                <div className="shadow-md">
                                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                        <h1 className='flex justify-center font-bold'>Other Charges -</h1>
                                                        <h1 className=' flex justify-center'>{parseInt(data.other).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                        <h1 className='flex justify-center font-bold'>Expected Delivery Date</h1>
                                                        <h1 className=' flex justify-center'>{data.date}</h1>
                                                    </div>
                                                </div>
                                                <div className="shadow-md">
                                                    <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                        <h1 className='flex justify-center font-bold'>Grand Total -</h1>
                                                        {/* <h1 className=' flex justify-center'>{parseInt(data.grand_total).toLocaleString('en-IN')}</h1> */}
                                                        <h1 className=' flex justify-center'>{(data.grand_total).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='mb-4 px-2 flex justify-end'>
                                                        <Button className='text-default-color' data-modal-target="defaultModal" onClick={() => handleModelImage(data.supplierQuotationImg, 'owneradharCard')} data-modal-toggle="defaultModal" type="button">
                                                            <PhotoIcon className='w-5 h-5 pr-1 text-default-color' /> Quotation Image
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4 w-full'>
                                                {data.material_lists && data.material_lists.length > 0 ? (
                                                    <Table tableHeading={tableHeading3}>
                                                        {data.material_lists.map((tableData, index) => {
                                                            return (<Fragment key={index}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td>{index + 1}</td>
                                                                    <td className="px-4 py-3">
                                                                        {tableData.supplier_name}
                                                                    </td>
                                                                    <td className="px-4 py-3"> {tableData.material_name}
                                                                    </td>
                                                                    <td className="px-4 py-3"> {tableData.material_unit}
                                                                    </td>
                                                                    <td className="px-4 py-3"> {parseInt(tableData.quantity).toLocaleString('en-IN')}
                                                                    </td>
                                                                    <td className="px-4 py-3"> {parseInt(tableData.unit_price).toLocaleString('en-IN')}
                                                                    </td>
                                                                    <td className="px-4 py-3"> {tableData.purchase_gst}
                                                                    </td>
                                                                    <td className='px-4 py-3' >{parseInt(tableData.total).toLocaleString('en-IN')}</td>
                                                                    <td className='px-4 py-3'>
                                                                        <div className='flex justify-center gap-3'>
                                                                            <Link onClick={() => handleDelete(tableData.id)} className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "}><TrashIcon className='w-5 h-5' /></Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}
                                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                                            <td className='px-4 py-3 font-bold'>Total</td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className="px-4 py-3"></td>
                                                            <td className='px-4 py-3 font-bold'>{parseInt(data.totalSum).toLocaleString('en-IN')}</td>
                                                            <td className="px-4 py-3"></td>
                                                        </tr>
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[20vh]'>
                                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Material Data Not Found</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Card>
                    </div>
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Add Quotation</h1>
                                    <hr className='my-4 border border-primary-color' />
                                    <form onSubmit={(e) => handleAddQuotation(e)}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                            <div className='mb-3'>
                                                <div className="flex justify-center">
                                                    <Label htmlFor={'supplier_name'} labelText={'Supplier Name'} className={'mb-1'} />
                                                </div>
                                                <CustomSelect id={'supplier_name'} name={'supplier_name'} value={formDetail.supplier_name} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose User Name ---</option>
                                                    {supplier.map(data => {
                                                        return (
                                                            <option value={data.id}>{data.supplier_name}</option>
                                                        )
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.supplier_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'send_to'} labelText={'Send To'} className={'mb-1'} />
                                                <CustomSelect id={'send_to'} name={'send_to'} value={formDetail.send_to} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose User Name ---</option>
                                                    {user.map((data, id) => {
                                                        return (<Fragment key={id}>
                                                            <option value={data.id}>{data.name} ({((data.role).split('_')).join(' ')})</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.send_to} />
                                            </div>
                                            <div className=''>
                                                <div className="flex justify-center">
                                                    <Label labelText={'Generate Quotation'} className={' text-white text-xl'} />
                                                </div>
                                                <div className="flex justify-center">
                                                    <Link title='Add' className={' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '} onClick={() => setViewModel2(true)}>
                                                        <PlusCircleIcon className='w-5 h-5 mr-1' /> Add Material
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-4 w-full'>
                                            {quotationTable && quotationTable.length > 0 ? (
                                                <Table tableHeading={tableHeading2}>
                                                    {quotationTable.map((tableData, tableDataID) => {
                                                        return (<Fragment key={tableDataID}>
                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                <td>{tableDataID + 1}</td>
                                                                <td className="px-4 py-3">
                                                                    {
                                                                        supplier && supplier.map((data, id) => {
                                                                            return (
                                                                                <Fragment key={id}>
                                                                                    <h1>{data.id == tableData.supplier_name && data.supplier_name}</h1>
                                                                                </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-3">
                                                                    {
                                                                        requisitionList && requisitionList.map((data, id) => {
                                                                            return (<Fragment key={id}>
                                                                                <h1>{data.id == tableData.material_name && data.material_name}</h1>
                                                                            </Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-3"> {tableData.material_unit}
                                                                </td>
                                                                <td className="px-4 py-3"> {parseInt(tableData.quantity).toLocaleString('en-IN')}
                                                                </td>
                                                                <td className="px-4 py-3"> {parseInt(tableData.unit_price).toLocaleString('en-IN')}
                                                                </td>
                                                                <td className="px-4 py-3"> {tableData.gst}
                                                                </td>
                                                                <td className='px-4 py-3' >{parseInt(tableData.total).toLocaleString('en-IN')}</td>
                                                                <td className='px-4 py-3'>
                                                                    <div className='flex justify-center gap-3'>
                                                                        <Link onClick={() => handleModalDelete(tableDataID)} className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "}><TrashIcon className='w-5 h-5' /></Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>)
                                                    })}
                                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                                        <td className='px-4 py-3 font-bold'>Total</td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className="px-4 py-3"></td>
                                                        <td className='px-4 py-3 font-bold'>{parseInt(tableTotal).toLocaleString('en-IN')}</td>
                                                        <td className="px-4 py-3"></td>
                                                    </tr>
                                                </Table>
                                            ) : (
                                                <div className='flex justify-center items-center h-[20vh]'>
                                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Material Data Not Found</p>
                                                </div>
                                            )}
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-5">
                                                <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                                    <div>
                                                        <Label htmlFor={'delivery'} labelText={'Delivery Charges'} className={'mb-1'} />
                                                        <Input id={'delivery'} type={'text'} name={'delivery'} value={formDetail.delivery} onChange={handleChange} />
                                                        <Validation error={errors.delivery} />
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={'loading'} labelText={'Loading Charges'} className={'mb-1'} />
                                                        <Input id={'loading'} type={'text'} name={'loading'} value={formDetail.loading} onChange={handleChange} />
                                                        <Validation error={errors.loading} />
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={'other'} labelText={'Other Charges'} className={'mb-1'} />
                                                        <Input id={'other'} type={'text'} name={'other'} value={formDetail.other} onChange={handleChange} />
                                                        <Validation error={errors.other} />
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={'date'} labelText={'Expected Delivery Date'} className={'mb-1'} />
                                                        <Input id={'date'} type={'date'} name={'date'} value={formDetail.date} onChange={handleChange} />
                                                        <Validation error={errors.date} />
                                                    </div>
                                                    <div>
                                                        <Label htmlFor={'grand_total'} labelText={'Grand Total'} className={'mb-1'} />
                                                        <Input id={'grand_total'} type={'text'} name={'grand_total'} value={formDetail.grand_total = parseInt(parseInt(formDetail.delivery) + parseInt(formDetail.loading) + parseInt(formDetail.other) + parseInt(tableTotal)).toLocaleString('en-IN')} onChange={handleChange} />
                                                        <Validation error={errors.grand_total} />
                                                    </div>
                                                </div>

                                                <div className=''>
                                                    <div className="flex justify-center">
                                                        <Label htmlFor={'purchase_img'} labelText={'Upload Quotation'} />
                                                    </div>
                                                    <div className="flex justify-center">
                                                        <img src={supplierQuotationImg ? (
                                                            supplierQuotationImg.name ? URL.createObjectURL(supplierQuotationImg) : IMG_URL + " /assets/images/PurchaseOrder/Quotation/" + supplierQuotationImg
                                                        ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="purchase-img" className='w-60 h-36 rounded' />
                                                    </div>
                                                    <Input id={'purchase_img'} type={'file'} name={'purchase_img'} onChange={handlesupplierQuotationImg} className={'p-[0px!important] mt-2'} />
                                                    <Validation error={errors.purchase_img} />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </form>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }

                    {
                        viewModel2 && <ViewModel viewModel={viewModel2} setViewModel={setViewModel2} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Add Material Form</h1>
                                    <hr className='my-4 border border-primary-color' />
                                    <form onSubmit={(e) => handleAddToCart(e)}>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                <CustomSelect id={'material_name'} name={'material_name'} value={formDetail.material_name} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose User Name ---</option>
                                                    {requisitionList && requisitionList.map(data => {
                                                        return (
                                                            <option value={data.id}>{data.material_name}</option>
                                                        )
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.material} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'unit'} labelText={'Unit'} className={'mb-1'} />
                                                <Input id={'unit'} type={'text'} name={'unit'} value={formDetail.material_unit} readOnly={true} />
                                                <Validation error={errors.unit} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'quantity'} labelText={'Quantity'} className={'mb-1'} />
                                                <Input id={'quantity'} type={'text'} name={'quantity'} value={parseInt(formDetail.quantity).toLocaleString('en-IN')} quantity onChange={handleChange} />
                                                <Validation error={errors.quantity} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'unit_price'} labelText={'Unit Price'} className={'mb-1'} />
                                                <CurrencyInput
                                                    className={"block w-full rounded-md border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                    id="flat_amount_without_gst"
                                                    name="flat_amount_without_gst"
                                                    decimalsLimit={2}
                                                    defaultValue={formDetail.unit_price}
                                                    onValueChange={handleUnitPrice}
                                                />
                                                <Validation error={errors.unit_price} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'gst'} labelText={"GST %"} className={'mb-1'} />
                                                <CustomSelect id={'gst'} name={'gst'} defaultValue={formDetail.gst} value={formDetail.gst} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose GST ---</option>
                                                    <option value={0}>0</option>
                                                    <option value={5}>5</option>
                                                    <option value={18}>18</option>
                                                    <option value={28}>28</option>
                                                </CustomSelect>
                                                <Validation error={errors.gst} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'total'} labelText={'Total'} className={'mb-1'} />
                                                <Input id={'total'} type={'text'} name={'total'} readOnly={true} value={parseInt(formDetail.total).toLocaleString('en-IN')} onChange={handleChange} />
                                                <Validation error={errors.total} />
                                            </div>
                                        </div>
                                        <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit"
                                        >
                                            Add
                                        </Button>
                                    </form>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }

                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>View Full Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={modelImage ? (
                                                modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/PurchaseOrder/Quotation/` + modelImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Quotation-img" className='w-full h-full rounded' />
                                        }
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center mr-3 rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent >
            )
            }
        </Fragment >
    )
}


export default QuotationAddEdit


