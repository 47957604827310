import React, { Fragment, useRef, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Menu, Transition } from '@headlessui/react'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import { CSVLink } from 'react-csv'
import ViewModel from '../helpers/ViewModel'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import PleaseWait from '../Common/PleaseWait'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AgencyMaster() {
    document.title = 'SiteSmart - Agency Master'
    const tableHeading = ['Sr.No', 'Company Name', 'Owner Name', 'Co-Owner Name', 'GST Number', 'Action']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [companylist, setCompanylist] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [csvFile, setCsvFile] = useState('')
    const [ViewCsv, setViewCsv] = useState(false)

    const getCompanylist = () => {
        setLoader(true)
        axiosClient.get('/company_lists')
            .then(({ data }) => {
                setLoader(false)
                setCompanylist(data.lists.sort((a, b) => a.name.localeCompare(b.name)));
                setCompanylist(data.lists)
            })
    }
    const deletecompany = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_company/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getCompanylist()
                    })
            }
        })
    }
    const [modalData, setModalData] = useState([])
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_company_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
            })
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Company Name', key: 'name' },
        { label: 'Owner Name', key: 'ownername' },
        { label: 'Contact No ', key: 'contact_no' },
        { label: 'Co-Owner Name', key: 'co_owner' },
        { label: 'Co-Owner Contact No', key: 'co_owner_contact_no' },
        { label: 'Reg No', key: 'reg_no' },
        { label: 'Address', key: 'address' },
        { label: 'GST No', key: 'gst_no' },
        { label: 'Pan No', key: 'pan_no' },
        { label: 'Branck Name', key: 'bank_name' },
        { label: 'Account Number', key: 'bank_acc' },
        { label: 'Bank Branch', key: 'bank_branch' },
        { label: 'IFSC Code', key: 'ifsc_code' },
    ];

    const handleCsvFile = () => {
        setLoader(true)
        axiosClient.post(`import_material`, csvFile)
            .then(({ data }) => {
                flasher.success(data.msg)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
        setViewCsv(false)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getCompanylist()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Company Master'}
                //  button={
                //     <LinkButton to={'/company-master/add-company-master'}>
                //         <PlusCircleIcon className='w-6 h-6' />
                //         <span>Add New Company</span>
                //     </LinkButton>
                // }
                >
                    <div className="flex justify-end mb-2">
                        <Menu as="div" className="">
                            <div className="flex justify-end pt-2">
                                <Menu.Button >
                                    <span className="sr-only">Open user menu</span>
                                    <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                </Menu.Button>
                            </div>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="px-2 py-2">
                                            <CSVLink data={companylist} headers={headers} filename={'Company List.csv'}>
                                                <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                            </CSVLink>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {companylist && companylist.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {companylist.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.ownername}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.co_owner}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.gst_no}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/company-master/edit-company-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                {/* <ActionButton onClick={() => deletecompany(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton> */}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Agencies Data Not Found</p>
                        </div>
                    )}
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Company Information</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-4  py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Company Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.name}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Owner Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.ownername}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Owner Contact Number</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.contact_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Co-owner Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.co_owner}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Co-Owner Contact Number</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.co_owner_contact_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Address</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.address}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Registration Number</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.reg_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>GST No.</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.gst_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>PAN No</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.pan_no}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Bank Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_name}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Bank Branch</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_branch}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>Account No.</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.bank_acc}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-36 font-bold'>IFSC Code </h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.ifsc_code}</h1>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className=" rounded-md bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        ViewCsv && <ViewModel viewModel={ViewCsv} setViewModel={setViewCsv} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Import CSV File</h1>
                                    <hr className='my-4 border border-primary-color' />
                                    <h1 className='text-lg font-bold'>Note :-</h1>
                                    <div className='m-y-4 pl-5'>
                                        <code className='block'><span className='font-semibold'>1.</span>The Excel File must include required column's</code>
                                        <code className='block mb-1'><span className='font-semibold'>2.</span>Column heading's should be...</code>
                                        <code className='grid grid-cols-3 gap-x-5 mb-1'>
                                            <span>company_name</span>
                                            <span>owner_name</span>
                                            <span>contact_no</span>
                                            <span>co_owner_name</span>
                                            <span>co_owner_contact_no</span>
                                            <span>reg_no</span>
                                            <span>address</span>
                                            <span>gst_no</span>
                                            <span>pan_no</span>
                                            <span>bank_name</span>
                                            <span>branck_name</span>
                                            <span>account_number</span>
                                            <span>ifsc_code</span>
                                        </code>
                                        <code className='block'><span className='font-semibold'>3.</span>Serial No. column is optional.</code>
                                        <code className='block'><span className='font-semibold'>4.</span>The file format should be .csv or .xls</code>

                                    </div>
                                    <Input id={'csvFile'} type={'file'} name={'csvFile'} onChange={(e) => setCsvFile(e.target.files[0])} className={'p-[0px!important] mt-2 mb-2'} />
                                    <div className="mt-4 grid grid-cols-3">
                                        <Button onClick={() => handleCsvFile()} className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default AgencyMaster