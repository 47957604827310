import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon, XMarkIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import TextArea from '../helpers/TextArea'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function SupplierMasterAddEdit() {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Supplier'
    } else {
        document.title = 'SiteSmart - Add New Supplier'
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [selectedMaterialsFromBackend, setSelectedMaterialsFromBackend] = useState([]);
    const [newlyAddedMaterials, setNewlyAddedMaterials] = useState([]);
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        supplier_name: '',
        company_name: '',
        supplier_email: '',
        supplier_phone: '',
        supplier_address: '',
        supplier_pincode: '',
        supplier_poc: '',
        supplier_gst_no: '',
        supplier_bank_name: '',
        supplier_bank_branch: '',
        supplier_bank_account_no: '',
        supplier_bank_ifsc_code: '',
        material_name: ''
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const materialId = selectedMaterialsFromBackend.map((material) => material.id);
        const payload = {
            'supplier_name': formInfo.supplier_name,
            'company_name': formInfo.company_name,
            'supplier_email': formInfo.supplier_email,
            'supplier_phone': formInfo.supplier_phone,
            'supplier_address': formInfo.supplier_address,
            'supplier_pincode': formInfo.supplier_pincode,
            'supplier_poc': formInfo.supplier_poc,
            'supplier_gst_no': formInfo.supplier_gst_no,
            'supplier_bank_name': formInfo.supplier_bank_name,
            'supplier_bank_branch': formInfo.supplier_bank_branch,
            'supplier_bank_account_no': formInfo.supplier_bank_account_no,
            'supplier_bank_ifsc_code': formInfo.supplier_bank_ifsc_code,
            'material_name': materialId
        }
        axiosClient.post(id ? `update_supplier/${id}` : '/store_supplier', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/supplier-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const getSingleSupplierData = () => {
        setLoader(true)
        axiosClient.get(`/single_supplier_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setSelectedMaterialsFromBackend(data.Material_data);
            })
    }

    const [material, setMaterial] = useState([])
    const getMaterial = () => {
        setLoader(true)
        axiosClient.get(`/material_lists`)
            .then(({ data }) => {
                setLoader(false)
                setMaterial(data.lists)
            })
    }

    const deletematerials = (id) => {
        axiosClient.delete(`/delete_mulitimaterial/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
            })
    }

    const handleMaterialSelection = (selectedOption) => {
        const selectedTask = material.find((data) => data.material_name === selectedOption);
        if (selectedTask) {
            if (selectedTask.id) {
                setSelectedMaterialsFromBackend([...selectedMaterialsFromBackend, selectedTask]);
            } else {
                setNewlyAddedMaterials([...newlyAddedMaterials, selectedTask]);
            }
        }
    };

    const removeSelectedMaterial = (materialtoremove) => {
        if (materialtoremove.id) {
            deletematerials(materialtoremove.id);
            setSelectedMaterialsFromBackend((prevTasks) => prevTasks.filter((material) => material.id !== materialtoremove.id));
        } else {
            setNewlyAddedMaterials((prevTasks) => prevTasks.filter((material) => material !== materialtoremove));
        }
    };

    useEffect(() => {
        if (id) {
            getSingleSupplierData()
        }
        getMaterial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Supplier' : 'Add New Supplier'} button={
                    <LinkButton to={'/supplier-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className="w-[60rem] p-4 bg-default-color">
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
                                    <div className='col-start-1 md:col-span-2'>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_name'} labelText={'Name'} className={'mb-1'} />
                                                <Input id={'supplier_name'} type={'text'} name={'supplier_name'} value={formInfo.supplier_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.supplier_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'company_name'} labelText={'Company Name'} className={'mb-1'} />
                                                <Input id={'company_name'} type={'text'} name={'company_name'} value={formInfo.company_name} onChange={handleChange} />
                                                <Validation error={errors.company_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_poc'} labelText={'Point Of Contact'} className={'mb-1'} />
                                                <Input id={'supplier_poc'} type={'text'} name={'supplier_poc'} value={formInfo.supplier_poc} onChange={handleChange} />
                                                <Validation error={errors.supplier_poc} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                <CustomSelect id={'material_name'} name={'material_name'} value={formInfo.material_name} className={'w-full'}
                                                    onChange={(e) => {
                                                        const selectedOption = e.target.value;
                                                        if (selectedOption) {
                                                            handleMaterialSelection(selectedOption);
                                                        }
                                                    }}>

                                                    <option value="" >Select a Material</option>
                                                    {material.map((materialData, materialId) => (
                                                        <option key={materialId} value={materialData.material_name}>
                                                            {materialData.material_name}
                                                        </option>
                                                    ))}
                                                </CustomSelect>
                                                <Validation error={errors.material_name} />

                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_email'} labelText={'Email'} className={'mb-1'} />
                                                <Input id={'supplier_email'} type={'email'} name={'supplier_email'} value={formInfo.supplier_email} onChange={handleChange} />
                                                <Validation error={errors.supplier_email} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_address'} labelText={'Address'} className={'mb-1'} />
                                                <TextArea id={'supplier_address'} name={'supplier_address'} value={formInfo.supplier_address} rows={1} onChange={handleChange} />
                                                <Validation error={errors.supplier_address} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_pincode'} labelText={'Pincode'} className={'mb-1'} />
                                                <Input id={'supplier_pincode'} type={'text'} name={'supplier_pincode'} value={formInfo.supplier_pincode} onChange={handleChange} maxLength={6} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.supplier_pincode} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_phone'} labelText={'Phone'} className={'mb-1'} />
                                                <Input id={'supplier_phone'} type={'text'} name={'supplier_phone'} value={formInfo.supplier_phone} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.supplier_phone} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'supplier_gst_no'} labelText={'GST No.'} className={'mb-1'} />
                                                <Input id={'supplier_gst_no'} type={'text'} name={'supplier_gst_no'} value={formInfo.supplier_gst_no} maxLength={15} onChange={handleChange} />
                                                <Validation error={errors.supplier_gst_no} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Label htmlFor={'material_name'} labelText={'Material List'} className={'mb-1'} />
                                            <div className=" grid grid-cols-1 gap-4">
                                                {[...selectedMaterialsFromBackend, ...newlyAddedMaterials].map((task, index) => (
                                                    <div className="flex">
                                                        <Input id={''} type={'text'} name={''} className={'rounded-r-none bg-primary-color shadow-none border-primary-color text-white'} value={task.material_name} onChange={handleChange} readOnly={true} />
                                                        <span onClick={() => removeSelectedMaterial(task)} className="inline-flex items-center px-3 text-sm text-white bg-red-600 border border-l-0 border-red-600 rounded-r-md dark:bg-red-600 dark:text-white dark:border-red-600">
                                                            <XMarkIcon className='w-5 h-5' />
                                                        </span>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                                <div className='mb-4'>
                                    <span className='text-xl font-light'>Bank Account Details :</span>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'supplier_bank_name'} labelText={'Bank Name'} className={'mb-1'} />
                                        <Input id={'supplier_bank_name'} type={'text'} name={'supplier_bank_name'} value={formInfo.supplier_bank_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.supplier_bank_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'supplier_bank_branch'} labelText={'Bank Branch'} className={'mb-1'} />
                                        <Input id={'supplier_bank_branch'} type={'text'} name={'supplier_bank_branch'} value={formInfo.supplier_bank_branch} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.supplier_bank_branch} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'supplier_bank_account_no'} labelText={'Bank Account No'} className={'mb-1'} />
                                        <Input id={'supplier_bank_account_no'} type={'text'} name={'supplier_bank_account_no'} value={formInfo.supplier_bank_account_no} onChange={handleChange} maxLength={16} onKeyPress={(event) => { if (!/[0-9]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.supplier_bank_account_no} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'supplier_bank_ifsc_code'} labelText={'IFSC Code'} className={'mb-1'} />
                                        <Input id={'supplier_bank_ifsc_code'} type={'text'} name={'supplier_bank_ifsc_code'} value={formInfo.supplier_bank_ifsc_code} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.supplier_bank_ifsc_code} />
                                    </div>
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default SupplierMasterAddEdit