import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import LinkButton from '../../helpers/LinkButton'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'
import axiosClient from '../../../Axios'
import { useStateContext } from '../../../context/ContextProvider'
import Loader from '../../Common/Loader'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'

const WorkOverview = () => {
    const { work_id } = useParams()
    const { IMG_URL, FRONTEND_URL } = useStateContext()
    const [loader, setLoader] = useState(false)
    const [workname, setWorkName] = useState("")
    const [workid, setWorkId] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
                setWorkId(data.list.id)
            })
    }
    const [work_wise_ClientPayment, setClientpayment] = useState("")
    const getClientPaymentDetails = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_ClientPayment/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setClientpayment(data.work_wise_ClientPayment)
            })
    }
    const [contractorpayment, setContractorPayment] = useState("")
    const getContractorPayment = () => {
        setLoader(true)
        axiosClient.get(`/workwise_Contractorpayment_reportlists/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setContractorPayment(data.total_sum)
            })
    }
    const [labourwages, setLabourwages] = useState("")
    const getTotalLabourWages = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_totalLabourwages/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setLabourwages(data.work_wise_totalLabourwages)
            })
    }
    const [work_wise_flat_count, setwork_wise_flat_count] = useState("")
    const [work_wise_soldflat, setwork_wise_soldflat] = useState("")
    const [work_wise_unsoldflat, setwork_wise_unsoldflat] = useState("")
    const getFlatDetails = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_flatinfo/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setwork_wise_flat_count(data.work_wise_flat_count)
                setwork_wise_soldflat(data.work_wise_soldflat)
                setwork_wise_unsoldflat(data.work_wise_unsoldflat)
            })
    }
    //  Modal data Starts //

    // Get All Payment satge data When Page Load //
    // const [stagename, setStagename] = useState("")
    // const getPaymentStageDetails = () => {
    //     setLoader(true)
    //     axiosClient.get(`/work_wise_Workpayment_stagestatus/${work_id}`)
    //         .then(({ data }) => {
    //             setLoader(false)
    //             setStagename(data.work_wise_Workpayment_stagestatus[0]) // [0] To get data into Array //

    //         })
    // }
    //     const [wing, setWing] = useState([]);
    //     const getWingData = () => {
    //         workid && axiosClient.get(`/work_wise_wings/${workid}`)
    //             .then(({ data }) => {
    //                 setLoader(false)
    //                 setWing(data.work_wise_wingdata)
    //             })
    //     }
    //     const [paymentLists, setPaymentLists] = useState('')
    //     const getWingWiseStageMasterData = () => {
    //         formInfo.wing_name && axiosClient.get(`/wing_wise_stage_master_data/${work_id}/${formInfo.wing_name}`)
    //             .then(({ data }) => {
    //                 setLoader(false)
    //                 setPaymentLists(data.work_wise_paymentstagedata)
    //             })
    //     }
    //       // Store Payment stage data //
    //       const handleSubmit = (e) => {
    //         e.preventDefault()
    //         setLoader(true)
    //         const formData = new FormData()
    //         formData.append('work_name', workname.id)
    //         formData.append('wing_name', formInfo.wing_name);
    //         formData.append('workpayment_stage', formInfo.workpayment_stage);
    //         axiosClient.post(id ? `update_Workpayment_stagestatus/${id}` : '/store_Workpayment_stagestatus', formData)
    //         .then(({ data }) => {
    //             setLoader(false)
    //             flasher.success(data.msg)
    //             Navigate('/work-management')
    //         })
    //         .catch(({ response }) => {
    //             setLoader(false)
    //             setIsOpen(false)
    //             setIsOpenOne(false)
    //                 setErrors(response.data.errors)
    //             })
    //     }
    // //  Modal data End //

    //     const [formInfo, setFormInfo] = useState({
    //         work_name: '',
    //         work_status: '',
    //         wings: '',
    //     })
    //     const handleChange = (e) => {
    //         setFormInfo(formInfo => ({
    //             ...formInfo,
    //             [e.target.name]: e.target.value
    //         }))
    //     }

    // useEffect(() => {
    //     getWingWiseStageMasterData();
    // }, [formInfo.wing_name])

    useEffect(() => {
        getWorkDetails();
        getClientPaymentDetails();
        getFlatDetails();
        getTotalLabourWages();
        getContractorPayment();
        //  getWingData();
    }, [workid])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Work Information'} button={
                    <LinkButton to={'/work-management'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%] mb-5'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* {workname.cad_image && <img src={IMG_URL + `/assets/images/CADFile/` + workname.cad_image}  alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}

                            {/* <img src={workImage ? (
                                                workImage.name ? URL.createObjectURL(workImage) : IMG_URL + `/assets/images/CADFile/` + workname.cad_image
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' /> */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <h1 className='text-center mt-1 mb-2 hidden md:block'>
                                {
                                    workname.work_category === 'project_management' ? "Project Management"
                                        : workname.marketing_type === 'interior_designing' ? "Interior Designing"
                                            : workname.marketing_type === 'plan_and_designing' ? "Paln & Designing"
                                                : "Construction"
                                }
                            </h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%] text-sm mb-5'>
                            <div className="grid grid-cols-1 md:grid-cols-3 rounded md:divide-x p-4 text-center text-base align-middle bg-default-color mb-4">
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Project Budget</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(workname.budget_amount).toLocaleString('en-IN')}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-48'>Received From Client</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(work_wise_ClientPayment).toLocaleString('en-IN')}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-48'>Total Labour Wages</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(labourwages).toLocaleString('en-IN')}</h1>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x p-4 text-center text-base rounded align-middle bg-default-color mb-4">
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Total Contractor Payments</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(contractorpayment).toLocaleString('en-IN')}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Total Purchase Payments</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{'4,00,000'}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Total Expense Payments</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{'3,85,000'}</h1>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x p-4 text-center text-base rounded  align-middle bg-default-color mb-4">
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Actual Profit</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{'0'}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Balance to Receive</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{'45,00,000'}</h1>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x p-4 text-center rounded text-base align-middle bg-default-color mb-4">
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Total No. Of Flats </h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(work_wise_flat_count).toLocaleString('en-IN')}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Sold Out Flats</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(work_wise_soldflat).toLocaleString('en-IN')}</h1>
                                </div>
                                <div className='flex p-8'>
                                    <h1 className='font-bold w-40'>Unsold Flats</h1>
                                    <span>:</span>
                                    <h1 className='pl-10'>{parseInt(work_wise_unsoldflat).toLocaleString('en-IN')}</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Transition appear show={isOpen} as={Fragment} >
                        <Dialog as="div" className="relative z-50" onClose={closeModal}>
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>
                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                        <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                            {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                                                <Fragment>
                                                    <div className='flex justify-between items-center gap-2'>
                                                        <h1 className='flex justify-between items-center text-center uppercase font-bold text-xl tracking-widest'>Work Information</h1>
                                                        <Button className={'py-1 bg-red-600 hover:bg-red-600'} onClick={closeModal}>Close</Button>
                                                    </div>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <form onSubmit={handleSubmit} className='mt-4'>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                            <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} disabled={true} />
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                            <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                                <option value={''}>--- Choose Wing Name ---</option>
                                                                {wing && wing.map((wingData, wingId) => {
                                                                    return (<Fragment key={wingId}>
                                                                        <option value={wingData.id}>{wingData.wing_name}</option>
                                                                    </Fragment>)
                                                                })}
                                                            </CustomSelect>
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'workpayment_stage'} labelText={'Payment Stages'} className={'mb-1'} />
                                                            <CustomSelect id={'workpayment_stage'} name={'workpayment_stage'} value={formInfo.workpayment_stage} onChange={handleChange}>
                                                                <option value={''}>--- Choose Payment Stages ---</option>
                                                                {paymentLists && paymentLists.map((paymentData, paymentId) => {
                                                                    return (<Fragment key={paymentId}>
                                                                        <option value={paymentData.id}><p>{paymentData.stage_name}</p></option>
                                                                    </Fragment>
                                                                    )
                                                                })}
                                                            </CustomSelect>
                                                        </div>
                                                        <Button className={'w-60 mt-6 mx-auto'}>
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                                            <span className='ml-5'>Save Now</span>
                                                        </Button>
                                                    </form>
                                                </Fragment>
                                            )}
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                    <Transition appear show={isOpenone} as={Fragment} >
                        <Dialog as="div" className="relative z-50" onClose={closeModalOne}>
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>
                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                        <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                                                <Fragment>
                                                    <div className='flex justify-between items-center gap-2'>
                                                        <h1 className='flex justify-between items-center text-center uppercase font-bold text-xl tracking-widest'>Work Information</h1>
                                                        <Button className={'py-1 bg-red-600 hover:bg-red-600'} onClick={closeModalOne}>Close</Button>
                                                    </div>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <form onSubmit={handleSubmit} className='mt-4'>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                            <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} disabled={true} />
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                            <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                                <option value={''}>--- Choose Wing Name ---</option>
                                                                {wing && wing.map((wingData, wingId) => {
                                                                    return (<Fragment key={wingId}>
                                                                        <option value={wingData.id}>{wingData.wing_name}</option>
                                                                    </Fragment>)
                                                                })}
                                                            </CustomSelect>
                                                        </div>
                                                        <div className='mb-3'>
                                                            <Label htmlFor={'workpayment_stage'} labelText={'Payment Stages'} className={'mb-1'} />
                                                            <CustomSelect id={'workpayment_stage'} name={'workpayment_stage'} value={formInfo.workpayment_stage} onChange={handleChange}>
                                                                <option value={''}>--- Choose Payment Stages ---</option>
                                                                {paymentLists && paymentLists.map((paymentData, paymentId) => {
                                                                    return (<Fragment key={paymentId}>
                                                                        <option value={paymentData.id}><p>{paymentData.stage_name}</p></option>
                                                                    </Fragment>
                                                                    )
                                                                })}
                                                            </CustomSelect>
                                                        </div>
                                                        <Button className={'w-60 mt-6 mx-auto'}>
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                                            <span className='ml-5'>Save Now</span>
                                                        </Button>
                                                    </form>
                                                </Fragment>
                                            )}
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition> */}
                </PageComponent>
            )}
        </Fragment>
    )
}

export default WorkOverview