
import React, { useRef, Fragment, useEffect, useState } from 'react';
import { PrinterIcon } from '@heroicons/react/24/solid';
import axiosClient from '../../Axios';
import PageComponent from '../../components/PageComponent';
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader';
import ViewModel from '../helpers/ViewModel';
import Table from '../helpers/Table';
import { Dialog } from '@headlessui/react';
import CustomSelect from '../helpers/CustomSelect';
import Label from '../helpers/Label';
import Validation from '../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import Card from '../helpers/Card'
// import Pagination from '../helpers/Pagination';

function ContractorBillReport() {
    const { id } = useParams();
    document.title = 'SiteSmart - Contractor Bill Report';
    const tableHeading = ['Sr.No', 'Work Name', 'Wing Name', 'Level Name', 'Task Name', 'Contractor Name', 'Bill Installments'];
    const tableHeading2 = ['Sr.No', 'Bill No', 'Bill Date', 'Payment Type', 'Total Amount', 'Paid Amount', 'Balance', 'Remark', 'Description', 'Send To'];
    const [loader, setLoader] = useState(false);
    const [billData, setBillData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [viewModel, setViewModel] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    const [errors, setErrors] = useState([])
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 1;
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }


    const getBillData = () => {
        setLoader(true);
        axiosClient.get('/Contractor_work_reportlists')
            .then(({ data }) => {
                setBillData(data.lists);
                setLoader(false)
            });
    };

    const getalldata = () => {
        formInfo.work_name && setLoader(true)
        formInfo.work_name && formInfo.work_name == 'All' ? (getBillData()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const getModalData = (id) => {
        setLoader(true);
        setModalLoader(true);
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setModalData((prevModalData) => ({ ...prevModalData, [id]: data.bill_data }));
                setModalLoader(false);
                setLoader(false)
            });
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [fetchedModalData, setFetchedModalData] = useState(false);
    const getModalDataForAllRows = async () => {
        try {
            const promises = billData.map((rowData) => getModalData(rowData.id));
            await Promise.all(promises);
            setFetchedModalData(true);
        } catch (error) {
            console.error("Error fetching modal data:", error);
        }
    };

    const getWorkWiseList = () => {
        setLoader(true)
        formInfo.work_name && axiosClient.get(`/workwise_Contractor_work_reportlists/${formInfo.work_name}`)
            .then(({ data }) => {
                setLoader(false)
                setBillData(data.lists)
            })
    }

    const getWingWiseList = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wingwise_Contractor_work_reportlists/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setBillData(data.lists)
            })
    }

    const getLevelWiseList = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_lavelwise_Contractor_work_reportlists/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setLoader(false)
                setBillData(data.lists)
            })
    }
    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWork(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingList = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelList = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    useEffect(() => {
        getWingList()
        getWorkWiseList()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelList()
        getWingWiseList()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        getLevelWiseList()
    }, [formInfo.level])

    useEffect(() => {
        getalldata();
    }, [formInfo.work_name])

    useEffect(() => {
        getBillData();
        getWork();
    }, []);

    useEffect(() => {
        getModalDataForAllRows();
    }, [billData]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <PageComponent title={'Contractor Bill Report'}>
                    <form>
                        <div className='grid grid-cols-1 md:grid-cols-4 mt-4 mb-2 gap-3 '>
                            <div className=''>
                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Work ---</option>
                                    <option value={"All"}>All</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.work_name} />
                            </div>
                            <div className=''>
                                <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Wing ---</option>
                                    {wing && wing.map((wingData, wingId) => {
                                        return (<Fragment key={wingId}>
                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.wing_name} />
                            </div>
                            <div className=''>
                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Level ---</option>
                                    {level && level.map((levelData, levelId) => {
                                        return (<Fragment key={levelId}>
                                            <option value={levelData.level}>{levelData.level_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                                <Validation error={errors.level} />
                            </div>

                            <div className='ml-2 flex justify-end pt-8'>
                                <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                            </div>
                        </div>
                    </form>

                    <div ref={componentRef} className='flex items-center justify-center '>
                        <Card className={' md:w-[100%] bg-default-color'}>
                            {
                                billData && billData.length > 0 ? (
                                    <div>
                                        {billData.map((data, ID) => {
                                            return (<Fragment key={ID}>
                                                <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] mt-4 tracking-widest'>{data.work_name}</h1>
                                                <hr className='mt-4 mx-4 border border-primary-color' />
                                                <div className='grid grid-cols-1 md:grid-cols-3 mt-4 gap-x-1 gap-y-2 divide-x p-4'>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Work Name </h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Wing Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.wing_name}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Level Name </h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.level_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Task Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.task_name}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Contractor Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.contractor_name}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {fetchedModalData ? (
                                                        <div className="px-4">
                                                            <div className='w-[60vw] md:w-full'>
                                                            {
                                                                <Table className='rounded-lg shadow-md bg-primary-color' tableHeading={tableHeading2}>
                                                                    {modalData && modalData[data.id] && modalData[data.id].map((modalTableData, modalTableDataID) => {
                                                                        return (<Fragment key={modalTableDataID}>
                                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                                <td className="px-4 py-3">{(ID + 1) + ((modalTableDataID + 1) / 10)}</td>
                                                                                <td className="px-4 py-3">{modalTableData.id}</td>
                                                                                <td className="px-4 py-3 whitespace-nowrap">{moment(modalTableData.bill_date).format('DD-MM-YYYY')}</td>
                                                                                <td className="px-4 py-3">{modalTableData.payment_type}</td>
                                                                                <td className="px-4 py-3">{parseInt(modalTableData.total_amount).toLocaleString('en-IN')}</td>
                                                                                <td className="px-4 py-3">{parseInt(modalTableData.paid_amount).toLocaleString('en-IN')}</td>
                                                                                <td className="px-4 py-3">{parseInt(modalTableData.balance).toLocaleString('en-IN')}</td>
                                                                                <td className="px-4 py-3">{modalTableData.bill_remark}</td>
                                                                                <td className="px-4 py-3">{modalTableData.bill_description}</td>
                                                                                <td className="px-4 py-3">{modalTableData.name}</td>
                                                                            </tr>
                                                                        </Fragment>)
                                                                    })}
                                                                </Table>
                                                            }
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='flex justify-center items-center h-[68vh] bg-gray-200'>
                                                            <p className='text-3xl font-bold'>Sorry!Installments Not Found</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <hr className='mt-4 border-4 color bg-gray-200 ' />
                                            </Fragment>)
                                        })}
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-[68vh] bg-gray-200'>
                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Bill Data Not Found</p>
                                    </div>
                                )
                            }
                        </Card>
                    </div>
                    {/* {billData && billData.length > 1 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={billData.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    } */}
                </PageComponent>
            )}
        </Fragment>
    );
}
export default ContractorBillReport;

