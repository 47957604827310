import React, { Fragment, useEffect, useRef, useState } from 'react'
import { EyeIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import moment from 'moment'
import { PrinterIcon } from '@heroicons/react/20/solid'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'
import ActionButton from '../helpers/ActionButton'


function StockConsumed() {
    document.title = 'SiteSmart - Stock Consumed'
    const tableHeading = ['Sr.No', 'Date', 'Work Name', 'Wing Name', 'Level Name', 'Work Type', 'Material', 'Unit', 'Consumed Qty', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [consumedLists, setConsumedLists] = useState('')
    const getConsumedLists = () => {
        setLoader(true)
        axiosClient.get('/Stock_consumed_lists')
            .then(({ data }) => {
                setConsumedLists(data.lists)
                setLoader(false)
            })
    }

    const tableRef = useRef();
    const modalRef = useRef();
    const handlePrintTable = useReactToPrint({
        content: () => tableRef.current,
    });

    const handlePrintModal = useReactToPrint({
        content: () => modalRef.current,
    });

    const openModal = (id) => {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Stock_consumed_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }
    useEffect(() => {
        getConsumedLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Stock Consumed'} button={
                    <LinkButton to={'/stock-consumed/add-new-consumed'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add Consumed Stock</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-1'>
                        <div>
                            <Label htmlFor={'date'} labelText={'Start Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} />
                        </div>
                        <div >
                            <Label htmlFor={'date'} labelText={'End Date'} className={'mb-1 '} />
                            <Input id={'date'} type={'date'} name={'date'} />
                        </div>
                        <div className=""></div>
                        <div className="">
                            <Label htmlFor={'date'} labelText={'Print'} className={'mb-1 opacity-0'} />
                            <div className=' flex justify-end'>
                                <Link onClick={handlePrintTable} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                            </div>
                        </div>
                    </div>
                    <br />
                    {consumedLists && consumedLists.length > 0 ? (
                        <div ref={tableRef}>
                            <Table tableHeading={tableHeading}>
                                {consumedLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                            <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3">{tableData.work_name}</td>
                                            <td className="px-4 py-3">{tableData.wing_name}</td>
                                            <td className="px-4 py-3">{tableData.level_name}</td>
                                            <td className="px-4 py-3">{tableData.work_type}</td>
                                            <td className="px-4 py-3">{tableData.material_name}</td>
                                            <td className="px-4 py-3">{tableData.material_unit}</td>
                                            <td className="px-4 py-3">{parseInt(tableData.cus_qty).toLocaleString('en-IN')}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <Link onClick={() => openModal(tableData.id)}><ActionButton className={'p-1.5 rounded-lg bg-primary'}><EyeIcon className='w-5 h-5 ' /></ActionButton></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Stock Consumed Data Not Found</p>
                        </div>
                    )}
                    {consumedLists && consumedLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={consumedLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">

                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div ref={modalRef} className='p-5'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Consumed Stock Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Date</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{moment(modalData.created_at).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Work Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.work_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Wing Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.wing_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Level Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.level_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Work Type</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.work_type}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Material Name</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.material_name}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Unit</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.material_unit}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Previous Quantity</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{parseInt(modalData.current_qty).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Consumed Quantity</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{parseInt(modalData.cus_qty).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Current Quantity</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{parseInt(modalData.balance).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 py-1 md:py-2'>
                                                <h1 className='w-20 md:w-48 font-bold'>Responsible Person</h1>
                                                <h1>-</h1>
                                                <h1 className='ml-6'>{modalData.resp_person}</h1>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrintModal} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default StockConsumed