import PageComponent from '../../../components/PageComponent'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { Fragment, useEffect, useState } from 'react'
import { EyeIcon } from '@heroicons/react/20/solid'
import Button from '../../helpers/Button'

function CashMaster() {
    document.title = 'SiteSmart - Cash Master'
    const tableHeading = ['Sr.No', 'Cash Amount', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])

    const [bankLists, setBankLists] = useState('')
    const getBankLists = () => {
        setLoader(true)
        axiosClient.get('/Bank_master_lists')
        .then(({data})=>{
            setLoader(false)
            setBankLists(data.lists)
        })
    }
    function openModal(id) {
      setViewModel(true)
      setModalLoader(true)
      axiosClient.get(`/single_Cash_master_data/${id}`)
          .then(({ data }) => {
              setModalLoader(false)
              setModalData(data.single_data)
          })
  }
    const deleteBank = (id) =>{
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Cash_master/${id}`)
                .then(({data})=>{
                    flasher.success(data.msg)
                    getBankLists()
                })
            }
        })
    }
    useEffect(()=>{
        getBankLists()
    },[])
  return (
    <Fragment>
            {loader ? (<Loader/>) : (
                <PageComponent title={'Cash Master'} button={
                    <LinkButton to={'/accounting/add-cash-master'}>
                        <PlusCircleIcon className='w-5 h-5'/>
                        <span>Add Cash Details </span>
                    </LinkButton>
                }>
                    {bankLists && bankLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {bankLists.map((tableData, tableDataID)=>{
                                return(<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID+1}</td>
                                        <td className="px-4 py-3">{tableData.cash_amount}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={()=>deleteBank(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5'/></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Cash Master Data Not Found</p>
                        </div>
                    )}                   
                </PageComponent>
            )}
        </Fragment>
  )
}



export default CashMaster
