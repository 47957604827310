import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import CustomSelect from '../../helpers/CustomSelect'

function CashMasterAdd() {
    const { id } = useParams()
    // eslint-disable-next-line no-lone-blocks
    {
        id ? (
            document.title = 'SiteSmart - Edit Cash Master'
        ) : (
            document.title = 'SiteSmart - Add New Cash Master'
        )
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()

    const [formInfo, setFormInfo] = useState({
        cash_amount: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('cash_amount', formInfo.cash_amount)

        axiosClient.post(id ? `update_cash_master/${id}` : '/store_cash_master', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/accounting/cash-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleAccountLedgerData = () => {
        setLoader(true)
        axiosClient.get(`/single_cash_master_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleAccountLedgerData()
        }
    }, [])

    return (
        <Fragment>

            {loader ? (<Loader />) : (
                <PageComponent title={'Cash Master'} button={
                    <LinkButton to={'/accounting/cash-master'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                     <div className='flex items-center justify-center h-[80vh]'>
                     <Card className={'w-full md:w-80 px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                    <Label htmlFor={'cash_amount'} labelText={'Cash Amount'} className={'mb-1'} />
                                    <Input id={'cash_amount'} type={'text'} name={'cash_amount'} value={formInfo.cash_amount} onChange={handleChange} />
                                    <Validation error={errors.cash_amount} />
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default CashMasterAdd
