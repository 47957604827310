import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'

function MaterialMasterAddEdit() {
    const { id } = useParams()
    if(id){
        document.title = 'SiteSmart - Edit Material'
    }else{
        document.title = 'SiteSmart - Add New Material'
    }
    
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        material_name : '',
        material_unit : '',
    })
    const handleChange = (e) =>{
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name] : e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('material_name', formInfo.material_name)
        formData.append('material_unit', formInfo.material_unit)
        axiosClient.post(id ? `update_material/${id}` : '/store_material', formData)
        .then(({data})=>{
            setLoader(false)
            flasher.success(data.msg)
            navigate('/material-master')
        })
        .catch(({response})=>{
            setLoader(false)
            setErrors(response.data.errors)
        })
    }
    const getSingleMaterialData = () => {
        setLoader(true)
        axiosClient.get(`/single_material_data/${id}`)
        .then(({data})=>{
            setLoader(false)
            setFormInfo(data.single_data)
        })
    }
   
    useEffect(()=>{
        if(id){
            getSingleMaterialData()
        }
    },[])
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <PageComponent title={id ? 'Edit Material' : 'Add New Material'} button={
                    <LinkButton to={'/material-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6'/>
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center h-[70vh]'>
                        <Card className={'w-full md:w-80 px-4 py-4 grid grid-cols-1 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'}/>
                                    <Input id={'material_name'} type={'text'} name={'material_name'} value={formInfo.material_name} onChange={handleChange}/>
                                    <Validation error={errors.material_name}/>
                                </div>
                                <div className='mb-3'>
                                    <Label htmlFor={'material_unit'} labelText={'Material Unit'} className={'mb-1'}/>
                                    <Input id={'material_unit'} type={'text'} name={'material_unit'} value={formInfo.material_unit} onChange={handleChange}/>
                                    <Validation error={errors.material_unit}/>
                                </div>
                                <Button className={'w-full mt-6'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color"/></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default MaterialMasterAddEdit