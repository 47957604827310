import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { PlusCircleIcon, PencilSquareIcon, TrashIcon, EyeIcon, PhotoIcon } from '@heroicons/react/24/solid'
import Loader from '../../Common/Loader'
import { useParams } from 'react-router-dom'
import flasher from '@flasher/flasher'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Table from '../../helpers/Table'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import LinkButton from '../../helpers/LinkButton'
import axiosClient from '../../../Axios'
import { useStateContext } from '../../../context/ContextProvider'
import { Dialog } from '@headlessui/react'
import Button from '../../helpers/Button'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import ViewModel from '../../helpers/ViewModel'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'
import PleaseWait from '../../Common/PleaseWait'

function FloorDetails() {
    document.title = 'SiteSmart - Floor Details'
    const tableHeading = ['Sr.No', 'Wing Name', 'Level no.', 'Action']
    const { work_id } = useParams()

    const [loader, setLoader] = useState(false)
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [modalData, setModalData] = useState([])
    const [modelImage, setModelImage] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setImgModalLoader(false)
    }
    // Api for Work Data for ID //

    // Api for View All Floor Data //
    const [workname, setWorkName] = useState("")
    const [floorLists, setFloorLists] = useState('')
    const getFloorLists = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_floordata/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setFloorLists(data.work_wise_floordata)
                setWorkName(data.list)
            })
    }
    // Api for Wing Name Dropdown //
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    const [level, setLevel] = useState([]);
    const getLevelData = () => {
        formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${work_id}/${formInfo.wing_name}/`)
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.work_wing_wise_leveldata)
            })
    }
    const [formInfo, setFormInfo] = useState({
        wing_name: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    // api for Sort by Wing Name dropdown //
    const getwingSortDetails = () => {
        formInfo.wing_name && setLoader(true)
        formInfo.wing_name === 'All' ? (getFloorLists()) : (axiosClient.get(`/work_wing_wise_floordata/${work_id}/${formInfo.wing_name}`))
            .then(({ data }) => {
                setLoader(false)
                setFloorLists(data.work_wise_floordata)
            })
    }

    const getWingLevelSortDetails = () => {
        formInfo.wing_name && formInfo.level && setLoader(true)
        formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_level_wise_floordata/${work_id}/${formInfo.wing_name}/${formInfo.level} `)
            .then(({ data }) => {
                setLoader(false)
                setFloorLists(data.work_wing_level_floordata)
            })
    }
    // Api for Delete Floor Data //
    const deleteFloor = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_floor_details/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getFloorLists()
                    })
            }
        })
    }
    // Api for View Single Floor Data //
    const [floorImage, setFloorImage] = useState()
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_floor_details_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setFloorImage(data.single_data.upload_floor_file)
            })
    }
    useEffect(() => {
        getFloorLists();
        getWingData();
    }, [work_id])

    useEffect(() => {
        getwingSortDetails();
    }, [formInfo.wing_name])

    useEffect(() => {
        getLevelData();
    }, [work_id && formInfo.wing_name])

    useEffect(() => {
        getWingLevelSortDetails();
    }, [formInfo.wing_name && formInfo.level])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Floor Details'} button={
                    <LinkButton to={`/work-management/add-floor-details-form/${work_id}`}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add Floor Details</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                            {workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                            ) : (workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />)
                            }
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <h1 className='text-center mt-1 mb-2 hidden md:block'>
                                {
                                    workname.work_category === 'project_management' ? "Project Management"
                                        : workname.marketing_type === 'interior_designing' ? "Interior Designing"
                                            : workname.marketing_type === 'plan_and_designing' ? "Plan & Designing"
                                                : "Construction"
                                }
                            </h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className="flex gap-x-3">
                                <div className='mb-3 w-[16rem]'>
                                    <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                    <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'bg-[#000]'}>
                                        <option disabled value={''}>--- Choose Wing Name ---</option>
                                        <option value={'All'}>{'All'}</option>
                                        {wing && wing.map((wingData, wingId) => {
                                            return (<Fragment key={wingId}>
                                                <option value={wingData.id}>{wingData.wing_name}</option>
                                            </Fragment>)
                                        })}
                                    </CustomSelect>
                                </div>
                                <div className='mb-3 w-[16rem]'>
                                    <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                    <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-full'}>
                                        <option value={''}>--- Choose Level Name ---</option>
                                        {level && level.map((levelData, levelId) => {
                                            return (<Fragment key={levelId}>
                                                <option value={levelData.level}>{levelData.level_name}</option>
                                            </Fragment>)
                                        })}
                                    </CustomSelect>
                                </div>
                            </div>
                            {floorLists && floorLists.length > 0 ? (
                                <Table tableHeading={tableHeading}>
                                    {floorLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                        return (<Fragment key={tableDataID}>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.wing_name}</td>
                                                {/* <td className="px-4 py-3">{tableData.no_of_levels}</td> */}
                                                <td className="px-4 py-3">{tableData.level_name}</td>
                                                {/* <td className="px-4 py-3">{tableData.name_of_floor}</td> */}
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                        <Link title='Edit' to={`/work-management/edit-floor-details-form/${work_id}/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                        <ActionButton onClick={() => deleteFloor(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>)
                                    })}
                                </Table>
                            ) : (
                                <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Floor Data Not Found</p>
                                </div>
                            )}
                            {floorLists && floorLists.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={floorLists.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                            {
                                viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                                    <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ? (<PleaseWait />) : (
                                            <Fragment>
                                                {/* <div className="w-[25vw]"> */}
                                                <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Floor Information</h1>
                                                <hr className='my-4 border border-primary-color' />
                                                <div className='flex items-center gap-4 py-2'>
                                                    <h1 className='w-36 font-bold'>Wing Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                </div>
                                                {/* <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> level No.</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.no_of_levels}</h1>
                                                </div> */}
                                                <div className='flex items-center gap-4 py-2'>
                                                    <h1 className='w-36 font-bold'>Level Name</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.level_name}</h1>
                                                </div>
                                                <div className='flex items-center gap-4 py-2'>
                                                    <h1 className='w-36 font-bold'>Refugee Area</h1>
                                                    <h1>-</h1>
                                                    <h1 className='ml-6'>{modalData.refugee_area}</h1>
                                                </div>
                                                <div className='flex justify-between mt-3'>
                                                    <div>
                                                        <button type="button" className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel(false)} >
                                                            Got it, thanks!
                                                        </button>
                                                    </div>
                                                    <div className="flex">
                                                        <Button className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium " onClick={() => handleModelImage()}>
                                                            <PhotoIcon className="h-5 w-5 text-default-color" />
                                                            <h1 className='bg-primary-color text-white text-center rounded-md pl-2'>Floor Image</h1>
                                                        </Button>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-auto max-w-2xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Floor Plan</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={floorImage ? (
                                                floorImage.name ? URL.createObjectURL(floorImage) : IMG_URL + `/assets/images/FloorCADFile/` + floorImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                        }
                                        <div className="mt-4">
                                            <button type="button" className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)} > Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default FloorDetails
