
import React, { Fragment, useEffect, useState } from "react";
import { ArrowLeftCircleIcon, InboxArrowDownIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import flasher from "@flasher/flasher";
import { useNavigate, useParams } from "react-router-dom";
import PageComponent from "../../components/PageComponent";
import Label from "../helpers/Label";
import Card from "../helpers/Card";
import Input from "../helpers/Input";
import Button from "../helpers/Button";
import LinkButton from "../helpers/LinkButton";
import Loader from "../Common/Loader";
import Validation from "../helpers/Validation";
import TextArea from "../helpers/TextArea";
import axiosClient from "../../Axios";
import CustomSelect from "../helpers/CustomSelect";
import ViewModel from "../helpers/ViewModel";
import Table from "../helpers/Table";
import Swal from "sweetalert2";
import { Dialog } from "@headlessui/react";
import CurrencyInput from 'react-currency-input-field';

function DailyWagesAddEdit() {
  const { id } = useParams();
  {
    id
      ? (document.title = "SiteSmart - Edit Wages")
      : (document.title = "SiteSmart - Add New Wages");
  }

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({
    month: '',
    team_leader: "",
    phone_no: "",
    work_name: "",
    wing_name: "",
    work_type: "",
    fixed_wage: "",
    gst: "",
    total_amount: "",
    overtime_rate_per_hours: "",
    overtime_hours: "",
    final_amount: "",
    total_payable: "",
    paid_amount: "",
    paid_date: "",
    paid_from_acount: "",
    payment_mode: "",
    tr_advanced_paid: "",
    tr_balance: "",
    tr_reference_no: "",
    tr_reference_date: "",
    transaction_remarks: "",
    total_labours_worked: "",
    total_wage: "",
    wage_remarks: "",
    total_paid_amount: '',
    grand_total: ''
  });

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFixedWage = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      fixed_wage: e,
    }))
  }

  const handleOvertimerate = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      overtime_rate_per_hours: e,
    }))
  }

  const handleTotalWage = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      total_wage: e,
    }))
  }

 
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      month: formInfo.month,
      team_leader: formInfo.team_leader,
      phone_no: formInfo.phone_no,
      work_name: formInfo.work_name,
      wing_name: formInfo.wing_name,
      work_type: formInfo.work_type,
      fixed_wage: formInfo.fixed_wage,
      gst: formInfo.gst,
      overtime_rate_per_hours: formInfo.overtime_rate_per_hours,
      overtime_hours: formInfo.overtime_hours,
      total_amount: formInfo.total_amount,
      final_amount: formInfo.final_amount,
      total_payable: formInfo.total_payable,
      total_labours_worked: formInfo.total_labours_worked,
      total_wage: formInfo.total_wage,
      wage_remarks: formInfo.wage_remarks,
    }
    axiosClient.post(id ? `update_addLabourGroup/${id}` : "/store_addLabourGroup",payload)
      .then(({ data }) => {
        setLoader(false);
        flasher.success(data.msg);
        navigate("/daily-wages");
      })
      .catch(({ response }) => {
        setLoader(false);
        setErrors(response.data.errors);
      });
  };

  const getSingleWagesData = () => {
    setLoader(true);
    axiosClient.get(`/single_addLabourGroup_data/${id}`)
      .then(({ data }) => {
        setLoader(false);
        setFormInfo(data.single_data);
      });
  };

  const [workNameLists, setWorkNameLists] = useState([])
  const getWorkData = () => {
    setLoader(true)
    axiosClient.get(`/work_lists`)
      .then(({ data }) => {
        setLoader(false)
        setWorkNameLists(data.lists)
      })
  }
  const [wing, setWing] = useState([])
  const getWingLists = () => {
    formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
      .then(({ data }) => {
        setWing(data.work_wise_wingdata)
      })
  }

  const [worktype, setWorktype] = useState('')
  const getWorktype = () => {
    setLoader(true)
    axiosClient.get(`/worktype_list`)
      .then(({ data }) => {
        setWorktype(data.lists)
      })
  }

 

  useEffect(() => {
    getWingLists()
  }, [formInfo.work_name])

  useEffect(() => {
    if (id) {
      getSingleWagesData()
    }
    getWorkData()
    getWorktype()
  }, []);

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <PageComponent
          title={id ? "Edit wage" : "Add New Wage"} button={
            <LinkButton to={"/daily-wages"}>
              <ArrowLeftCircleIcon className="w-5 h-5 text-default-color" />
              <span>Back To List</span>
            </LinkButton>}>
          <div className='flex items-center justify-center'>
            <Card className={'w-full md:w-[70rem] p-6  bg-default-color'}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"team_leader"} labelText={"Team Leader"} className={"mb-1"} />
                    <Input id={"team_leader"} type={"text"} name={"team_leader"} value={formInfo.team_leader} onChange={handleChange}  />
                    <Validation error={errors.team_leader} />
                  </div>
                  <div>
                    <Label htmlFor={"phone_no"} labelText={"Phone No."} className={"mb-1"} />
                    <Input id={"phone_no"} type={"text"} name={"phone_no"} value={formInfo.phone_no} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} maxLength={10} minLength={10}/>
                    <Validation error={errors.phone_no} />
                  </div>
                  <div>
                    <Label htmlFor={"work_name"} labelText={"Work Name"} className={"mb-1"} />
                    <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                      <option value={''}>--- Select Work ---</option>
                      {
                        workNameLists.map(data => {
                          return (
                            <option value={data.id}>{data.work_name}</option>
                          )
                        })
                      }
                    </CustomSelect>
                    <Validation error={errors.work_name} />
                  </div>
                  <div>
                    <Label htmlFor={"wing_name"} labelText={"Wing Name"} className={"mb-1"} />
                    <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                      <option value={''}>--- Select Wing ---</option>
                      {wing && wing.map((wingData, wingId) => {
                        return (<Fragment key={wingId}>
                          <option value={wingData.id}>{wingData.wing_name}</option>
                        </Fragment>)
                      })}
                    </CustomSelect>
                    <Validation error={errors.wing_name} />
                  </div>

                  <div>
                    <Label htmlFor={"work_type"} labelText={"Work Type"} className={"mb-1"} />
                    <CustomSelect id={'work_type'} name={'work_type'} value={formInfo.work_type} onChange={handleChange}>
                      <option value={''}>--- Select Work Type ---</option>
                      {worktype && worktype.map((worktypedata, worktypeID) => {
                        return (<Fragment key={worktypeID}>
                          <option value={worktypedata.id}>{worktypedata.work_type}</option>
                        </Fragment>)
                      })}
                    </CustomSelect>
                    <Validation error={errors.work_type} />
                  </div>
                  <div>
                    <Label htmlFor={"fixed_wage"} labelText={"Fixed Wage"} className={"mb-1"} />
                    <CurrencyInput id={'fixed_wage'} type={'text'} name={'fixed_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.fixed_wage}
                      onValueChange={handleFixedWage} />
                    <Validation error={errors.fixed_wage} />
                  </div>
                  <div>
                    <Label htmlFor={"overtime_rate_per_hours"} labelText={"Overtime Rate per Hour"} className={"mb-1"} />
                    <CurrencyInput id={'overtime_rate_per_hours'} type={'text'} name={'overtime_rate_per_hours'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.overtime_rate_per_hours}
                      onValueChange={handleOvertimerate} />
                    <Validation error={errors.overtime_rate_per_hours} />
                  </div>
                  <div>
                    <Label htmlFor={"overtime_hours"} labelText={"Overtime Hours"} className={"mb-1"} />
                    <Input id={"overtime_hours"} type={"text"} name={"overtime_hours"} value={formInfo.overtime_hours} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.overtime_hours} />
                  </div>
                  <div>
                    <Label htmlFor={"total_amount"} labelText={"Amount Without GST"} className={"mb-1"} />
                    <CurrencyInput id={'total_amount'} type={'text'} name={'total_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.total_amount = parseInt(formInfo.fixed_wage) + (parseInt(formInfo.overtime_rate_per_hours * parseInt(formInfo.overtime_hours)))} />
                    <Validation error={errors.total_amount} />
                  </div>
                  <div> <Label htmlFor={"gst"} labelText={"GST"} className={"mb-1"} />
                    <CustomSelect id={"gst"} name={"gst"} value={formInfo.gst} onChange={handleChange} >
                      <option value={""}>---GST ---</option>
                      <option value={"0%"}>0%</option>
                      <option value={"5%"}>5%</option>
                      <option value={"18%"}>18%</option>
                      <option value={"28%"}>28%</option>
                    </CustomSelect>
                    <Validation error={errors.gst} />
                  </div>
                  <div>
                    <Label htmlFor={"final_amount"} labelText={"Amount With GST"} className={"mb-1"} />
                    <CurrencyInput id={'final_amount'} type={'text'} name={'final_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.final_amount = parseInt(formInfo.total_amount) - parseInt(formInfo.total_amount * (parseInt(formInfo.gst) / 100))} />
                    <Validation error={errors.final_amount} />
                  </div>
                  <div>
                    <Label htmlFor={"total_labours_worked"} labelText={"Total Labours Worked"} className={"mb-1"} />
                    <Input id={"total_labours_worked"} type={"text"} name={"total_labours_worked"} value={formInfo.total_labours_worked} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.total_labours_worked} />
                  </div>
                  <div>
                    <Label htmlFor={"total_wage"} labelText={"Total Wage"} className={"mb-1"} />
                    <CurrencyInput id={'total_wage'} type={'text'} name={'total_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.total_wage} onValueChange={handleTotalWage} />

                    <Validation error={errors.total_wage} />
                  </div>
                  <div>
                    <Label htmlFor={"wage_remarks"} labelText={"Wage Remarks"} className={"mb-1"} />
                    <Input id={"wage_remarks"} type={"text"} name={"wage_remarks"} value={formInfo.wage_remarks} onChange={handleChange} />
                    <Validation error={errors.wage_remarks} />
                  </div>
                  <div>
                    <Label htmlFor={"grand_total"} labelText={"Grand Total"} className={"mb-1"} />
                    <Input id={"grand_total"} type={"text"} name={"grand_total"} value={parseInt((formInfo.grand_total) = parseInt(formInfo.final_amount) + parseInt(formInfo.total_wage)).toLocaleString('en-IN')} onChange={handleChange} />
                    <Validation error={errors.grand_total} />
                  </div>
                </div>
                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                  </span>
                  <span>Save Now</span>
                </Button>
              </form>
            </Card>
          </div>
        </PageComponent>
      )}
    </Fragment>
  )
}
export default DailyWagesAddEdit



