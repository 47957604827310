import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import Card from '../helpers/Card'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import Validation from '../helpers/Validation'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../helpers/LinkButton'
import { Dialog } from '@headlessui/react'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Currency from '../helpers/Currency'
import PleaseWait from '../Common/PleaseWait'


function InwardAddEdit() {
    const { id } = useParams()

    document.title = 'SiteSmart - Inventory'
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const tableHeading = ['Sr.No', 'Supplier Name', 'Material Name', 'Unit', 'PO Quantity', 'Inward Quantity', 'Action']
    const [material, setMaterial] = useState([])
    const [purchaseMaterialList, setPurchaseMaterialList] = useState([])
    const [purchasedata, setPurchaseData] = useState([])
    const [viewPurchasedata, setViewPurchaseData] = useState([])
    
    console.log('purchaseMaterialList :', purchaseMaterialList);

    const [inputs, setInputs] = useState({
        id: '',
        material_name: '',
        material_unit: '',
        quantity: '',
        inward_qty: '',
        total: '',
    });


    const handleUpdateInward = (e) => {
        e.preventDefault();
        let array = JSON.parse(JSON.stringify(purchaseMaterialList));
        const updatedData = array.map(x => (x.id === inputs.id ? { ...x, inward_qty: inputs.inward_qty, remaining_qty: (inputs.quantity - inputs.inward_qty) } : x));
        setPurchaseMaterialList(updatedData);
        setViewModel(false);
    }

    const getQuotataionData = () => {
        setLoader(true)
        axiosClient.get(`/Aproved_po_quotation_material_lists/${id}`)
            .then(({ data }) => {
                setLoader(false)
                let array = [];
                data.lists.map(i => {
                    return (
                        array.push({
                            id: i.id,
                            poID: i.po_quotation_id,
                            supplier_name: i.supplier_name,
                            material_name: i.material,
                            material_unit: i.material_unit,
                            quantity: i.quantity,
                            inward_qty: i.quantity,
                            total: i.total,
                        })
                    )
                });
                setPurchaseMaterialList(array)
                setPurchaseData(data.po_quotation)
                setViewPurchaseData(data.view_po_quotation);
                setLoader(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            work_name: purchasedata.work_name,
            wing_name: purchasedata.wing_name,
            level_name: purchasedata.level_name,
            poId: id,
            supplier_name: purchasedata.supplier_name,
            material_list: purchaseMaterialList,
        }
        axiosClient.post('/store_Stock_inward_material', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/stock-inward')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const handleInwardQty = (e) => {
        setInputs(inputs => ({
            ...inputs,
            inward_qty: e,
        }))
    }

    function handleInward(tableData) {
        setModalLoader(true);
        setViewModel(true);
        setInputs({
            id: tableData.id,
            material_name: tableData.material_name,
            material_unit: tableData.material_unit,
            quantity: tableData.quantity,
            inward_qty: ((tableData.inward_qty).split(',')).join('')
        })
        setModalLoader(false);
    }

    const getUnitData = () => {
        inputs.material_name && axiosClient.get(`/material_wise_unit_data/${inputs.material_name}`)
            .then(({ data }) => {
                data.material_wise_unit_data.material_unit && (
                    setInputs(inputs => ({
                        ...inputs,
                        material_unit: data.material_wise_unit_data.material_unit,
                    })))
            })
    }

    const getMaterial = () => {
        setLoader(true)
        axiosClient.get(`/material_lists`)
            .then(({ data }) => {
                setMaterial(data.lists)
            })
    }

    useEffect(() => {
        getUnitData()
    }, [inputs.material_name])


    useEffect(() => {
        getMaterial();
        getQuotataionData()
    }, [])

    return (
        <Fragment>

            {loader ? (<Loader />) : (
                <PageComponent title={'Inward'} button={
                    <LinkButton to={'/stock-inward'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-full md:w-[65rem] p-6  bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <Input id={'work_name'} type={'text'} name={'work_name'} value={viewPurchasedata.work_name} readOnly={true} />
                                        <Validation error={errors.work_name} />
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <Input id={'wing_name'} type={'text'} name={'wing_name'} value={viewPurchasedata.wing_name} readOnly={true} />
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <Input id={'level_name'} type={'text'} name={'level_name'} value={viewPurchasedata.level_name} readOnly={true} />
                                        <Validation error={errors.level} />
                                    </div>
                                </div>

                                <div className='grid grid-cols-3'>
                                    <div >
                                        <h3 className='text-[3vw] md:text-2xl font-bold mb-4 mt-4'> Purchase Material List</h3>
                                    </div>
                                    <div></div>
                                    <div className='grid grid-cols-2'>
                                        <div></div>
                                        <div>
                                            {/* <Link type='button' className={'w-full mt-4 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '} onClick={openModal}>
                                                Add
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                                {purchaseMaterialList && purchaseMaterialList.length > 0 ? (
                                    <Table tableHeading={tableHeading}>
                                        {purchaseMaterialList.map((tableData, tableDataID) => {
                                            return (<Fragment key={tableDataID}>
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                    <td className="px-4 py-3">{tableData.supplier_name}</td>
                                                    <td className="px-4 py-3">
                                                        {
                                                            material && material.map((data, id) => {
                                                                return (<Fragment key={id}>
                                                                    <h1>{data.id == tableData.material_name && data.material_name}</h1>
                                                                </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td className="px-4 py-3">{tableData.material_unit}</td>
                                                    <td className="px-4 py-3">{(tableData.quantity)}</td>
                                                    <td className="px-4 py-3">{(tableData.inward_qty)}</td>
                                                    <td className="px-4 py-3">
                                                        <div className='flex justify-center gap-3'>
                                                            <Link title='Edit' onClick={() => handleInward(tableData)}><Button className={'p-1.5'}><PencilSquareIcon className='w-5 h-5' /></Button></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                    </Table>
                                ) : (
                                    <div className='flex justify-center items-center h-[15vh]'>
                                        <p className='text-[4vw] md:text-3xl font-bold'>Sorry! Data Not Found</p>
                                    </div>
                                )
                                }

                                <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                    Save Now
                                </Button>
                            </form>
                        </Card>
                    </div>

                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <div className='m-5'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Add Inward </h1>
                                            <hr className='my-4 border border-primary-color' />
                                        </div>
                                        <form onSubmit={(e) => { handleUpdateInward(e) }}>
                                            <div className='grid grid-cols-1'>
                                                <div>
                                                    <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                    {
                                                        material && material.map((data, id) => {
                                                            return (<Fragment key={id}>
                                                                {data.id == inputs.material_name && <Input id={'material_name'} type={'text'} name={'material_name'} value={data.material_name} readOnly={true} />}
                                                            </Fragment>
                                                            )
                                                        })
                                                    }
                                                    <Validation error={errors.material_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'material_unit'} labelText={'Material unit'} className={'mb-1'} />
                                                    <Input id={'material_unit'} type={'text'} name={'material_unit'} value={inputs.material_unit} readOnly={true} />
                                                    <Validation error={errors.material_unit} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'quantity'} labelText={'PO Quantity '} className={'mb-1'} />
                                                    <Input id={'quantity'} type={'text'} name={'quantity'} value={inputs.quantity} readOnly={true} />
                                                    <Validation error={errors.quantity} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'inward_qty'} labelText={'Inward Quantity '} className={'mb-1'} />
                                                    <Currency
                                                        id="inward_qty"
                                                        name="inward_qty"
                                                        decimalsLimit={2}
                                                        defaultValue={inputs.inward_qty}
                                                        onValueChange={handleInwardQty}
                                                    />
                                                    <Validation error={errors.inward_qty} />
                                                </div>
                                            </div>
                                            <Button className={'w-full mt-2 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                                Save
                                            </Button>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default InwardAddEdit

