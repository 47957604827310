import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon, PencilSquareIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import flasher from '@flasher/flasher'
import TextArea from '../helpers/TextArea'
import { TrashIcon } from '@heroicons/react/20/solid'

function QaqcMasterAddEdit() {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart -QA/QC Master'
    } else {
        document.title = 'SiteSmart - Add New QA/QC'
    }
    const [loader, setLoader] = useState(false)
    const [taskList, setTaskList] = useState([]);
    const [QAQCList, SetQAQCList] = useState([]);
    const [errors, setErrors] = useState([])
    const [originalQAQCList, setOriginalQAQCList] = useState([]); // Add this state variable
    const [editingIndex, setEditingIndex] = useState(null); // Initialize as null
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        task_name: '',
        qaqc: ''
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const [task_id, setTask_id] = useState('')
    const getSingleQaqcData = () => {
        setLoader(true);
        axiosClient.get(`/single_qaqc_master/${id}`)
            .then(({ data }) => {
                setLoader(false);
                setFormInfo(data.single_data);
                setTask_id(data.single_data.id);
                var originalQAQCPoints = data.Mulitiqaqc_checkpoints.map((data) => {
                    return data.qaqc_checkpoints;
                });
                SetQAQCList(data.Mulitiqaqc_checkpoints);
                setOriginalQAQCList(originalQAQCPoints)
            })
            .catch((error) => {
                setLoader(false);
            });
    };
    const [data, setData] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        const newPoints = QAQCList.filter((point) => !point.id);
        const data = newPoints && newPoints.map((data) => {
            return data.qaqc_checkpoints
        });
        setData(data)
        const payload = {
            task_name: id ? task_id : formInfo.task_name,
            qaqc_checkpoints: data,
        };
        axiosClient
            .post(id ? `update_qaqc_master/${id}` : '/store_qaqc_master', payload)
            .then(({ data }) => {
                setLoader(false);
                flasher.success(data.msg);
                navigate('/qaqc-master');
            })
            .catch(({ response }) => {
                setLoader(false);
                setErrors(response.data.errors);
            });
    };

    const handleQAQC = () => {
        if (formInfo.qaqc) {
            const newQAQCPoint = {
                qaqc_checkpoints: formInfo.qaqc,
            };
            SetQAQCList([...QAQCList, newQAQCPoint]);
            setFormInfo({
                ...formInfo,
                qaqc: '',
            });
        }
    };

    const handleCancelEdit = () => {
        if (editingIndex !== null) {
            setFormInfo({
                ...formInfo,
                qaqc: '',
            });
            setEditingIndex(null);
        }
    };

    const handleEdit = (index) => {
        const editedCheckpoint = QAQCList[index].qaqc_checkpoints;
        setOriginalQAQCList([...originalQAQCList]);
        setFormInfo({
            ...formInfo,
            qaqc: editedCheckpoint,
        });
        setEditingIndex(index);
    };


    const handleSaveEdit = () => {
        if (formInfo.qaqc) {
            if (editingIndex !== null) {
                const updatedList = [...QAQCList];
                updatedList[editingIndex].qaqc_checkpoints = formInfo.qaqc;
                formInfo.qaqc && SetQAQCList(updatedList);
                setFormInfo({
                    ...formInfo,
                    qaqc: '',
                });
                setEditingIndex(null);
                if (originalQAQCList[editingIndex] !== formInfo.qaqc) {
                    const editedPointId = QAQCList[editingIndex].id; // Assuming there's an ID property
                    formInfo.qaqc && axiosClient.post(`/update_single_qaqc_point/${editedPointId}`, { qaqc_checkpoints: formInfo.qaqc })
                        .then(({ data }) => {
                            flasher.success(data.msg);
                        })
                        .catch((error) => {
                        });
                }
            }
        }
    };

    const handleDelete = (index, id) => {
        const updatedList = [...QAQCList];
        if (!id) {
            updatedList.splice(index, 1);
            SetQAQCList(updatedList);
        } else {
            handleDeleteFrombackend(id)
        }

    };

    const handleDeleteFrombackend = (id) => {
        axiosClient.delete(`/delete_qaqc_master/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
                getSingleQaqcData()
            })
    };

    const getTaskList = () => {
        setLoader(true)
        axiosClient.get(`/show_task_master`)
            .then(({ data }) => {
                setLoader(false)
                setTaskList(data.lists)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleQaqcData()
        }
        getTaskList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit QA/QC' : 'Add New QA/QC'} button={
                    <LinkButton to={'/qaqc-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[60rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mt-4 '>
                                        <div className='mb-3'>
                                            {
                                                id ? (
                                                    <div>
                                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                                        <Input id={'task_name'} type={'text'} name={'task_name'} className={'rounded-r-none'} value={formInfo.task_name} onChange={handleChange} disabled={true} />
                                                        <Validation error={errors.task_name} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                                        <CustomSelect id={'task_name'} name={'task_name'} value={formInfo.task_name} onChange={handleChange}>
                                                            <option value={''}>--- Choose Task ---</option>
                                                            {taskList.map((data, id) => {
                                                                return (
                                                                    <Fragment key={id}>
                                                                        <option value={data.id}>{data.task_name}</option>
                                                                    </Fragment>)
                                                            })}
                                                        </CustomSelect>
                                                        <Validation error={errors.task_name} />
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='mb-3'>
                                            <Label htmlFor={'qaqc'} labelText={'Check Points'} className={'mb-1'} />
                                            <div className="flex">
                                                <Input id={'qaqc'} type={'text'} name={'qaqc'} className={'rounded-r-none'} value={formInfo.qaqc} onChange={handleChange} rows={1} />
                                                <Link onClick={handleQAQC} className="inline-flex bg-primary-color items-center px-3 text-2xl rounded-r-md ">
                                                    <PlusIcon className='w-6 h-6' />
                                                </Link>
                                            </div>

                                            <Validation error={errors.qaqc} />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 mt-4'>
                                        {QAQCList.map((data, index) => (
                                            <div key={data.id} className="flex flex-col items-center md:flex-row">
                                                {editingIndex === index ? (
                                                    <TextArea
                                                        rows={1}
                                                        type="text"
                                                        value={formInfo.qaqc}
                                                        className='mt-2 rounded-md md:rounded-l-md'
                                                        onChange={(e) => setFormInfo({ ...formInfo, qaqc: e.target.value })}
                                                    />
                                                ) : (
                                                    <h1 className='bg-primary-color text-white p-2 mt-2 rounded-md w-full md:w-[82%]' style={{ wordWrap: 'break-word' }}>{data.qaqc_checkpoints}</h1>
                                                )}
                                                <div className="flex items-center mt-2">
                                                    {editingIndex === index ? (
                                                        <div className="flex items-center">
                                                            <button
                                                                type="button"
                                                                onClick={handleSaveEdit}
                                                                className="group relative flex justify-center bg-blue-500 ml-1 rounded-lg p-2 tracking-wider font-semibold text-default-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                            >
                                                                <PlusIcon className='w-5 h-5' />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={handleCancelEdit}
                                                                className="group relative flex justify-center bg-red-600 ml-1 rounded-lg p-2 tracking-wider font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                            >
                                                                <XMarkIcon className='w-5 h-5' />
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center">
                                                            <button
                                                                type="button"
                                                                onClick={() => handleEdit(index)}
                                                                className="group relative flex justify-center bg-yellow-400 ml-1 rounded-lg p-2 tracking-wider font-semibold text-default-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" >
                                                                <PencilSquareIcon className='w-5 h-5' />
                                                            </button>
                                                            <button
                                                                type='button'
                                                                onClick={() => handleDelete(index, data.id)}
                                                                className="group relative flex justify-center bg-red-600 ml-1 rounded-lg p-2 tracking-wider font-semibold text-default-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" >
                                                                <TrashIcon className='w-5 h-5' />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Button className={'w-60 mt-6 mx-auto'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span className='ml-5'>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default QaqcMasterAddEdit