import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Transition } from '@headlessui/react'
import ActionButton from '../helpers/ActionButton'
import ViewModel from '../helpers/ViewModel'
import Pagination from '../helpers/Pagination'
import { useStateContext } from '../../context/ContextProvider'
import PleaseWait from '../Common/PleaseWait'

function UsersMaster() {
    document.title = 'SiteSmart - User Master'
    const tableHeading = ['Sr.No', 'Name', 'Profile', 'Email', 'Phone', 'User Role', 'Action']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [userLists, setUserLists] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const getUserLists = () => {
        setLoader(true)
        axiosClient.get('/user_lists')
            .then(({ data }) => {
                setLoader(false)
                setUserLists(data.lists.sort((a, b) => a.name.localeCompare(b.name)));
                // setUserLists(data.lists)
            })
    }
    const deleteUser = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_user/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getUserLists()
                    })
            }
        })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_user_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
            })
    }
    useEffect(() => {
        getUserLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'User Master'} button={
                    <LinkButton to={'/user-master/add-user-master'}>
                        <PlusCircleIcon className='w-6 h-6' />
                        <span>Add New User</span>
                    </LinkButton>
                }>
                    {userLists && userLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {userLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">
                                            <div className='flex justify-center gap-3'>
                                                <img src={tableData.user_image ? (
                                                    tableData.user_image.name ? URL.createObjectURL(tableData.user_image) : IMG_URL + "/assets/images/UserImages/" + tableData.user_image
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded-full' />
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.email}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.mobile}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.role && ((tableData.role).split('_')).join(' ')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/user-master/edit-user-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteUser(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! User Data Not Found</p>
                        </div>
                    )}
                    {userLists && userLists.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={userLists.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange} />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>User Information</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>Name</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.name}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>Email</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.email}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>Phone</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.mobile}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>Role</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.role}</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>Password</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>**********</h1>
                                        </div>
                                        <div className='flex items-center gap-4 w-full py-1 md:py-2'>
                                            <h1 className='w-24 font-bold'>User Status</h1>
                                            <h1>-</h1>
                                            <h1 className='ml-6'>{modalData.user_status}</h1>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className=" rounded-md bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={() => setViewModel()} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default UsersMaster