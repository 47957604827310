import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import Label from '../helpers/Label'
import ViewModel from '../helpers/ViewModel'
import CustomSelect from '../helpers/CustomSelect'
import { useStateContext } from '../../context/ContextProvider'
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function TransactionDetails() {
    document.title = 'SiteSmart - Transaction Details'
    const tableHeading = ['Sr.No', 'Work Name', 'Contractor Name', 'Task Name', 'Paid Amount ', 'Payment', 'Approval Status', 'Actions']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [transactionList, setTransactionList] = useState('')
    const [workname, setWorkname] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [errors, setErrors] = useState([])
    const [tax, setTax] = useState('')
    const [tds, setTds] = useState('')
    const [grandtotal, setGrandtotal] = useState('')
    const [modalData, setModalData] = useState([])
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [userRole, SetUserRole] = useState(localStorage.getItem('USER_ROLE'));
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const getTransactionList = () => {
        setLoader(true)
        axiosClient.get('/Contractorpayment_lists')
            .then(({ data }) => {
                setLoader(false)
                setTransactionList(data.lists)
            })
    }
    const handleChangeWorkName = (e) => {
        setWorkname(e.target.value)
    }

    const getWorkWiseData = () => {
        setLoader(true)
        workname == '' ? (getTransactionList()) : axiosClient.get(`/workwise_Contractorpayment_lists/${workname}`)
            .then(({ data }) => {
                setLoader(false)
                setTransactionList(data.lists)
            })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(false)
        axiosClient.get(`/single_Contractorpayment_data/${id}`)
            .then(({ data }) => {
                setModalData(data.single_data)
                if (data.single_data.contract_type == "Fixed Rate") {
                    setTax(data.single_data.fixed_rate_tax_amount)
                    setTds(data.single_data.fixed_rate_TDS_amount)
                    setGrandtotal(data.single_data.fixed_rate_grand_total)
                } else {
                    setTax(data.single_data.unit_based_tax_amount)
                    setTds(data.single_data.unit_based_TDS_amount)
                    setGrandtotal(data.single_data.unit_based_grand_total)
                }
            })
    }

    const [name, setName] = useState('')
    const changeApproval = (id) => {
        axiosClient.get(`/change_approval_status/${id}`)
            .then(({ data }) => {
                flasher.success(data.msg)
            })
        axiosClient.get(`/single_Contractorpayment_data/${id}`)
            .then(({ data }) => {
                setName(data.single_data.contractor_name)
            })
        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: '2',
            message: name + 'Bill Approved',
            module: 'Contractor Management',
            link: '/transactiondetails',
        }
        axiosClient.post(`store_Notification`, notification)
            .then(({ data }) => {
                setLoader(false)
            })
            .catch(({ response }) => {
                setErrors(response.data.errors)
                setLoader(false)
            })
        getTransactionList()
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }
    function Warning() {
        Swal.fire({
            title: 'Without Approval you can not make payment',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        })
    }
    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setImgModalLoader(false)
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getAllData = () => {
        workname && setLoader(true)
        workname && workname == 'All' ? (getTransactionList()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWorks(data.lists)
                setLoader(false)
            })
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getWorkWiseData()
    }, [workname])

    useEffect(() => {
        getAllData();
    }, [workname])

    useEffect(() => {
        getTransactionList()
        getWorkData()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Transaction Details'} >
                    <div className="grid grid-cols-1 md:grid-cols-4 mb-3">
                        <div >
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={workname} onChange={handleChangeWorkName} className={'w-full'}>
                                <option disabled value={''}>--- Choose Work Name ---</option>
                                <option value={"All"}>All</option>
                                {works.map((userData, userId) => {
                                    return (<Fragment key={userId}>
                                        <option value={userData.id}>{userData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div></div>
                        <div></div>
                        <div className="justify-end flex py-5 ml-3 mt-2">
                        </div>
                    </div>

                    {transactionList && transactionList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {transactionList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{tableData.work_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.contractor_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.task_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{parseInt(tableData.paid_amount).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            {tableData.approval_status !== null ? (
                                                <div className='flex justify-center'>
                                                    {tableData.paid_date == null ?
                                                        <Link title='Edit' to={`/transactiondetails/edit-transactiondetails/${tableData.id}`}>
                                                            <button className='border-2  bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Payment Incomplete</button>
                                                        </Link>
                                                        :
                                                        <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Payment Completed</button>
                                                    }
                                                </div>
                                            ) : (
                                                <div className='flex justify-center'>
                                                    {tableData.paid_date == null ?
                                                        <button className='border-2  bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg' onClick={() => Warning()}>Payment Incomplete</button>
                                                        :
                                                        <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Payment Completed</button>
                                                    }
                                                </div>
                                            )}

                                        </td>
                                        <td className="px-4 py-3">
                                            {
                                                // userRole == 'Admin' && (
                                                tableData.approval_status === null ? (
                                                    <button className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg' onClick={() => changeApproval(tableData.id)}> Not Approve</button>
                                                ) : (
                                                    <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg' > Approved</button>
                                                )
                                                // )
                                            }
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-3xl font-bold'>Sorry!Transaction Data Not Found</p>
                        </div>
                    )}
                    {transactionList && transactionList.length > 5 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={transactionList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        {modalData.paid_date == null ? (
                                            <div>
                                                <div className='m-5' ref={componentRef} id='print'>
                                                    <div>
                                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Contractor Payment Information</h1>
                                                    </div>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Work Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Wing Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.wing_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Level Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.level_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Task Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.task_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold whitespace-nowrap'>Contractor Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.contractor_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Bill No.</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.c_billid}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Bill Date</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{moment(modalData.bill_date).format('DD-MM-YYYY')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Gross Amount</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.fixed_rate_est_amount == null ? (parseInt(modalData.unit_based_rate_est_amount).toLocaleString('en-IN')) : (parseInt(modalData.fixed_rate_est_amount).toLocaleString('en-IN'))}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Tax</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{tax}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>TDS</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{tds}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Grand Total</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{parseInt(grandtotal).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Paid Amount</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{parseInt(modalData.paid_amount).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold whitespace-nowrap'>Balance Amount</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{parseInt(modalData.balance).toLocaleString('en-IN')}</h1>
                                                        </div>

                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Bill Description</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.bill_description}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                            <h1 className='font-bold '>Bill Remark</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1>{modalData.bill_remark}</h1>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-4  grid grid-cols-3">
                                                    <button id='gotit'
                                                        type="button"
                                                        className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => setViewModel(false)} >
                                                        Got it, thanks!
                                                    </button>
                                                    <div className=""></div>
                                                    <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                                </div>
                                            </div>
                                ) : (
                                <div>
                                    <div className='m-5' ref={componentRef} id='print'>
                                        <div>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[2vw] tracking-widest'>Contractor Payment Information</h1>
                                        </div>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Work Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.work_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold whitespace-nowrap'>Contractor Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.contractor_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Task Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.task_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Level Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.level_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Wing Name</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.wing_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Bill No.</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.c_billid}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Bill Date</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{moment(modalData.bill_date).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Gross Amount</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.fixed_rate_est_amount == null ? (parseInt(modalData.unit_based_rate_est_amount).toLocaleString('en-IN')) : (parseInt(modalData.fixed_rate_est_amount).toLocaleString('en-IN'))}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Tax</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{tax}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>TDS</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{tds}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Grand Total</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{parseInt(grandtotal).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Paid Date</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{moment(modalData.paid_date).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Payment Mode</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.payment_mode}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Paid Amount</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words'>{parseInt(modalData.paid_amount).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold whitespace-nowrap'>Remaining Amount</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words text-red-800 font-bold'>{parseInt(modalData.balance).toLocaleString('en-IN')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Paid from Acc</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1 className='break-words '>{modalData.paid_from_acc}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Bill Description</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.bill_description}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Bill Remark</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.bill_remark}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>TR  Number</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.tr_reference_no}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>TR  Date</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{moment(modalData.tr_reference_date).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Transaction Remark</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <h1>{modalData.transasction_remark}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                <h1 className='font-bold '>Bill Copy</h1>
                                                <h1 className='justify-center flex'>-</h1>
                                                <div className="">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage()} >
                                                            <img src={modalData.upload_bill_copy ? (
                                                                modalData.upload_bill_copy.name ? URL.createObjectURL(modalData.upload_bill_copy) : IMG_URL + "/assets/images/BillcopyFile/" + modalData.upload_bill_copy
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="bill-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4  grid grid-cols-3">
                                        <button id='gotit'
                                            type="button"
                                            className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => setViewModel(false)} >
                                            Got it, thanks!
                                        </button>
                                        <div className=""></div>
                                        <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                    </div>
                                </div>

                                )
                                        }
                            </Fragment>
                                )}
                        </Dialog.Panel>
                        </ViewModel>
                    }
            {
                imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                    <Dialog.Panel className="w-[60%] max-w-2xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                        {imgmodalLoader ? (<PleaseWait />) : (
                            <Fragment>
                                <h1 className='text-center uppercase font-bold md:text-2xl text-[3vw] tracking-widest'>Bill Image</h1>
                                <hr className='my-4 border border-primary-color' />
                                {
                                    <img src={modalData.upload_bill_copy ? (
                                        modalData.upload_bill_copy.name ? URL.createObjectURL(modalData.upload_bill_copy) : IMG_URL + `/assets/images/BillcopyFile/` + modalData.upload_bill_copy
                                    ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full md:h-[500px] h-[300px] rounded' />
                                }
                               
                                <div className="mt-4  grid grid-cols-3">
                                        <button id='gotit'
                                            type="button"
                                            className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 md:px-4 md:py-2 py-1 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => setImgModel(false)}>
                                            Got it, thanks!
                                        </button>
                                        <div className=""></div>
                                        <div className=""></div>
                                </div>
                            </Fragment>
                        )}
                    </Dialog.Panel>
                </ViewModel>
            }
        </PageComponent>
    )
}
        </Fragment >
    )
}
export default TransactionDetails