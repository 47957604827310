import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'


function StockConsumedAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Stock Consumed'
        ) : (
            document.title = 'SiteSmart - Add Stock Consumed '
        )
    }
    const user_name = localStorage.getItem('CURRENT_USERNAME');
    const user_id = localStorage.getItem('CURRENT_USERID');
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [workTypeList, setWorkTypeList] = useState([])

    const navigate = useNavigate()

    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level_name: '',
        material_name: '',
        material_unit: '',
        work_type: "",
        current_qty: '',
        cus_qty: '',
        balance: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', formInfo.work_name)
        formData.append('wing_name', formInfo.wing_name)
        formData.append('level_name', formInfo.level_name)
        formData.append('material_name', formInfo.material_name)
        formData.append('material_unit', formInfo.material_unit)
        formData.append('current_qty', formInfo.current_qty)
        formData.append('cus_qty', formInfo.cus_qty)
        formData.append('balance', formInfo.balance)
        formData.append('work_type', formInfo.work_type)
        formData.append('resp_person', user_name)
        formData.append('resp_person_id', user_id)
        axiosClient.post(id ? `update_Stock_consumed/${id}` : '/store_Stock_consumed', formData)
            .then(({ data }) => {
                data.status == true ? flasher.success(data.msg) : flasher.warning(data.msg)
                data.status == true ? navigate('/stock-consumed') : console.log();
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleConsumedData = () => {
        setLoader(true)
        axiosClient.get(`/single_Stock_consumed_data/${id}`)
            .then(({ data }) => {
                setFormInfo(data.single_data)
                setLoader(false)
            })
    }


    const getWorkTypeList = () => {
        setLoader(true)
        axiosClient.get('/worktype_list')
            .then(({ data }) => {
                setWorkTypeList(data.lists)
                setLoader(false)
            })
    }

    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [material, setMaterial] = useState([])
    const getMaterial = () => {
        formInfo.work_name && formInfo.wing_name && formInfo.level_name && axiosClient.get(`/work_wing_level_wise_Current_Stock_lists/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level_name}`)
            .then(({ data }) => {
                setMaterial(data.lists)
            })
    }

    const getCurrentstock = () => {
        formInfo.material_name && axiosClient.get(`/single_Current_Stock/${formInfo.material_name}`)
            .then(({ data }) => {
                setFormInfo(formInfo => ({
                    ...formInfo,
                    current_qty: data.single_data.quantity,
                    material_unit: data.single_data.material_unit
                }))
            })
    }

    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        getCurrentstock();
    }, [formInfo.material_name])

    useEffect(() => {
        getMaterial();
    }, [formInfo.wing_name, formInfo.level_name])

    useEffect(() => {
        if (id) {
            getSingleConsumedData()
        }

        getWork();
        getWorkTypeList();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Stock Consumed' : 'Add Stock Consumed'} button={
                    <LinkButton to={'/stock-consumed'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[80%] p-6 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Work ---</option>
                                            {
                                                work.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select level_name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                        <CustomSelect id={'material_name'} name={'material_name'} value={formInfo.material_name} onChange={handleChange} className={'w-full'}>
                                            <option disabled value={''}>--- Choose Material ---</option>
                                            {material.map((materialData, materialId) => {
                                                return (<Fragment key={materialId}>
                                                    <option value={materialData.id}>{materialData.material_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.material_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'material_unit'} labelText={'Material Unit'} className={'mb-1'} />
                                        <Input id={'material_unit'} type={'text'} name={'material_unit'} value={formInfo.material_unit} readOnly={true} />
                                        <Validation error={errors.material_unit} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_type'} labelText={'Work Type'} className={'mb-1'} />
                                        <CustomSelect id={'work_type'} name={'work_type'} value={formInfo.work_type} onChange={handleChange} className={'w-full'}>
                                            <option disabled value={''}>--- Choose Type ---</option>
                                            {workTypeList && workTypeList.map((data, Id) => {
                                                return (<Fragment key={Id}>
                                                    <option value={data.id}>{data.work_type}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        
                                        <Validation error={errors.work_type} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'current_qty'} labelText={'Current Quantity'} className={'mb-1'} />
                                        <Input id={'current_qty'} type={'text'} name={'current_qty'} value={formInfo.current_qty} readOnly={true} />
                                        <Validation error={errors.current_qty} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'cus_qty'} labelText={'Consumed Quantity'} className={'mb-1'} />
                                        <Input id={'cus_qty'} type={'text'} name={'cus_qty'} value={formInfo.cus_qty} onChange={handleChange} />
                                        <Validation error={errors.cus_qty} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'balance'} labelText={'Balance'} className={'mb-1'} />
                                        <Input id={'balance'} type={'text'} name={'balance'} value={parseInt(formInfo.balance = parseInt(formInfo.current_qty) - parseInt(formInfo.cus_qty)).toLocaleString('en-IN')} readOnly={true} />
                                        <Validation error={errors.balance} />
                                    </div>
                                </div>

                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button type="submit" className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>

                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}


export default StockConsumedAddEdit
