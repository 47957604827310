import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'

function PaymentDetails() {
    const { work_id } = useParams()
    document.title = 'SiteSmart - Payment Details'
    const tableHeading = ['Sr.No', 'Wing Name', 'Stage Name', 'Percentage %', 'Action']
    const [loader, setLoader] = useState(false)
    const [paymentLists, setPaymentLists] = useState('')
    const { IMG_URL, FRONTEND_URL } = useStateContext()
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [workname, setWorkName] = useState("")
    const [totalsum, setTotalSum] = useState()
    const getPaymentLists = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_paymentstagedata/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
                setPaymentLists(data.work_wise_paymentstagedata)
                setTotalSum(data.total_percentage)

            })
    }
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    const [formInfo, setFormInfo] = useState({
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const getWingWiseStageMasterData = () => {
        formInfo.wing_name && setLoader(true)
        formInfo.wing_name === 'All' ? (getPaymentLists()) : (axiosClient.get(`/wing_wise_stage_master_data/${work_id}/${formInfo.wing_name}`))
            .then(({ data }) => {
                setLoader(false)
                setPaymentLists(data.work_wise_paymentstagedata)
                setTotalSum(data.total_percentage)
            })
    }

    const deleteWork = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Payment_Stages_master/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getPaymentLists()
                    })
            }
        })
    }
    useEffect(() => {
        getWingWiseStageMasterData();
    }, [formInfo.wing_name])

    useEffect(() => {
        getPaymentLists();
        getWingData();
    }, [work_id])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Payment Details'} button={
                    <LinkButton to={`/work-management/add-payment-details-form/${work_id}`}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add Payment Stage</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                            {workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                            ) : (
                                workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                            )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <h1 className='text-center mt-1 mb-2 hidden md:block'>
                                {
                                    workname.work_category === 'project_management' ? "Project Management"
                                        : workname.marketing_type === 'interior_designing' ? "Interior Designing"
                                            : workname.marketing_type === 'plan_and_designing' ? "Plan & Designing"
                                                : "Construction"
                                }
                            </h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='mb-3 w-[16rem]'>
                                <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                    <option disabled value={''}>--- Choose Wing Name ---</option>
                                    <option value={'All'}>{'All'}</option>
                                    {wing && wing.map((wingData, wingId) => {
                                        return (<Fragment key={wingId}>
                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                            {paymentLists && paymentLists.length > 0 ? (
                                <Table tableHeading={tableHeading}>
                                    {paymentLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                        return (<Fragment key={tableDataID}>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.wing_name}</td>
                                                <td className="px-4 py-3">{tableData.stage_name}</td>
                                                <td className="px-4 py-3">{tableData.percentage}</td>
                                                {/* <td className="px-4 py-3">
                                            {
                                                tableData.work_category === 'project_management' ? "Project Management" 
                                                : tableData.marketing_type === 'interior_designing' ? "Interior Designing"
                                                : tableData.marketing_type === 'plan_and_designing' ? "Plan & Designing"
                                                : "Construction"
                                            }
                                        </td> */}
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link title='Edit' to={`/work-management/edit-payment-details-form/${work_id}/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                        <ActionButton onClick={() => deleteWork(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>)
                                    })}
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <th className="px-4 py-3 whitespace-nowrap">TOTAL</th>
                                        <th className="px-4 py-3 font-bold whitespace-nowrap"></th>
                                        <th className="px-4 py-3 font-bold whitespace-nowrap"></th>
                                        <th className="px-4 py-3 whitespace-nowrap">{totalsum}{'%'}</th>
                                        <th className="px-4 py-3">
                                        </th>
                                    </tr>
                                </Table>
                            ) : (
                                <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Payment Data Not Found</p>
                                </div>
                            )}
                            {paymentLists && paymentLists.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={paymentLists.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default PaymentDetails
