
import React, { Fragment, useEffect, useState } from 'react'
import { PencilSquareIcon, ArrowLeftCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { Dialog, Transition } from '@headlessui/react'
import ViewModel from '../helpers/ViewModel'
import Swal from 'sweetalert2'
import Table from '../helpers/Table'
import moment from 'moment'
import PleaseWait from '../Common/PleaseWait'

function TaskDetailsAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Task'
        ) : (
            document.title = 'SiteSmart - Add New Task'
        )
    }
    const tableHeading = ['Sr.No', 'Sub Activity Name', 'Description', 'Start Date ', 'Due Date', 'Action']
    const task_id = id;
    const navigate = useNavigate()
    const [errors, setErrors] = useState([])
    const [loader, setLoader] = useState(false)
    const [tableId, setTableId] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    function closeModal() { setIsOpen(false) }
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [viewModel1, setViewModel1] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [activity_name, setActivity_name] = useState([])
    const [activity_description, setActivity_description] = useState([])
    const [activity_start_date, setActivity_start_date] = useState([])
    const [activity_due_date, setActivity_due_date] = useState([])
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        level_name: '',
        wing_name: '',
        task_name: '',
        start_date: '',
        due_date: '',
        description: '',
        assign_to: '',
        contractor_name: "",
        status: '',
        upload_photos: '',
        remark: '',
    })
    const [addTask, setAddTask] = useState({
        task_name: '',
    })
    const [inputs, setInputs] = useState({
        task_id: task_id,
        activity_name: '',
        activity_description: '',
        activity_start_date: '',
        activity_due_date: '',
    });

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeSub = (e) => {
        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeTask = (e) => {
        setAddTask(addTask => ({
            ...addTask,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmitTask = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('task_name', addTask.task_name)
        axiosClient.post('/store_task_master', formData)
            .then(({ data }) => {
                setLoader(false)
                setViewModel1(false)
                flasher.success(data.msg)
                navigate(`/task-details/add-task-details/`)
                getTaskList()
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const handleSubmitSubTask = (e) => {
        e.preventDefault();
        if (!inputs.activity_name || !inputs.activity_start_date || !inputs.activity_due_date) {
            flasher.error("Please Fill the Data")
            return;
        }
        if (isEditing && editingIndex !== null) {
            const updatedTableData = [...tableData];
            updatedTableData[editingIndex] = { ...inputs };
            setTableData(updatedTableData);
        } else {
            setTableData([...tableData, { ...inputs }]);
        }
        setViewModel(false)
        setIsEditing(false);
        setEditingIndex(null);
    };

    const handleSubmitSubwithBackend = (e) => {
        e.preventDefault();
        axiosClient.post(tableId ? `update_subtask/${tableId}` : '/store_subtask', inputs)
            .then(({ data }) => {
                setLoader(false)
                setViewModel1(false)
                flasher.success(data.msg)
                getSingleTaskData()
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            });
        setViewModel(false)
    };


    const handleEdit = (index) => {
        openModal()
        const selectedSubtask = tableData[index];
        setInputs({
            task_id: selectedSubtask.task_id,
            activity_name: selectedSubtask.activity_name,
            activity_description: selectedSubtask.activity_description,
            activity_start_date: selectedSubtask.activity_start_date,
            activity_due_date: selectedSubtask.activity_due_date,
        });
        setIsEditing(true);
        setEditingIndex(index);
    };

    function getSingleSubTaskData(id) {
        axiosClient.get(`/single_subtask_data/${id}`)
            .then(({ data }) => {
                setViewModel(true)
                setInputs(data.single_data)
                setTableId(data.single_data.id)
            })
    }

    const handleDelete = (index) => {
        const filterData = tableData.filter((item, i) => i !== index)
        setTableData(filterData)
    };

    const deleteSubTask = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_subtask/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSingleTaskData()
                    })
            }
        })
    }

    const handleSubTaskData = (e) => {
        const activity_name = tableData && tableData.map((data) => {
            return data.activity_name
        });
        setActivity_name(activity_name)

        const activitydes = tableData && tableData.map((data) => {
            return data.activity_description
        });
        setActivity_description(activitydes)

        const activitydstartdate = tableData && tableData.map((data) => {
            return data.activity_start_date
        });
        setActivity_start_date(activitydstartdate)

        const activity_due_date = tableData && tableData.map((data) => {
            return data.activity_due_date
        });
        setActivity_due_date(activity_due_date)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            work_name: formInfo.work_name,
            task_name: formInfo.task_name,
            level_name: formInfo.level_name,
            wing_name: formInfo.wing_name,
            start_date: formInfo.start_date,
            due_date: formInfo.due_date,
            description: formInfo.description,
            assign_to: formInfo.assign_to,
            contractor_name: formInfo.contractor_name,
            status: formInfo.status,
            remark: formInfo.remark,
            activity_id: inputs.activity_id,
            activity_name: activity_name,
            activity_description: activity_description,
            activity_start_date: activity_start_date,
            activity_due_date: activity_due_date
        }
        axiosClient.post(id ? `update_task/${id}` : '/store_task', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/task-details')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const getSingleTaskData = () => {
        setLoader(true)
        axiosClient.get(`/single_task_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data1)
                setTableData(data.Mulitisubtasks)
            })
    }

    function openModal() {
        setInputs({
            task_id: task_id,
            activity_name: '',
            activity_description: '',
            activity_start_date: '',
            activity_due_date: '',
        }
        );
        setTableId('')
        setViewModel(true)
        setModalLoader(true)
        setModalLoader(false)
    }

    function openModal1() {
        setViewModel1(true)
        setAddTask({ task_name: "" })
        setModalLoader(false)

    }

    const [work, setWork] = useState([])
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWork(data.lists)
            })
    }

    const [wing, setWing] = useState([])
    const getWingList = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelList = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const [taskName, setTaskName] = useState([])
    const getTaskList = () => {
        axiosClient.get(`/show_task_master`)
            .then(({ data }) => {
                setTaskName(data.lists)
            })
    }

    const [user, setUser] = useState([]);
    const getUserData = () => {
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setLoader(false)
                setUser(data.lists)
            })
    }
    const [contractor, setContractor] = useState([]);
    const getContractorData = () => {
        axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setLoader(false)
                setContractor(data.lists)
            })
    }

    useEffect(() => {
        getWingList()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelList()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        handleSubTaskData()
    }, [tableData])

    useEffect(() => {
        if (id) {
            getSingleTaskData()
        }
        getWorkData();
        getUserData();
        getTaskList();
        getContractorData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Task' : 'Add New Task'} button={
                    <LinkButton to={'/task-details'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[60rem] p-6  bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                            <option value={''}>--- Select Work ---</option>
                                            {work.map(data => {
                                                return (
                                                    <option value={data.id}>{data.work_name}</option>
                                                )
                                            })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option value={''}>--- Select level_name ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                        <div class="flex">
                                            <select id={'task_name'} name={'task_name'} value={formInfo.task_name} onChange={handleChange} className={"block w-full rounded-l-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset text-sm ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-color"}>
                                                <option value={''}>--- Select Task Name ---</option>
                                                {taskName && taskName.map((taskData, TaskId) => {
                                                    return (<Fragment key={TaskId}>
                                                        <option value={taskData.id}>{taskData.task_name}</option>
                                                    </Fragment>)
                                                })}
                                            </select>
                                            <button type='button' class=" inline-flex bg-primary-color items-center px-3 text-2xl border border-l-0 rounded-r-md  " onClick={() => openModal1()} >
                                                +
                                            </button>
                                        </div>
                                        <Validation error={errors.expense_head} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'description'} labelText={'Description'} className={'mb-1'} />
                                        <Input id={'description'} type={'text'} name={'description'} value={formInfo.description} onChange={handleChange} />
                                        <Validation error={errors.description} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'assign_to'} labelText={'Assign To'} className={'mb-1'} />
                                        <CustomSelect id={'assign_to'} name={'assign_to'} value={formInfo.assign_to} onChange={handleChange} className={'w-full'}>
                                            <option value={''}>--- Choose User Name ---</option>
                                            {user.map((taskData, taskId) => {
                                                return (<Fragment key={taskId}>
                                                    <option value={taskData.id}>{taskData.name} ({((taskData.role).split('_')).join(' ')})</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.assign_to} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'contractor_name'} labelText={'Contractor Name'} className={'mb-1'} />
                                        <CustomSelect id={'contractor_name'} name={'contractor_name'} value={formInfo.contractor_name} onChange={handleChange} className={'w-full'}>
                                            <option value={''}>--- Choose Contractor Name ---</option>
                                            {contractor.map((taskData, taskId) => {
                                                return (<Fragment key={taskId}>
                                                    <option value={taskData.id}>{taskData.contractor_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.contractor_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'start_date'} labelText={'Start Date'} className={'mb-1'} />
                                        <Input id={'start_date'} type={'date'} name={'start_date'} value={formInfo.start_date} onChange={handleChange} />
                                        <Validation error={errors.start_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'due_date'} labelText={'Estimated Due Date'} className={'mb-1'} />
                                        <Input id={'due_date'} type={'date'} name={'due_date'} value={formInfo.due_date} onChange={handleChange} />
                                        <Validation error={errors.due_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'status'} labelText={'Status'} className={'mb-1'} />
                                        <CustomSelect id={'status'} name={'status'} value={formInfo.status} onChange={handleChange}>
                                            <option value={''}>--- Choose Status ---</option>
                                            <option value={'To-Do'}>To-Do</option>
                                            <option value={'Ongoing'}>Ongoing</option>
                                            <option value={'Completed'}>Completed</option>
                                        </CustomSelect>
                                        <Validation error={errors.status} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'remark'} labelText={'Remarks'} className={'mb-1'} />
                                        <Input id={'remark'} type={'text'} name={'remark'} value={formInfo.remark} onChange={handleChange} />
                                        <Validation error={errors.remark} />
                                    </div>
                                </div><br></br>
                                <h3 className='md:text-2xl text-[4vw] font-bold mb-4'>Task Activity</h3>
                                <button type='button' className={'w-[40%] md:w-[20%] group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color mb-4'} onClick={openModal}>
                                    <span>Add Sub Task</span>
                                </button>
                                {tableData && tableData.length > 0 ? (
                                    <Table tableHeading={tableHeading}>
                                        {tableData.map((item, i) => {
                                            return (<Fragment key={i}>
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <td className="px-4 py-3">{i + 1}</td>
                                                    <td className="px-4 py-3">{item.activity_name}</td>
                                                    <td className="px-4 py-3">{item.activity_description}</td>
                                                    <td className="px-4 py-3">{moment(item.activity_start_date).format('DD-MM-YYYY')}</td>
                                                    <td className="px-4 py-3">{moment(item.activity_due_date).format('DD-MM-YYYY')}</td>
                                                    <td className="px-4 py-3">
                                                        <div className='flex justify-center gap-3'>
                                                            <button onClick={id ? (() => getSingleSubTaskData(item.id)) : (() => handleEdit(i))} className={"group relative flex justify-center rounded-md bg-yellow-400 p-2 tracking-wider font-semibold text-default-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><PencilSquareIcon className='w-5 h-5' /></button>
                                                            <button onClick={id ? (() => deleteSubTask(item.id)) : (() => handleDelete(i))} className={"group relative flex justify-center rounded-md bg-red-700 p-2 tracking-wider font-semibold text-default-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><TrashIcon className='w-5 h-5' /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                    </Table>
                                ) : (
                                    <div className='flex justify-center items-center h-[15vh]'>
                                        <p className='md:text-3xl text-[4vw] font-bold'>Sorry! Sub Tasks Not Found</p>
                                    </div>
                                )
                                }
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-[75%] max-w-xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <form>
                                            <div className=' grid grid-cols-1 md:grid-cols-2 gap-4'>
                                                <div>
                                                    <Label htmlFor={'activity_name'} labelText={'Sub Task Name'} className={'mb-1'} />
                                                    <Input id={'activity_name'} type={'text'} name={'activity_name'} value={inputs.activity_name} onChange={handleChangeSub} />
                                                    <Validation error={errors.activity_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'activity_description'} labelText={'Description'} className={'mb-1'} />
                                                    <Input id={'activity_description'} type={'text'} name={'activity_description'} value={inputs.activity_description} onChange={handleChangeSub} />
                                                    <Validation error={errors.activity_description} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'activity_start_date'} labelText={'Start Date '} className={'mb-1'} />
                                                    <Input id={'activity_start_date'} type={'date'} name={'activity_start_date'} value={inputs.activity_start_date} onChange={handleChangeSub} />
                                                    <Validation error={errors.activity_start_date} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'activity_due_date'} labelText={'Due Date'} className={'mb-1'} />
                                                    <Input id={'activity_due_date'} type={'date'} name={'activity_due_date'} value={inputs.activity_due_date} onChange={handleChangeSub} />
                                                    <Validation error={errors.activity_due_date} />
                                                </div>
                                            </div>
                                        </form>
                                        <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'} onClick={id ? (handleSubmitSubwithBackend) : (handleSubmitSubTask)}>
                                            <span>Save</span>
                                        </Button>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        viewModel1 && <ViewModel viewModel={viewModel1} setViewModel={setViewModel1}>
                            <Dialog.Panel className="w-[65%] max-w-xl transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <form>
                                            <div>
                                                <div>
                                                    <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                                    <Input id={'task_name'} type={'text'} name={'task_name'} value={addTask.task_name} onChange={handleChangeTask} />
                                                    <Validation error={errors.task_name} />
                                                </div>
                                                <div className="mt-4 grid grid-cols-2 md:grid-cols-2 gap-3">
                                                    <Button
                                                        type="submit"
                                                        className=" inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={handleSubmitTask} >
                                                        Save
                                                    </Button>
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 md:text-sm text-[2vw] font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => setViewModel1(false)} >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default TaskDetailsAddEdit

