import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink, Navigate, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../helpers/ActionButton'
import { Menu, Transition } from '@headlessui/react'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination';
import { useStateContext } from '../../context/ContextProvider'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Notification() {
    document.title = 'SiteSmart - Notification List'
    const tableHeading = ['Sr.No', 'Module', 'From User', 'Profile', 'Message', 'Time & Date', 'Action']
    const [loader, setLoader] = useState(false)
    const [notificationList, setNotificationList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const navigate = useNavigate()

    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const getNotificationList = () => {
        setLoader(true)
        axiosClient.get(`/userwise_notification_data/${localStorage.getItem('CURRENT_USERID')}`)
            .then(({ data }) => {
                setLoader(false)
                setNotificationList(data.userwise_notification_data)
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const deleteNotification = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Notification/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getNotificationList()
                    })
            }
        })
    }

    useEffect(() => {
        getNotificationList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Notification List'} >

                    {notificationList && notificationList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {notificationList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="bg-default-color cursor-pointer hover:bg-gray-100 text-gray-900 border-b text-center">
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">{tableDataID + 1}</td>
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">{tableData.module}</td>
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">{tableData.from_user}</td>
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <img src={tableData.user_image ? (
                                                            tableData.user_image.name ? URL.createObjectURL(tableData.user_image) : IMG_URL + "/assets/images/UserImages/" + tableData.user_image
                                                        ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded-full' />
                                                    </div>
                                                </td>
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">{tableData.message}</td>
                                                <td onClick={() => navigate(tableData.link)} className="px-4 py-3">{tableData.timeDifference}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <ActionButton onClick={() => deleteNotification(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Notifications Data Not Found</p>
                        </div>
                    )}
                    {notificationList && notificationList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={notificationList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default Notification
