import React, { Fragment } from 'react'

function Input({ type, id, name, value, className, onChange, readOnly, required, disabled, onKeyPress, maxLength, placeHolder }) {
    return (
        <Fragment>
            <input type={type} name={name} value={value} id={id} autoComplete="given-name" className={"block w-full rounded-md border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 " + (className)} readOnly={readOnly} required={required} disabled={disabled} onChange={onChange} onKeyPress={onKeyPress} maxLength={maxLength} placeholder={placeHolder} />
        </Fragment>
    )
}

export default Input