import React, { useEffect, useState } from 'react'
import Input from '../helpers/Input'
import CheckBox from '../helpers/CheckBox'

function QaqcComponent({ data, updateQaqcArray }) {


    const [checkPoint, setCheckPoint] = useState(data.check_mark === '1' ? true : false)
    const [qaqcRemark, setQaqcRemark] = useState(data.remark)

    const handleCheck = (e) => {
        setCheckPoint(current => !current)
    }

    const handleRemark = (e) => {
        setQaqcRemark(e.target.value); 

    }

    useEffect(() => {
        qaqcRemark && updateQaqcArray(data.id, checkPoint, qaqcRemark)
    }, [checkPoint, qaqcRemark])


    return (
        <div>
            <div>
                <div className='flex justify-center md:grid md:grid-cols-2 gap-x-1 mt-4 mb-3 '>
                    <div className='flex items-center gap-2 py-2'>
                        <h1 className='md:w-36 w-20 font-bold'>{data.qaqc_checkpoints}</h1>
                        <h1>-</h1>
                        <CheckBox id={'checkPoint'} type={'checkbox'} name={'checkPoint'} checked={checkPoint} value={checkPoint} className={'ml-3'} onClick={handleCheck} />
                    </div>
                    <div className='flex items-center'>
                        {/* <Label htmlFor={'qaqcRemark'} labelText={'Remark'} className={'mb-1 mr-1'} /> */}
                        <Input id={'qaqcRemark'} type={'text'} name={'qaqcRemark'} value={qaqcRemark} onChange={handleRemark} />
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default QaqcComponent