import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import moment from 'moment'
import Pagination from '../helpers/Pagination'



function PurchaseOrder() {
    document.title = 'Site Smart - Purchase Order'
    const tableHeading = ['Sr.No', 'Work Name', 'Supplier', 'Order Date', 'Amount', 'Generate PO']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [poList, setPOList] = useState('')
    const getEmployeeLists = () => {
        setLoader(true)
        axiosClient.get('/Aproved_po_quotation_wise_supplier_lists/')
            .then(({ data }) => {
                setPOList(data.lists)
                setLoader(false)
            })
    }
 
    useEffect(() => {
        getEmployeeLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Purchase Order'}>
                    {poList && poList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {poList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.supplier_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">{tableData.grand_total}</td>
                                        <td className="px-4 py-3"><div className='flex justify-center'>
                                            <Link to={`/purchase-order/postructure/${tableData.id}`} className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>PO Structure</Link>
                                        </div></td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Purchase Order Data Not Found</p>
                        </div>
                    )}
                     {poList && poList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={poList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default PurchaseOrder