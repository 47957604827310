import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './views/Dashboard';
import Login from './views/auth/Login';
import Register from './views/auth/Register';
import ForgetPassword from './views/auth/ForgetPassword';
import ResetPassword from './views/auth/ResetPassword';
import ChangePassword from './views/auth/ChangePassword';
import GuestLayout from './components/GuestLayout';
import MainLayout from './components/MainLayout';
import PageComponent from './components/PageComponent';
import YourProfile from './views/YourProfile';

// START MASTERS
import MaterialMaster from './views/Master/MaterialMaster'
import MaterialMasterAddEdit from './views/Master/MaterialMasterAddEdit';
import WorkTypeMasterAddEdit from './views/Master/WorkTypeMasterAddEdit';
import ContractorMaster from './views/Master/ContractorMaster';
import ContractorMasterAddEdit from './views/Master/ContractorMasterAddEdit';
import TaskMaster from './views/Master/TaskMaster';
import TaskMasterAddEdit from './views/Master/TaskMasterAddEdit';
import QAQCMaster from './views/Master/QAQCMaster';
import QaqcMasterAddEdit from './views/Master/QaqcMasterAddEdit';
import LevelMaster from './views/Master/LevelMaster';
import LevelMasterAddEdit from './views/Master/LevelMasterAddEdit';
import CompanyMaster from './views/Master/CompanyMaster'
import CompanyMasterAddEdit from './views/Master/CompanyMasterAddEdit'
import SupplierMaster from './views/Master/SupplierMaster';
import SupplierMasterAddEdit from './views/Master/SupplierMasterAddEdit';
import UsersMaster from './views/Master/UsersMaster';
import UsersMasterAddEdit from './views/Master/UsersMasterAddEdit';
//END MASTERS

// START Work Contracts

import AllWork from './views/Work Contracts/Add Work/AllWork';
import WorkAddEdit from './views/Work Contracts/Add Work/WorkAddEdit';
import WorkOverview from './views/Work Contracts/Work Overview/WorkOverview';
import FloorDetails from './views/Work Contracts/Add Work Details/FloorDetails';
import FloorDetailsAddEdit from './views/Work Contracts/Add Work Details/FloorDetailsAddEdit';
import WingDetails from './views/Work Contracts/Add Work Details/WingDetails';
import WingDetailsAddEdit from './views/Work Contracts/Add Work Details/WingDetailsAddEdit';
import FlatDetails from './views/Work Contracts/Add Work Details/FlatDetails';
import FlatDetailsAddEdit from './views/Work Contracts/Add Work Details/FlatDetailsAddEdit';
import PaymentDetails from './views/Work Contracts/Add Work Details/PaymentDetails';
import PaymentDetailsAddEdit from './views/Work Contracts/Add Work Details/PaymentDetailsAddEdit';
import MaterialEstimation from './views/Work Contracts/Add Work Details/MaterialEstimation';
import MaterialEstimationAddEdit from './views/Work Contracts/Add Work Details/MaterialEstimationAddEdit';
import WingInfo from './views/Work Contracts/Wing Info/WingInfo';
import SaleDashboard from './views/Work Contracts/Sale Management/SaleDashboard';
import SaleReport from './views/Work Contracts/Sale Management/SaleReport';

// End Work Contracts


//START TASK MANAGEMENT
import TaskDetails from './views/Task Management/TaskDetails';
import TaskDetailsAddEdit from './views/Task Management/TaskDetailsAddEdit';
import TaskReport from './views/Task Management/TaskReport';
//END TASK MANAGEMENT


// START CONTRACTOR MANAGEMENT
import ContractorWork from './views/Contractor Management/ContractorWork';
import ContractorWorkAddEdit from './views/Contractor Management/ContractorWorkAddEdit';
import ContractorBillCreation from './views/Contractor Management/ContractorBillCreation';
import ContractorBillReport from './views/Contractor Management/ContractorBillReport';
import TransactionDetails from './views/Contractor Management/TransactionDetails';
import TransactionDetailsAddEdit from './views/Contractor Management/TransactionDetailsAddEdit';
import ContractorPaymentReports from './views/Contractor Management/ContractorPaymentReport';
//END CONTRACTOR MANAGEMENT

//START HR AND PAYROLL
import EmployeeDetails from './views/HR and Payroll/EmployeeDetails';
import EmployeeDetailsAddEdit from './views/HR and Payroll/EmployeeDetailsAddEdit';
import DailyWages from './views/HR and Payroll/DailyWages';
import DailyWagesAdd from './views/HR and Payroll/DailyWagesAdd';
import DailyWagesAddEdit from './views/HR and Payroll/DailyWagesAddEdit';
import EmployeeAdvance from './views/HR and Payroll/EmployeeAdvance';
import EmployeeAdvanceAddEdit from './views/HR and Payroll/EmployeeAdvanceAddEdit';
import LabourAdvance from './views/HR and Payroll/LabourAdvance';
import LabourAdvanceAddEdit from './views/HR and Payroll/LabourAdvanceAddEdit';
import SalaryManagement from './views/HR and Payroll/SalaryManagement';
import AddEmployeeSalary from './views/HR and Payroll/AddEmployeeSalary';
import SalaryManagementAddEdit from './views/HR and Payroll/SalaryManagementAddEdit';
import DailyAttendanceReports from './views/HR and Payroll/DailyAttendanceReports';
// END HR AND PAYROLL

// START Accounts

import AccountGroup from './views/Account/AccountGroup';
import AccountGroupAddEdit from './views/Account/AccountGroupAddEdit';
import AccountLedgerMaster from './views/Account/Masters/AccountLedgerMaster';
import AccountLedgerMasterAddEdit from './views/Account/Masters/AccountLedgerMasterAddEdit';
import ClientPayment from './views/Account/Transaction/ClientPayment';
import ClientPaymentAddEdit from './views/Account/Transaction/ClientPaymentAddEdit';
import Balancesheet from './views/Account/Transaction/Balancesheet';
import GroupMaster from './views/Account/Masters/GroupMaster';
import GroupMasterAddEdit from './views/Account/Masters/GroupMasterAddEdit';
import VoucherMaster from './views/Account/Masters/VoucherMaster';
import VoucherMasterAddEdit from './views/Account/Masters/VoucherMasterAddEdit';
import Voucher from './views/Account/Transaction/Voucher';
import VoucherAddEdit from './views/Account/Transaction/VoucherAddEdit';
import BankMaster from './views/Account/Masters/BankMaster';
import BankMasterAddEdit from './views/Account/Masters/BankMasterAddEdit';
import BankTransaction from './views/Account/Transaction/BankTransaction';
import BankTransactionAddEdit from './views/Account/Transaction/BankTransactionAddEdit';
import CashMaster from './views/Account/Masters/CashMaster';
import CashMasterAdd from './views/Account/Masters/CashMasterAdd';
import CashTransaction from './views/Account/Transaction/CashTransaction';
import CashTransactionAdd from './views/Account/Transaction/CashTransactionAdd';
import ProfitLossReport from './views/Account/Transaction/ProfitLossReport';
import LedgerReport from './views/Account/Transaction/LedgerReport';
import RawFieldone from './views/Account/Transaction/RawFieldone';
import RawFieldtwo from './views/Account/Transaction/RawFieldtwo';
import RawFieldthree from './views/Account/Transaction/RawfieldThree';
// END Accounts

import Enquiry from './views/CRM/Enquiry'
import EnquiryAddEdit from './views/CRM/EnquiryAddEdit';
import FollowUp from './views/CRM/FollowUp';
import PurchaseOrder from './views/Purchase/PurchaseOrder';
import StockConsumed from './views/Stock Management/StockConsumed';
import StockConsumedAddEdit from './views/Stock Management/StockConsumedAddEdit';
import StockTransfer from './views/Stock Management/StockTransfer';
import LegalAgreementAddEdit from './views/CRM/LegalAgreementAddEdit';
import DealRegistrationAddEdit from './views/CRM/DealRegistrationAddEdit';
import RequisitionAddEdit from './views/Purchase/RequisitionAddEdit';
import PurchaseRequisition from './views/Purchase/PurchaseRequisition';
import ClientPaymentReports from './views/Reports/ClientPaymentReports';
import ApproveQuotation from './views/Purchase/ApproveQuotation';
import ViewQuotationList from './views/Purchase/ViewQuotationList';
import PurchaseBillPaymentReports from './views/Reports/PurchaseBillPaymentReports';
import DealRegistration from './views/CRM/DealRegistration';
import Qaqc from './views/QaQc/Qaqc';

import LeadClosed from './views/CRM/LeadClosed';
import CalledList from './views/CRM/CalledList';

import AddEditQaqc from './views/QaQc/QaqcAddEdit';
import QaqcReport from './views/QaQc/QaqcReport';
import Inward from './views/Stock Management/Inward';
import InwardAddEdit from './views/Stock Management/InwardAddEdit';
import Quotation from './views/Purchase/Quotation';
import QuotationAddEdit from './views/Purchase/QuotationAddEdit';
import POStructure from './views/Purchase/POStructure';

import CurrentStock from './views/Stock Management/CurrentStock';
import Dpr from './views/Task Management/Dpr';
import DprAddEdit from './views/Task Management/DprAddEdit';
import DprReport from './views/Task Management/DprReport';
import ViewInward from './views/Stock Management/ViewInward';
import InwardRecord from './views/Stock Management/InwardRecord';
import StockTranferAddEdit from './views/Stock Management/StockTranferAddEdit';
import PendingStock from './views/Stock Management/PendingStock';
import WorkTypeMaster from './views/Master/WorkType';
import Notification from './views/Common/Notification';

export const user_roll = localStorage.getItem('USER_ROLE');
const Router = createBrowserRouter([
    {
        element: <GuestLayout />,
        children: [
            { path: '/login', element: <Login /> },
            { path: '/register', element: <Register /> },
            { path: '/forget-password', element: <ForgetPassword /> },
            { path: '/reset-password/:emailID', element: <ResetPassword /> },
        ]
    }, {
        element: <MainLayout />,
        children: [
            { path: '/your-profile', element: <YourProfile /> },
            { path: '/change-password', element: <ChangePassword /> },

            { path: '/', element: <Dashboard /> },
            { path: '/notifications', element: <Notification /> },
// ================================================= Start Masters Routes ========================================================= //

            // MATERIAL MASTER ROUTES
            { path: '/material-master', element: <MaterialMaster /> },
            { path: '/material-master/add-material-master', element: <MaterialMasterAddEdit /> },
            { path: '/material-master/edit-material-master/:id', element: <MaterialMasterAddEdit /> },
            // END MATERIAL MASTER ROUTES

            // Work Type MASTER ROUTES 
            { path: '/worktype-master', element: <WorkTypeMaster /> },
            { path: '/worktype-master/add-worktype-master', element: <WorkTypeMasterAddEdit /> },
            { path: '/worktype-master/edit-worktype-master/:id', element: <WorkTypeMasterAddEdit /> },
            // END Work Type MASTER ROUTE

            // Contractor MASTER ROUTES
            { path: '/contractor-master', element: <ContractorMaster /> },
            { path: '/contractor-master/add-contractor-master', element: <ContractorMasterAddEdit /> },
            { path: '/contractor-master/edit-contractor-master/:id', element: <ContractorMasterAddEdit /> },
            // END Contractor MASTER ROUTES

            //Task MASTER ROUTES 
            { path: '/task-master', element: <TaskMaster /> },
            { path: '/task-master/add-task-master', element: <TaskMasterAddEdit /> },
            { path: '/task-master/edit-task-master/:id', element: <TaskMasterAddEdit /> },
            // END Task MASTER ROUTES

            // QAQC MASTER ROUTES 
            { path: '/qaqc-master', element: <QAQCMaster /> },
            { path: '/qaqc-master/add-qaqc-master', element: <QaqcMasterAddEdit /> },
            { path: '/qaqc-master/edit-qaqc-master/:id', element: <QaqcMasterAddEdit /> },
            // END QAQC MASTER ROUTES

            // LEVEL MASTER ROUTES 
            { path: '/level-master', element: <LevelMaster /> },
            { path: '/level-master/add-level-master', element: <LevelMasterAddEdit /> },
            { path: '/level-master/edit-level-master/:id', element: <LevelMasterAddEdit /> },
            // END LEVEL MASTER ROUTES

            // COMPANY MASTER ROUTES 
            { path: '/company-master', element: <CompanyMaster /> },
            { path: '/company-master/add-company-master', element: <CompanyMasterAddEdit /> },
            { path: '/company-master/edit-company-master/:id', element: <CompanyMasterAddEdit /> },
            // END COMPANY MASTER ROUTES

            // Supplier MASTER ROUTES
            { path: '/Supplier-master', element: <SupplierMaster /> },
            { path: '/Supplier-master/add-Supplier-master', element: <SupplierMasterAddEdit /> },
            { path: '/Supplier-master/edit-Supplier-master/:id', element: <SupplierMasterAddEdit /> },
            // END Supplier MASTER ROUTES


            // User MASTER ROUTES
            { path: '/user-master', element: <UsersMaster /> },
            { path: '/user-master/add-user-master', element: <UsersMasterAddEdit /> },
            { path: '/user-master/edit-user-master/:id', element: <UsersMasterAddEdit /> },
            // END AGENCY MASTER ROUTES
// ================================================= End Masters Routes ========================================================= //

// ================================================= Start Work Contract Routes ========================================================= //

            { path: '/work-management', element: <AllWork /> },
            { path: '/work-management/add-work-management', element: <WorkAddEdit /> },
            { path: '/work-management/edit-work-management/:id', element: <WorkAddEdit /> },

            { path: '/work-management/view-work-management/:work_id', element: <WorkOverview /> },

            { path: '/work-management/view-wing-details/:work_id', element: <WingDetails /> },
            { path: '/work-management/add-wing-details-form/:work_id', element: <WingDetailsAddEdit /> },
            { path: '/work-management/edit-wing-details-form/:work_id/:id', element: <WingDetailsAddEdit /> },

            { path: '/work-management/view-floor-details/:work_id', element: <FloorDetails /> },
            { path: '/work-management/add-floor-details-form/:work_id', element: <FloorDetailsAddEdit /> },
            { path: '/work-management/edit-floor-details-form/:work_id/:id', element: <FloorDetailsAddEdit /> },

            { path: '/work-management/view-flat-details/:work_id', element: <FlatDetails /> },
            { path: '/work-management/add-flat-details-form/:work_id', element: <FlatDetailsAddEdit /> },
            { path: '/work-management/edit-flat-details-form/:work_id/:id', element: <FlatDetailsAddEdit /> },

            { path: '/work-management/view-payment-details/:work_id', element: <PaymentDetails /> },
            { path: '/work-management/add-payment-details-form/:work_id', element: <PaymentDetailsAddEdit /> },
            { path: '/work-management/edit-payment-details-form/:work_id/:id', element: <PaymentDetailsAddEdit /> },

            { path: '/work-management/view-materialestimation-details/:work_id', element: <MaterialEstimation /> },
            { path: '/work-management/add-materialestimation-details-form/:work_id', element: <MaterialEstimationAddEdit /> },
            { path: '/work-management/edit-materialestimation-details-form/:work_id/:id', element: <MaterialEstimationAddEdit /> },

            { path: '/work-management/view-wing-info/:work_id', element: <WingInfo /> },

            { path: '/work-management/view-sale-dashboard/:work_id', element: <SaleDashboard /> },
            { path: '/work-management/view-sale-report/:work_id', element: <SaleReport /> },


// =================================================End Work Contract Routes ========================================================= //


// ================================================= Start QA/QC Routes ========================================================= //
            // QA/QC Management ROUTES  
            { path: '/qa-qc', element: <Qaqc /> },
            { path: '/qa-qc/add-qa-qc', element: <AddEditQaqc /> },
            { path: '/qa-qc-report', element: <QaqcReport /> },
            // ================================================= End QA/QC Routes ========================================================= //


            // ================================================= Start Task Management Routes ========================================================= //
            { path: '/task-details', element: <TaskDetails /> },
            { path: '/task-details/add-task-details', element: <TaskDetailsAddEdit /> },
            { path: '/task-details/edit-task-details/:id', element: <TaskDetailsAddEdit /> },
            { path: '/task-reports', element: <TaskReport /> },
            { path: '/task-activity/dprdetails', element: <Dpr /> },
            { path: '/task-activity/dprdetails/add-dprdetails', element: <DprAddEdit /> },
            { path: '/task-activity/dprdetails/edit-task-details-dprreport/:id', element: <DprAddEdit /> },
            { path: '/dpr-report', element: <DprReport /> },

            // ================================================= End Task Management  Routes ========================================================= //

            // ================================================= Start Contractor Routes ========================================================= //
            //Contractor ROUTES  
            { path: '/contractorwork', element: <ContractorWork /> },
            { path: '/contractorwork/add-contractorwork', element: <ContractorWorkAddEdit /> },
            { path: '/contractorwork/edit-contractorwork/:id', element: <ContractorWorkAddEdit /> },
            { path: '/Contractorbillcreation', element: <ContractorBillCreation /> },
            { path: '/contractorPayment-/add-contractorPayment', element: <TransactionDetailsAddEdit /> },
            { path: '/contractorPayment-/edit-contractorPayment/:id', element: <TransactionDetailsAddEdit /> },
            { path: '/transactiondetails', element: <TransactionDetails /> },
            { path: '/transactiondetails/edit-transactiondetails/:id', element: <TransactionDetailsAddEdit /> },
            { path: '/contractorbill-report', element: <ContractorBillReport /> },
            { path: '/contractorpayment-report', element: <ContractorPaymentReports /> },
            //Contractor ROUTES
// ================================================= End Contractor Routes ========================================================= //

// ================================================= Start Purchase  Routes ========================================================= //

            // Purchase Order ROUTES  
            { path: '/purchase-order', element: <PurchaseOrder /> },
            // End Purchase Order ROUTES

            // Purchase Quotation ROUTES  
            { path: '/quotation', element: <Quotation /> },
            { path: '/quotation/add-quotation', element: <QuotationAddEdit /> },
            { path: '/quotation/edit-quotation/:id', element: <QuotationAddEdit /> },
            { path: '/purchase-order/postructure/:id', element: <POStructure /> },
            { path: '/purchase/quotation-list/:id', element: <ViewQuotationList /> },
            { path: '/purchase/approve-quotation/:id', element: <ApproveQuotation /> },
            // End Purchase Quotation ROUTES  

            // Purchase Requisition ROUTES  
            { path: '/purchase-requisition', element: <PurchaseRequisition /> },
            { path: '/purchase-requisition/add-requisition', element: <RequisitionAddEdit /> },
            { path: '/purchase-requisition/edit-requisition/:id', element: <RequisitionAddEdit /> },
            // Purchase Requisition ROUTES
// ================================================= End Purchase  Routes ========================================================= //

// ================================================= Start Stock Management Routes ========================================================= //

            // STOCK MANAGEMENT ROUTES
            { path: '/stock-inward', element: <Inward /> },
            { path: '/inward/add-inward', element: <InwardAddEdit /> },
            { path: '/stock-inward/edit-inward/:id', element: <InwardAddEdit /> },
            { path: '/stock-inward/view-inward/:id', element: <ViewInward /> },
            { path: '/stock-inward/inward-record', element: <InwardRecord /> },
            { path: '/inward/pending-stock', element: <PendingStock /> },
            // END STOCK MANAGEMENT MASTER ROUTES

            { path: '/stock-consumed', element: <StockConsumed /> },
            { path: '/stock-consumed/add-new-consumed', element: <StockConsumedAddEdit /> },
            { path: '/stock-consumed/edit-consumed/:id', element: <StockConsumedAddEdit /> },
            { path: '/stock-transfer', element: <StockTransfer /> },
            { path: '/stock-transfer/add-new-transfer', element: <StockTranferAddEdit /> },
            { path: '/stock-transfer/edit-transfer/:id', element: < StockTranferAddEdit /> },
            { path: '/current-stock', element: < CurrentStock /> },
            // ================================================= End Stock Management Routes ========================================================= //

            // ================================================= Start CRM Routes ========================================================= //
            // CRM ROUTES
            { path: '/enquiry', element: <Enquiry /> },
            { path: '/enquiry/add-enquiry', element: <EnquiryAddEdit /> },
            { path: '/enquiry/edit-enquiry/:id', element: <EnquiryAddEdit /> },

            { path: '/follow_up', element: <FollowUp /> },

            { path: '/post-sales/deal-registration', element: <DealRegistration /> },
            { path: '/post-sales/add-deal-registration', element: <DealRegistrationAddEdit /> },
            { path: '/post-sales/edit-deal-registration/:id', element: <DealRegistrationAddEdit /> },

            { path: '/crm/lead-closed', element: <LeadClosed /> },
            { path: '/follow_up/called-list', element: <CalledList /> },

            { path: '/post-sales/add-legal-agreement', element: <LegalAgreementAddEdit /> },
            // END CRM ROUTES
            // ================================================= End CRM Routes ========================================================= //

            // ================================================= Start HR Payroll  Routes ========================================================= //
            { path: '/employee-details', element: <EmployeeDetails /> },
            { path: '/employee-details/add-employee-details', element: <EmployeeDetailsAddEdit /> },
            { path: '/employee-details/edit-employee-details/:id', element: <EmployeeDetailsAddEdit /> },
            { path: '/daily-wages', element: <DailyWages /> },
            { path: '/daily-wages/add-daily-wages', element: <DailyWagesAdd /> },
            { path: '/daily-wages/edit-daily-wages/:id', element: <DailyWagesAddEdit /> },
            { path: '/advance-details', element: <EmployeeAdvance /> },
            { path: '/advance-details/add-advance-details', element: <EmployeeAdvanceAddEdit /> },
            { path: '/advance-details/edit-advance-details/:id', element: <EmployeeAdvanceAddEdit /> },
            { path: '/labour-advance-details', element: <LabourAdvance /> },
            { path: '/labour-advance-details/add-labour-advance-details', element: <LabourAdvanceAddEdit /> },
            { path: '/labour-advance-details/edit-labour-advance-details/:id', element: <LabourAdvanceAddEdit /> },
            { path: '/salarymanagement', element: <SalaryManagement /> },
            { path: '/salarymanagement/addemployeesalary', element: <AddEmployeeSalary /> },
            { path: '/salarymanagement/add-salarymanagement', element: <SalaryManagementAddEdit /> },
            { path: '/salarymanagement/edit-salarymanagement/:id', element: <SalaryManagementAddEdit /> },
            { path: '/daily-attendance-report', element: <DailyAttendanceReports /> },
            // ================================================= End HR Payroll  Routes ========================================================= //

            // ================================================= Start Accounting  Routes Masters ========================================================= //   

            { path: '/accounting/bank-master', element: <BankMaster /> },
            { path: '/accounting/bank-master/add-bank-master', element: <BankMasterAddEdit /> },
            { path: '/accounting/bank-master/edit-bank-master/:id', element: <BankMasterAddEdit /> },

            { path: '/accounting/group-master', element: <GroupMaster /> },
            { path: '/accounting/group-master/add-group-master', element: <GroupMasterAddEdit /> },
            { path: '/accounting/group-master/edit-group-master/:id', element: <GroupMasterAddEdit /> },

            { path: '/accounting/account-ledger', element: <AccountLedgerMaster /> },
            { path: '/accounting/account-ledger/add-account-ledger', element: <AccountLedgerMasterAddEdit /> },
            { path: '/accounting/account-ledger/edit-account-ledger/:id', element: <AccountLedgerMasterAddEdit /> },

            { path: '/accounting/voucher-master', element: <VoucherMaster /> },
            { path: '/accounting/voucher-master/add-voucher-master', element: <VoucherMasterAddEdit /> },
            { path: '/accounting/voucher-master/edit-voucher-master/:id', element: <VoucherMasterAddEdit /> },

            { path: '/accounting/cash-master', element: <CashMaster /> },
            { path: '/accounting/add-cash-master', element: <CashMasterAdd /> },

            // ================================================= End Accounting  Routes Masters ========================================================= //

            // ================================================= Start Accounting  Routes Transaction ========================================================= //  

            { path: '/accounting/bank-transaction', element: <BankTransaction /> },
            { path: '/accounting/bank-transaction/add-bank-transaction', element: <BankTransactionAddEdit /> },
            { path: '/accounting/bank-transaction/edit-bank-transaction/:id', element: <BankTransactionAddEdit /> },

            { path: '/accounting/account-voucher', element: <Voucher /> },
            { path: '/accounting/account-voucher/add-account-voucher', element: <VoucherAddEdit /> },
            { path: '/accounting/account-voucher/edit-account-voucher/:id', element: <VoucherAddEdit /> },

            { path: '/accounting/account-ledgerreport', element: <LedgerReport /> },

            { path: '/balancesheet', element: <Balancesheet /> },

            { path: '/profitlossreport', element: <ProfitLossReport /> },

            { path: '/accounting/account-rawfieldone', element: <RawFieldone /> },
            { path: '/accounting/account-rawfieldtwo', element: <RawFieldtwo /> },
            { path: '/accounting/account-rawfieldthree', element: <RawFieldthree /> },

            { path: '/accounting/client-payment', element: <ClientPayment /> },
            { path: '/accounting/client-payment/add-client-payment', element: <ClientPaymentAddEdit /> },
            { path: '/accounting/client-payment/edit-client-payment/:id', element: <ClientPaymentAddEdit /> },

            // ================================================= End Accounting  Routes Transaction ========================================================= //

            // ================================================= Extra Accounting  Routes ========================================================= //

            { path: '/accounting/cash-transaction', element: <CashTransaction /> },
            { path: '/accounting/add-cash-transaction', element: <CashTransactionAdd /> },
            { path: '/accounting/edit-cash-transaction/:id', element: <CashTransactionAdd /> },

            { path: '/accounting/account-group', element: <AccountGroup /> },
            { path: '/accounting/add-account-group', element: <AccountGroupAddEdit /> },
            { path: '/accounting/edit-account-group/:id', element: <AccountGroupAddEdit /> },


            // { path: '/accounting/expenses-bills', element: <ExpensesBills /> },
            // { path: '/accounting/add-expenses-bills', element: <ExpensesBillsAddEdit /> },
            // { path: '/accounting/edit-expenses-bills/:id', element: <ExpensesBillsAddEdit /> },

            // { path: '/accounting/other-income-receipts', element: <OtherIncomeReceipts /> },
            // { path: '/accounting/add-other-income-receipts', element: <OtherIncomeReceiptsAddEdit /> },
            // { path: '/accounting/edit-other-income-receipts/:id', element: <OtherIncomeReceiptsAddEdit /> },

            // { path: '/accounting/user-payment', element: <UserPayment /> },
            // { path: '/accounting/add-user-payment', element: <UserPaymentAddEdit /> },
            // ================================================= Extra Accounting  Routes ========================================================= //


            // ================================================= Start Reports Routes ========================================================= //
            { path: '/clientpayment', element: <ClientPaymentReports /> },
            { path: '/PurchaseBillPaymentReports', element: <PurchaseBillPaymentReports /> },
            // ================================================= End Reports Routes ========================================================= //

        ]
    }, {
        path: '*',
        element: <PageComponent />
    }
]);

export default Router