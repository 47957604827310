import React, { useRef, Fragment, useEffect, useState } from 'react'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from "react-to-print";
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function SalaryManagement() {
    document.title = 'SiteSmart - View Salary And Payment Details'
    const tableHeading = ['Sr.No', 'Employee', 'Designation', 'Salary/Month', 'Total', 'Adv Paid', 'Total Payable', 'Balance', ' Payments', 'Action']
    const tableHeading1 = ['Sr.No', 'Paid Date', 'Paid Amount', 'Balance', 'paid from acc', 'Payment Mode', 'TR No', 'TR Date', ' Remark']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [slipmodel, setSlipmodel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [salaryList, setSalaryList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const getSalaryList = () => {
        setLoader(true)
        axiosClient.get('/SalaryManagement_lists/')
            .then(({ data }) => {
                setLoader(false)
                setSalaryList(data.lists)
            })
    }
    const deleteSalary = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_SalaryManagement/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getSalaryList()
                    })
            }
        })
    }
    const [transactions, setTransactions] = useState([])
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_SalaryManagement_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setTransactions(data.Emp_salary_transation)
            })
    }
    const [company, setCompany] = useState('')
    function openslip(id) {
        setSlipmodel(true)
        setModalLoader(true)
        axiosClient.get(`/single_SalaryManagement_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
        axiosClient.get(`/company_lists`)
            .then(({ data }) => {
                setModalLoader(false)
                setCompany(data.lists[0].name)
            })
    }

    const componentRef = useRef();
    const salaryslipRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleSlip = useReactToPrint({
        content: () => salaryslipRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getSalaryList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Salary Management'}
                 button={
                    <LinkButton to={'/salarymanagement/addemployeesalary'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add Employee Salary</span>
                    </LinkButton>}
                >
                    {salaryList && salaryList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {salaryList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.emp_name}</td>
                                        <td className="px-4 py-3">{tableData.designation}</td>
                                        {/* <td className="px-4 py-3">{tableData.work_name}</td> */}
                                        <td className="px-4 py-3">{parseInt(tableData.salary_per_month).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.total).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.advanced_paid).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.total_salary).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3 text-red-600 font-bold">{tableData.balance == null?(parseInt(tableData.total_salary).toLocaleString('en-IN')): parseInt(tableData.balance).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            {
                                                tableData.balance === null ?
                                                    <Link to={`/salarymanagement/edit-salarymanagement/${tableData.id}`}><button className={'border-2  bg-red-800 border-red-800 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button'>Add</button></Link>
                                                    : tableData.balance === '0' ?
                                                        <button className={'border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button' onClick={() => openslip(tableData.id)}>Generete Slip</button>
                                                        :
                                                        <Link to={`/salarymanagement/edit-salarymanagement/${tableData.id}`}><button className={'border-2  bg-orange-500 border-orange-500 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button'>Add</button></Link>
                                            }

                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                {/* <Link title='Edit' to={`/salarymanagement/edit-salarymanagement/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg '}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link> */}
                                                <ActionButton onClick={() => deleteSalary(tableData.id)} title={'Delete'} className={'p-1.5  text-white bg-red-600 rounded-lg cursor-pointer '}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Salary  Details Not Found</p>
                        </div>
                    )}
                    {salaryList && salaryList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={salaryList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Employee Salary Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-3 mb-1 '>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Work</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.work_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Employee</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.emp_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Designation</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.designation}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Salary</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{parseInt(modalData.salary_per_month).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Worked Days</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.total_days_worked}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold whitespace-nowrap'>Professional Tax</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.professional_tax}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Allowance</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{modalData.travelling_allowance}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Total</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{parseInt(modalData.total).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Advance Paid</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{parseInt(modalData.advanced_paid).toLocaleString('en-IN')}</h1>
                                                </div>
                                                {/* <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Total Payable </h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=''>{parseInt(modalData.total_salary).toLocaleString('en-IN')}</h1>
                                                </div> */}
                                                <div className=''></div>
                                                <div className='flex  bg-gray-200 md:p-2 p-1 rounded-lg mt-4'>
                                                    <h1 className='md:text-xl text-[3vw] font-extrabold'>Total Payable </h1>
                                                    <h1 className='md:text-xl text-[3vw]  font-extrabold ml-8'>-</h1>
                                                    <h1 className='md:text-xl text-[3vw] font-extrabold ml-14'>{parseInt(modalData.total_salary).toLocaleString('en-IN')}</h1>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4'>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold md:text-2xl text-[3vw]'>Transactions</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                </div>
                                                <div></div>
                                            </div>

                                            {transactions && transactions.length > 0 ? (
                                                <div className='w-[50vw] md:w-full'>
                                                <Table tableHeading={tableHeading1}>
                                                    {transactions.map((tableData, tableDataID) => {
                                                        return (<Fragment key={tableDataID}>
                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.paid_date).format('DD-MM-YYYY')}</td>
                                                                <td className="px-4 py-3">{parseInt(tableData.paid_amount).toLocaleString('en-IN')}</td>
                                                                <td className="px-4 py-3">{(tableData.balance)}</td>
                                                                <td className="px-4 py-3">{tableData.paid_from_account}</td>
                                                                <td className="px-4 py-3">{tableData.payment_mode}</td>
                                                                <td className="px-4 py-3">{tableData.Tr_no}</td>
                                                                <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.Tr_date).format('DD-MM-YYYY')}</td>
                                                                <td className="px-4 py-3">{tableData.Tr_remark}</td>
                                                            </tr>
                                                        </Fragment>)
                                                    })}
                                                </Table>
                                                </div>
                                            ) : (
                                                <div className='flex justify-center items-center h-[20vh] md:h-[30vh]'>
                                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Salary  Details Not Found</p>
                                                </div>
                                            )}
                                        </div>
                                       
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        slipmodel && <ViewModel viewModel={slipmodel} setViewModel={setSlipmodel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={salaryslipRef} id='print'>
                                            <h1 className='text-center  font-extrabold md:text-3xl text-[4vw]  tracking-wide'>{company}</h1>
                                            <h1 className='text-center mt-3 font-bold md:text-xl text-[3vw] tracking-wide'>Salary Slip</h1>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4'>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Employee Name </h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.emp_name}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Designation </h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.designation}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Salary per Month </h1>
                                                    <h1>-</h1>
                                                    <div className=''>{parseInt(modalData.salary_per_month).toLocaleString('en-IN')}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Worked Days </h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.total_days_worked}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Bank Name</h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.bank_name}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'>Branch Name</h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.branch_name}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'> Bank Acc No</h1>
                                                    <h1>-</h1>
                                                    <div className='break-words'>{modalData.bank_acc_no}</div>
                                                </div>
                                                <div className='flex items-center gap-4  py-2'>
                                                    <h1 className='w-36 font-bold'>IFSC Code</h1>
                                                    <h1>-</h1>
                                                    <div className=''>{modalData.IFSC_code}</div>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-0'>
                                                <table class="table-fixed mt-6 border border-collapse">
                                                    <thead className='bg-gray-300'>
                                                        <tr>
                                                            <th class="border px-4 py-2">Earnings</th>
                                                            <th class="border px-4 py-2"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="border px-4 py-2">Basic Salary</td>
                                                            <td class="border px-4 py-2">{parseInt(modalData.salary_per_month / 30) * parseInt(modalData.total_days_worked)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="border px-4 py-2">Travelling Allowence</td>
                                                            <td class="border px-4 py-2">{modalData.travelling_allowance}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                                <table class="table-fixed mt-6 border border-collapse">
                                                    <thead className='bg-gray-300'>
                                                        <tr>
                                                            <th class="border px-4 py-2">Deduction</th>
                                                            <th class="border px-4 py-2"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="border px-4 py-2">professional  Tax</td>
                                                            <td class="border px-4 py-2">{parseInt(modalData.professional_tax) / 100 * (modalData.salary_per_month)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="border px-4 py-2">Advance Paid</td>
                                                            <td class="border px-4 py-2">{modalData.advanced_paid}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-0'>
                                                <div></div>
                                                <table class="table-fixed  border border-collapse">

                                                    <tbody>
                                                        <tr className='bg-gray-300 '>
                                                            <td class="border px-4 py-2 font-bold">Total Amount</td>
                                                            <td class="border px-4 py-2 font-bold">{modalData.total_salary}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-14'>
                                                <div class="text-center flex items-center flex-col">
                                                    <hr class="my-2 w-52 border border-primary-color"></hr>
                                                    <h1 class="mb-4">Employer Signature</h1>
                                                </div>
                                                <div class="text-center flex items-center flex-col">
                                                    <hr class="my-2 w-52 border border-primary-color"></hr>
                                                    <h1 class="mb-4">Employee Signature</h1>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="mt-4 mb-2 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setSlipmodel(false)} >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handleSlip} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default SalaryManagement