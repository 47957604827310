import React, { Fragment, useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Align,
} from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import faker from 'faker';
import { Link } from 'react-router-dom';
import Loader from '../views/Common/Loader';
import Card from '../views/helpers/Card';
import Label from '../views/helpers/Label';
import CustomSelect from '../views/helpers/CustomSelect';
import Validation from '../views/helpers/Validation';
import axiosClient from '../Axios';
import Input from '../views/helpers/Input';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const bar_options = {
    responsive: true,
    plugins: {
        legend: {
            //   position: 'top' as const,
        },
        title: {
            display: true,
            // text: 'Chart.js Bar Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const Bar1 = {
    labels,
    datasets: [
        {
            label: 'Cost Estimate',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#FD9C0C',
        },
        {
            label: 'Site Expenditure',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#00B0F3',
        },
    ],
};



export function NewDashboard() {
    document.title = 'SiteSmart - Dashboard'
    const [articlesCount, setArticlesCount] = useState({
        new_articles: 20,
        approved_articles: 25,
        published_articles: 25,
        rejected_articles: 25,
        draft_and_save_articles: 25
    })
    const [loader, setLoader] = useState(false)
    const [totalSites, setTotalSites] = useState()
    const [soldFlats, SetSoldFlats] = useState();
    const [unSoldFlats, SetUnSoldFlats] = useState();
    const [salesWork, setSalesWork] = useState();
    const [projectWork, setProjectWork] = useState();
    const [expenseWork, setExpenseWork] = useState();

    const [work, setWork] = useState([])
    const [workcount, setWorkCount] = useState([])

    const doughnut1 = {
        // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: 'Completed Sites',

                data: [workcount.Completedworkcount, workcount.incompletedworkcount],
                backgroundColor: [
                    '#38E54D',
                    'rgba(239,239,240, 0.3)',
                ],
                borderColor: [
                    '#38E54D',
                    'rgba(239,239,240, 0.3)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const doughnut2 = {
        // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: ['Ongoing Sites'],
                data: [workcount.incompletedworkcount, workcount.Completedworkcount],
                backgroundColor: [
                    '#CF0A0A',
                    'rgba(239, 239, 240, 0.3)',
                ],
                borderColor: [
                    '#CF0A0A',
                    'rgba(239,239,240, 0.3)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const [barColor, setBarColor] = useState('#6495ED')
    const Bar2 = {
        labels,
        datasets: [
            {
                label: 'Expense',
                data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                backgroundColor: barColor,
                // backgroundColor: '#17c7d3', 


            },
        ],
    };



    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setTotalSites(data.count)
                setWorkCount(data.workcounts)
                setLoader(false)
            })
    }

    const getSalesReport = () => {
        setLoader(true)
        axiosClient.get('/all_flat_count')
            .then(({ data }) => {
                SetSoldFlats(data.all_sold_flat)
                SetUnSoldFlats(data.all_Unsold_flat)
                setLoader(false)
            })
    }

    const getWorkSalesReport = () => {
        setLoader(true)
        salesWork && axiosClient.get(`/work_wise_flatinfo/${salesWork}`)
            .then(({ data }) => {
                SetSoldFlats(data.work_wise_soldflat)
                SetUnSoldFlats(data.work_wise_unsoldflat)
                setLoader(false)
            })
    }
    useEffect(() => {
        getWorkSalesReport();
    }, [salesWork])

    useEffect(() => {
        getSalesReport();
        getWork();
    }, [])
    return (
        <Fragment>
            <section>
                <div className='grid grid-cols-1 md:grid-cols-2 justify-center gap-3'>
                    <div className="origin-center hover:origin-top">
                        <Card className='bg-white h-[100%]'>
                            <div className='p-1 grid grid-cols-1 md:grid-cols-2 gap-1'>
                                <h1 className='text-center font-bold mt-1 pt-2 text-lg uppercase tracking-wider'>Total Sites - {workcount.allworkcount}</h1>
                            </div>
                            <hr className='mt-1 mb-1 border-spacing-1 border-gray-400' />
                            <section className='grid grid-cols-1 md:grid-cols-2'>
                                <div className="pr-14 pl-14">
                                    <h1 className='text-center font-bold mt-2 ml-3 uppercase tracking-wider'>Completed Sites</h1>
                                    <Doughnut data={doughnut1} />
                                </div>
                                <div className="pr-14 pl-14">
                                    <h1 className='text-center font-bold mt-2 ml-3 uppercase tracking-wider'>Ongoing Sites</h1>
                                    <Doughnut data={doughnut2} />
                                </div>
                            </section>
                        </Card>
                    </div>
                    <Card className='bg-white h-[100%]'>
                        <div className='p-1 grid grid-cols-1 md:grid-cols-2 gap-1'>
                            <h1 className='text-center font-bold mt-1 pt-2 text-lg uppercase tracking-wider'>Sales Report</h1>
                            <div>
                                <CustomSelect id={'salesWork'} name={'salesWork'} value={salesWork} onChange={(e) => setSalesWork(e.target.value)} className={'w-full'}>
                                    <option value={''}>--- Select Work ---</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                        </div>

                        <hr className='mt-1 mb-1 border-spacing-1 border-gray-400' />
                        <section className='pr-48 pl-48'>
                            <Pie
                                data={{
                                    labels: [
                                        'Sold Flats',
                                        'UnSold Flats',
                                    ],
                                    datasets: [{

                                        responsive: true,
                                        Align: 'right',
                                        data: [
                                            soldFlats,
                                            unSoldFlats,
                                        ],
                                        backgroundColor: [
                                            '#FD9C0C',
                                            '#2192FF',
                                        ],
                                        hoverOffset: 4
                                    }]
                                }} />
                        </section>
                    </Card>
                    <Card className='bg-white h-[100%]'>
                        <div className='p-1 grid grid-cols-1 md:grid-cols-2 gap-1'>
                            <h1 className='text-center mt-1 ml-3 text-lg font-bold uppercase tracking-wider'>Project Details</h1>
                            <div>
                                <CustomSelect id={'projectWork'} name={'projectWork'} value={projectWork} onChange={(e) => setProjectWork(e.target.value)} className={'w-full'}>
                                    <option value={''}>--- Select Work ---</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                        </div>
                        <hr className='border-spacing-1 border-gray-400' />
                        <section className='pr-36 pl-48'>
                            {/* <Bar options={bar_options} data={Bar1} /> */}
                            <Pie
                                data={{
                                    // clip: {left: 5, top: false, right: -2, bottom: 0},
                                    // circumference: 80,
                                    labels: [
                                        'Received Amount',
                                        'Expense',
                                        'Purchase',
                                        'Contractor',
                                        'Labour Wages',
                                        'Balance',
                                    ],
                                    datasets: [{
                                        responsive: true,
                                        data: [
                                            300000,
                                            100000,
                                            217000,
                                            200000,
                                            80000,
                                            700000
                                        ],
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.5)',
                                            '#00B0F3',
                                            '#1EAA73',
                                            '#FFC008',
                                            '#012060',
                                            '#CF0A0A'
                                        ],
                                        hoverOffset: 4
                                    }]
                                }} />
                        </section>
                    </Card>
                    <Card className='bg-white h-[100%]'>
                        <div className='p-1 grid grid-cols-1 md:grid-cols-3 gap-1'>
                            <h1 className='text-center text-lg mt-1 mb-2 ml-3 font-bold uppercase tracking-wider'>Total Expense</h1>
                            <div className='col-span-'>
                                <CustomSelect id={'expenseWork'} name={'expenseWork'} value={expenseWork} onChange={(e) => setExpenseWork(e.target.value)} className={'w-full'}>
                                    <option value={''}>--- Select Work ---</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3">
                                <div className=""></div>
                                <Input id={'barColor'} type={'color'} name={'barColor'} className={'h-10'} value={barColor} onChange={(e) => setBarColor(e.target.value)} />
                                <div className=""></div>
                            </div>
                        </div>
                        <hr className='border-spacing-1 border-gray-400' />
                        <section className='pr-16 pl-16'>
                            <Bar options={bar_options} data={Bar2} />
                        </section>
                    </Card>
                </div>
            </section>
        </Fragment>
    )
}
