import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { PrinterIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect';
import moment from 'moment'
import { useReactToPrint } from "react-to-print";
import Card from '../helpers/Card'
// import Pagination from '../helpers/Pagination';

function TaskReport() {
    document.title = 'SiteSmart - Task Report '
    const tableHeading2 = ['Sr.No', 'Activity Name', 'Activity Description ', 'Start Date', 'Due Date'];
    const [loader, setLoader] = useState(false)
    // const [modalData, setModalData] = useState([]);
    const [reportList, setReportList] = useState('')
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 1;
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const getTaskData = () => {
        setLoader(true)
        axiosClient.get('/task_Details')
            .then(({ data }) => {
                setReportList(data.lists)
            })
        setFormInfo({
            ...formInfo,
            wing_name: '',
            level: ''
        })
    }

    const getAllData = () => {
        formInfo.work_name && setLoader(true)
        formInfo.work_name && formInfo.work_name == 'All' ? (getTaskData()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // const getModalData = (id) => {
    //     setLoader(true)
    //     axiosClient.get(`/single_task_data/${id}`)
    //         .then(({ data }) => {
    //             setModalData((prevModalData) => ({ ...prevModalData, [id]: data.Mulitisubtasks }));
    //             setLoader(false)
    //         });
    // };

    // const [fetchedModalData, setFetchedModalData] = useState(false);
    // const getModalDataForAllRows = async () => {

    //     try {
    //         const promises = reportList.map((rowData) => getModalData(rowData.id));
    //         await Promise.all(promises);
    //         setFetchedModalData(true);
    //     } catch (error) {
    //         console.error("Error fetching modal data:", error);
    //     }
    // };

    const getWorkWiseList = () => {
        setLoader(true)
        formInfo.work_name && axiosClient.get(`/work_wise_taskdata/${formInfo.work_name}`)
            .then(({ data }) => {
                setLoader(false)
                setReportList(data.work_wise_taskdata)
            })
    }

    const getWingWiseList = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_taskdata/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setReportList(data.work_wing_wise_taskdata)
            })
    }

    const getLevelWiseList = () => {
        setLoader(true)
        formInfo.work_name && formInfo.wing_name && formInfo.level && axiosClient.get(`/work_wing_level_wise_taskdata/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setLoader(false)
                setReportList(data.work_wing_level_wise_taskdata)
            })
    }
    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
            })
    }
    const [wing, setWing] = useState([])
    const getWingList = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelList = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    useEffect(() => {
        getWingList()
        getWorkWiseList()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelList()
        getWingWiseList()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        getLevelWiseList()
    }, [formInfo.level])

    useEffect(() => {
        getAllData();
    }, [formInfo.work_name])

    useEffect(() => {
        getTaskData()
        getWork();
    }, [])

    // useEffect(() => {
    //     getModalDataForAllRows();
    // }, [reportList]);

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Task Report'}>
                    <form>
                        <div className='grid grid-cols-1 md:grid-cols-4 gap-x-4 mt-4 mb-2 '>
                            <div className=''>
                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Work ---</option>
                                    <option value={"All"}>All</option>
                                    {work.map((workData, workId) => {
                                        return (<Fragment key={workId}>
                                            <option value={workData.id}>{workData.work_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                            <div >
                                <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Wing ---</option>
                                    {wing && wing.map((wingData, wingId) => {
                                        return (<Fragment key={wingId}>
                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                            <div >
                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                    <option disabled value={''}>--- Select Level ---</option>
                                    {level && level.map((levelData, levelId) => {
                                        return (<Fragment key={levelId}>
                                            <option value={levelData.level}>{levelData.level_name}</option>
                                        </Fragment>)
                                    })}
                                </CustomSelect>
                            </div>
                            <div className='ml-2 flex justify-end pt-8'>
                                <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                            </div>
                        </div>
                    </form>
                    <div ref={componentRef} className='flex items-center justify-center '>
                        <Card className={' md:w-[100%] bg-default-color'}>
                            {
                                reportList && reportList.length > 0 ? (
                                    <div>
                                        {reportList.map((data, ID) => {
                                            return (<Fragment key={ID}>
                                                <h1 className='text-center uppercase font-bold text-2xl mt-4 tracking-widest'>{data.work_name}</h1>
                                                <hr className='mt-4 mx-4 border border-primary-color' />
                                                <div className='grid grid-cols-1 md:grid-cols-3 mt-4 gap-x-1 gap-y-2 divide-x p-4'>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Work Name </h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Wing Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.wing_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Level</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.level_name}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Task Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.task_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Contractor Name</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.contractor_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Status</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{data.status}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-md">
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Start Date</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{moment(data.start_date).format('DD-MM-YYYY')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-1 md:grid-cols-3 mb-4'>
                                                            <h1 className='flex justify-center font-bold'>Due Date</h1>
                                                            <h1 className='col-span-2 flex justify-center'>{moment(data.due_date).format('DD-MM-YYYY')}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {data.Mulitisubtasks && data.Mulitisubtasks.length > 0 ? (
                                                        <div className="px-4">
                                                            <div className='w-[60vw] md:w-full'>
                                                            {
                                                                <Table className='rounded-lg shadow-md bg-primary-color' tableHeading={tableHeading2}>
                                                                    {data.Mulitisubtasks && data.Mulitisubtasks.map((modalTableData, modalTableDataID) => {
                                                                        return (<Fragment key={modalTableDataID}>
                                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                                <td className="px-4 py-3">{(ID + 1) + ((modalTableDataID + 1) / 10)}</td>
                                                                                <td className="px-4 py-3">{modalTableData.activity_name}</td>
                                                                                <td className="px-4 py-3">{modalTableData.activity_description}</td>
                                                                                <td className="px-4 py-3">{moment(modalTableData.activity_start_date).format('DD-MM-YYYY')}</td>
                                                                                <td className="px-4 py-3 whitespace-nowrap">{moment(modalTableData.activity_due_date).format('DD-MM-YYYY')}</td>
                                                                            </tr>
                                                                        </Fragment>)
                                                                    })}
                                                                </Table>
                                                            }
                                                        </div>
                                                        </div>

                                                    ) : (
                                                        <div className='flex justify-center items-center h-[15vh]'>
                                                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Sub Task Data Not Found</p>
                                                        </div>
                                                    )}
                                                </div>

                                                <hr className='mt-4 border-4 color bg-gray-200 ' />
                                            </Fragment>)
                                        })}
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-[68vh] bg-gray-200'>
                                        <p className='text-3xl font-bold'>Sorry!Task Data Not Found</p>
                                    </div>
                                )
                            }
                        </Card>
                    </div>
                    {/* {reportList && reportList.length > 1 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={reportList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    } */}
                </PageComponent >
            )
            }
        </Fragment >
    )
}
export default TaskReport