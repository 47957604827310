import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../../helpers/Card'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import flasher from '@flasher/flasher'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import LinkButton from '../../helpers/LinkButton'
import Button from '../../helpers/Button'

function AddFloorDetails() {
    const { id, work_name, work_id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Floor'
    } else {
        document.title = 'SiteSmart - Add New Floor'
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const [floorfileImage, setFloorFileImage] = useState('');
    const floorFileChange = (e) => {
        setFloorFileImage(e.target.files[0]);
    }
    const [workname, setWorkName] = useState("")

    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
            })
    }

    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        no_of_levels: '',
        refugee_area: '',
        level: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    // const [wingNameNew, setWingNameNew] = useState('');
    // const [noOfFloors, setNoOfFloors] = useState([]);
    // const ff = [];
    // const handleWingChange = (e) => {
    //     setNoOfFloors([])
    //     axiosClient.get(`getFloorLevelData/${e.target.value}`)
    //     .then(({data})=>{
    //         for(var i=1;i<=data.data.wingDetails.no_of_levels;i++){
    //             ff.push(i);
    //         }
    //         setNoOfFloors(ff);
    //         setWingNameNew(data.data.wingDetails.id)
    //     })
    // }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', workname.id)
        formData.append('wing_name', formInfo.wing_name)
        formData.append('no_of_levels', formInfo.no_of_levels)
        formData.append('level', formInfo.level)
        formData.append('refugee_area', formInfo.refugee_area)
        formData.append('upload_floor_file', floorfileImage)
        axiosClient.post(id ? `update_floor_details/${id}` : '/store_floor_details', formData)
            .then(({ data }) => {
                setLoader(false)
                data.status ? flasher.success(data.msg) :
                    flasher.warning(data.msg)
                data.status ? navigate(`/work-management/view-floor-details/${work_id}`) : navigate(`/work-management/add-floor-details-form/${work_id}`)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    const [level, setLevel] = useState([]);
    const getLevelData = () => {
        axiosClient.get('/levelmaster_lists')
            .then(({ data }) => {
                setLoader(false)
                setLevel(data.lists)
            })
    }
    const getSingleFloorData = () => {
        setLoader(true)
        axiosClient.get(`/single_floor_details_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.view_data)
                setFloorFileImage(data.single_data.upload_floor_file)
            })
    }
    useEffect(() => {
        getWingData();
    }, [work_id])

    useEffect(() => {
        if (id) {
            getSingleFloorData();
        }
        getLevelData();
        getWorkDetails();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Floor' : 'Add New Floor'} button={
                    <LinkButton to={`/work-management/view-floor-details/${work_id}`}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                            {workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />

                            ) : (
                                workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />

                            )
                            }
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                            <div className='flex items-center justify-center'>
                                <Card className="w-[60rem] p-4 bg-default-color ">
                                    <form onSubmit={handleSubmit} className='mt-4'>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true} />
                                                <Validation error={errors.work_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose Wing Name ---</option>
                                                    {wing && wing.map((wingData, wingId) => {
                                                        return (<Fragment key={wingId}>
                                                            <option value={wingData.id}>{wingData.wing_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div>
                                            {/* <div className='mb-3'>
                                                <Label htmlFor={'no_of_levels'} labelText={'No. Of Levels'} className={'mb-1'} />
                                                <CustomSelect id={'no_of_levels'} name={'no_of_levels'} value={formInfo.no_of_levels} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose Level No ---</option>
                                                    {noOfFloors.map((data,id)=>{
                                                        return(<Fragment key={id}>
                                                            <option value={data}>{data}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                            </div> */}
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'refugee_area'} labelText={'Refugee Area'} className={'mb-1'} />
                                                <CustomSelect id={'refugee_area'} name={'refugee_area'} value={formInfo.refugee_area} onChange={handleChange}>
                                                    <option disabled value={''}>--- Choose Refugee Area ---</option>
                                                    <option value={'Yes'}>Yes</option>
                                                    <option value={'No'}>No</option>
                                                </CustomSelect>
                                                <Validation error={errors.refugee_area} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                                <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-full'}>
                                                    <option disabled value={''}>--- Choose level Name ---</option>
                                                    {level.map((levelData, levelId) => {
                                                        return (<Fragment key={levelId}>
                                                            <option value={levelData.id}>{levelData.level_name}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.level} />
                                            </div>
                                        </div>
                                        <div>
                                            <Label htmlFor={'upload_floor_file'} labelText={'Upload Floor CAD File'} />
                                            <img src={floorfileImage ? (
                                                floorfileImage.name ? URL.createObjectURL(floorfileImage) : IMG_URL + "/assets/images/FloorCADFile/" + floorfileImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Floor-File" className='w-80 h-64  rounded' />
                                            <Input id={'upload_floor_file'} type={'file'} name={'upload_floor_file'} onChange={floorFileChange} className={'p-[0px!important] mt-1'} />
                                            <Validation error={errors.upload_floor_file} />
                                        </div>
                                        <Button className={'w-60 mx-auto mt-9'}>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                            <span className='ml-5'>Save Now</span>
                                        </Button>
                                    </form>
                                </Card>
                            </div>
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default AddFloorDetails
