import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'

function GroupMaster() {
    document.title = 'SiteSmart - Group Master'
    const tableHeading = ['Sr.No', 'Group Name', 'Action']
    const [loader, setLoader] = useState(false)
    const [groupmasterLists, setGroupMasterLists] = useState('')
    const getGroupMasterlist = () => {
        setLoader(true)
       axiosClient.get('/groupmaster_list')
            .then(({ data }) => {
                setLoader(false)
                setGroupMasterLists(data.lists)
            })
    }

    const deleteGroupMaster = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_groupmaster/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getGroupMasterlist()
                    })
            }
        })
    }
    useEffect(() => {
        getGroupMasterlist()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Group Master'} button={
                    <LinkButton to={'/accounting/group-master/add-group-master'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add Group</span>
                    </LinkButton>
                 }>
                    <h1>{groupmasterLists.sub_groupmaster}</h1>
                    {groupmasterLists && groupmasterLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {groupmasterLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.group_name}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link title='Edit' to={`/accounting/group-master/edit-group-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteGroupMaster(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5'/></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Group Master Data Not Found</p>
                        </div>
                    )}
                </PageComponent>
            )}
        </Fragment>
    )
}
export default GroupMaster
