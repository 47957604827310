import React, { Fragment } from 'react'

function Label({htmlFor,className,labelText}) {
    return (
        <Fragment>
            <label htmlFor={htmlFor} className={"block font-bold text-sm leading-6 text-gray-900 ml-1 "+(className)}>{labelText}</label>
        </Fragment>
    )
}

export default Label