import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import Button from '../helpers/Button'
import ActionButton from '../helpers/ActionButton'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import ViewModel from '../helpers/ViewModel'
import Input from '../helpers/Input'
import Pagination from '../helpers/Pagination';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MaterialMaster() {
    document.title = 'SiteSmart - Material Master'
    const tableHeading = ['Sr.No', 'Material', 'Unit', 'Action']
    const [loader, setLoader] = useState(false)
    const [materialLists, setMaterialLists] = useState('')
    const [materialCsv, setMaterialCsv] = useState('')
    const [viewModel, setViewModel] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const getMaterialLists = () => {
        setLoader(true)
        axiosClient.get('/material_lists')
            .then(({ data }) => {
                setLoader(false)
                setMaterialLists(data.lists.sort((a, b) => a.material_name.localeCompare(b.material_name)));
                // setMaterialLists(result)
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Material', key: 'material_name' },
        { label: 'Unit', key: 'material_unit' },
    ];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleMaterialCsv = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('materialCsv', materialCsv)
        axiosClient.post(`import_material`, formData)
            .then(({ data }) => {
                flasher.success(data.msg)
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
            })
        setViewModel(false)
        getMaterialLists()
    }

    const deleteMaterial = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_material/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getMaterialLists()
                    })
            }
        })
    }
    useEffect(() => {
        getMaterialLists()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Material Master'} button={
                    <LinkButton to={'/material-master/add-material-master'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Material</span>
                    </LinkButton>
                }>
                    <div className="flex justify-end mb-2">
                        <Menu as="div">
                            <div className="flex justify-end">
                                <Menu.Button >
                                    <span className="sr-only">Open user menu</span>
                                    <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                </Menu.Button>
                            </div>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={() => setViewModel(true)} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item >
                                        {({ active }) => (
                                            <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <div className="px-2 py-2">
                                            <CSVLink data={materialLists} headers={headers} filename={'Material List.csv'}>
                                                <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                            </CSVLink>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                    {materialLists && materialLists.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {materialLists.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.material_name}</td>
                                                <td className="px-4 py-3">{tableData.material_unit}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link title='Edit' to={`/material-master/edit-material-master/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 '}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                        <ActionButton onClick={() => deleteMaterial(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Materials Data Not Found</p>
                        </div>
                    )}
                    {materialLists && materialLists.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={materialLists.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                <Fragment>
                                    <div className='m-5'>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Import CSV File</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <h1 className='text-lg font-bold'>Note :-</h1>
                                        <div className='m-y-4 pl-5'>
                                            <code className='block'><span className='font-semibold'>1.</span>The Excel File must include min 2 column's</code>
                                            <code className='block'><span className='font-semibold'>2.</span>First Column heading should be "material"</code>
                                            <code className='block'><span className='font-semibold'>3.</span>Second Column heading should be "unit"</code>
                                            <code className='block'><span className='font-semibold'>4.</span>Serial No. column is optional.</code>
                                            <code className='block'><span className='font-semibold'>5.</span>The file format should be .csv or .xls</code>
                                        </div>
                                    </div>
                                    <Input id={'materialCsv'} type={'file'} name={'materialCsv'} onChange={(e) => setMaterialCsv(e.target.files[0])} className={'p-[0px!important] mt-2 mb-2'} />
                                    <div className="mt-4 grid grid-cols-3">
                                        <Button onClick={() => handleMaterialCsv()} className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Fragment>
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default MaterialMaster