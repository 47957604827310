import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import TextArea from '../helpers/TextArea'
import flasher from '@flasher/flasher'

function ContractorMasterAddEdit({ }) {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Contractor'
    } else {
        document.title = 'SiteSmart - Add New Contractor'
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        contractor_name: '',
        address: '',
        firm_name: '',
        work_type: '',
        mobile_no: '',
        email: '',
        aadhar_card: '',
        pan_card: '',
        gst_no: '',
        bank_account_name: '',
        bank_account_no: '',
        bank_branch: '',
        ifsc_code: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('contractor_name', formInfo.contractor_name)
        formData.append('firm_name', formInfo.firm_name)
        formData.append('address', formInfo.address)
        formData.append('mobile_no', formInfo.mobile_no)
        formData.append('email', formInfo.email)
        formData.append('aadhar_card', formInfo.aadhar_card)
        formData.append('pan_card', formInfo.pan_card)
        formData.append('gst_no', formInfo.gst_no)
        formData.append('bank_account_name', formInfo.bank_account_name)
        formData.append('bank_account_no', formInfo.bank_account_no)
        formData.append('bank_branch', formInfo.bank_branch)
        formData.append('ifsc_code', formInfo.ifsc_code)
        formData.append('work_type', formInfo.work_type)

        axiosClient.post(id ? `update_Contractormaster/${id}` : '/store_Contractormaster', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/contractor-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const [worktype, setWorktype] = useState('')
    const getWorktype = () => {
        setLoader(true)
        axiosClient.get(`/worktype_list`)
            .then(({ data }) => {
                setWorktype(data.lists)
                setLoader(false)
            })
    }

    const getSingleContractorData = () => {
        setLoader(true)
        axiosClient.get(`/single_Contractormaster_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleContractorData()
        }
        getWorktype();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Contractor' : 'Add New Contractor'} button={
                    <LinkButton to={'/contractor-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-[60rem] px-6 py-4 bg-default-color '}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'contractor_name'} labelText={'Contractor Name'} className={'mb-1'} />
                                        <Input id={'contractor_name'} type={'text'} name={'contractor_name'} value={formInfo.contractor_name} onChange={handleChange} />
                                        <Validation error={errors.contractor_name} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'mobile_no'} labelText={'Mobile No.'} className={'mb-1'} />
                                        <Input id={'mobile_no'} type={'text'} name={'mobile_no'} value={formInfo.mobile_no} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.mobile_no} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'firm_name'} labelText={'Firm Name'} className={'mb-1'} />
                                        <Input id={'firm_name'} type={'text'} name={'firm_name'} value={formInfo.firm_name} onChange={handleChange} />
                                        <Validation error={errors.firm_name} />
                                    </div>





                                    <div className='mb-3'>
                                        <Label htmlFor={'work_type'} labelText={'Work Type'} className={'mb-1'} />
                                        <CustomSelect id={'work_type'} name={'work_type'} value={formInfo.work_type} onChange={handleChange}>
                                            <option value={''}>--- Select Work Type ---</option>
                                            {/* {worktype && worktype.map(data => {
                                                return (
                                                    <option value={data.id}>{data.work_type}</option>
                                                )
                                            })
                                            } */}
                                            {worktype && worktype.map((worktypeData, worktypeId) => {
                                                return (<Fragment key={worktypeId}>
                                                    <option value={worktypeData.id}>{worktypeData.work_type}</option>
                                                </Fragment>)
                                            })}


                                        </CustomSelect>
                                        <Validation error={errors.work_type} />
                                    </div>
                                    <div className='mb-3 mt-1'>
                                        <Label htmlFor={'address'} labelText={'Address'} classaddress={'mb-1'} />
                                        <TextArea id={'address'} type={'text'} name={'address'} rows={1} value={formInfo.address} onChange={handleChange} />
                                        <Validation error={errors.address} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'email'} labelText={'Email'} className={'mb-1'} />
                                        <Input id={'email'} type={'email'} name={'email'} value={formInfo.email} onChange={handleChange} />
                                        <Validation error={errors.email} />
                                    </div>
                                    <div >
                                        <Label htmlFor={'aadhar_card'} labelText={'Aadhar Card No.'} className={'mb-1'} />
                                        <Input id={'aadhar_card'} type={'text'} name={'aadhar_card'} value={formInfo.aadhar_card} onChange={handleChange} maxLength={12} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.aadhar_card} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'pan_card'} labelText={'Pan Card'} className={'mb-1'} />
                                        <Input id={'pan_card'} type={'text'} name={'pan_card'} value={formInfo.pan_card} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.pan_card} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'gst_no'} labelText={'GST No.'} className={'mb-1'} />
                                        <Input id={'gst_no'} type={'text'} name={'gst_no'} value={formInfo.gst_no} onChange={handleChange} maxLength={15} />
                                        <Validation error={errors.gst_no} />
                                    </div>
                                </div>
                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                                <div className='mb-4'>
                                    <span className='text-xl font-light'>Bank Account details :</span>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mb-3 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'bank_account_name'} labelText={'Bank Name'} className={'mb-1'} />
                                        <Input id={'bank_account_name'} type={'text'} name={'bank_account_name'} value={formInfo.bank_account_name} onChange={handleChange} maxLength={50} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.bank_account_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'bank_branch'} labelText={'Bank Branch'} className={'mb-1'} />
                                        <Input id={'bank_branch'} type={'text'} name={'bank_branch'} value={formInfo.bank_branch} onChange={handleChange} />
                                        <Validation error={errors.bank_branch} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'bank_account_no'} labelText={'Bank Account No.'} className={'mb-1'} />
                                        <Input id={'bank_account_no'} type={'text'} name={'bank_account_no'} value={formInfo.bank_account_no} onChange={handleChange} maxLength={16} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.bank_account_no} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'ifsc_code'} labelText={'IFSC Code'} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default ContractorMasterAddEdit