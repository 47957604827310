import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ArrowLeftCircleIcon, PhotoIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { Link, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Card from '../helpers/Card'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import ViewModel from '../helpers/ViewModel'
import { useStateContext } from '../../context/ContextProvider'
import { Dialog } from '@headlessui/react'
import Swal from 'sweetalert2'
import { useReactToPrint } from 'react-to-print'
import PleaseWait from '../Common/PleaseWait'

function ApproveQuotation() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Purchase Order'
        ) : (
            document.title = 'SiteSmart - Add Purchase Order'
        )
    }
    const [loader, setLoader] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [supplier, setSupplier] = useState([])
    const [quotationList, setQuotationList] = useState([])
    const [modelImage, setModelImage] = useState(false)


    const tableHeading3 = ['Sr.No', 'Supplier', 'Material', 'Unit', 'Quantity', 'Price', 'GST', 'Total']

    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const getQuotationList = () => {
        setLoader(true)
        axiosClient.get(`/po_quotation_wise_supplier_lists/${id}`)
            .then(({ data }) => {
                setQuotationList(data.lists)
                setLoader(false)
            })
    }

    const getSupplierList = () => {
        setLoader(true)
        axiosClient.get(`/supplier_lists`)
            .then(({ data }) => {
                setLoader(false)
                setSupplier(data.lists)
            })
    }

    const handleModelImage = (image, foldername) => {
        setModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setModalLoader(false)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleApprove = (id) => {
        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: '42',
            message: 'Quotation Approved successfully...',
            module: 'Quotation',
            link: '/quotation',
        }
        Swal.fire({
            title: 'Are you sure to Approve this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approve it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.get(`/po_quotation_update_status/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getQuotationList();
                    })
                axiosClient.post(`store_Notification`, notification)
                    .then(({ data }) => {
                        setLoader(false)
                    })
                    .catch(({ response }) => {
                        setLoader(false)
                    })
            }
        })
    }

    useEffect(() => {
        getQuotationList();
        getSupplierList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Approve Quotation' : 'Approve Quotation'} button={
                    <LinkButton to={'/quotation'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-x-1 mb-2 '>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=''></div>
                        <div className=' flex justify-center pt-2'>
                            <Link onClick={handlePrint} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2 " ><PrinterIcon className='w-5 h-5' /></Link>
                        </div>
                    </div>
                    <div ref={componentRef} className='flex items-center justify-center mt-4 mb-4'>
                        <Card className={'w-[95%] p-6  bg-default-color'}>
                            {quotationList && quotationList.length > 0 ? (
                                <div>
                                    {
                                        quotationList && quotationList.map((data) => {
                                            return (
                                                <div className="mb-4">
                                                    <div className='grid grid-cols-1 md:grid-cols-2 '>
                                                        <div className='font-bold text-xl '>
                                                            {
                                                                supplier && supplier.map((data2, id) => {
                                                                    return (
                                                                        <Fragment key={id}>
                                                                            {data2.id == data.supplier_name && <h1>{data2.supplier_name} Quotation</h1>}
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="grid grid-cols-1 md:grid-cols-2">
                                                            <div></div>
                                                            <div className='flex justify-end'>
                                                                {data.po_quotation_aproved === null ? (
                                                                    <button onClick={() => handleApprove(data.id)} className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Not Approve</button>
                                                                ) : (
                                                                    <button onClick={''} className='border-2 bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Approve</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 mt-4 gap-1 divide-x p-4'>
                                                        <div className="shadow-md">
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                                <h1 className='flex justify-center font-bold'>Delivery Charges -</h1>
                                                                <h1 className=' flex justify-center'>{data.delivery}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                                <h1 className='flex justify-center font-bold'>Loading Charges</h1>
                                                                <h1 className=' flex justify-center'>{data.loading}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="shadow-md">
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                                <h1 className='flex justify-center font-bold'>Other Charges -</h1>
                                                                <h1 className=' flex justify-center'>{data.other}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                                <h1 className='flex justify-center font-bold'>Expected Delivery Date</h1>
                                                                <h1 className=' flex justify-center'>{data.date}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="shadow-md">
                                                            <div className='grid grid-cols-1 md:grid-cols-2 mb-4'>
                                                                <h1 className='flex justify-center font-bold'>Grand Total -</h1>
                                                                <h1 className=' flex justify-center'>{data.grand_total}</h1>
                                                            </div>
                                                            <div className='mb-4 px-2 flex justify-end'>
                                                                <Button className='text-default-color' data-modal-target="defaultModal" onClick={() => handleModelImage(data.supplierQuotationImg, 'owneradharCard')} data-modal-toggle="defaultModal" type="button">
                                                                    <PhotoIcon className='w-5 h-5 pr-1 text-default-color' /> Quotation Image
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4 w-full'>
                                                        {data.material_lists && data.material_lists.length > 0 ? (
                                                            <Table tableHeading={tableHeading3}>
                                                                {data.material_lists.map((tableData, index) => {
                                                                    return (<Fragment key={index}>
                                                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                            <td>{index + 1}</td>
                                                                            <td className="px-4 py-3">
                                                                                {tableData.supplier_name}
                                                                            </td>
                                                                            <td className="px-4 py-3"> {tableData.material_name}
                                                                            </td>
                                                                            <td className="px-4 py-3"> {tableData.material_unit}
                                                                            </td>
                                                                            <td className="px-4 py-3"> {tableData.quantity}
                                                                            </td>
                                                                            <td className="px-4 py-3"> {parseInt(tableData.unit_price).toLocaleString('en-IN')}
                                                                            </td>
                                                                            <td className="px-4 py-3"> {tableData.purchase_gst}
                                                                            </td>
                                                                            <td className='px-4 py-3' >{parseInt(tableData.total).toLocaleString('en-IN')}</td>
                                                                        </tr>
                                                                    </Fragment>)
                                                                })}
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td className='px-4 py-3 font-bold'>Total</td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className="px-4 py-3"></td>
                                                                    <td className='px-4 py-3 font-bold'>{parseInt(data.totalSum).toLocaleString('en-IN')}</td>
                                                                </tr>
                                                            </Table>
                                                        ) : (
                                                            <div className='flex justify-center items-center h-[20vh]'>
                                                                <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Material Data Not Found</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>) : (
                                <div className='flex justify-center items-center h-[20vh]'>
                                    <p className='text-3xl font-bold'>Please Add Quotation !</p>
                                </div>
                            )
                            }
                        </Card>
                    </div>

                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>View Full Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={modelImage ? (
                                                modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/PurchaseOrder/Quotation/` + modelImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Quotation-img" className='w-full h-full rounded' />
                                        }
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center mr-3 rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}


export default ApproveQuotation



