import React, { Fragment, useEffect, useState } from 'react'
import { EyeIcon, ForwardIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination'



function Quotation() {
    document.title = 'SiteSmart - Quotation'
    const tableHeading = ['Sr.No', 'Work Name', 'Pro Date', 'Approval Status', 'Action']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [quotationList, setQuotationList] = useState('')
    const getQuotationList = () => {
        setLoader(true)
        axiosClient.get('/purchase_Order_lists/')
            .then(({ data }) => {
                setQuotationList(data.lists)
                setLoader(false)
            })
    }

    useEffect(() => {
        getQuotationList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Quotation'}>
                    {quotationList && quotationList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {quotationList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3"> {tableData.purchase_status_aproved === null ? (
                                            <Link to={`/purchase/approve-quotation/${tableData.id}`} className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-5 py-1.5 rounded-lg'>Not Approve</Link>
                                        ) : (
                                            <button className='border-2 bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Approve</button>
                                        )}
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link title='View' to={`/purchase/quotation-list/${tableData.id}`}><ActionButton className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton></Link>
                                                {tableData.purchase_status_aproved === null ? (
                                                    <Link title='Edit' to={`/quotation/edit-quotation/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                ) : (
                                                    <Link title='Forward' to={`/purchase-order`}><ActionButton className={'p-1.5 text-white bg-teal-600 rounded-lg'}><ForwardIcon className='w-5 h-5' /></ActionButton></Link>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Purchase Order Data Not Found</p>
                        </div>
                    )}
                  {quotationList && quotationList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={quotationList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default Quotation
