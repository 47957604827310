import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'

function CompanyMasterAddEdit(){
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Employee'
        ) : (
            document.title = 'SiteSmart - Add New Employee'
        )
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        name: '',
        ownername: '',
        co_owner: '',
        address: '',
        contact_no: '',
        co_owner_contact_no:'',
        reg_no: "",
        gst_no: '',
        pan_no: '',
        bank_name: '',
        bank_acc: '',
        bank_branch: '',
        ifsc_code: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('name', formInfo.name)
        formData.append('ownername', formInfo.ownername)
        formData.append('co_owner', formInfo.co_owner)
        formData.append('co_owner_contact_no', formInfo.co_owner_contact_no)
        formData.append('contact_no', formInfo.contact_no)
        formData.append('reg_no', formInfo.reg_no)
        formData.append('address', formInfo.address)
        formData.append('gst_no', formInfo.gst_no)
        formData.append('pan_no', formInfo.pan_no)
        formData.append('bank_name', formInfo.bank_name)
        formData.append('bank_acc', formInfo.bank_acc)
        formData.append('bank_branch', formInfo.bank_branch)
        formData.append('ifsc_code', formInfo.ifsc_code)
        axiosClient.post(id ? `update_company/${id}` : '/store_company', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/company-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleCompanyData = () => {
        setLoader(true)
        axiosClient.get(`/single_company_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleCompanyData()
        }
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Company' : 'Add New Company'} button={
                    <LinkButton to={'/company-master'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[50rem] p-6 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'name'} labelText={'Company Name'} className={'mb-1'} />
                                        <Input id={'name'} type={'text'} name={'name'} value={formInfo.name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'reg_no'} labelText={'Registration Number'} className={'mb-1'} />
                                        <Input id={'reg_no'} type={'text'} name={'reg_no'} value={formInfo.reg_no} onChange={handleChange} />
                                        <Validation error={errors.reg_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'gst_no'} labelText={'GST Number'} className={'mb-1'} />
                                        <Input id={'gst_no'} type={'text'} name={'gst_no'} value={formInfo.gst_no} onChange={handleChange} 
                                            maxLength={16} />
                                        <Validation error={errors.gst_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ownername'} labelText={'Owner Name'} className={'mb-1'} />
                                        <Input id={'ownername'} type={'text'} name={'ownername'} value={formInfo.ownername} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.ownername} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'contact_no'} labelText={'Owner Contact No'} className={'mb-1'} />
                                        <Input id={'contact_no'} type={'text'} name={'contact_no'} value={formInfo.contact_no} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.contact_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'pan_no'} labelText={'PAN Number'} className={'mb-1'} />
                                        <Input id={'pan_no'} type={'text'} name={'pan_no'} value={formInfo.pan_no} onChange={handleChange}
                                            maxLength={10} />
                                        <Validation error={errors.pan_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'address'} labelText={'Address'} className={'mb-1'} />
                                        <Input id={'address'} type={'text'} name={'address'} value={formInfo.address} onChange={handleChange} />
                                        <Validation error={errors.address} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'co_owner'} labelText={'Co-Owner Name'} className={'mb-1'} />
                                        <Input id={'co_owner'} type={'text'} name={'co_owner'} value={formInfo.co_owner} onChange={handleChange}  onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.co_owner} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'co_owner_contact_no'} labelText={'Co-Owner Contact No'} className={'mb-1'} />
                                        <Input id={'co_owner_contact_no'} type={'text'} name={'co_owner_contact_no'} value={formInfo.co_owner_contact_no} onChange={handleChange} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.co_owner_contact_no} />
                                    </div>
                                </div>
                                <h1 className='font-bold  text-xl mb-4 '>Bank Details:</h1>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'bank_name'} labelText={'Bank Name'} className={'mb-1'} />
                                        <Input id={'bank_name'} type={'text'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.bank_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_branch'} labelText={'Bank Branch'} className={'mb-1'} />
                                        <Input id={'bank_branch'} type={'text'} name={'bank_branch'} value={formInfo.bank_branch} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }}/>
                                        <Validation error={errors.bank_branch} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_acc'} labelText={'Bank Account No'} className={'mb-1'} />
                                        <Input id={'bank_acc'} type={'text'} name={'bank_acc'} value={formInfo.bank_acc} onChange={handleChange} maxLength={16} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.bank_acc} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'ifsc_code'} labelText={'IFSC Code '} className={'mb-1'} />
                                        <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.ifsc_code} />
                                    </div>
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}


export default CompanyMasterAddEdit
