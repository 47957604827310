import React, { useRef, Fragment, useEffect, useState } from "react";
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axiosClient from "../../Axios";
import flasher from "@flasher/flasher";
import PageComponent from "../../components/PageComponent";
import Button from "../helpers/Button";
import LinkButton from "../helpers/LinkButton";
import Loader from "../Common/Loader";
import ViewModel from "../helpers/ViewModel";
import Table from "../helpers/Table";
import { Dialog } from "@headlessui/react";
import moment from 'moment'
import { useReactToPrint } from "react-to-print";
import ActionButton from "../helpers/ActionButton";
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function DailyWages() {
  document.title = "SiteSmart - Daily Wages";
  const tableHeadings = ["Sr.No", "Team Leader", "Work Name", "Wing", "Total Labours Worked", ' Total Payable', , "Balance ", 'Payments', "Action",];
  const tableHeading1 = ["Sr No", "Paid Amount", "Paid Date", "Paid from Acount", "Payment Mode", "Balance", "Tr Reference Date", "Tr Reference No", "Transaction Remarks",];
  const [loader, setLoader] = useState(false);
  const [viewModel, setViewModel] = useState(false);
  const [slipmodel, setSlipmodel] = useState(false)
  const [modalLoader, setModalLoader] = useState(false);
  const [modalData, setModalData] = useState([]);
  const wage = parseInt(modalData.labour_fixed_wage);
  const rate = parseInt(modalData.rate_per_hours);
  const overtime = parseInt(modalData.labour_overtime_hours);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const total = wage + rate;
  const Final = total * overtime;
  const [DailyWages, setDailyWages] = useState("");
  const getDailyWages = () => {
    setLoader(true);
    axiosClient.get("/addLabourGroup_lists/").then(({ data }) => {
      setLoader(false);
      setDailyWages(data.lists);
    });
  };

  const deleteDailyWage = (id) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete(`/delete_addLabourGroup/${id}`).then(({ data }) => {
          flasher.success(data.msg);
          getDailyWages();
        });
      }
    });
  };

  // const [sumfinalamount, setSumfinalamount] = useState('')
  // const [count, setCount] = useState(0)
  // const [labourdata, setLabourdata] = useState('')
  const [transactions, setTransactions] = useState([])
  function openModalDailyWages(id) {
    setViewModel(true);
    setModalLoader(true);
    axiosClient.get(`/single_addLabourGroup_data/${id}`).then(({ data }) => {
      setModalData(data.view_data);
      setModalLoader(false);
      setTransactions(data.daily_wages_transaction)
      // setLabourdata(data.MulitiLabours)
      // setCount(data.MulitiLabours.length)
      // setSumfinalamount(data.Sumof_final_amount)
    });

  }

  const [company, setCompany] = useState('')
  function openslip(id) {
    setSlipmodel(true)
    setModalLoader(true)
    axiosClient.get(`/single_addLabourGroup_data/${id}`)
      .then(({ data }) => {
        setModalLoader(false)
        setModalData(data.view_data)
      })
    axiosClient.get(`/company_lists`)
      .then(({ data }) => {
        setModalLoader(false)
        setCompany(data.lists[0].name)
      })
  }

  const componentRef = useRef();
  const salaryslipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePageChange = (pageparseInt) => {
    setCurrentPage(pageparseInt);
  };
  const handleSlip = useReactToPrint({
    content: () => salaryslipRef.current,
  });


  useEffect(() => {
    getDailyWages();
  }, []);
  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <PageComponent title={'Daily Wage Details'} button={
          <LinkButton to={"/daily-wages/add-daily-wages"}>
            <PlusCircleIcon className="w-5 h-5 text-default-color" />
            <span>Add New Wage Slip</span>
          </LinkButton>
        }>
          {DailyWages && DailyWages.length > 0 ? (
            <Table tableHeading={tableHeadings}>
              {DailyWages.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((a, b) => {
                return (
                  <Fragment key={b}>
                    <tr className="bg-default-color text-gray-900 border-b text-center">
                      <td className="px-4 py-3">{b + 1}</td>
                      <td className="px-4 py-3">{a.team_leader}</td>
                      <td className="px-4 py-3">{a.work_name}</td>
                      <td className="px-4 py-3">{a.wing_name}</td>
                      <td className="px-4 py-3">{a.total_labours_worked}</td>
                      <td className="px-4 py-3">{parseInt(a.grand_total).toLocaleString('en-IN')}</td>
                      <td className="px-4 py-3 text-red-600">{parseInt(a.tr_balance).toLocaleString('en-IN')}</td>
                      <td className="px-4 py-3">
                        <td className="px-4 py-3">{
                          a.tr_balance === null ?
                            <Link to={`/daily-wages/edit-daily-wages/${a.id}`}><button className={'border-2  bg-red-800 border-red-800 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button'>Add</button></Link>
                            : a.tr_balance === '0' ?
                              <button className={'border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button' onClick={() => openslip(a.id)}>Generate Salary Slip </button>
                              :
                              <Link to={`/daily-wages/edit-daily-wages/${a.id}`}><button className={'border-2  bg-orange-500 border-orange-500 text-white w-32 text-center px-3 py-1 rounded-lg'} title='view' type='button'>Add</button></Link>
                        }
                        </td>
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex justify-center gap-3">
                          <Link onClick={() => openModalDailyWages(a.id)}><ActionButton className={"p-1.5"}> <EyeIcon className="w-5 h-5" /></ActionButton></Link>
                          {/* <Link
                            title="Edit"
                            to={`/daily-wages/edit-daily-wages/${a.id}`} >
                            <ActionButton className={"p-1.5 text-white bg-yellow-400 rounded-lg"}>
                              <PencilSquareIcon className="w-5 h-5" />
                            </ActionButton>
                          </Link> */}
                          <ActionButton onClick={() => deleteDailyWage(a.id)} title={"Delete"} className={"p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"}> <TrashIcon className="w-5 h-5" /></ActionButton>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </Table>
          ) : (
            <div className="flex justify-center items-center h-[30vh]">
              <p className="text-3xl font-bold">Sorry! Daily Wages Data Not Found</p>
            </div>
          )}
          {DailyWages && DailyWages.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={DailyWages.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }
          {viewModel && (
            <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
              <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                {modalLoader ? (<PleaseWait />) : (
                  <Fragment>
                    <div className='m-5' ref={componentRef} id='print'>
                      <h1 className="text-center uppercase font-bold md:text-xl text-[3vw]  tracking-wide"> Worker Wages Information</h1>
                      <hr className="my-4 border border-primary-color" />
                      <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Team Leader</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.team_leader}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Phone No.</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.phone_no}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Work Name</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.work_name}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Wing Name</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.wing_name}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Work Type</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.work_type}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '> Fixed Wage</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.fixed_wage).toLocaleString('en-IN')}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold whitespace-nowrap'>Overtime Rate/Hr</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">
                            {parseInt(modalData.overtime_rate_per_hours).toLocaleString('en-IN')}
                          </h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold whitespace-nowrap'>Overtime Hours</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.overtime_hours).toLocaleString('en-IN')}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold whitespace-nowrap'> Amount Without GST</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.total_amount).toLocaleString('en-IN')}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '> GST</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{modalData.gst}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold whitespace-nowrap'> Amount with GST </h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.final_amount).toLocaleString('en-IN')}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold whitespace-nowrap'>Total Labours Worked</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{(modalData.total_labours_worked)}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Total Wage</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{(modalData.total_wage)}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Wage Remark</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{(modalData.wage_remarks)}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Grand Total </h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.grand_total).toLocaleString('en-IN')}</h1>
                        </div>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Advance Paid </h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.tr_advanced_paid).toLocaleString('en-IN')}</h1>
                        </div>
                        {/* <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold '>Total Payable</h1>
                          <h1 className='justify-center flex'>-</h1>
                          <h1 className="">{parseInt(modalData.total_payable).toLocaleString('en-IN')}</h1>
                        </div> */}
                        <div className='flex  bg-gray-200 md:p-2 p-1 rounded-lg mt-4'>
                          <h1 className='md:text-xl text-[3vw] font-extrabold'>Total Payable </h1>
                          <h1 className='md:text-xl text-[3vw]  font-extrabold ml-8'>-</h1>
                          <h1 className='md:text-xl text-[3vw] font-extrabold ml-14'>{parseInt(modalData.total_payable).toLocaleString('en-IN')}</h1>
                        </div>
                      </div>
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4'>
                        <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                          <h1 className='font-bold md:text-2xl text-[3vw]'>Transactions</h1>
                          <h1 className='justify-center flex'>-</h1>
                        </div>
                        <div></div>
                      </div>
                      {transactions && transactions.length > 0 ? (
                        <div className='w-[50vw] md:w-full'>
                          <Table tableHeading={tableHeading1}>
                            {transactions.map((tableData, tableDataID) => {
                              return (<Fragment key={tableDataID}>
                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                  <td className="px-4 py-3">{tableDataID + 1}</td>
                                  <td className="px-4 py-3">{parseInt(tableData.paid_amount).toLocaleString('en-IN')}</td>
                                  <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.paid_date).format('DD-MM-YYYY')}</td>
                                  <td className="px-4 py-3">{(tableData.paid_from_acount)}</td>
                                  <td className="px-4 py-3">{(tableData.payment_mode)}</td>
                                  <td className="px-4 py-3 text-red-600">{(tableData.tr_balance)}</td>
                                  <td className="px-4 py-3 whitespace-nowrap">{moment(tableData.tr_reference_date).format('DD-MM-YYYY')}</td>
                                  <td className="px-4 py-3">{tableData.tr_reference_no}</td>
                                  <td className="px-4 py-3">{tableData.transaction_remarks}</td>
                                </tr>
                              </Fragment>)
                            })}
                          </Table>
                        </div>
                      ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[30vh]'>
                          <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Salary  Details Not Found</p>
                        </div>
                      )}
                      {/* {count > 0 ? (
                        <div>
                          <h1 className="text-center uppercase font-bold text-xl tracking-wide mt-4 mb-3">
                            Labours Information
                          </h1>
                          {labourdata && labourdata.length > 0 ? (
                            <Table tableHeading={tableHeading1} >
                              {labourdata.map((item, i) => {
                                return (
                                  <Fragment key={i}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                      <td className="px-4 py-3">{i + 1}</td>
                                      <td className="px-4 py-3">{item.labour_name}</td>
                                      <td className="px-4 py-3">{item.labour_contact}</td>
                                      <td className="px-4 py-3">{parseInt(item.labour_fixed_wage).toLocaleString('en-IN')}</td>
                                      <td className="px-4 py-3">{parseInt(item.rate_per_hours).toLocaleString('en-IN')}</td>
                                      <td className="px-4 py-3">{parseInt(item.labour_overtime_hours).toLocaleString('en-IN')}</td>
                                      <td className="px-4 py-3">{parseInt(item.labour_total_amount).toLocaleString('en-IN')}</td>
                                      <td className="px-4 py-3">{item.labour_gst}</td>
                                      <td className="px-4 py-3">{parseInt(item.labour_final_amount).toLocaleString('en-IN')}</td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                            </Table>
                          ) : (
                            <div className="flex justify-center items-center h-[30vh]">
                              <p className="text-3xl font-bold">Sorry! Data Not Found</p>
                            </div>
                          )}
                          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-5 mt-4 '>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Total Labours Worked </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{count}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Total Wage </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{parseInt(sumfinalamount).toLocaleString('en-IN')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '>  Wage Remark </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{modalData.wage_remarks}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Paid Amount</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{parseInt(modalData.labour_paid_amount).toLocaleString('en-IN')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Paid Date</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className=''>{moment(modalData.labour_paid_date).format('DD-MM-YYYY')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Paid from Account </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{modalData.labour_paid_from_acount}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Payment Mode </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{modalData.labour_payment_mode}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '>Advanced Paid</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{parseInt(modalData.labour_tr_advanced_paid).toLocaleString('en-IN')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '> Balance</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{parseInt(modalData.labour_tr_balance).toLocaleString('en-IN')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '>TR No</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{modalData.labour_tr_reference_no}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '>TR Date </h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className=''>{moment(modalData.labour_tr_reference_date).format('DD-MM-YYYY')}</h1>
                            </div>
                            <div className='grid grid-cols-3 gap-4 mb-4'>
                              <h1 className='font-bold '>Transaction Remarks</h1>
                              <h1 className='flex justify-center '>-</h1>
                              <h1 className="">{modalData.labour_transaction_remarks}</h1>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center h-[20vh]">
                          <p className="text-3xl font-bold">Sorry! Labour Not Found</p>
                        </div>
                      )} */}
                    </div>
                    <div className="mt-4  grid grid-cols-3">
                      <button id='gotit'
                        type="button"
                        className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => setViewModel(false)} > Got it, thanks! </button>
                      <div className=""></div>
                      <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                    </div>
                  </Fragment>
                )}
              </Dialog.Panel>
            </ViewModel>
          )}
          {
            slipmodel && <ViewModel viewModel={slipmodel} setViewModel={setSlipmodel}>
              <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                {modalLoader ? (<PleaseWait />) : (
                  <Fragment>
                    <div className='m-5' ref={salaryslipRef} id='print'>
                      <h1 className='text-center  font-extrabold md:text-3xl text-[4vw]  tracking-wide'>{company}</h1>
                      <h1 className='text-center mt-3 font-bold md:text-xl text-[3vw] tracking-wide'>Salary Slip</h1>
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4'>
                        <div className='flex items-center gap-4  py-2'>
                          <h1 className='w-36 font-bold'> Team Leader Name </h1>
                          <h1>-</h1>
                          <div className=''>{modalData.team_leader}</div>
                        </div>
                        <div className='flex items-center gap-4  py-2'>
                          <h1 className='w-36 font-bold'> Wotk Name </h1>
                          <h1>-</h1>
                          <div className=''>{modalData.work_name}</div>
                        </div>
                        <div className='flex items-center gap-4  py-2'>
                          <h1 className='w-36 font-bold'> Phone No </h1>
                          <h1>-</h1>
                          <div className=''>{(modalData.phone_no)}</div>
                        </div>
                        <div className='flex items-center gap-4  py-2'>
                          <h1 className='w-36 font-bold'> Total Labours Worked</h1>
                          <h1>-</h1>
                          <div className=''>{modalData.total_labours_worked}</div>
                        </div>

                      </div>
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-0'>
                        <table class="table-fixed mt-6 border border-collapse">
                          <thead className='bg-gray-300'>
                            <tr>
                              <th class="border px-4 py-2">Earnings</th>
                              <th class="border px-4 py-2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2">Basic Salary</td>
                              <td class="border px-4 py-2">{parseInt(modalData.total_amount) + parseInt(modalData.total_wage)}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table class="table-fixed mt-6 border border-collapse">
                          <thead className='bg-gray-300'>
                            <tr>
                              <th class="border px-4 py-2">Deduction</th>
                              <th class="border px-4 py-2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2">GST</td>
                              <td class="border px-4 py-2">{parseInt(modalData.gst) / 100 * (modalData.total_amount)}</td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2">Advance Paid</td>
                              <td class="border px-4 py-2">{modalData.tr_advanced_paid}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='grid grid-cols-1 md:grid-cols-2 gap-0'>
                        <div></div>
                        <table class="table-fixed  border border-collapse">

                          <tbody>
                            <tr className='bg-gray-300 '>
                              <td class="border px-4 py-2 font-bold">Total Amount</td>
                              <td class="border px-4 py-2 font-bold">{modalData.total_payable}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-14'>
                        <div class="text-center flex items-center flex-col">
                          <hr class="my-2 w-52 border border-primary-color"></hr>
                          <h1 class="mb-4">Team Leader Signature</h1>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mb-2 grid grid-cols-3">
                      <button id='gotit'
                        type="button"
                        className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => setSlipmodel(false)} >
                        Got it, thanks!
                      </button>
                      <div className=""></div>
                      <Link id='printButton' onClick={handleSlip} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                    </div>
                  </Fragment>
                )}
              </Dialog.Panel>
            </ViewModel>
          }
        </PageComponent>
      )}
    </Fragment>
  );
}
export default DailyWages;
