import React, { Fragment } from 'react'

function Validation({error}) {
    return (
        <Fragment>
            {error && <p className='text-red-600 mt-1 text-sm'>{error}</p>}
        </Fragment>
    )
}

export default Validation