import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function LinkButton({children,to}) {
    return (
        <Fragment>
            <Link to={to} className='flex items-center px-3 py-1.5 gap-2 border border-primary-color bg-primary-color text-default-color rounded-lg hover:bg-primary-color hover:text-default-color cursor-pointer text-[2vw] md:text-sm transition delay-100'>
                {children}
            </Link>
        </Fragment>
    )
}

export default LinkButton