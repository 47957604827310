import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import Card from '../helpers/Card'
import axiosClient from '../../Axios'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Validation from '../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'

function LegalAgreementAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Legal Agreement'
        ) : (
            document.title = 'SiteSmart - Add New Legal Agreement'
        )
    }

    const [loader, setLoader] = useState(false)
    const [clientNameLists, setClientNameLists] = useState(['Omkar Jadhav', 'Suresh Kadam', 'Ashok Shinde'])
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level: '',
        flat_no: '',
        client_name: '',
        area: '',
        final_amount: '',
        date_of_agreement: '',
        transaction_remarks: '',
        floor: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const [work, setWork] = useState([])
    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWork(data.lists)
            })
    }
    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        formInfo.wing_name && axiosClient.get(`/wing_wise_level/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.wing_wise_level)
            })
    }

    const [flat, setFlat] = useState([])
    const getflatLists = () => {
        formInfo.level && axiosClient.get(`/level_wise_flat/${formInfo.wing_name}/${formInfo.level}`)
            .then(({ data }) => {
                setFlat(data.level_wise_flat)
            })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('final_quoted_amount', formInfo.bill_no)
        formData.append('bill_date', formInfo.bill_date)
        formData.append('bill_amount', formInfo.bill_amount)
        formData.append('bill_description', formInfo.bill_description)
        formData.append('paid_amount', formInfo.paid_amount)
        formData.append('paid_from_account', formInfo.paid_from_account)
        formData.append('payment_mode', formInfo.payment_mode)
        formData.append('transaction_remarks', formInfo.transaction_remarks)

        axiosClient.post(id ? `update_Expenses_bills/${id}` : '/store_Expenses_bills', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/accounting/expenses-bills')
            })
            .catch(({ response }) => {
                setLoader(false)

                setErrors(response.data.errors)
            })
    }

    const getSinglePostSalesData = () => {
        setLoader(true)
        axiosClient.get(`/single_Expenses_bills_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }
    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getflatLists();
    }, [formInfo.level])

    useEffect(() => {
        if (id) {
            getSinglePostSalesData()
        }
        getWork();
    }, [])

    return (
        <Fragment>

            {loader ? (<Loader />) : (
                <PageComponent title={'Legal Agreement'} button={
                    <LinkButton to={'/enquiry'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-96 md:w-[70%] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                            <option  value={''}>--- Choose ---</option>
                                            {
                                                work.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div className='ml-2'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                            <option  value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>

                                    <div className='ml-2'>
                                        <Label htmlFor={'level'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'level'} name={'level'} value={formInfo.level} onChange={handleChange} className={'w-[25%]'}>
                                            <option  value={''}>--- Select Level ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level} />
                                    </div>
                                    <div className='ml-2'>
                                        <Label htmlFor={'flat_no'} labelText={'Flat No.'} className={'mb-1'} />
                                        <CustomSelect id={'flat_no'} name={'flat_no'} value={formInfo.flat_no}  onChange={handleChange} >
                                            <option  value={''}>--- Select Flat No ---</option>
                                            {flat && flat.map((flatData, flatId) => {
                                                return (<Fragment key={flatId}>
                                                    <option value={flatData.id}>{flatData.flat_no}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.flat_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'client_name'} labelText={'Client Name'} className={'mb-1'} />
                                        <CustomSelect id={'client_name'} name={'client_name'} className={''} value={formInfo.client_name} onChange={handleChange}>
                                            <option  value={''}>--- Choose ---</option>
                                            {
                                                clientNameLists.map(data => {
                                                    return (
                                                        <option value={data}>{data}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.client_name} />
                                    </div>

                                    <div>
                                        <Label htmlFor={'floor'} labelText={'Floor'} className={'mb-1'} />
                                        <Input id={'floor'} type={'text'} name={'floor'} value={formInfo.floor} onChange={handleChange} />
                                        <Validation error={errors.floor} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'area'} labelText={'Area'} className={'mb-1'} />
                                        <Input id={'area'} type={'text'} name={'area'} value={formInfo.area} onChange={handleChange} />
                                        <Validation error={errors.area} />
                                    </div>

                                    <div>
                                        <Label htmlFor={'final_amount'} labelText={'Final Amount'} className={'mb-1'} />
                                        <Input id={'final_amount'} type={'text'} name={'final_amount'} value={formInfo.final_amount} onChange={handleChange} />
                                        <Validation error={errors.final_amount} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'date_of_agreement'} labelText={'Date of Agreement'} className={'mb-1'} />
                                        <Input id={'date_of_agreement'} type={'text'} name={'date_of_agreement'} value={formInfo.date_of_agreement} onChange={handleChange} />
                                        <Validation error={errors.date_of_agreement} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'document_one'} labelText={'Document One'} className={'mb-1'} />
                                        <Input id={'document_one'} type={'file'} name={'document_one'} value={formInfo.document_one} onChange={handleChange} className={'p-[0px!important] mt-2'} />
                                        <Validation error={errors.document_one} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'document_two'} labelText={'Document Two'} className={'mb-1'} />
                                        <Input id={'document_two'} type={'file'} name={'document_two'} value={formInfo.document_two} onChange={handleChange} className={'p-[0px!important] mt-2'} />
                                        <Validation error={errors.document_two} />
                                    </div>

                                </div>

                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>

                            </form>

                        </Card>

                    </div>

                </PageComponent>
            )}
        </Fragment>
    )
}

export default LegalAgreementAddEdit

