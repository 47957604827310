import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import CustomSelect from '../../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'

function VoucherAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Account Voucher'
        ) : (
            document.title = 'SiteSmart - Add New Account Voucher'
        )
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        date: '',
        bill_no: '',
        type_of_voucher: '',
        ledger: '',
        group: '',
        paid_from: '',
        bank_name: '',
        amount: '',
        officeorwork:''
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const [groupsLists, setGroupLists] = useState('')
    const getGroupsLists = () => {
        setLoader(false)
        formInfo.ledger && axiosClient.get(`/single_Accountledger_data/${formInfo.ledger}`)
            .then(({ data }) => {
                setLoader(false)
                setGroupLists(data.Multigroups)
            })
    }

    //   const [incrementid, setIncrementId] = useState('')
    const [voucherLists, setVoucherLists] = useState('')
    const getVoucherlist = () => {
        setLoader(true)
        axiosClient.get('/vouchermaster_list')
            .then(({ data }) => {
                setLoader(false)
                setVoucherLists(data.lists)
                //  setIncrementId(data.lists)
            })
    }

    // const [count, setCount] = useState(0);
    // const incrementCount = () => {
    //     // Update state with incremented value
    //     setCount(incrementid + 1);
    // };


    const [workNameLists, setWorkNameLists] = useState([])
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkNameLists(data.lists)
            })
    }
    const [ledgerLists, setLedgerList] = useState('')
    const getLedgerList = () => {
        setLoader(true)
        axiosClient.get('/Accountledger_lists')
            .then(({ data }) => {
                setLoader(false)
                setLedgerList(data.lists)
            })
    }
    const [bankLists, setBankLists] = useState('')
    const getBankLists = () => {
        setLoader(true)
        axiosClient.get('/Bank_master_lists')
            .then(({ data }) => {
                setLoader(false)
                setBankLists(data.lists)
            })
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     setLoader(true)
    //     const formData = new FormData()
    //     formData.append('work_name', formInfo.work_name)
    //     formData.append('date', formInfo.date)
    //     formData.append('bill_no', formInfo.bill_no)
    //     formData.append('type_of_voucher', formInfo.type_of_voucher)
    //     formData.append('ledger', formInfo.ledger)
    //     formData.append('group', formInfo.group)
    //     formData.append('paid_from', formInfo.paid_from)
    //     formData.append('bank_name', formInfo.bank_name)
    //     formData.append('amount', formInfo.amount)
    //     formData.append('officeorwork', formInfo.officeorwork)
    //     axiosClient.post(id ? `update_VoucherTransaction/${id}` : '/store_VoucherTransaction', formData)
    //         .then(({ data }) => {
    //             setLoader(false)
    //             flasher.success(data.msg)
    //             navigate('/accounting/account-voucher')
    //         })
    //         .catch(({ response }) => {
    //             setLoader(false)
    //             setErrors(response.data.errors)
    //         })
    // }


    const handleSubmit = (e) => {
        e.preventDefault()
       .then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                const payload = {
                    officeorwork: formInfo.officeorwork,
                    date: formInfo.date,
                    bill_no: formInfo.bill_no,
                    type_of_voucher: formInfo.type_of_voucher,
                    ledger: formInfo.ledger,
                    group: formInfo.group,
                    paid_from: formInfo.paid_from,
                    bank_name: formInfo.bank_name,
                    amount: formInfo.amount,
                }
                const payload1 = {
                    work_name: formInfo.work_name,
                    date: formInfo.date,
                    bill_no: formInfo.bill_no,
                    type_of_voucher: formInfo.type_of_voucher,
                    ledger: formInfo.ledger,
                    group: formInfo.group,
                    paid_from: formInfo.paid_from,
                    bank_name: formInfo.bank_name,
                    amount: formInfo.amount,
                }
                if (formInfo.officeorwork === "Office") {
                    axiosClient.post(`/store_VoucherTransaction/${id}`, payload)
                        .then(({ data }) => {
                            setLoader(false)
                            flasher.success(data.msg)
                              navigate('/accounting/account-voucher')

                        })
                        .catch(({ response }) => {
                            setLoader(false)
                            setErrors(response.data.errors)
                        })
                } else {
                    axiosClient.post(`/store_VoucherTransaction/${id}`, payload1)
                        .then(({ data }) => {
                            setLoader(false)
                            flasher.success(data.msg)
                              navigate('/accounting/account-voucher')

                        })
                        .catch(({ response }) => {
                            setLoader(false)
                            setErrors(response.data.errors)
                        })
                }
            }
        })

    }
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     setLoader(true)
    //     const formData = new FormData()
    //     formData.append('work_name', formInfo.work_name)
    //     formData.append('date', formInfo.date)
    //     formData.append('bill_no', formInfo.bill_no)
    //     formData.append('type_of_voucher', formInfo.type_of_voucher)
    //     formData.append('ledger', formInfo.ledger)
    //     formData.append('group', formInfo.group)
    //     formData.append('paid_from', formInfo.paid_from)
    //     formData.append('bank_name', formInfo.bank_name)
    //     formData.append('amount', formInfo.amount)
        
    //     axiosClient.post(id ? `update_VoucherTransaction/${id}` : '/store_VoucherTransaction', formData)
    //         .then(({ data }) => {
    //             setLoader(false)
    //             flasher.success(data.msg)
    //             navigate('/accounting/account-voucher')
    //         })
    //         .catch(({ response }) => {
    //             setLoader(false)
    //             setErrors(response.data.errors)
    //         })

    // const getAccountGroupLists = () => {
    //     setLoader(true)
    //     axiosClient.get('/accountgroup_lists')
    //         .then(({ data }) => {
    //             setLoader(false)
    //             setAccountGroupLists(data.lists)
    //         })
    // }

    const getSingleGroupData = (id) => {
        setLoader(true)
        axiosClient.get(`/single_VoucherTransaction_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleGroupData();
        }
        getVoucherlist();
        getWorkData();
        getLedgerList();
        getBankLists();

    }, [])

    useEffect(() => {
        getGroupsLists();
    }, [formInfo.ledger])

    useEffect(() => {
        getBankLists();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Voucher '} button={
                    <LinkButton to={'/accounting/account-voucher'}>
                        <ArrowLeftCircleIcon className='w-5 h-5' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='grid items-center justify-center '>
                        <Card className={'w-[60rem] px-6 py-4 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className=' grid grid-cols-1 md:grid-cols-4 gap-4'>
                                    <div className='mb-4'>
                                        <Label htmlFor={'officeorwork'} labelText={'Office/ Work'} className={'mb-1'} />
                                        <CustomSelect id={'officeorwork'} name={'officeorwork'} value={formInfo.officeorwork} onChange={handleChange}>
                                            <option value={''}>--- Choose Office/ Work ---</option>
                                            <option value={'Office'}>Office</option>
                                            <option value={'Work'}>Work</option>
                                        </CustomSelect>
                                    </div>
                                    {
                                        formInfo.officeorwork === "Work" ?
                                            (
                                                <div>
                                                    <div className='mb-4'>
                                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                                            <option value={''}>--- Select Work ---</option>
                                                            {/* <option value={'Office'}>Office</option> */}
                                                            {workNameLists.map(data => {
                                                                return (
                                                                    <option value={data.id}>{data.work_name}</option>
                                                                )
                                                            })
                                                            }
                                                        </CustomSelect>
                                                        <Validation error={errors.work_name} />
                                                    </div>
                                                </div>)
                                            :
                                            (
                                                <div>

                                                </div>
                                            )
                                    }


                                    <div className='mb-4'>
                                        <Label htmlFor={'date'} labelText={'Date'} className={'mb-1'} />
                                        <Input id={'date'} type={'date'} name={'date'} value={formInfo.date} onChange={handleChange} />
                                        <Validation error={errors.date} />
                                    </div>
                                    <div className='mb-4'>
                                        <Label htmlFor={'bill_no'} labelText={'Bill No./ Voucher No'} className={'mb-1'} />
                                        <Input id={'bill_no'} type={'text'} name={'bill_no'} value={formInfo.bill_no} onChange={handleChange} />
                                        <Validation error={errors.bill_no} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div className='mb-4'>
                                        <Label htmlFor={'type_of_voucher'} labelText={'Type Of Voucher'} className={'mb-1'} />
                                        <CustomSelect id={'type_of_voucher'} name={'type_of_voucher'} value={formInfo.type_of_voucher} onChange={handleChange}>
                                            <option value={''}>--- Select Voucher Name ---</option>
                                            {voucherLists && voucherLists.map((voucherdata, voucherID) => {
                                                return (<Fragment key={voucherID}>
                                                    <option value={voucherdata.id}>{voucherdata.voucher_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.type_of_voucher} />
                                    </div>
                                    <div className='mb-4'>
                                        <Label htmlFor={'ledger'} labelText={'Ledger'} className={'mb-1'} />
                                        <CustomSelect id={'ledger'} name={'ledger'} value={formInfo.ledger} onChange={handleChange}>
                                            <option value={''}>--- Select Ledger Name ---</option>
                                            {ledgerLists && ledgerLists.map((ledgerdata, groupID) => {
                                                return (<Fragment key={groupID}>
                                                    <option value={ledgerdata.id}>{ledgerdata.ledger_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.ledger} />
                                    </div>
                                    <div className='mb-4'>
                                        <Label htmlFor={'group'} labelText={'Group'} className={'mb-1'} />
                                        <CustomSelect id={'group'} name={'group'} value={formInfo.group} onChange={handleChange}>
                                            <option value={''}>--- Select Group Name ---</option>
                                            {groupsLists && groupsLists.map((groupdata, groupID) => {
                                                return (<Fragment key={groupID}>
                                                    <option value={groupdata.ledger_id}>{groupdata.group_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.group} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div className='mb-4'>
                                        <Label htmlFor={'paid_from'} labelText={'Paid From'} className={'mb-1'} />
                                        <CustomSelect id={'paid_from'} name={'paid_from'} value={formInfo.paid_from} onChange={handleChange}>
                                            <option value={''}>--- Choose Paid From ---</option>
                                            <option value={'Cash'}>Cash</option>
                                            <option value={'Bank'}>Bank</option>
                                        </CustomSelect>
                                        <Validation error={errors.paid_from} />
                                    </div>
                                    {
                                        formInfo.paid_from === "Bank" ?
                                            (
                                                <div>
                                                    <div className='mb-4'>
                                                        <Label htmlFor={'bank_name'} labelText={'Bank Name'} className={'mb-1'} />
                                                        <CustomSelect id={'bank_name'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange}>
                                                            <option value={''}>--- Select Bank Name ---</option>
                                                            {bankLists && bankLists.map((bankdata, bankID) => {
                                                                return (<Fragment key={bankID}>
                                                                    <option value={bankdata.id}>{bankdata.bank_name}</option>
                                                                </Fragment>)
                                                            })}
                                                        </CustomSelect>
                                                        <Validation error={errors.ledger} />
                                                    </div>
                                                </div>)
                                            :
                                            (
                                                <div>

                                                </div>
                                            )
                                    }
                                </div>
                                <div className='mb-4'>
                                    <Label htmlFor={'amount'} labelText={'Amount'} className={'mb-1'} />
                                    <Input id={'amount'} type={'text'} name={'amount'} value={formInfo.amount} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                    <Validation error={errors.amount} />
                                </div>
                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4'>
                                    <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>
                        </Card>
                        {/* <Card className="w-[70rem] p-4 bg-default-color mt-4">
                            {
                                formInfo.contract_type === "Purchase" ?
                                  (  <div>
                                        <div className='mb-4'>
                                            <span className='text-xl font-normal'>Purchase Details :</span>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                            <div>
                                                <Label htmlFor={'fixed_rate_est_amount'} labelText={'Date'} className={'mb-1'} />
                                                <Input id={'fixed_rate_est_amount'} type={'date'} name={'fixed_rate_est_amount'} value={formInfo.fixed_rate_est_amount} onChange={handleChange} />
                                                <Validation error={errors.fixed_rate_est_amount} />
                                            </div>
                                            <div className='mb-3 '>
                                                <Label htmlFor={'fixed_rate_work_type'} labelText={' Work Name'} className={'mb-1'} />
                                                <CustomSelect id={'fixed_rate_work_type'} name={'fixed_rate_work_type'} value={formInfo.fixed_rate_work_type} onChange={handleChange}>
                                                    <option disabled value={''}>--- Select Work Type ---</option>
                                                    {worktype && worktype.map((worktypedata, worktypeID) => {
                                                        return (<Fragment key={worktypeID}>
                                                            <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.fixed_rate_work_type} />
                                            </div>
                                            <div className='mb-3 '>
                                                <Label htmlFor={'fixed_rate_work_type'} labelText={' Wing Name'} className={'mb-1'} />
                                                <CustomSelect id={'fixed_rate_work_type'} name={'fixed_rate_work_type'} value={formInfo.fixed_rate_work_type} onChange={handleChange}>
                                                    <option disabled value={''}>--- Select Work Type ---</option>
                                                    {worktype && worktype.map((worktypedata, worktypeID) => {
                                                        return (<Fragment key={worktypeID}>
                                                            <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                        </Fragment>)
                                                    })}
                                                </CustomSelect>
                                                <Validation error={errors.fixed_rate_work_type} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Material Name'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Description'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Price'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Quantity'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Total Amount'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Gst'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Delivery Charges'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Loading Charges'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Grand Total'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'fixed_rate_remarks'} labelText={'Remarks'} className={'mb-1'} />
                                                <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} />
                                                <Validation error={errors.fixed_rate_remarks} />
                                            </div>
                                        </div>
                                    </div>)
                                    :
                                    (<div>
                                        <div className='mb-4'>
                                            <span className='text-xl font-normal'>Direct Expense :</span>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                            <div className='mb-3'>
                                                <Label htmlFor={'accountLedger_name'} labelText={'Expense Type'} className={'mb-1'} />
                                                <Input id={'accountLedger_name'} type={'text'} name={'accountLedger_name'} value={formInfo.accountLedger_name} onChange={handleChange} />
                                                <Validation error={errors.accountLedger_name} />
                                            </div>

                                            <div className='mb-3'>
                                                <Label htmlFor={'op_balance'} labelText={'Date'} className={'mb-1'} />
                                                <Input id={'op_balance'} type={'date'} name={'op_balance'} value={formInfo.op_balance} onChange={handleChange} />
                                                <Validation error={errors.op_balance} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'description'} labelText={'Description'} className={'mb-1'} />
                                                <Input id={'description'} type={'text'} name={'description'} value={formInfo.description} onChange={handleChange} />
                                                <Validation error={errors.description} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'bank_name'} labelText={'Invoice/Transction No.'} className={'mb-1'} />
                                                <Input id={'bank_name'} type={'text'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange} />
                                                <Validation error={errors.bank_name} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'bank_branch'} labelText={'Amount'} className={'mb-1'} />
                                                <Input id={'bank_branch'} type={'text'} name={'bank_branch'} value={formInfo.bank_branch} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                <Validation error={errors.bank_branch} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'bank_account_no'} labelText={'Currency'} className={'mb-1'} />
                                                <Input id={'bank_account_no'} type={'text'} name={'bank_account_no'} value={formInfo.bank_account_no} onChange={handleChange} />
                                                <Validation error={errors.bank_account_no} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'ifsc_code'} labelText={'Payment Method'} className={'mb-1'} />
                                                <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                                <Validation error={errors.ifsc_code} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'ifsc_code'} labelText={'Vendor/Supplier Name'} className={'mb-1'} />
                                                <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                                <Validation error={errors.ifsc_code} />
                                            </div>

                                            <div className='mb-3'>
                                                <Label htmlFor={'ifsc_code'} labelText={'Vendor/Supplier Contact'} className={'mb-1'} />
                                                <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                                <Validation error={errors.ifsc_code} />
                                            </div>
                                            <div className='mb-3'>
                                                <Label htmlFor={'ifsc_code'} labelText={'Tax Amount'} className={'mb-1'} />
                                                <Input id={'ifsc_code'} type={'text'} name={'ifsc_code'} value={formInfo.ifsc_code} onChange={handleChange} />
                                                <Validation error={errors.ifsc_code} />
                                            </div>
                                        </div>
                                    </div>)
                            }
                            <Button className={'w-60 mt-6 mx-auto'}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                <span className='ml-5'>Save Now</span>
                            </Button>
                        </Card> */}
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}


export default VoucherAddEdit
