import React, { Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'

function GuestLayout() {
    const { userToken, FRONTEND_URL } = useStateContext()
    if(userToken){
        return(
            <Navigate to="/"/>
        )
    }
    return (
        <Fragment>
            <div className='flex items-center gap-4 h-screen relative justify-center'>
                <img src={FRONTEND_URL+"/assets/images/loginimg1.jpg"} className='w-full h-full' alt='loginimg'/>
                <div className='bg-[rgba(0,0,0,0.8)] absolute top-0 h-full w-full'></div>
                <Outlet/>
            </div>
        </Fragment>
    )
}

export default GuestLayout