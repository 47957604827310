
import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { Dialog } from '@headlessui/react'
import ViewModel from '../helpers/ViewModel'
import Swal from 'sweetalert2'
import Table from '../helpers/Table'
import { ShoppingCartIcon } from '@heroicons/react/20/solid'
import PleaseWait from '../Common/PleaseWait'

function RequisitionAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Purchase Requisition'
        ) : (
            document.title = 'SiteSmart - Add New Purchase Requisition'
        )
    }
    const [loader, setLoader] = useState(false)
    const [estimatedData, setEstimatedData] = useState([])
    const [viewModel, setViewModel] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)
    const [requisitionData, setRequisitionData] = useState([])
    const navigate = useNavigate()
    const [user, setUser] = useState([]);
    const [material, setMaterial] = useState([])
    const [workNameLists, setWorkNameLists] = useState([])
    const [wing, setWing] = useState([])
    const [level, setLevel] = useState([])
    const tableHeading = ['Sr.No', 'Material Name', 'Unit', 'Est. Quantity ', 'Stock Inward', 'Action']
    const tableHeading1 = ['Sr.No', 'Material Name', 'Unit', 'Required Quantity', 'Action']

    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
        level_name: '',
        send_to: ''
    })

    const [inputs, setInputs] = useState({
        material_name: '',
        material_unit: '',
        quantity: '',
    });

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangesub = (e) => {
        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }))
    }

    const handleModelAdd = (e) => {
        e.preventDefault();
        setRequisitionData(
            [...requisitionData,
            {
                material_name: inputs.material_name,
                material_unit: inputs.material_unit,
                quantity: inputs.quantity
            }
            ])

        setInputs({
            material_name: '',
            material_unit: '',
            quantity: '',
        });
        setViewModel(false)
    };

    const handleModelUpdate = (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            work_name: formInfo.work_name,
            wing_name: formInfo.wing_name,
            level_name: formInfo.level_name,
            send_to: formInfo.send_to,
            requisitionID: id,
            material: inputs.material_name,
            material_unit: inputs.material_unit,
            quantity: inputs.quantity
        }
        axiosClient.post(`store_material_addto_card`, payload)
            .then(({ data }) => {
                getSingleNewData();
                setLoader(false)
                flasher.success(data.msg)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })

        setInputs({
            material_name: '',
            material_unit: '',
            quantity: '',
        });
        setViewModel(false)
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const payload = {
            work_name: formInfo.work_name,
            wing_name: formInfo.wing_name,
            level_name: formInfo.level_name,
            send_to: formInfo.send_to,

            material_list: requisitionData,
        }

        const notification = {
            from_user: localStorage.getItem('CURRENT_USERID'),
            to_user: formInfo.send_to,
            message: 'You have a new Requisition approval request...',
            module: 'Requisition',
            link: '/purchase-requisition',
        }
        axiosClient.post(id ? `update_purchaseRequisition/${id}` : '/store_purchaseRequisition', payload)
            .then(({ data }) => {
                navigate('/purchase-requisition')
                setLoader(false)
                flasher.success(data.msg)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
        axiosClient.post(`store_Notification`, notification)
            .then(({ data }) => {
                setLoader(false)
            })
            .catch(({ response }) => {
                setErrors(response.data.errors)
                setLoader(false)
            })
    }



    const deleteActivity = (index) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_material_addto_card/${index}`)
                    .then(({ data }) => {
                        flasher.warning(data.msg)
                        getSingleNewData()
                    })
            }
        })
    }

    const handleDelete = (index) => {
        const filterData = requisitionData.filter((item, i) => i !== index)
        setRequisitionData(filterData)
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        setInputs({
            material_name: '',
            material_unit: '',
            quantity: '',
        })
        setModalLoader(false)
    }

    function handleCart(tableData) {
        setViewModel(true)
        setInputs({
            material_name: tableData.material_name,
            material_unit: tableData.unit,
            quantity: '',
        })
    }

    const getMaterialEstimationLists = () => {
        setLoader(true)
        axiosClient.get(`/work_wing_level_wise_Material_estimationdata/${formInfo.work_name}/${formInfo.wing_name}/${formInfo.level_name}`)
            .then(({ data }) => {
                setEstimatedData(data.Est_Material_id)
                setLoader(false)
            })
    }

    const getUserData = () => {
        setLoader(true)
        axiosClient.get(`/user_lists`)
            .then(({ data }) => {
                setUser(data.lists)
                setLoader(false)
            })
    }

    const getUnitData = () => {
        inputs.material_name && axiosClient.get(`/material_wise_unit_data/${inputs.material_name}`)
            .then(({ data }) => {
                data.material_wise_unit_data.material_unit && (
                    setInputs(inputs => ({
                        ...inputs,
                        material_unit: data.material_wise_unit_data.material_unit,
                    })))
            })
    }

    const getMaterial = () => {
        setLoader(true)
        axiosClient.get(`/material_lists`)
            .then(({ data }) => {
                setMaterial(data.lists)
                setLoader(false)
            })
    }
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWorkNameLists(data.lists)
                setLoader(false)
            })
    }

    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const getLevelLists = () => {
        formInfo.work_name && formInfo.wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const getSingleNewData = () => {
        setLoader(true)
        axiosClient.get(`/single_purchaseRequisition/${id}`)
            .then(({ data }) => {
                setFormInfo(data.single_data)
                setRequisitionData(data.material_lists)
                setLoader(false)
            })
    }

    useEffect(() => {
        getWingLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getLevelLists()
    }, [formInfo.work_name, formInfo.wing_name])

    useEffect(() => {
        getUnitData()
    }, [inputs.material_name])

    useEffect(() => {
        formInfo.level_name && getMaterialEstimationLists();
    }, [formInfo.level_name])

    useEffect(() => {
        if (id) {
            getSingleNewData()
        }
        getWorkData()
        getMaterial();
        getUserData();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Requisition' : 'Add New Requisition'} button={
                    <LinkButton to={'/purchase-requisition'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[65rem] p-6  bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Work ---</option>
                                            {
                                                workNameLists.map((data, id) => {
                                                    return (<Fragment key={id}>
                                                        <option value={data.id}>{data.work_name}</option>
                                                    </Fragment>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>

                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Wing ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <CustomSelect id={'level_name'} name={'level_name'} value={formInfo.level_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select level ---</option>
                                            {level && level.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.level} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'send_to'} labelText={'Send To'} className={'mb-1'} />
                                        <CustomSelect id={'send_to'} name={'send_to'} value={formInfo.send_to} onChange={handleChange} className={'w-full'}>
                                            <option disabled value={''}>--- Choose User Name ---</option>
                                            {user.map((data, id) => {
                                                return (<Fragment key={id}>
                                                    <option value={data.id}>{data.name} ({((data.role).split('_')).join(' ')})</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.send_to} />
                                    </div>
                                </div>

                                <div className='flex justify-start items-center mb-1'>
                                    <p className='text-[4vw] md:text-2xl font-bold'>Estimated Material List</p>
                                </div>
                                {estimatedData && estimatedData.length > 0 ? (
                                    <Table tableHeading={tableHeading}>
                                        {estimatedData.map((tableData, tableDataID) => {
                                            return (<Fragment key={tableDataID}>
                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                    <td className="px-4 py-3">
                                                        {
                                                            material && material.map((data, id) => {
                                                                return (<Fragment key={id}>
                                                                    <h1>{data.id == tableData.material_name && data.material_name}</h1>
                                                                </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td className="px-4 py-3">{tableData.unit}</td>
                                                    <td className="px-4 py-3">{parseInt(tableData.estimate_quantity).toLocaleString('en-IN')}</td>
                                                    <td className="px-4 py-3">0</td>
                                                    <td className="px-4 py-3">
                                                        <div className='flex justify-center gap-3'>
                                                            <Link type='button' className={"p-1.5 group text-black transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"} onClick={() => handleCart(tableData)}><ShoppingCartIcon className='w-5 h-5' title='Add to Cart' /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>)
                                        })}
                                    </Table>
                                ) : (
                                    <div className='flex justify-center items-center h-[15vh]'>
                                        <p className='text-[3vw] md:text-2xl font-bold'>Sorry! Data Not Found</p>
                                    </div>
                                )
                                }
                                <br />
                                <div className='grid grid-cols-3 mb-1'>
                                    <div className='col-span-2 flex justify-start items-center'>
                                        <p className='text-[4vw] md:text-2xl font-bold'>Required Material List</p>
                                    </div>
                                    <div className='grid grid-cols-2'>
                                        <div></div>
                                        <div className='flex justify-start items-center'>
                                            <Link type='button' className={'w-full group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color '} onClick={openModal}>
                                                Add
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {
                                    requisitionData && requisitionData.length > 0 ? (
                                        <Table tableHeading={tableHeading1}>
                                            {requisitionData.map((item, i) => {
                                                return (<Fragment key={i}>
                                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                                        <td className="px-4 py-3">{i + 1}</td>
                                                        <td className="px-4 py-3">
                                                            {
                                                                id ? (item.material_name) : (
                                                                    material && material.map((data, id) => {
                                                                        return (<Fragment key={id}>
                                                                            <h1>{data.id == item.material_name && data.material_name}</h1>
                                                                        </Fragment>
                                                                        )
                                                                    }))
                                                            }
                                                        </td>
                                                        <td className="px-4 py-3">{item.material_unit}</td>
                                                        <td className="px-4 py-3">{parseInt(item.quantity).toLocaleString('en-IN')}</td>
                                                        <td className="px-4 py-3">
                                                            <div className='flex justify-center gap-3'>
                                                                <Link onClick={() => { id ? deleteActivity(item.id) : handleDelete(i) }} className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><TrashIcon className='w-5 h-5' /></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>)
                                            })}
                                        </Table>
                                    ) : (
                                        <div className='flex justify-center items-center h-[15vh]'>
                                            <p className='text-[3vw] md:text-2xl font-bold'>Sorry! Data Not Found</p>
                                        </div>
                                    )

                                }
                                <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                    Save Now
                                </Button>
                            </form>
                        </Card>
                    </div>

                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='m-5' id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Add Requisition </h1>
                                            <hr className='my-4 border border-primary-color' />
                                        </div>
                                        <form onSubmit={(e) => { id ? handleModelUpdate(e) : handleModelAdd(e) }}>
                                            <div className=' grid grid-cols-1 gap-4'>
                                                <div>
                                                    <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                                    <CustomSelect id={'material_name'} name={'material_name'} value={inputs.material_name} onChange={handleChangesub} >
                                                        <option value={''}>--- Choose Material ---</option>
                                                        {material.map((materialData, Id) => {
                                                            return (<Fragment key={Id}>
                                                                <option value={materialData.id}>{materialData.material_name}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.material_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'material_unit'} labelText={'Material unit'} className={'mb-1'} />
                                                    <Input id={'material_unit'} type={'text'} name={'material_unit'} value={inputs.material_unit} readOnly={true} />
                                                    <Validation error={errors.material_unit} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'quantity'} labelText={'Required Quantity '} className={'mb-1'} />
                                                    <Input id={'quantity'} type={'text'} name={'quantity'} value={inputs.quantity} onChange={handleChangesub} />
                                                    <Validation error={errors.quantity} />
                                                </div>
                                            </div>
                                            <Button className={'w-full mt-2 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                                Save
                                            </Button>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }


                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default RequisitionAddEdit

