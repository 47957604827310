import React from 'react';

function Pagination({ itemsPerPage, totalItems, currentPage, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

      if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(3, totalPages); 
    }
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - 2);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }
  
    return paginationNumbers.map((pageNumber) => (
      <button
        key={pageNumber}
        className={`"flex items-center justify-center px-3 h-8 leading-tight text-gray-700 bg-white border border-gray-300 hover:bg-gray-200 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" ${
          currentPage === pageNumber
            ? 'bg-[#75b0eb] text-black'
            : 'hover:bg-gray-200'
        }`}
        onClick={() => handlePageClick(pageNumber)}>
        {pageNumber}
      </button>
    ));
  };
  
  return (
    <div className= "flex items-center justify-end h-8 text-sm mt-1">
    <div className="flex  border">
      <button className={`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"  ${
          currentPage === 1
            ? ' text-black rounded'
            : 'hover:bg-gray-200'
        }`}
        disabled={currentPage === 1}
        onClick={() => handlePageClick(currentPage - 1)}>
        {'<'}
      </button>
      {renderPaginationNumbers()}
      <button className={`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" ${
          currentPage === totalPages
            ? ' text-black rounded'
            : 'hover:bg-gray-200'
        }`}
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(currentPage + 1)}>
        {'>'}
      </button>
    </div>
  </div>
  );
}
export default Pagination;