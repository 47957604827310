import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'


function StockTranferAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Stock Transfer'
        ) : (
            document.title = 'SiteSmart - Add Stock Transfer '
        )
    }
    const user_name = localStorage.getItem('CURRENT_USERNAME');
    const user_id = localStorage.getItem('CURRENT_USERID');
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])

    const navigate = useNavigate()

    const [formInfo, setFormInfo] = useState({
        from_work_name: '',
        from_wing_name: '',
        from_level_name: '',
        to_work_name: '',
        to_wing_name: '',
        to_level_name: '',
        material_name: '',
        material_unit: '',
        work_type: "",
        current_qty: '',
        transfer_qty: '',
        balance: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('from_work_name', formInfo.from_work_name)
        formData.append('from_wing_name', formInfo.from_wing_name)
        formData.append('from_level_name', formInfo.from_level_name)
        formData.append('to_work_name', formInfo.to_work_name)
        formData.append('to_wing_name', formInfo.to_wing_name)
        formData.append('to_level_name', formInfo.to_level_name)
        formData.append('material_name', formInfo.material_name)
        formData.append('material_unit', formInfo.material_unit)
        formData.append('current_qty', formInfo.current_qty)
        formData.append('transfer_qty', formInfo.transfer_qty)
        formData.append('balance', formInfo.balance)
        formData.append('work_type', formInfo.work_type)
        formData.append('resp_person', user_name)
        formData.append('resp_person_id', user_id)
        axiosClient.post(id ? `update_Stock_transfer/${id}` : '/store_Stock_transfer', formData)
            .then(({ data }) => {
                data.status == true ? flasher.success(data.msg) : flasher.warning(data.msg)
                data.status == true ? navigate('/stock-transfer') : console.log();
                setLoader(false)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleTransferData = () => {
        setLoader(true)
        axiosClient.get(`/single_Stock_consumed_data/${id}`)
            .then(({ data }) => {
                setFormInfo(data.single_data)
                setLoader(false)
            })
    }


    const [work, setWork] = useState([])
    const getWorkList = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const [fromWing, setFromWing] = useState([])
    const getFromWingList = () => {
        formInfo.from_work_name && axiosClient.get(`/work_wise_wings/${formInfo.from_work_name}`)
            .then(({ data }) => {
                setFromWing(data.work_wise_wingdata)
            })
    }

    const [fromLevel, setFromLevel] = useState([])
    const getFromLevelList = () => {
        formInfo.from_work_name && formInfo.from_wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.from_work_name}/${formInfo.from_wing_name}`)
            .then(({ data }) => {
                setFromLevel(data.work_wing_wise_leveldata)
            })
    }

    const [toWing, setToWing] = useState([])
    const getToWingList = () => {
        formInfo.from_work_name && axiosClient.get(`/work_wise_wings/${formInfo.to_work_name}`)
            .then(({ data }) => {
                setToWing(data.work_wise_wingdata)
            })
    }

    const [toLevel, setToLevel] = useState([])
    const getToLevelList = () => {
        formInfo.from_work_name && formInfo.from_wing_name && axiosClient.get(`/work_wing_wise_level/${formInfo.to_work_name}/${formInfo.to_wing_name}`)
            .then(({ data }) => {
                setToLevel(data.work_wing_wise_leveldata)
            })
    }

    const [material, setMaterial] = useState([])
    const getMaterial = () => {
        formInfo.from_work_name && formInfo.from_wing_name && formInfo.from_level_name && axiosClient.get(`/work_wing_level_wise_Current_Stock_lists/${formInfo.from_work_name}/${formInfo.from_wing_name}/${formInfo.from_level_name}`)
            .then(({ data }) => {
                setMaterial(data.lists)
            })
    }

    const getCurrentstock = () => {
        formInfo.material_name && axiosClient.get(`/single_Current_Stock/${formInfo.material_name}`)
            .then(({ data }) => {
                setFormInfo(formInfo => ({
                    ...formInfo,
                    current_qty: data.single_data.quantity,
                    material_unit: data.single_data.material_unit
                }))
            })
    }

    useEffect(() => {
        getFromWingList()
    }, [formInfo.from_work_name])

    useEffect(() => {
        getFromLevelList()
    }, [formInfo.from_work_name, formInfo.from_wing_name])

    useEffect(() => {
        getToWingList()
    }, [formInfo.to_work_name])

    useEffect(() => {
        getToLevelList()
    }, [formInfo.to_work_name, formInfo.to_wing_name])

    useEffect(() => {
        getCurrentstock();
    }, [formInfo.material_name])

    useEffect(() => {
        getMaterial();
    }, [formInfo.from_wing_name, formInfo.from_level_name])

    useEffect(() => {
        if (id) {
            getSingleTransferData()
        }
        getWorkList();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Stock Transfer' : 'Add Stock Transfer'} button={
                    <LinkButton to={'/stock-transfer'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[80%] p-6 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'from_work_name'} labelText={'From Work'} className={'mb-1'} />
                                        <CustomSelect id={'from_work_name'} name={'from_work_name'} value={formInfo.from_work_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Work ---</option>
                                            {
                                                work && work.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.from_work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'from_wing_name'} labelText={'From Wing'} className={'mb-1'} />
                                        <CustomSelect id={'from_wing_name'} name={'from_wing_name'} value={formInfo.from_wing_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Wing ---</option>
                                            {fromWing && fromWing.map((data, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={data.id}>{data.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.from_wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'from_level_name'} labelText={'Level'} className={'mb-1'} />
                                        <CustomSelect id={'from_level_name'} name={'from_level_name'} value={formInfo.from_level_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select from_level_name ---</option>
                                            {fromLevel && fromLevel.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.from_level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'material_name'} labelText={'Material Name'} className={'mb-1'} />
                                        <CustomSelect id={'material_name'} name={'material_name'} value={formInfo.material_name} onChange={handleChange} className={'w-full'}>
                                            <option disabled value={''}>--- Choose Material ---</option>
                                            {material.map((data, materialId) => {
                                                return (<Fragment key={materialId}>
                                                    <option value={data.id}>{data.material_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.material_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'material_unit'} labelText={'Material Unit'} className={'mb-1'} />
                                        <Input id={'material_unit'} type={'text'} name={'material_unit'} value={formInfo.material_unit} readOnly={true} />
                                        <Validation error={errors.material_unit} />
                                    </div>

                                    <div>
                                        <Label htmlFor={'current_qty'} labelText={'Current Quantity'} className={'mb-1'} />
                                        <Input id={'current_qty'} type={'text'} name={'current_qty'} value={parseInt(formInfo.current_qty).toLocaleString('en-IN')} readOnly={true} />
                                        <Validation error={errors.current_qty} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'transfer_qty'} labelText={'Transfer Quantity'} className={'mb-1'} />
                                        <Input id={'transfer_qty'} type={'text'} name={'transfer_qty'} value={formInfo.transfer_qty} onChange={handleChange} />
                                        <Validation error={errors.transfer_qty} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'balance'} labelText={'Balance'} className={'mb-1'} />
                                        <Input id={'balance'} type={'text'} name={'balance'} value={parseInt(formInfo.balance = parseInt(formInfo.current_qty) - parseInt(formInfo.transfer_qty)).toLocaleString('en-IN')} readOnly={true} />
                                        <Validation error={errors.balance} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'to_work_name'} labelText={'To Work'} className={'mb-1'} />
                                        <CustomSelect id={'to_work_name'} name={'to_work_name'} className={''} value={formInfo.to_work_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Work ---</option>
                                            {
                                                work.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.to_work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'to_wing_name'} labelText={'To Wing'} className={'mb-1'} />
                                        <CustomSelect id={'to_wing_name'} name={'to_wing_name'} value={formInfo.to_wing_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select Wing ---</option>
                                            {toWing && toWing.map((data, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={data.id}>{data.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.to_wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'to_level_name'} labelText={'To Level'} className={'mb-1'} />
                                        <CustomSelect id={'to_level_name'} name={'to_level_name'} value={formInfo.to_level_name} onChange={handleChange}>
                                            <option disabled value={''}>--- Select to_level_name ---</option>
                                            {toLevel && toLevel.map((levelData, levelId) => {
                                                return (<Fragment key={levelId}>
                                                    <option value={levelData.level}>{levelData.level_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.to_level_name} />
                                    </div>
                                </div>

                                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                                    <Button type="submit" className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span>Save Now</span>
                                    </Button>
                                </div>
                            </form>

                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}


export default StockTranferAddEdit

