import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, ForwardIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Button from '../helpers/Button'
import Loader from '../Common/Loader'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination'

function Inward() {
    document.title = 'SiteSmart - Stock Inward'
    const tableHeading = ['Sr.No', 'Work Name', 'Wing Name', 'Level Name', 'Supplier Name', 'Order Date', 'Inward Material', 'Action']
    const [loader, setLoader] = useState(false)
    const [inward, setInward] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const getInwardList = () => {
        setLoader(true)
        axiosClient.get('/Aproved_po_quotation_wise_supplier_lists')
            .then(({ data }) => {
                setLoader(false)
                setInward(data.lists)
            })
    }

    useEffect(() => {
        getInwardList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Stock Inward'} button={
                    <LinkButton to={'/stock-inward/inward-record'}>
                        <span>Inward Record</span>
                        <ForwardIcon className='w-5 h-5' />
                    </LinkButton>
                }>
                    {inward && inward.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {inward.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.wing_name}</td>
                                        <td className="px-4 py-3">{tableData.level_name}</td>
                                        <td className="px-4 py-3">{tableData.supplier_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.created_at).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">
                                            {
                                                tableData.inward_status === null ?
                                                    <Link title='Edit' to={`/stock-inward/edit-inward/${tableData.id}`} className={'border-2  bg-red-600 border-red-600 text-white w-32 text-center px-3 py-1.5 rounded-md'}><span className='py-1.5 px-3'>Inward</span></Link> :
                                                    <Link title='Approved' className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1.5 rounded-md'><span className='py-1.5 px-3'>Approved</span></Link>
                                            }
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link title='View' to={`/stock-inward/view-inward/${tableData.id}`} ><ActionButton className={'p-1.5 rounded-lg bg-primary'}><EyeIcon className='w-5 h-5 ' /></ActionButton></Link>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Inward Data Not Found</p>
                        </div>
                    )}
                    {inward && inward.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={inward.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default Inward