import React, { Fragment } from 'react'

function CheckBox({id,name,type,className,disabled,onClick,checked}) {
    return (
        <Fragment>
            <input id={id} name={name} type={type} className={"h-5 w-5 rounded border-gray-500 text-primary-color focus:ring-primary-color "+(className)} disabled={disabled} onClick={onClick} checked={checked}/>
        </Fragment>
    )
}

export default CheckBox