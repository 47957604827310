import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { ArrowDownCircleIcon, EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../../helpers/Button'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'

function ProfitLossReport() {
  document.title = 'SiteSmart - Trading,Profit & Loss Account '
  const tableHeading = ['Sr.No', 'date', 'Invoice No.', 'Total Amount.', 'Due Date', 'Balance Due  ', 'Payment Date', 'Payment 1', ' Payment Date.', 'Payment 2', ' Payment Date', ' Payment 3  ', ' Payment Date.', 'Payment 4', ' Payment Date', ' Payment 5', 'Payment Date']
  const [loader, setLoader] = useState(false)
  const [modalLoader, setModalLoader] = useState(false)
  const [agencyLists, setAgencyLists] = useState('')
  const getAgencyLists = () => {
    setLoader(true)
    axiosClient.get('/Contractorpayment_lists')
      .then(({ data }) => {
        setLoader(false)
        setAgencyLists(data.lists)
      })
  }
  const deleteAgency = (id) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete(`/delete_Contractorpayment/${id}`)
          .then(({ data }) => {
            flasher.success(data.msg)
            getAgencyLists()
          })
      }
    })
  }


  const [totalGrossPayment, setTotalGrossPayment] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState([])
  function closeModal() { setIsOpen(false) }
  function openModal(id) {
    setIsOpen(true)
    setModalLoader(true)
    axiosClient.get(`/single_Contractorpayment_data/${id}`)
      .then(({ data }) => {
        setModalLoader(false)
        setModalData(data.single_data)
      })
  }
  useEffect(() => {
    getAgencyLists()
  }, [])
  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <PageComponent title={'Trading,Profit & Loss Account'} >
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className='pr-5'>
              <Label htmlFor={'date'} labelText={'From Date'} className={'mb-1'} />
              <Input id={'date'} type={'date'} name={'date'} />
              {/* <Validation error={errors.date} /> */}
            </div>
            <div className="pl-5">
              <Label htmlFor={'date'} labelText={'To Date'} className={'mb-1'} />
              <Input id={'date'} type={'date'} name={'date'} />
            </div>
            <div className="justify-end flex py-5 ml-3 mt-2">
              <Button className='ml-2' to={'/accounting/add-client-payment'}>
                <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                <span>Sort By Site name</span>
              </Button>
            </div>
          </div>
          <br />
          <div className='grid place-content-center font-bold text-2xl gap-2 mb-3'>Trading,Profit & Loss Account</div>
          

          <div className='bg-gray-300  content-center grid border-secondary-color '>
            <table class="table-auto  ">
              <thead>
                <tr className='border'>
                  <th class="w-96 px-4 py-2">Particulers</th>
                  <th class="px-4 py-2">01.04.2022 To 31.03.2023</th>
                  <th class="w-96 px-4 py-2">Particulers</th>
                  <th class="px-4 py-2">01.04.2022 To 31.03.2023</th>

                </tr>
              </thead>
              <tbody>
                <tr >
                  <td class="border px-4 py-4">Opening Stock</td>
                  <td class="border px-4 py-4"></td>
                  <td class="border px-4 py-4">Sales</td>
                  <td class="border px-4 py-4"></td>
                </tr>
                <tr>
                  <td class="border px-4 py-4">Purchase</td>
                  <td class="border px-4 py-4"></td>
                  <td class="border px-4 py-4">Direct Income </td>
                  <td class="border px-4 py-4"></td>
                </tr>
                <tr>
                  <td class="border px-4 py-4"> Direct Expenses</td>
                  <td class="border px-4 py-4"></td>
                  <td class="border px-4 py-4">Indirect Income</td>
                  <td class="border px-4 py-4"> </td>
                </tr>
                <tr>
                  <td class="border px-4 py-4">Indirect Expenses</td>
                  <td class="border px-4 py-4"></td>
                  <td class="border px-4 py-4">Closing Stock </td>
                  <td class="border px-4 py-4"></td>
                </tr>
                <tr>
                  <td class="border px-4 py-4">Net Profit
                  <span className='flex '>( At The Time Of Profit)</span>
                  </td>
                  <td class="border px-4 py-4"></td>
                  <td class="border px-4 py-4">Net Loss  
                  <span className='flex '>( At The Time Of Loss)</span>
                  </td>
                  <td class="border px-4 py-4"></td>
                </tr>
                <tr>
                  <td class="border font-bold px-4 py-5"> </td>
                  <td class="border font-bold  px-4 py-5"></td>
                  <td class="border font-bold  px-4 py-5"></td>
                  <td class="border font-bold px-4 py-5"></td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* View Modal */}
          <Transition appear show={isOpen} as={Fragment} >
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                    <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                      {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                        // <Fragment>
                        //     <div className='flex justify-between items-center gap-4'>
                        //         <h1 className='text-center uppercase font-bold text-xl tracking-widest'>Contractor Payment Information</h1>
                        //         <Button className={'py-1 bg-red-600 hover:bg-red-600'} onClick={closeModal}>Close</Button>
                        //     </div>
                        //     <hr className='my-4 border border-primary-color' />
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Work Name</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{''}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Work Type</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.work_type}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Bill No.</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.bill_no}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Bill Date</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.bill_date}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Bill Gross Amount</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.bill_gross_amount}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Tax Amount</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.tax_amount}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>TDS Amount</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.tds_amount}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Bill Payable Amount</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.bill_payable_amount}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>beneficiary</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.beneficiary}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Unit Based Rate Total Qty</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.unit_based_rate_total_qty}</h1>
                        //     </div>
                        //     <div className='flex items-center gap-4 w-full py-2'>
                        //         <h1 className='w-36 font-bold'>Unit Based Rate Est Amount</h1>
                        //         <h1>-</h1>
                        //         <h1 className='ml-6'>{modalData.unit_based_rate_est_amount}</h1>
                        //     </div>
                        // </Fragment>
                        <Fragment>
                          <table class="table-auto">
                            <thead>
                              <tr>
                                <th class="px-4 py-2">Title</th>
                                <th class="px-4 py-2">Author</th>
                                <th class="px-4 py-2">Views</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="border px-4 py-2">Intro to CSS</td>
                                <td class="border px-4 py-2">Adam</td>
                                <td class="border px-4 py-2">858</td>
                              </tr>
                              <tr class="bg-gray-100">
                                <td class="border px-4 py-2">A Long and Winding Tour of the History of UI Frameworks and Tools and the Impact on Design</td>
                                <td class="border px-4 py-2">Adam</td>
                                <td class="border px-4 py-2">112</td>
                              </tr>
                              <tr>
                                <td class="border px-4 py-2">Intro to JavaScript</td>
                                <td class="border px-4 py-2">Chris</td>
                                <td class="border px-4 py-2">1,280</td>
                              </tr>
                            </tbody>
                          </table>
                        </Fragment>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </PageComponent>
      )}
    </Fragment>
  )
}




export default ProfitLossReport


