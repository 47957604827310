import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import axiosClient from '../../Axios'
import CustomSelect from '../helpers/CustomSelect'
import CurrencyInput from 'react-currency-input-field';

function SalaryManagementAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Add Transctions'
        ) : (
            document.title = 'SiteSmart - Add New Salary'
        )
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        month: '',
        work_name: '',
        employee_name: '',
        designation: '',
        salary_per_month: '',
        total_days_worked: '',
        professional_tax: '',
        travelling_allowance:'',
        total: '',
        advanced_paid: 0,
        total_salary: '',
        balance: '',
        paid_amount: '',
        paid_date: '',
        paid_from_account: '',
        payment_mode: '',
        Tr_no: '',
        Tr_date: '',
        Tr_remark: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleAdvPaid = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            advanced_paid: e
        }))
    }

    const handlePaidAmt = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            paid_amount: e
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('month', formInfo.month)
        formData.append('work_name', formInfo.work_name)
        formData.append('employee_name', formInfo.employee_name)
        formData.append('designation', empdesignation)
        formData.append('salary_per_month', empsalarypermonth)
        formData.append('total_days_worked', formInfo.total_days_worked)
        formData.append('professional_tax', formInfo.professional_tax)
        formData.append('travelling_allowance', formInfo.travelling_allowance)
        formData.append('total', formInfo.total)
        formData.append('advanced_paid', formInfo.advanced_paid)
        formData.append('total_salary', formInfo.total_salary)
        formData.append('balance', formInfo.balance)
        formData.append('paid_amount', formInfo.paid_amount)
        formData.append('paid_date', formInfo.paid_date)
        formData.append('paid_from_account', formInfo.paid_from_account)
        formData.append('payment_mode', formInfo.payment_mode)
        formData.append('Tr_no', formInfo.Tr_no)
        formData.append('Tr_date', formInfo.Tr_date)
        formData.append('Tr_remark', formInfo.Tr_remark)
        axiosClient.post(id ? `update_SalaryManagement/${id}` : '/store_SalaryManagement', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/salarymanagement')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const [empsalarypermonth, setEmpsalarypermonth] = useState()
    const [empdesignation, setEmpdesignation] = useState()
    const getEmpData = () => {
        formInfo.employee_name && axiosClient.get(`/single_emp_data/${formInfo.employee_name}`)
            .then(({ data }) => {
                setLoader(false)
                setEmpdesignation(data.single_data.job_title)
                setEmpsalarypermonth(data.single_data.salary_per_month)
            })
    }

    const getAdvAmount = () => {
        formInfo.employee_name && axiosClient.get(`/employee_advance_monthwise_data/${formInfo.employee_name}/${formInfo.month}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo({
                    ...formInfo,
                    advanced_paid: data.advanced_paid_amount
                })
            })
    }

    const [workNameList, setWorkNameList] = useState([])
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorkNameList(data.lists)
            })
    }

    const [employeelist, setEmployeelist] = useState([])
    const getEmployeeData = () => {
        setLoader(true)
        axiosClient.get(`/employee_list`)
            .then(({ data }) => {
                setLoader(false)
                setEmployeelist(data.lists)
            })
    }
    const[balance, setBalance]=useState('')
    const getSingleSalaryData = () => {
        setLoader(true)
        axiosClient.get(`/single_SalaryManagement_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setBalance(data.single_data.balance)
            })
        }
        console.log("bal",formInfo);

    useEffect(() => {
        if (id) {
            getSingleSalaryData()
        }
        getWorkData();
        getEmployeeData();
    }, [])

    useEffect(() => {
        getEmpData()
    }, [formInfo.employee_name])

    useEffect(() => {
        getAdvAmount()
    }, [formInfo.employee_name, formInfo.month])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Add Transctions' : 'Add New Salary'} button={
                    <LinkButton to={'/salarymanagement'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>}>
                    <div className='flex items-center justify-center '>
                        <Card className={'w-full md:w-[70rem] p-6 bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'month'} labelText={'Month'} className={'mb-1'} />
                                        <Input id={'month'} type={'month'} name={'month'} value={formInfo.month} />
                                        <Validation error={errors.month} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name} >
                                            <option value={''}>--- Select Work ---</option>
                                            {
                                                workNameList.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.work_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'employee_name'} labelText={'Employee Name'} className={'mb-1'} />
                                        <CustomSelect id={'employee_name'} name={'employee_name'} className={''} value={formInfo.employee_name} >
                                            <option value={''}>--- Select Employee ---</option>
                                            {
                                                employeelist.map(data => {
                                                    return (
                                                        <option value={data.id}>{data.emp_name}</option>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                        <Validation error={errors.employee_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'designation'} labelText={'Designation'} className={'mb-1'} />
                                        <Input id={'designation'} type={'text'} name={'designation'} value={empdesignation} readOnly={true} />
                                        <Validation error={errors.designation} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'salary_per_month'} labelText={'Salary Per Month'} className={'mb-1'} />
                                        <CurrencyInput id={'salary_per_month'} type={'text'} name={'salary_per_month'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={empsalarypermonth} readOnly={true} />
                                        <Validation error={errors.salary_per_month} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'total_days_worked'} labelText={'Total Days Worked'} className={'mb-1'} />
                                        <Input id={'total_days_worked'} type={'text'} name={'total_days_worked'} value={formInfo.total_days_worked}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.total_days_worked} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'professional_tax'} labelText={'Professional Tax'} className={'mb-1'} />
                                        <CustomSelect
                                            id={"professional_tax"}
                                            name={"professional_tax"}
                                            value={formInfo.professional_tax}
                                             >
                                            <option value={""}>--- Choose TAX ---</option>
                                            <option value={"0%"}>0%</option>
                                            <option value={"2%"}>2%</option>
                                            <option value={"5%"}>5%</option>
                                            <option value={"7%"}>7%</option>
                                        </CustomSelect>
                                        <Validation error={errors.professional_tax} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'travelling_allowance'} labelText={'Travelling Allowance'} className={'mb-1'} />
                                        <CurrencyInput id={'travelling_allowance'} type={'text'} name={'travelling_allowance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "} 
                                            value={formInfo.travelling_allowance}/>
                                        <Validation error={errors.travelling_allowance} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'total'} labelText={'Total'} className={'mb-1'} />
                                        <CurrencyInput id={'total'} type={'text'} name={'total'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.total = ((parseInt(empsalarypermonth / 30 * parseInt(formInfo.total_days_worked)) - parseInt(formInfo.professional_tax) / 100 * parseInt(empsalarypermonth) + parseInt(formInfo.travelling_allowance)))} />
                                        <Validation error={errors.total} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'advanced_paid'} labelText={'Advanced Paid'} className={'mb-1'} />
                                        {/* <Input id={'advanced_paid'} type={'text'} name={'advanced_paid'} value={formInfo.advanced_paid}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}/> */}
                                        <CurrencyInput id={'advanced_paid'} type={'text'} name={'advanced_paid'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.advanced_paid}/>
                                        <Validation error={errors.advanced_paid} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'total_salary'} labelText={'Total Payable'} className={'mb-1'} />
                                        <CurrencyInput id={'total_salary'} type={'text'} name={'total_salary'} className={"block w-full  rounded-lg border-0 font-bold text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.total_salary = (parseInt(formInfo.total) - parseInt(formInfo.advanced_paid))} />
                                        <Validation error={errors.total_salary} />
                                    </div>
                                </div>
                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                                <div className='mb-4'>
                                    <span className='text-xl font-light'>Transaction Details :</span>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div>
                                        <Label htmlFor={'paid_amount'} labelText={' Paid Amount'} className={'mb-1'} />
                                        <CurrencyInput id={'paid_amount'} type={'text'} name={'paid_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.paid_amount}
                                            onValueChange={handlePaidAmt} />
                                        <Validation error={errors.paid_amount} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'paid_date'} labelText={'Paid Date'} className={'mb-1'} />
                                        <Input id={'paid_date'} type={'date'} name={'paid_date'} value={formInfo.paid_date} onChange={handleChange} />
                                        <Validation error={errors.paid_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'paid_from_account'} labelText={'Paid from Account'} className={'mb-1'} />
                                        <CustomSelect
                                            id={"paid_from_account"}
                                            name={"paid_from_account"}
                                            value={formInfo.paid_from_account}
                                            onChange={handleChange}>
                                            <option value={""}>--- Choose ---</option>
                                            <option value={"Cash"}>Cash</option>
                                            <option value={"Bank"}>Bank</option>
                                        </CustomSelect>
                                        <Validation error={errors.paid_from_account} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'payment_mode'} labelText={'Payment Mode'} className={'mb-1'} />
                                        <CustomSelect id={'payment_mode'} name={'payment_mode'} value={formInfo.payment_mode} onChange={handleChange}>
                                            <option value={''}>--- Cash Account ---</option>
                                            <option value={'NEFT'}>NEFT</option>
                                            <option value={'RTGS'}>RTGS</option>
                                            <option value={'Online'}>Online</option>
                                            <option value={'Cash'}>Cash</option>
                                        </CustomSelect>
                                        <Validation error={errors.payment_mode} />
                                    </div>
                                    {balance == null ?
                                        (
                                            <div>
                                                <Label htmlFor={'balance'} labelText={'Balance'} className={'mb-1'} />
                                                <CurrencyInput id={'balance'} type={'text'} name={'balance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                    value={formInfo.paid_amount == null? (formInfo.balance = (formInfo.total_salary)): formInfo.balance = (formInfo.total_salary)- (formInfo.paid_amount)} />
                                                <Validation error={errors.balance} />
                                            </div>
                                        ) :
                                        (
                                        <div>
                                            <Label htmlFor={'balance'} labelText={'Balance'} className={'mb-1'} />
                                            <input id={'balance'} type={'text'} name={'balance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                value={formInfo.paid_amount == null? (formInfo.balance = balance) :(formInfo.balance =  parseInt(balance)- parseInt(formInfo.paid_amount))}/>
                                            <Validation error={errors.balance} />
                                        </div>
                                        )
                                    }
                                    <div>
                                        <Label htmlFor={'Tr_no'} labelText={'Transaction Number'} className={'mb-1'} />
                                        <Input id={'Tr_no'} type={'text'} name={'Tr_no'} value={formInfo.Tr_no} onChange={handleChange} />
                                        <Validation error={errors.Tr_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Tr_date'} labelText={'Transaction Date'} className={'mb-1'} />
                                        <Input id={'Tr_date'} type={'date'} name={'Tr_date'} value={formInfo.Tr_date} onChange={handleChange} />
                                        <Validation error={errors.Tr_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Tr_remark'} labelText={'Transaction Remark'} className={'mb-1'} />
                                        <Input id={'Tr_remark'} type={'text'} name={'Tr_remark'} value={formInfo.Tr_remark} onChange={handleChange} />
                                        <Validation error={errors.Tr_remark} />
                                    </div>
                                </div>
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default SalaryManagementAddEdit
