import React, { Fragment, useEffect, useRef, useState } from 'react'
import axiosClient from '../../Axios'
import PageComponent from '../../components/PageComponent'
import Loader from '../Common/Loader'
import Table from '../helpers/Table'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import Validation from '../helpers/Validation'
import { Link, NavLink } from 'react-router-dom'
import { PrinterIcon } from '@heroicons/react/20/solid'
import { useReactToPrint } from 'react-to-print'
import { Menu, Transition } from '@headlessui/react'
import { ArrowsUpDownIcon, DeviceTabletIcon, DocumentArrowDownIcon, DocumentArrowUpIcon, DocumentChartBarIcon, DocumentIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import { CSVLink } from 'react-csv'
import Pagination from '../helpers/Pagination'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function CurrentStock() {
    document.title = 'SiteSmart - Current Stock'
    const tableHeading = ['Sr.No', 'Work', 'Wing', 'Level', 'Material', 'Unit', 'Current Stock']
    const [work, setWork] = useState([])
    const [loader, setLoader] = useState(false)
    const [stockList, setStockList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        wing_name: '',
    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [isOpen, setOpen] = useState(false);

    const handleDropDown = () => {
        setOpen(!isOpen);
    };


    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const getCurrentStockList = () => {
        setLoader(true)
        axiosClient.get('/Current_Stock_lists')
            .then(({ data }) => {
                setStockList(data.lists)
                setLoader(false)
            })
    }

    // const getWorkWiseLists = () => {
    //     // setLoader(true)
    //     formInfo.work_name && axiosClient.get(`/workwing_wise_Current_Stock_lists/${formInfo.work_name}/${formInfo.wing_name}`)
    //         .then(({ data }) => {
    //             setStockList(data.lists)
    //             setLoader(false)
    //         })
    // }


    const getWingWiseLists = () => {
        // setLoader(true)
        formInfo.work_name && axiosClient.get(`/workwing_wise_Current_Stock_lists/${formInfo.work_name}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setStockList(data.lists)
            })
    }

    const getWork = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWork(data.lists)
                setLoader(false)
            })
    }

    const [wing, setWing] = useState([])
    const getWingLists = () => {
        formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Work Name', key: 'work_name' },
        { label: 'Wing Name', key: 'wing_name' },
        { label: 'Level Name', key: 'level_name' },
        { label: 'Material Name', key: 'material_name' },
        { label: 'Material Unit', key: 'material_unit' },
        { label: 'Quantity', key: 'quantity' },
    ];

    const csvData = stockList && stockList.map((item, index) => ({
        index: index + 1,
        work_name: item.work_name,
        wing_name: item.wing_name,
        level_name: item.level_name,
        material_name: item.material_name,
        material_unit: item.material_unit,
        quantity: item.quantity,
    }))

    useEffect(() => {
        getWingLists()
        getWingWiseLists()
    }, [formInfo.work_name])

    useEffect(() => {
        getWingWiseLists()
    }, [formInfo.wing_name])

    useEffect(() => {
        getCurrentStockList()
        getWork();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Current Stock'}>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-x-1'>
                        <div className='ml-2'>
                            <Label htmlFor={'work_name'} labelText={'Work'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name}   onChange={handleChange} className={'w-[25%]'}>
                                <option disabled value={''}>--- Select Work ---</option>
                                {work.map((workData, workId) => {
                                    return(<Fragment key={workId}>
                                        <option value={workData.id}>{workData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className='ml-2'>
                            <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                            <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} className={'w-[25%]'}>
                                <option disabled value={''}>--- Select Wing ---</option>
                                {wing && wing.map((wingData, wingId) => {
                                    return (<Fragment key={wingId}>
                                        <option value={wingData.id}>{wingData.wing_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className=""></div>
                        <div className="">
                          
                        </div>
                        <div className="">
                            <Menu as="div" className="">
                                <Label htmlFor={'date'} labelText={'Print'} className={'mb-1 opacity-0'} />
                                <div className="flex justify-end pt-2">
                                    <Menu.Button >
                                        <span className="sr-only">Open user menu</span>
                                        <p className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 inline-flex justify-center rounded-md border border-transparent hover:bg-primary-color bg-primary-color px-7 py-2 text-sm font-medium text-white focus-visible:ring-offset-2" ><ArrowsUpDownIcon className='w-5 h-5' /></p>
                                    </Menu.Button>
                                </div>
                                
                                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item >
                                            {({ active }) => (
                                                <NavLink onClick={''} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><TableCellsIcon className='w-8 h-5 pr-1' /> Import CSV</NavLink>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item >
                                            {({ active }) => (
                                                <NavLink onClick={handlePrint} className={classNames(active ? 'bg-gray-200' : '', 'flex px-2 py-2 text-sm text-gray-700')}><DocumentIcon className='w-8 h-5 pr-1' /> Export PDF</NavLink>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            <div className="px-2 py-2">
                                                <CSVLink data={csvData} headers={headers} filename={'Stock Report.csv'}>
                                                    <button className='flex'><DocumentArrowUpIcon className='w-8 h-5 pr-1' /> Export CSV</button>
                                                </CSVLink>
                                            </div>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                    <br />
                    {stockList && stockList.length > 0 ? (
                        <div ref={componentRef}>
                            <Table tableHeading={tableHeading}>
                                {stockList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                            <td className="px-4 py-3">{tableData.work_name}</td>
                                            <td className="px-4 py-3">{tableData.wing_name}</td>
                                            <td className="px-4 py-3">{tableData.level_name}</td>
                                            <td className="px-4 py-3">{tableData.material_name}</td>
                                            <td className="px-4 py-3">{tableData.material_unit}</td>
                                            <td className="px-4 py-3">{parseInt(tableData.quantity).toLocaleString('en-IN')}</td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Current Stock Data Not Found</p>
                        </div>
                    )}
                    {stockList && stockList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={stockList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default CurrentStock
