import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../../helpers/Card'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import flasher from '@flasher/flasher'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Button from '../../helpers/Button'

function WingDetailsAddEdit() {
    const { id , work_name, work_id} = useParams()
    if (work_id) {
        document.title = 'SiteSmart - Edit Wing'
    } else {
        document.title = 'SiteSmart - Add New Wing'
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const [wingcadImage, setWingCadImage] = useState('');
    const wingcadImageChange = (e) => {
        setWingCadImage(e.target.files[0]);
    }
    const [workname, setWorkName] = useState("")    
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
        .then(({ data }) => {
            setLoader(false)
            setWorkName(data.list)            
            })
    }  
    const [userLists, setUserLists] = useState('')
    const getUserLists = () => {
        setLoader(true)
        axiosClient.get('/user_lists')
            .then(({ data }) => {
                setLoader(false)
                setUserLists(data.lists)
            })
    }
    const [formInfo, setFormInfo] = useState({
        work_name:'' || work_name,
        wing_name: '',
        wing_budget: '',
        wing_start_date: '',
        wing_completion_date: '',
        wing_description: '',
        assigned_project_incharge: '',
        no_of_levels:'',
        total_no_of_flats:'',
        total_no_of_parkings:'',
        wing_work_status: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', workname.id)
        formData.append('wing_name', formInfo.wing_name)
        formData.append('wing_budget', formInfo.wing_budget)
        formData.append('wing_start_date', formInfo.wing_start_date)
        formData.append('wing_completion_date', formInfo.wing_completion_date)
        formData.append('wing_description', formInfo.wing_description)
        formData.append('assigned_project_incharge', formInfo.assigned_project_incharge)
        formData.append('no_of_levels', formInfo.no_of_levels)
        formData.append('total_no_of_flats', formInfo.total_no_of_flats)
        formData.append('total_no_of_parkings', formInfo.total_no_of_parkings)
        formData.append('wing_work_status', formInfo.wing_work_status)
        formData.append('upload_wingcad', wingcadImage)
        axiosClient.post(id ? `update_wing_details/${id}` : '/store_wing_details', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate(`/work-management/view-wing-details/${work_id}`)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const getSingleWorkData = () => {
        setLoader(true)
        axiosClient.get(`/single_wing_details_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setWingCadImage(data.single_data.upload_wingcad)

            })
    }  
    useEffect(() => {
        if (id) {
            getSingleWorkData();
        }
        getWorkDetails();
        getUserLists();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Wing' : 'Add New Wing'} button={
                    <LinkButton to={`/work-management/view-wing-details/${work_id}`}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex gap-4'>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%]'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full'/> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0'>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-[85%] md:w-[80%]'>
                    <div className='flex items-center justify-center'>
                        <Card className="w-[60rem] p-4 bg-default-color ">
                            <form onSubmit={handleSubmit} className='mt-4'>
                                <div className='grid grid-cols-1 md:grid-cols-4 gap-x-6'>
                                <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} readOnly={true}/>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                        <Input id={'wing_name'} type={'text'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_budget'} labelText={'Wing Budget'} className={'mb-1'} />
                                        <Input id={'wing_budget'} type={'text'} name={'wing_budget'} value={formInfo.wing_budget} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  />
                                        <Validation error={errors.wing_budget} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_description'} labelText={'Wing Description'} className={'mb-1'} />
                                        <Input id={'wing_description'} type={'text'} name={'wing_description'} value={formInfo.wing_description} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.wing_description} />
                                    </div>                                   
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_start_date'} labelText={'Estimated Start date'} className={'mb-1'} />
                                        <Input id={'wing_start_date'} type={'date'} name={'wing_start_date'} value={formInfo.wing_start_date} onChange={handleChange} />
                                        <Validation error={errors.wing_start_date} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_completion_date'} labelText={'Estimated Completion Date'} className={'mb-1'} />
                                        <Input id={'wing_completion_date'} type={'date'} name={'wing_completion_date'} value={formInfo.wing_completion_date} onChange={handleChange} />
                                        <Validation error={errors.wing_completion_date} />
                                    </div>
                                    {/* <div className='mb-3'>
                                        <Label htmlFor={'assigned_project_incharge'} labelText={'Assigned Project Incharge'} className={'mb-1'} />
                                        <Input id={'assigned_project_incharge'} type={'text'} name={'assigned_project_incharge'} value={formInfo.assigned_project_incharge} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]| /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.assigned_project_incharge} />
                                    </div> */}
                                    <div className='mb-3'>
                                        <Label htmlFor={'assigned_project_incharge'} labelText={'Assigned Project Incharge'} className={'mb-1'} />
                                        <CustomSelect id={'assigned_project_incharge'} name={'assigned_project_incharge'} value={formInfo.assigned_project_incharge} onChange={handleChange} >
                                            <option disabled value={''}>--- Choose Project Incharge ---</option>
                                            {userLists && userLists.map((userData, userId) => {
                                                return (<Fragment key={userId}>
                                                    <option value={userData.id}>{userData.name} ({((userData.role).split('_')).join(' ')})</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-4 gap-x-6'>                                    
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_work_status'} labelText={'Work Status'} className={'mb-1'} />
                                        <CustomSelect id={'wing_work_status'} name={'wing_work_status'} value={formInfo.wing_work_status} onChange={handleChange}>
                                            <option value={''}>--- Choose Work Status ---</option>
                                            <option value={'Start'}>Start</option>
                                            <option value={'Pending'}>Pending</option>
                                            <option value={'In Progress'}>In Progress</option>
                                            <option value={'Completed'}>Completed</option>
                                        </CustomSelect>
                                        <Validation error={errors.wing_work_status}/>
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'no_of_levels'} labelText={'No. Of Levels'} className={'mb-1'} />
                                        <Input id={'no_of_levels'} type={'text'} name={'no_of_levels'} value={formInfo.no_of_levels} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  />
                                        <Validation error={errors.no_of_levels} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'total_no_of_flats'} labelText={'Total No. Of Flats'} className={'mb-1'} />
                                        <Input id={'total_no_of_flats'} type={'text'} name={'total_no_of_flats'} value={formInfo.total_no_of_flats} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.total_no_of_flats} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'total_no_of_parkings'} labelText={'Total No. Of Parkings'} className={'mb-1'} />
                                        <Input id={'total_no_of_parkings'} type={'text'} name={'total_no_of_parkings'} value={formInfo.total_no_of_parkings} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.total_no_of_parkings} />
                                    </div>    
                                </div>
                                <div>
                                    <Label htmlFor={'upload_wingcad'} labelText={'Wing CAD Image'} />
                                    <img src={wingcadImage ? (
                                        wingcadImage.name ? URL.createObjectURL(wingcadImage) : IMG_URL + "/assets/images/WingCADFile/" + wingcadImage
                                    ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Wing-img" className='w-96 h-80 rounded' />
                                    <Input id={'upload_wingcad'} type={'file'} name={'upload_wingcad'} onChange={wingcadImageChange} className={'p-[0px!important] mt-2'} />
                                    <Validation error={errors.upload_wingcad} />
                                </div>
                                <Button className={'w-60 mx-auto mt-9'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>                               
                            </form>
                        </Card>
                    </div>
                    </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default WingDetailsAddEdit
