import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import CustomSelect from '../helpers/CustomSelect'
import TextArea from '../helpers/TextArea'
import { useStateContext } from '../../context/ContextProvider'
import CurrencyInput from 'react-currency-input-field';
import PleaseWait from '../Common/PleaseWait'

function EmployeeDetailsAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'SiteSmart - Edit Employee'
        ) : (
            document.title = 'SiteSmart - Add New Employee'
        )
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const tableHeading = ['Sr.No', 'Document Name', 'Upload', 'Preview Img']
    const [viewModel, setViewModel] = useState(false)
    const [modelImage, setModelImage] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [adharcard, setAdharcard] = useState()
    const [pancard, setPancard] = useState()
    const [photo, setPhoto] = useState()
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [folderName, setFolderName] = useState('')
    const [empImage, setEmpImage] = useState('');
    const empImageChange = (e) => {
        setEmpImage(e.target.files[0]);
    }

    const [formInfo, setFormInfo] = useState({
        emp_name: '',
        dob: '',
        gender: '',
        job_title: '',
        hire_date: '',
        salary_per_month: '',
        address: '',
        phone_no: '',
        email: '',
        aadhar_no: '',
        pan_no: '',
        bank_name: '',
        branch_name: '',
        bank_acc_no: '',
        IFSC_code: '',
        // emp_image: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSalary = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            salary_per_month: e
        }))
    }

    const handleModelImage = (image, foldername) => {
        setModalLoader(true)
        setViewModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setModalLoader(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('emp_name', formInfo.emp_name)
        formData.append('job_title', formInfo.job_title)
        formData.append('dob', formInfo.dob)
        formData.append('gender', formInfo.gender)
        formData.append('address', formInfo.address)
        formData.append('phone_no', formInfo.phone_no)
        formData.append('email', formInfo.email)
        formData.append('hire_date', formInfo.hire_date)
        formData.append('salary_per_month', formInfo.salary_per_month)
        formData.append('pan_no', formInfo.pan_no)
        formData.append('aadhar_no', formInfo.aadhar_no)
        formData.append('bank_name', formInfo.bank_name)
        formData.append('branch_name', formInfo.branch_name)
        formData.append('bank_acc_no', formInfo.bank_acc_no)
        formData.append('IFSC_code', formInfo.IFSC_code)
        formData.append('adharcard', adharcard)
        formData.append('pancard', pancard)
        formData.append('photo', photo)
        axiosClient.post(id ? `update_employee/${id}` : '/store_employee', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/employee-details')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    const getSingleEmployeeData = () => {
        setLoader(true)
        axiosClient.get(`/single_emp_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
                setEmpImage(data.single_data.emp_image)
                setAdharcard(data.single_data.adharcard)
                setPancard(data.single_data.pancard)
                setPhoto(data.single_data.photo)
            })
    }
    useEffect(() => {
        if (id) {
            getSingleEmployeeData()
        }
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Employee' : 'Add New Employee'} button={
                    <LinkButton to={'/employee-details'}>
                        <ArrowLeftCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full md:w-[60rem] p-6  bg-default-color'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'emp_name'} labelText={'Employee Name'} className={'mb-1'} />
                                        <Input id={'emp_name'} type={'text'} name={'emp_name'} value={formInfo.emp_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.emp_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'dob'} labelText={'Date Of Birth'} className={'mb-1'} />
                                        <Input id={'dob'} type={'date'} name={'dob'} value={formInfo.dob} onChange={handleChange} />
                                        <Validation error={errors.dob} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'gender'} labelText={'Gender'} className={'mb-1'} />
                                        <CustomSelect id={'gender'} name={'gender'} value={formInfo.gender} onChange={handleChange}>
                                            <option value={''}>--- Choose Gender ---</option>
                                            <option value={'Male'}>Male</option>
                                            <option value={'Female'}>Female</option>
                                        </CustomSelect>
                                        <Validation error={errors.gender} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'job_title'} labelText={'Designation'} className={'mb-1'} />
                                        <Input id={'job_title'} type={'text'} name={'job_title'} value={formInfo.job_title} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.job_title} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'hire_date'} labelText={'Joining Date'} className={'mb-1'} />
                                        <Input id={'hire_date'} type={'date'} name={'hire_date'} value={formInfo.hire_date} onChange={handleChange} />
                                        <Validation error={errors.hire_date} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'salary_per_month'} labelText={'Salary Per Month'} className={'mb-1'} />
                                        {/* <Input id={'salary_per_month'} type={'text'} name={'salary_per_month'} value={formInfo.salary_per_month} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} /> */}
                                        <CurrencyInput id={'salary_per_month'} type={'text'} name={'salary_per_month'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.salary_per_month}
                                            onValueChange={handleSalary}/>
                                        <Validation error={errors.salary_per_month} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'address'} labelText={'Address'} className={'mb-1'} />
                                        <TextArea id={'address'} type={'text'} rows={1} name={'address'} value={formInfo.address} onChange={handleChange} />
                                        <Validation error={errors.address} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'phone_no'} labelText={'Phone No'} className={'mb-1'} />
                                        <Input id={'phone_no'} type={'text'} name={'phone_no'} value={formInfo.phone_no} onChange={handleChange} maxLength={10} minLength={10} onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                        <Validation error={errors.phone_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'email'} labelText={'Email'} className={'mb-1'} />
                                        <Input id={'email'} type={'text'} name={'email'} value={formInfo.email} onChange={handleChange} />
                                        <Validation error={errors.email} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'aadhar_no'} labelText={'Aadhar No.'} className={'mb-1'} />
                                        <Input id={'aadhar_no'} type={'text'} name={'aadhar_no'} value={formInfo.aadhar_no} onChange={handleChange} maxLength={12} minLength={12} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.aadhar_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'pan_no'} labelText={'Pan No.'} className={'mb-1'} />
                                        <Input id={'pan_no'} type={'text'} name={'pan_no'} value={formInfo.pan_no} onChange={handleChange} maxLength={10} />
                                        <Validation error={errors.pan_no} />
                                    </div>
                                </div>
                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                                <div className='mb-4'>
                                    <span className='text-xl font-light'>Bank Account Details :</span>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'bank_name'} labelText={'Bank Name'} className={'mb-1'} />
                                        <Input id={'bank_name'} type={'text'} name={'bank_name'} value={formInfo.bank_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.bank_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'branch_name'} labelText={'Branch Name'} className={'mb-1'} />
                                        <Input id={'branch_name'} type={'text'} name={'branch_name'} value={formInfo.branch_name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.branch_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'bank_acc_no'} labelText={'Bank Account No'} className={'mb-1'} />
                                        <Input id={'bank_acc_no'} type={'text'} name={'bank_acc_no'} value={formInfo.bank_acc_no} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} maxLength={16} />
                                        <Validation error={errors.bank_acc_no} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'IFSC_code'} labelText={'IFSC Code'} className={'mb-1'} />
                                        <Input id={'IFSC_code'} type={'text'} name={'IFSC_code'} value={formInfo.IFSC_code} onChange={handleChange} maxLength={11} />
                                        <Validation error={errors.IFSC_code} />
                                    </div>
                                </div>
                                <br></br>
                                <h1 className='text-xl font-bold mb-4'>Employee Documents</h1>
                                <div className="mb-3">
                                    <Table tableHeading={tableHeading}>
                                        <Fragment>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">3</td>
                                                <td className="px-4 py-3">Photo</td>
                                                <td className="px-4 py-3">
                                                    <label for="photo" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="photo" name={'photo'} type="file" onChange={(e) => setPhoto(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(photo, 'photo')}>
                                                            <img src={photo ? (
                                                                photo.name ? URL.createObjectURL(photo) : IMG_URL + "/assets/images/Employee/Photo/" + photo
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">1</td>
                                                <td className="px-4 py-3">Adhar Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="adharcard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="adharcard" name='adharcard' type="file" onChange={(e) => setAdharcard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(adharcard, 'adharcard')}>
                                                            <img src={adharcard ? (
                                                                adharcard.name ? URL.createObjectURL(adharcard) : IMG_URL + "/assets/images/Employee/adharcard/" + adharcard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                <td className="px-4 py-3">2</td>
                                                <td className="px-4 py-3">PAN Card</td>
                                                <td className="px-4 py-3">
                                                    <label for="pancard" class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                        <div class="flex flex-col items-center justify-center">
                                                            <svg aria-hidden="true" class="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                        <input id="pancard" name='pancard' type="file" onChange={(e) => setPancard(e.target.files[0])} class="hidden p-[0px!important] mt-2" />
                                                    </label>
                                                </td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(pancard, 'pancard')}>
                                                            <img src={pancard ? (
                                                                pancard.name ? URL.createObjectURL(pancard) : IMG_URL + "/assets/images/Employee/pancard/" + pancard
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>
                                            </tr>
                                        </Fragment>
                                    </Table>
                                    <br /><br />
                                </div>
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-2xl text-[3vw]  tracking-widest'>View Full Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={modelImage ? (
                                                modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/${folderName}/` + modelImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full md:h-[500px] h-[300px] rounded' />
                                        }

                                        <div className="mt-4  grid grid-cols-3">
                                        <button id='gotit'
                                            type="button"
                                            className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 px-3 py-1 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => setViewModel(false)}>
                                            Got it, thanks!
                                        </button>
                                        <div className=""></div>
                                        <div className=""></div>
                                    </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default EmployeeDetailsAddEdit
