import React, { useRef, Fragment, useEffect, useState } from 'react'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import { useReactToPrint } from "react-to-print";
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import { useStateContext } from '../../context/ContextProvider'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function EmployeeDetails() {
    document.title = 'SiteSmart - Employee Details'
    const tableHeading = ['Sr.No', 'Employee Name', 'Designation', 'Phone No.', 'Salary Per Month', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [imgModel, setImgModel] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [modelImage, setModelImage] = useState(false)
    const [folderName, setFolderName] = useState('')
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const [employeeList, setEmployeeList] = useState('')
    const getEmployeeList = () => {
        setLoader(true)
        axiosClient.get('/employee_list/')
            .then(({ data }) => {
                setLoader(false)
                setEmployeeList(data.lists)
            })
    }

    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setImgModalLoader(false)
    }

    const deleteEmployee = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_employee/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getEmployeeList()
                    })
            }
        })
    }
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_emp_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.single_data)
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        getEmployeeList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Employee Details'} button={
                    <LinkButton to={'/employee-details/add-employee-details'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add New Employee</span>
                    </LinkButton>
                }>
                    {employeeList && employeeList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {employeeList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.emp_name}</td>
                                        <td className="px-4 py-3">{tableData.job_title}</td>
                                        <td className="px-4 py-3">{tableData.phone_no}</td>
                                        <td className="px-4 py-3">{Number(tableData.salary_per_month).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Link onClick={() => openModal(tableData.id)}><ActionButton className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton></Link>
                                                <Link title='Edit' to={`/employee-details/edit-employee-details/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteEmployee(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer '}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Employee Data Not Found</p>
                        </div>
                    )}
                    {employeeList && employeeList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={employeeList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {/* {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Employee Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-3'>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Employee Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.emp_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Date Of Birth</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{moment(modalData.dob).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Gender</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.gender}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Address</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.address}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Joining Date</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{moment(modalData.hire_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Designation</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.job_title}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Salary Per Month</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{Number(modalData.salary_per_month).toLocaleString('en-IN')}</h1>
                                                </div>

                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Phone No.</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.phone_no}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Email</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className=' break-words'>{modalData.email}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Pan No</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.pan_no}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Bank Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.bank_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Branch Name</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.branch_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>Bank Acc. No.</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 className='break-words'>{modalData.bank_acc_no}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-2'>
                                                    <h1 className='font-bold '>IFSC Code</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1>{modalData.IFSC_code}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <h1 className='md:text-xl text-[3vw] font-bold mb-4 md:mb-2'>Document List</h1>
                                        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-3  '>
                                            <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.photo, 'photo')}>
                                                <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Photo</h1>
                                            </Link>
                                            <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.adharcard, 'adharcard')}>
                                                <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Aadhar Card</h1>
                                            </Link>
                                            <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.pancard, 'pancard')}>
                                                <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Pan Card</h1>
                                            </Link>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3 gap-4">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModel(false)} >
                                                    Got it, thanks!
                                                </button>
                                            </div>
                                            <div></div>
                                            <div className='flex justify-end'>
                                                <Link id='printButton' onClick={handlePrint} className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-7 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-[50%]">  Print </Link>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    } */}
                    {
                        viewModel && modalData && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Employee Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-4 '>
                                                <div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold '>Employee</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.emp_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Gender</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.gender}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Designation</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.job_title}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Phone No.</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.phone_no}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Date Of Birth</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{moment(modalData.dob).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Address</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.address}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Email</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=' break-words'>{modalData.email}</h1>
                                                    </div>
                                                </div>
                                                <div>
                                                <div className=' grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                    <h1 className='font-bold'>Joining Date</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{moment(modalData.hire_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                    <h1 className='font-bold'>Salary/Month</h1>
                                                    <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{Number(modalData.salary_per_month).toLocaleString('en-IN')}</h1>
                                                </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Pan No</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.pan_no}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Bank Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.bank_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Bank Acc. No.</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className='break-words'>{modalData.bank_acc_no}</h1>
                                                    </div>

                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>Branch Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 >{modalData.branch_name}</h1>
                                                    </div>
                                                    <div className='grid grid-cols-3 gap-1 mb-1 md:mb-3'>
                                                        <h1 className='font-bold'>IFSC Code</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className='break-words'>{modalData.IFSC_code}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className='md:text-xl text-[3vw] font-bold mb-4 md:mb-2'>Document List</h1>
                                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-3  '>
                                                <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.photo, 'photo')}>
                                                    <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Photo</h1>
                                                </Link>
                                                <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.adharcard, 'adharcard')}>
                                                    <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Aadhar Card</h1>
                                                </Link>
                                                <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 hover:bg-gray-50-500 duration-300 w-full' onClick={() => handleModelImage(modalData.pancard, 'pancard')}>
                                                    <h1 className='bg-primary-color text-white text-center p-2 mb-2 rounded-md'>Pan Card</h1>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-[60%] transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw]  tracking-widest'>View Full Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className="flex justify-center">
                                            {
                                                <img src={modelImage ? (
                                                    modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/Employee/${folderName}/` + modelImage
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-[500px] rounded' />
                                            }
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default EmployeeDetails