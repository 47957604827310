import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowLeftCircleIcon, EyeIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Button from '../helpers/Button'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ViewModel from '../helpers/ViewModel'
import { Link } from 'react-router-dom'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Validation from '../helpers/Validation'
import { InboxArrowDownIcon } from '@heroicons/react/24/outline'
import CustomSelect from '../helpers/CustomSelect'
import TextArea from '../helpers/TextArea'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import LinkButton from '../helpers/LinkButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'

function FollowUp() {
    document.title = 'SiteSmart - Follow Up'
    const tableHeading = ['Sr.No', 'Enquiry ID', 'Client Name', 'Mobile', 'Site Name', 'Reminder Date', 'Client Status', 'Called Status', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [editModel, setEditModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalData, setModalData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    let currentDate = new Date().toJSON().slice(0, 10);

    const [date, setDate] = useState(currentDate)

    const [followupList, setFollowUpList] = useState();
    const [followup, setFollowUp] = useState({
        id: '',
        client_name: '',
        enq_id: '',
        enquiries_slug: '',
        mobile_no: '',
        cus_status: '',
        no_of_reminders: '',
        previous_call_date: currentDate,
        work_name: '',
        wing_name: '',
        level_name: '',
        nature_of_flat: '',
        next_reminder_date: 0,
        contact_mode: '',
        clients_response: '',
        lead_by: '',
        remark: '',
    })

    const handleDate = (e) => {
        setDate(e.target.value);
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getFollowUpLists = () => {
        setLoader(true)
        axiosClient.get(`date_wise_data/${date}`)
            .then(({ data }) => {
                setLoader(false)
                setFollowUpList(data.date_data)
            })
    }

    const handleChange = (e) => {
        setFollowUp(followup => ({
            ...followup,
            [e.target.name]: e.target.value
        }))
    }

    const deleteFollowUp = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_followup/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getFollowUpLists()
                    })
            }
        })
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    function openFormModal(id) {
        setEditModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_followup_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setFollowUp(data.single_data)
                setArea(data.single_data.area)
            })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_followup_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                data.view_data && setModalData(data.view_data);
                setArea(data.view_data.area)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('enq_id', followup.enq_id);
        formData.append('client_name', followup.client_name);
        formData.append('mobile_no', followup.mobile_no);
        formData.append('work_name', followup.work_name);
        formData.append('wing_name', followup.wing_name)
        formData.append('level_name', followup.level_name)
        formData.append('nature_of_flat', followup.nature_of_flat)
        formData.append('area', area)
        formData.append('next_reminder_date', followup.next_reminder_date);
        formData.append('contact_mode', followup.contact_mode);
        formData.append('clients_response', followup.clients_response);
        formData.append('cus_status', followup.cus_status);
        formData.append('no_of_reminders', followup.no_of_reminders);
        formData.append('enquiries_slug', followup.enquiries_slug)
        formData.append('lead_by', followup.lead_by)
        formData.append('remark', followup.remark);
        formData.append('current_id', followup.id);
        formData.append('no_of_reminders', followup.no_of_reminders + 1);

        axiosClient.post(`/store_followup`, formData)
            .then(({ data }) => {
                flasher.success(data.msg)
                getFollowUpLists();
                setDate(currentDate)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
        getFollowUpLists();
        setEditModel(false);
    }


    const [wing, setWing] = useState([])
    const getWingLists = () => {
        followup.work_name && axiosClient.get(`/work_wise_wings/${followup.work_name}`)
            .then(({ data }) => {
                setWing(data.work_wise_wingdata)
            })
    }

    const [level, setLevel] = useState([])
    const getLevelLists = () => {
        followup.work_name && followup.wing_name && axiosClient.get(`/work_wing_wise_level/${followup.work_name}/${followup.wing_name}`)
            .then(({ data }) => {
                setLevel(data.work_wing_wise_leveldata)
            })
    }

    const getPendingCallList = () => {
        axiosClient.get(`/pending_call_data`)
            .then(({ data }) => {
                setFollowUpList(data.pending_call_data)
                setDate('');
            })
    }

    const [pendingcallCount, setPendingCallCount] = useState(0)
    const getPendingCallCount = () => {
        axiosClient.get(`/pending_call_data`)
            .then(({ data }) => {
                setPendingCallCount(data.pending_call_data.length)
            })
    }

    const [natureFlatList, setNatureFlatList] = useState([])
    const getNatureFlatLists = () => {
        followup.level_name && axiosClient.get(`/level_wise_flat/${followup.wing_name}/${followup.level_name}`)
            .then(({ data }) => {
                setNatureFlatList(data.level_wise_flat)
            })
    }

    const [area, setArea] = useState('');
    const getEstimateArea = () => {
        followup.nature_of_flat && natureFlatList.map((data) => {
            return (
                followup.nature_of_flat == data.id && (setArea(data.estimate_area))
            )
        })
    }


    useEffect(() => {
        getEstimateArea()
    }, [followup.nature_of_flat])

    useEffect(() => {
        getWingLists()
    }, [followup.work_name])

    useEffect(() => {
        getLevelLists()
    }, [followup.work_name, followup.wing_name])

    useEffect(() => {
        getNatureFlatLists()
    }, [followup.level_name])

    useEffect(() => {
        getFollowUpLists()
        getPendingCallCount()
        getWorkData()
        getEstimateArea()

    }, [date])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Client Follow Up'} button={
                    <LinkButton >
                        <div className='flex' onClick={() => { getFollowUpLists(); setDate(currentDate) }}>
                            <ArrowLeftCircleIcon className='w-5 h-5 mr-2 text-default-color' />
                            <span >Back To Follow Up</span>
                        </div>
                    </LinkButton>}
                >
                    <div className="grid grid-cols-4">
                        <div className='mb-3 '>
                            <Label htmlFor={'date'} labelText={'Enter Date'} className={'mb-1'} />
                            <Input id={'date'} className={'md:w-full w-28 md:text-sm text-[2vw]'} type={'date'} name={'date'} value={date} onChange={handleDate} />
                        </div>
                        <div className="items-center md:col-span-1 col-span-2  md:text-sm text-[2vw] flex justify-center">
                            <Button className='md:px-10 px-5 mt-4' onClick={getPendingCallList}>
                                Pending Call List
                                <div class="absolute inline-flex justify-center items-center w-6 h-6 md:text-xs text-[2vw] font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">{pendingcallCount}</div>
                            </Button>
                        </div>
                        <div className="items-center flex md:justify-start "> <Link className='p-3' to={'/follow_up/called-list'}><Button className='md:px-10 p-2 md:text-sm text-[2vw] mt-4'>Called List</Button></Link></div>
                        <div className=""></div>
                    </div>
                    {followupList && followupList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {followupList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.enq_id}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{tableData.mobile_no}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{moment(tableData.next_reminder_date).format('DD-MM-YYYY')}</td>
                                        <td className="px-4 py-3">{tableData.cus_status && ((tableData.cus_status).split('_')).join(' ')}</td>
                                        <td className="px-4 py-3">
                                            {tableData.call_status === null ? (
                                                <button title={'Forward'} onClick={() => openFormModal(tableData.id)} className='border-2 bg-red-500 border-red-500 text-white w-32 text-center px-3 py-1 rounded-lg'>Pending</button>
                                            ) : (
                                                <button className='border-2  bg-green-500 border-green-500 text-white w-32 text-center px-3 py-1 rounded-lg'> Called</button>
                                            )}
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <Button title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></Button>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Follow Up Data Not Found</p>
                        </div>
                    )}
                    {followupList && followupList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={followupList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef}>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Follow Up Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4 '>
                                                <div className="">
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Client Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Email Id</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className='break-words'>{modalData.email_id}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Mobile No</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.mobile_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Address</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.address}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Enquiry For</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.enquiry_for}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Client status</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.cus_status && ((modalData.cus_status).split('_')).join(' ')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>No. of Reminder</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.no_of_reminders}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Work Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Wing Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.wing_name}</h1>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Level Name</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.level_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Nature of Flat</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Area (sq / ft)</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Previous Call Date</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{moment(modalData.created_at).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Reminder Date</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{moment(modalData.next_reminder_date).format('DD-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Contact Mode</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.contact_mode}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Client Response</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.clients_response}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Lead By</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.lead_by}</h1>
                                                    </div>

                                                    <div className='flex items-center gap-1 py-0.5 md:py-2'>
                                                        <h1 className='md:w-36 w-[25vw] font-bold'>Remark</h1>
                                                        <h1 className='justify-center flex'>-</h1>
                                                        <h1 className=''>{modalData.remark}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center mr-3 rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                    {
                        editModel && <ViewModel viewModel={editModel} setViewModel={setEditModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Follow Up Details</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <form onSubmit={handleSubmit}>
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                                <div>
                                                    <Label htmlFor={'client_name'} labelText={'Client Name'} className={'mb-1'} />
                                                    <Input id={'client_name'} type={'text'} name={'client_name'} value={followup.client_name} readOnly={true} />
                                                    <Validation error={errors.client_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'email_id'} labelText={'Email Id'} className={'mb-1'} />
                                                    <Input id={'email_id'} type={'text'} name={'email_id'} value={followup.email_id} readOnly={true} />
                                                    <Validation error={errors.email_id} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'mobile_no'} labelText={'Mobile No'} className={'mb-1'} />
                                                    <Input id={'mobile_no'} type={'text'} name={'mobile_no'} value={followup.mobile_no} readOnly={true} />
                                                    <Validation error={errors.mobile_no} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'address'} labelText={'Address'} className={'mb-1'} />
                                                    <Input id={'address'} type={'text'} name={'address'} value={followup.address} readOnly={true} />
                                                    <Validation error={errors.address} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'enquiry_for'} labelText={'Enquiry For'} className={'mb-1'} />
                                                    <Input id={'enquiry_for'} type={'text'} name={'enquiry_for'} value={followup.enquiry_for} readOnly={true} />
                                                    <Validation error={errors.enquiry_for} />
                                                </div>
                                            </div>
                                            <div className='flex justify-center  content-center '>
                                                <hr className='my-4 md:w-32  border border-secondary-color' />
                                            </div>
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                                <div>
                                                    <Label htmlFor={'clients_response'} labelText={"Client's Response"} className={'mb-1'} />
                                                    <CustomSelect id={'clients_response'} name={'clients_response'} defaultValue={followup.clients_response} value={followup.clients_response} onChange={handleChange}>
                                                        <option value={''}>--- Choose ---</option>
                                                        <option value={'High'}>High</option>
                                                        <option value={'Low'}>Low</option>
                                                        <option value={'Moderate'}>Moderate</option>
                                                    </CustomSelect>
                                                </div>
                                                <div>
                                                    <Label htmlFor={'contact_mode'} labelText={'Contact Mode On'} className={'mb-1'} />
                                                    <CustomSelect id={'contact_mode'} name={'contact_mode'} value={followup.contact_mode} onChange={handleChange}>
                                                        <option value={''}>--- Choose ---</option>
                                                        <option value={'Call'}>Call</option>
                                                        <option value={'Site'}>Site</option>
                                                        <option value={'Other'}>Other</option>
                                                    </CustomSelect>
                                                </div>
                                                <div>
                                                    <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                    <CustomSelect id={'work_name'} name={'work_name'} value={followup.work_name} onChange={handleChange}>
                                                        <option value={''}>--- Choose Work Name ---</option>
                                                        {Array.isArray(works) && works.map((work_data, u_id) => {
                                                            return (<Fragment key={u_id}>
                                                                <option value={work_data.id}>{work_data.work_name}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                </div>

                                                <div>
                                                    <Label htmlFor={'wing_name'} labelText={'Wing'} className={'mb-1'} />
                                                    <CustomSelect id={'wing_name'} name={'wing_name'} value={followup.wing_name} onChange={handleChange}>
                                                        <option value={''}>--- Select Wing ---</option>
                                                        {wing && wing.map((wingData, wingId) => {
                                                            return (<Fragment key={wingId}>
                                                                <option value={wingData.id}>{wingData.wing_name}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.wing_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'level_name'} labelText={'Level'} className={'mb-1'} />
                                                    <CustomSelect id={'level_name'} name={'level_name'} value={followup.level_name} onChange={handleChange}>
                                                        <option value={''}>--- Select level_name ---</option>
                                                        {level && level.map((levelData, levelId) => {
                                                            return (<Fragment key={levelId}>
                                                                <option value={levelData.level}>{levelData.level_name}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.level_name} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'nature_of_flat'} labelText={'Nature Of Flat'} className={'mb-1'} />
                                                    <CustomSelect id={'nature_of_flat'} name={'nature_of_flat'} value={followup.nature_of_flat} onChange={(e) => { handleChange(e); getEstimateArea() }}>
                                                        <option value={''}>--- Select Flat Nature ---</option>
                                                        {natureFlatList && natureFlatList.map((natureData, natureId) => {
                                                            return (<Fragment key={natureId}>
                                                                <option value={natureData.id}>{natureData.nature_of_flat}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.nature_of_flat} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'area'} labelText={'Area (sq / ft)'} className={'mb-1'} />
                                                    <Input id={'area'} type={'text'} name={'area'} value={parseInt(area).toLocaleString('en-IN')} readOnly={true} />
                                                    <Validation error={errors.area} />
                                                </div>

                                                <div>
                                                    <Label htmlFor={'cus_status'} labelText={'Client Status'} className={'mb-1'} />
                                                    <CustomSelect id={'cus_status'} name={'cus_status'} defaultValue={followup.cus_status} value={followup.cus_status} onChange={handleChange}>
                                                        <option value={''}>--- Select ---</option>
                                                        <option value={'In_Process'}>In Process</option>
                                                        <option value={'Deal_Open'}>Deal Open</option>
                                                        <option value={'Lead_Closed'}>Lead Closed</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.cus_status} />
                                                </div>
                                                {
                                                    (followup.cus_status === 'In_Process' || followup.cus_status === null) &&
                                                    (<div>
                                                        <Label htmlFor={'next_reminder_date'} labelText={'Next Reminder Date'} className={'mb-1'} />
                                                        <Input id={'next_reminder_date'} type={'date'} name={'next_reminder_date'} value={followup.next_reminder_date} onChange={handleChange} />
                                                    </div>)
                                                }
                                                <div>
                                                    <Label htmlFor={'lead_by'} labelText={'Lead By'} className={'mb-1'} />
                                                    <TextArea id={'lead_by'} name={'lead_by'} rows={1} value={followup.lead_by} />
                                                    <Validation error={errors.lead_by} />
                                                </div>

                                                <div>
                                                    <Label htmlFor={'remark'} labelText={'Remark'} className={'mb-1'} />
                                                    <TextArea id={'remark'} name={'remark'} rows={1} value={followup.remark} onChange={handleChange} />
                                                </div>
                                                {
                                                    followup.cus_status !== 'In_Process' &&
                                                    (<div className='mb-3'> </div>)
                                                }
                                            </div>
                                            <div className='flex justify-center'>
                                                <Button type="submit" className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                                    <span>Submit</span>
                                                </Button>
                                            </div>

                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default FollowUp
