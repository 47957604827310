import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import PageComponent from '../../../components/PageComponent'
import Label from '../../helpers/Label'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import Card from '../../helpers/Card'
import axiosClient from '../../../Axios'
import flasher from '@flasher/flasher'
import Validation from '../../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import CustomSelect from '../../helpers/CustomSelect'
import { formToJSON } from 'axios'

function BankTransactionAddEdit() {
  const { id } = useParams()
  // eslint-disable-next-line no-lone-blocks
  {
    id ? (
      document.title = 'SiteSmart - Edit Bank Master'
    ) : (
      document.title = 'SiteSmart - Add New Bank Master'
    )
  }

  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  
  const [bankLists, setBankLists] = useState('')
  const getBankLists = () => {
    setLoader(true)
    axiosClient.get('/Bank_master_lists')
      .then(({ data }) => {
        setLoader(false)
        setBankLists(data.lists)
      })
  }
  const [formInfo, setFormInfo] = useState({
    bank_name: '',
    current_balance: '',
    add_balance: '',
    closing_balance: '',
    remark: '',
  })
  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    const formData = new FormData()
    formData.append('bank_name', formInfo.bank_name)
    formData.append('current_balance', bankData.current_balance)
    formData.append('add_balance', formInfo.add_balance)
    formData.append('closing_balance', formInfo.closing_balance)
    formData.append('remark', formInfo.remark)
    
    axiosClient.post(id ? `update_Bank_Transaction/${id}` : '/store_Bank_Transaction', formData)
      .then(({ data }) => {
        setLoader(false)
        flasher.success(data.msg)
        navigate('/accounting/bank-transaction')
      })
      .catch(({ response }) => {
        setLoader(false)
        setErrors(response.data.errors)
      })
  }
  const[bankData, SetBankData] = useState('')
  const getSingleBankData = () => {
    setLoader(true)
    formInfo.bank_name && axiosClient.get(`/single_Bank_master_data/${formInfo.bank_name}`)
      .then(({ data }) => {
        setLoader(false)
        SetBankData(data.single_data)
      })
  }

  useEffect(() => {
    getBankLists();
  }, [])

  useEffect(() => {
    getSingleBankData();
  }, [formInfo.bank_name])

  return (
    <Fragment>

      {loader ? (<Loader />) : (
        <PageComponent title={'Account Transaction'} button={
          <LinkButton to={'/accounting/bank-transaction'}>
            <ArrowLeftCircleIcon className='w-5 h-5' />
            <span>Back To List</span>
          </LinkButton>
        }>
          <div className='flex items-center justify-center '>
            <Card className={'w-96 md:w-[70%] px-6 py-4 bg-default-color'}>
              <form onSubmit={handleSubmit}>
                <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div className='mb-3'>
                    <Label htmlFor={'bank_name'} labelText={'Bank Name / Cash'} className={'mb-1'} />
                    <CustomSelect id={'bank_name'} name={'bank_name'} value={formInfo.bank_name} onChange={(e) => { handleChange(e)}} >
                      <option disabled value={''}>--- Select Bank Name ---</option>
                      {bankLists && bankLists.map((bankdata, bankID) => {
                        return (<Fragment key={bankID}>
                          <option value={bankdata.id}>{bankdata.bank_name}</option>
                        </Fragment>)
                      })}
                    </CustomSelect>
                    <Validation error={errors.bank_name} />
                  </div>
                  <div>
                    <Label htmlFor={'current_balance'} labelText={'Current Balance'} className={'mb-1'} />
                    <Input id={'current_balance'} type={'text'} name={'current_balance'} value={bankData.current_balance} onChange={handleChange} />
                    <Validation error={errors.current_balance} />
                  </div>
                  </div>
                  <div className='mb-3 grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div>
                    <Label htmlFor={'add_balance'} labelText={'Add Balance'} className={'mb-1'} />
                    <Input id={'add_balance'} type={'text'} name={'add_balance'} value={formInfo.add_balance} onChange={handleChange}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  />
                    <Validation error={errors.add_balance} />
                  </div>                
                  <div>
                    <Label htmlFor={'closing_balance'} labelText={'Final Closing Balance'} className={'mb-1'} />
                    <Input id={'closing_balance'} type={'text'} name={'closing_balance'} value={formInfo.closing_balance = (Number(bankData.current_balance) + Number(formInfo.add_balance))} onChange={handleChange}/>
                    <Validation error={errors.closing_balance} />
                  </div>
                  <div>
                    <Label htmlFor={'remark'} labelText={'Remark'} className={'mb-1'} />
                    <Input id={'remark'} type={'text'} name={'remark'} value={formInfo.remark} onChange={handleChange} />
                    <Validation error={errors.remark} />
                  </div>
                </div>
                <div className='mb-3 grid justify-items-center grid-cols-1 md:grid-cols-1 gap-4  mt-4'>
                  <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'}>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                    <span>Save Now</span>
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        </PageComponent>
      )}
    </Fragment>
  )
}


export default BankTransactionAddEdit
