import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import flasher from '@flasher/flasher'

function TaskMasterAddEdit() {
    const { id } = useParams()
    if (id) {
        document.title = 'SiteSmart -Task Master'
    } else {
        document.title = 'SiteSmart - Add New Task'
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        task_name: '',
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const getSingleTaskData = () => {
        setLoader(true)
        axiosClient.get(`/single_task_master/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = {
            task_name: formInfo.task_name,
        }
        setLoader(true)
        axiosClient.post(id ? `/update_task_master/${id}` : '/store_task_master', payload)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/task-master')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleTaskData()
        }
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Task' : 'Add New Task'} button={
                    <LinkButton to={'/task-master'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton> }>
                    <div className='flex items-center justify-center h-[80vh]'>
                        <Card className={'w-full md:w-80 px-6 py-4 bg-default-color'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-1 gap-x-6 mt-4 '>
                                    <div className='mb-3'>
                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                        <Input id={'task_name'} type={'text'} name={'task_name'} value={formInfo.task_name} onChange={handleChange} />
                                        <Validation error={errors.task_name} />
                                    </div>
                                </div>
                                <Button className={'w-60 mt-6 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}
export default TaskMasterAddEdit