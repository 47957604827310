import React from 'react'
import { useStateContext } from '../../context/ContextProvider'

function PleaseWait() {
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()

    return (
        <div className='bg-transparent'>
            <img src={FRONTEND_URL + '/assets/images/loading.gif'} className='w-[15vw]' alt="loader" />
        </div>
    )
}

export default PleaseWait
