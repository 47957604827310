import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { ArrowDownCircleIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import Button from '../helpers/Button'
import Label from '../helpers/Label'
import Input from '../helpers/Input'

function PurchaseBillPaymentReports() {
    document.title = 'SiteSmart - Purchase Bill  Payment '
    const tableHeading = ['Sr.No', 'invoice no', 'date', 'Work name', 'material name', ' total amount',]
    const [loader, setLoader] = useState(false)
    const [agencyLists, setAgencyLists] = useState('')
    const getAgencyLists = () => {
        setLoader(true)
        axiosClient.get('/Contractorpayment_lists')
            .then(({ data }) => {
                setLoader(false)
                setAgencyLists(data.lists)
            })
    }
    const deleteAgency = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Contractorpayment/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getAgencyLists()
                    })
            }
        })
    }


   
    useEffect(() => {
        getAgencyLists()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Purchase Bill  Payment '}>
                    <div className="grid grid-cols-1 md:grid-cols-4">
                        <div className='pr-5'>
                            <Label htmlFor={'date'} labelText={'From Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} />
                            {/* <Validation error={errors.date} /> */}
                        </div>
                        <div className="pl-5">
                            <Label htmlFor={'date'} labelText={'To Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} />
                        </div>
                        <div className="justify-end flex py-5 ml-3 mt-2">
                            <Button className='ml-2' to={'/accounting/add-client-payment'}>
                                <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                                <span>Sort By Work name</span>
                            </Button>
                        </div>
                        <div className="justify-end flex py-5 ml-3 mt-2">
                            <Button className='ml-2' to={'/accounting/add-client-payment'}>
                                <ArrowDownCircleIcon className='w-5 h-5 pr-1' />
                                <span>Sort By Supplier name</span>
                            </Button>
                        </div>
                    </div>
                    <br />
                    {agencyLists && agencyLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {agencyLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'345'}</td>
                                        <td className="px-4 py-3 font-bold whitespace-nowrap">{'28/09/2023'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'Emrald'}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{'Steel'}</td>
                                        <td className="px-4 py-3  whitespace-nowrap">{'₹ 3,51,400'}</td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Contractor Payment Data Not Found</p>
                        </div>
                    )}
                  
                </PageComponent>
            )}
        </Fragment>
    )
}





export default PurchaseBillPaymentReports
