import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import CurrencyInput from 'react-currency-input-field';


function ContractorWorkAddEdit() {
    const { id } = useParams()
    document.title = 'SiteSmart - Add contractor Work'
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        work_name: '',
        contractor_name: '',
        contract_type: '',
        wing_name: '',
        level_name: '',
        contractor_work_name: '',
        fixed_rate_work_type: '',
        fixed_rate_est_amount: '',
        fixed_rate_remarks: '',
        fixed_rate_tax_amount: "",
        fixed_rate_TDS_amount: "",
        fixed_rate_grand_total: '',
        unit_based_rate_work_type: '',
        unit_based_rate_measure_unit: '',
        unit_based_tax_amount: '',
        unit_based_TDS_amount: '',
        unit_based_grand_total: '',
        unit_based_rate_per_unit: '',
        unit_based_rate_total_qty: '',
        unit_based_rate_est_amount: '',
        unit_based_rate_remarks: '',

    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value,
        }))
    }
    const fixed_rate_est_amount = parseInt(formInfo.fixed_rate_est_amount)
    const fixed_rate_tax_amount = parseInt(formInfo.fixed_rate_tax_amount) / 100 * (fixed_rate_est_amount)
    const fixed_rate_tds_amount = parseInt(formInfo.fixed_rate_TDS_amount) / 100 * (fixed_rate_est_amount)
    const fixed_rate_grand_total = (fixed_rate_est_amount - fixed_rate_tds_amount) + fixed_rate_tax_amount
    const unit_rate_est_amount = parseInt(formInfo.unit_based_rate_est_amount)
    const unit_rate_tax_amount = parseInt(formInfo.unit_based_tax_amount) / 100 * (unit_rate_est_amount)
    const unit_based_tds_amount = parseInt(formInfo.unit_based_TDS_amount) / 100 * (unit_rate_est_amount)
    const unit_based_grand_total = (unit_rate_est_amount - unit_based_tds_amount) + unit_rate_tax_amount

    const [contractorfirmname, setContractorfirmname] = useState('')
    const getFirmName = () => {
        setLoader(true)
        axiosClient.get(`/single_Contractormaster_data/${contractorname}`)
            .then(({ data }) => {
                setLoader(false)
                setContractorfirmname(data.single_data.firm_name)
            })
    }
    const handlefixedestamt = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            fixed_rate_est_amount: e
        }))
    }

    const handleunitrateperunitamt = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            unit_based_rate_per_unit: e
        }))
    }

    const handleunittotalqtyamt = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            unit_based_rate_total_qty: e
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        Swal.fire({
            title: 'Are you sure to save this?',
            text: "You won't be able to revert this!",
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                const payload = {
                    work_name: formInfo.work_name,
                    contractor_work_name: contractorfirmname,
                    contractor_name: formInfo.contractor_name,
                    contract_type: formInfo.contract_type,
                    contractor_name: formInfo.contractor_name,
                    fixed_rate_work_type: formInfo.fixed_rate_work_type,
                    fixed_rate_est_amount: formInfo.fixed_rate_est_amount,
                    fixed_rate_TDS_amount: formInfo.fixed_rate_TDS_amount,
                    fixed_rate_tax_amount: formInfo.fixed_rate_tax_amount,
                    fixed_rate_grand_total: fixed_rate_grand_total,
                    fixed_rate_remarks: formInfo.fixed_rate_remarks,
                }
                const payload1 = {
                    work_name: formInfo.work_name,
                    contractor_work_name: contractorfirmname,
                    contractor_name: formInfo.contractor_name,
                    contract_type: formInfo.contract_type,
                    contractor_name: formInfo.contractor_name,
                    unit_based_rate_work_type: formInfo.unit_based_rate_work_type,
                    unit_based_rate_measure_unit: formInfo.unit_based_rate_measure_unit,
                    unit_based_rate_per_unit: formInfo.unit_based_rate_per_unit,
                    unit_based_rate_total_qty: formInfo.unit_based_rate_total_qty,
                    unit_based_tax_amount: formInfo.unit_based_tax_amount,
                    unit_based_TDS_amount: formInfo.unit_based_TDS_amount,
                    unit_based_rate_est_amount: formInfo.unit_based_rate_est_amount,
                    unit_based_grand_total: unit_based_grand_total,
                    unit_based_rate_remarks: formInfo.unit_based_rate_remarks
                }
                if (formInfo.contract_type == "Fixed Rate") {
                    axiosClient.post(`/update_Contractor_work/${id}`, payload)
                        .then(({ data }) => {
                            setLoader(false)
                            flasher.success(data.msg)
                            navigate('/contractorwork')
                        })
                        .catch(({ response }) => {
                            setLoader(false)
                            setErrors(response.data.errors)
                        })
                } else {
                    axiosClient.post(`/update_Contractor_work/${id}`, payload1)
                        .then(({ data }) => {
                            setLoader(false)
                            flasher.success(data.msg)
                            navigate('/contractorwork')
                        })
                        .catch(({ response }) => {
                            setLoader(false)
                            setErrors(response.data.errors)
                        })
                }
            }
        })

    }

    var contractorname = formInfo.contractor_name
    const getSingleContractorData = () => {
        setLoader(true)
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setFormInfo(data.single_data)
            })
    }

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }
    const [contractor, setContractor] = useState([]);
    const getContractorData = () => {
        setLoader(true)
        axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setLoader(false)
                setContractor(data.lists)
            })
    }

    const [worktype, setWorktype] = useState('')
    const getWorktype = () => {
        setLoader(true)
        axiosClient.get(`/worktype_list`)
            .then(({ data }) => {
                setWorktype(data.lists)
            })
    }

    useEffect(() => {
        getFirmName();
    }, [contractorname])

    useEffect(() => {
        if (id) {
            getSingleContractorData()
        }
        getWorkData();
        getWorktype();
        getContractorData()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Contractor' : ' Contractor Work Details'} button={
                    <LinkButton to={'/contractorwork'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='grid items-center justify-center '>
                        <form onSubmit={(e) => handleSubmit(e)}>
                        <Card className={'w-full md:w-[60rem] p-6  bg-default-color'}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <CustomSelect id={'work_name'} name={'work_name'} value={formInfo.work_name} >
                                            <option value={''}>--- Choose Work Name ---</option>
                                            {works.map((u_data, u_id) => {
                                                return (<Fragment key={u_id}>
                                                    <option value={u_data.id}>{u_data.work_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                        <Input id={'wing_name'} type={'text'} name={'wing_name'} value={formInfo.wing_name} readOnly={true} />
                                        <Validation error={errors.wing_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <Input id={'level_name'} type={'text'} name={'level_name'} value={formInfo.level_name} readOnly={true} />
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'contractor_name'} labelText={'Contractor Name'} className={'mb-1'} />
                                        <CustomSelect id={'contractor_name'} name={'contractor_name'} value={formInfo.contractor_name} >
                                            <option disabled value={''}>--- Choose Contractor Name ---</option>
                                            {contractor.map((u_data, u_id) => {
                                                return (<Fragment key={u_id}>
                                                    <option value={u_data.id}>{u_data.contractor_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.contractor_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'contractor_work_name'} labelText={'Contractor Firm Name'} className={'mb-1'} />
                                        <Input id={'contractor_work_name'} type={'text'} name={'contractor_work_name'} value={contractorfirmname} readOnly={true} />
                                        <Validation error={errors.contractor_work_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'contract_type'} labelText={'Contractor Type'} className={'mb-1'} />
                                        <CustomSelect id={'contract_type'} name={'contract_type'} value={formInfo.contract_type} onChange={handleChange}>
                                            <option value={''}>--- Choose Contractor Type ---</option>
                                            <option value={'Fixed Rate'}>Fixed Rate</option>
                                            <option value={'Unit Based Rate'}>Unit Based Rate</option>
                                        </CustomSelect>
                                        <Validation error={errors.contract_type} />
                                    </div>
                                </div>

                            </Card>
                            <Card className={'w-full md:w-[60rem] p-6  bg-default-color'}>
                                {
                                    formInfo.contract_type === "Fixed Rate" ?
                                        <div>
                                            <div className='mb-4'>
                                                <span className='text-xl font-normal'>Work Details :</span>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                                <div className='mb-3 '>
                                                    <Label htmlFor={'fixed_rate_work_type'} labelText={' Work Type'} className={'mb-1'} />
                                                    <CustomSelect id={'fixed_rate_work_type'} name={'fixed_rate_work_type'} value={formInfo.fixed_rate_work_type} onChange={handleChange}>
                                                        <option value={''}>--- Select Work Type ---</option>
                                                        {worktype && worktype.map((worktypedata, worktypeID) => {
                                                            return (<Fragment key={worktypeID}>
                                                                <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.fixed_rate_work_type} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fixed_rate_est_amount'} labelText={'Est. Amount'} className={'mb-1'} />
                                                    <CurrencyInput id={'fixed_rate_est_amount'} type={'text'} name={'fixed_rate_est_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                     defaultValue={formInfo.fixed_rate_est_amount}
                                                     onValueChange={handlefixedestamt}
                                                    />
                                                    <Validation error={errors.fixed_rate_est_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fixed_rate_tax_amount'} labelText={'TAX Amount'} className={'mb-1'} />
                                                    <CustomSelect id={'fixed_rate_tax_amount'} name={'fixed_rate_tax_amount'} value={formInfo.fixed_rate_tax_amount} onChange={handleChange}>
                                                        <option  value={''}>--- Choose TAX ---</option>
                                                        <option value={'0%'}>0%</option>
                                                        <option value={'5%'}>5%</option>
                                                        <option value={'18%'}>18%</option>
                                                        <option value={'25%'}>25%</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.fixed_rate_tax_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fixed_rate_TDS_amount'} labelText={'TDS Amount'} className={'mb-1'} />
                                                    <CustomSelect id={'fixed_rate_TDS_amount'} name={'fixed_rate_TDS_amount'} value={formInfo.fixed_rate_TDS_amount} onChange={handleChange}>
                                                        <option  value={''}>--- Choose TDS ---</option>
                                                        <option value={'0%'}>0%</option>
                                                        <option value={'1%'}>1%</option>
                                                        <option value={'2%'}>2%</option>
                                                        <option value={'4%'}>4%</option>
                                                        <option value={'6%'}>6%</option>
                                                    </CustomSelect>                                                       
                                                     <Validation error={errors.fixed_rate_TDS_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fixed_rate_grand_total'} labelText={'Grand Total'} className={'mb-1'} />
                                                    <Input id={'fixed_rate_grand_total'} type={'text'} name={'fixed_rate_grand_total'} value={fixed_rate_grand_total.toLocaleString('en-IN')} onChange={handleChange} maxLength={11} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                    <Validation error={errors.fixed_rate_grand_total} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'fixed_rate_remarks'} labelText={'Remarks'} className={'mb-1'} />
                                                    <Input id={'fixed_rate_remarks'} type={'text'} name={'fixed_rate_remarks'} value={formInfo.fixed_rate_remarks} onChange={handleChange} />
                                                    <Validation error={errors.fixed_rate_remarks} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className='mb-4'>
                                                <span className='text-xl font-normal'>Work Details :</span>
                                            </div>
                                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                                                <div className='mb-3 '>
                                                    <Label htmlFor={'unit_based_rate_work_type'} labelText={' Work Type'} className={'mb-1'} />
                                                    <CustomSelect id={'unit_based_rate_work_type'} name={'unit_based_rate_work_type'} value={formInfo.unit_based_rate_work_type} onChange={handleChange}>
                                                        <option value={''}>--- Select Work Type ---</option>
                                                        {worktype && worktype.map((worktypedata, worktypeID) => {
                                                            return (<Fragment key={worktypeID}>
                                                                <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                                    <Validation error={errors.unit_based_rate_work_type} />
                                                </div>
                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_rate_measure_unit'} labelText={'Measuring Unit'} className={'mb-1'} />
                                                    <Input id={'unit_based_rate_measure_unit'} type={'text'} name={'unit_based_rate_measure_unit'} value={formInfo.unit_based_rate_measure_unit} onChange={handleChange} />
                                                    <Validation error={errors.unit_based_rate_measure_unit} />
                                                </div>

                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_rate_per_unit'} labelText={'Rate Per Unit'} className={'mb-1'} />
                                                    <CurrencyInput id={'unit_based_rate_per_unit'} type={'text'} name={'unit_based_rate_per_unit'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                     defaultValue={formInfo.unit_based_rate_per_unit}
                                                     onValueChange={handleunitrateperunitamt}
                                                    />
                                                    <Validation error={errors.unit_based_rate_per_unit} />
                                                </div>
                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_rate_total_qty'} labelText={'Total Measurments'} className={'mb-1'} />
                                                    <CurrencyInput id={'unit_based_rate_total_qty'} type={'text'} name={'unit_based_rate_total_qty'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                     defaultValue={formInfo.unit_based_rate_total_qty}
                                                     onValueChange={handleunittotalqtyamt}
                                                    /> 
                                                    <Validation error={errors.unit_based_rate_total_qty} />
                                                </div>
                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_rate_est_amount'} labelText={'Est. Amount'} className={'mb-1'} />
                                                    <CurrencyInput id={'unit_based_rate_est_amount'} type={'text'} name={'unit_based_rate_est_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                     value={formInfo.unit_based_rate_est_amount = (formInfo.unit_based_rate_total_qty * formInfo.unit_based_rate_per_unit)}/> 
                                                    <Validation error={errors.unit_based_rate_est_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'unit_based_tax_amount'} labelText={'TAX Amount'} className={'mb-1'} />
                                                    <CustomSelect id={'unit_based_tax_amount'} name={'unit_based_tax_amount'} value={formInfo.unit_based_tax_amount} onChange={handleChange}>
                                                        <option value={''}>--- Choose TAX ---</option>
                                                        <option value={'0%'}>0%</option>
                                                        <option value={'5%'}>5%</option>
                                                        <option value={'18%'}>18%</option>
                                                        <option value={'25%'}>25%</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.unit_based_tax_amount} />
                                                </div>
                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_TDS_amount'} labelText={'TDS Amount'} className={'mb-1'} />
                                                    <CustomSelect id={'unit_based_TDS_amount'} name={'unit_based_TDS_amount'} value={formInfo.unit_based_TDS_amount} onChange={handleChange}>
                                                        <option value={''}>--- Choose TDS ---</option>
                                                        <option value={'0%'}>0%</option>
                                                        <option value={'1%'}>1%</option>
                                                        <option value={'2%'}>2%</option>
                                                        <option value={'4%'}>4%</option>
                                                        <option value={'6%'}>6%</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.unit_based_TDS_amount} />
                                                </div>
                                                <div>
                                                    <Label htmlFor={'unit_based_grand_total'} labelText={'Grand Total'} className={'mb-1'} />
                                                    <Input id={'unit_based_grand_total'} type={'text'} name={'unit_based_grand_total'} value={parseInt(unit_based_grand_total).toLocaleString('en-IN')} onChange={handleChange}  />
                                                    <Validation error={errors.unit_based_grand_total} />
                                                </div>
                                                <div className='mb-3'>
                                                    <Label htmlFor={'unit_based_rate_remarks'} labelText={'Remarks'} className={'mb-1'} />
                                                    <Input id={'unit_based_rate_remarks'} type={'text'} name={'unit_based_rate_remarks'} value={formInfo.unit_based_rate_remarks} onChange={handleChange} />
                                                    <Validation error={errors.unit_based_rate_remarks} />
                                                </div>
                                            </div>
                                        </div>
                                }
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                    <span className='ml-5'>Save Now</span>
                                </Button>
                            </Card>
                        </form>
                    </div>
                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default ContractorWorkAddEdit