import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function Loader() {
    const { FRONTEND_URL } = useStateContext()
    return (
        <Fragment>
            <div className='grid justify-center h-screen w-full bg-default-color fixed top-0 left-0 z-50 items-center'>
                <div>
                    <img className='w-[35vw]' src={FRONTEND_URL + '/assets/images/loader.gif'} alt="loader" />
                    <h1 className='text-center text-[2vw] tracking-wider font-bold mt-4'>PLEASE WAIT...</h1>
                </div>
            </div>
        </Fragment>
    )
}

export default Loader