import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { InboxArrowDownIcon, PencilSquareIcon, } from '@heroicons/react/24/solid'
import Label from '../../helpers/Label'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import CustomSelect from '../../helpers/CustomSelect'
import { Navigate, useParams } from 'react-router-dom'
import { useStateContext } from '../../../context/ContextProvider'
import flasher from '@flasher/flasher'
import WorkInfoSideMenu from '../../Common/WorkInfoSideMenu'
import Button from '../../helpers/Button'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import ViewModel from '../../helpers/ViewModel'

function WingInfo() {
    const { id, work_name, work_id } = useParams()
    if (id) {
        document.title = 'SiteSmart - Edit Wing Info'
    } else {
        document.title = 'SiteSmart - Add New Wing Info'
    }
    const [loader, setLoader] = useState(false)
    const { IMG_URL, FRONTEND_URL } = useStateContext()
    const [modalLoader, setModalLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalData, setModalData] = useState([])
    const [viewModel, setViewModel] = useState(false)
    function openModal() {
        setViewModel(true)
        setModalLoader(true)
        setModalLoader(false)
    }
    // Api to Get Work Name And Id   //
    const [workname, setWorkName] = useState("")
    const getWorkDetails = () => {
        setLoader(true)
        axiosClient.get(`/get_work_details/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWorkName(data.list)
            })
    }
    // Api to Get Wing List  //
    const [wing, setWing] = useState([]);
    const getWingData = () => {
        work_id && axiosClient.get(`/work_wise_wings/${work_id}`)
            .then(({ data }) => {
                setLoader(false)
                setWing(data.work_wise_wingdata)
            })
    }
    const [paymentLists, setPaymentLists] = useState('')
    const getWingWiseStageMasterData = () => {
        formInfo.wing_name && axiosClient.get(`/wing_wise_stage_master_data/${work_id}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setPaymentLists(data.work_wise_paymentstagedata)
            })
    }
    // Store Payment stage data //
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', workname.id)
        formData.append('wing_name', formInfo.wing_name);
        formData.append('workpayment_stage', formInfo.workpayment_stage);
        axiosClient.post(id ? `update_Workpayment_stagestatus/${id}` : '/store_Workpayment_stagestatus', formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                Navigate('/work-management') 
            })
            .catch(({ response }) => {
                setLoader(false)
                setViewModel(false)
                setErrors(response.data.errors)
            })
    }
    //  Modal data End //
    const [formInfo, setFormInfo] = useState({
        work_name: '' || work_name,
        wing_name: '',
        no_of_levels: ''
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const [wingData, setWingData] = useState('')
    const [paymentstageData, setPaymentStageData] = useState('')
    const getWingDetails = () => {
        setLoader(true)
        formInfo.wing_name && axiosClient.get(`/workwing_wise_data/${work_id}/${formInfo.wing_name}`)
            .then(({ data }) => {
                setLoader(false)
                setWingData(data.workwing_wise_data)
                setPaymentStageData(data.latest_paymentstagedata)
            })
    }
    useEffect(() => {
        getWingWiseStageMasterData();
    }, [formInfo.wing_name])

    useEffect(() => {
        getWorkDetails();
    }, [])

    useEffect(() => {
        getWingData();
    }, [work_id])

    useEffect(() => {
        getWingDetails();
    }, [formInfo.wing_name])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Wing Information'}>
                    <div className='flex gap-4 '>
                        <div className='bg-default-color p-4 rounded w-[15%] md:w-[20%] mb-5'>
                        { workname.cad_image === null ? (<img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />
                        ) :(
                         workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />                           
                        )}
                            {/* <img src={FRONTEND_URL + '/assets/images/work_logo.avif'} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' /> */}
                            {/* {workname.cad_image && <img src={IMG_URL + '/assets/images/CADFile/' + workname.cad_image} alt="workimage" className='w-8 h-8 md:w-32 md:h-32 mx-auto rounded-full' />} */}
                            <h1 className='text-center font-bold text-lg mt-2 mb-1 hidden md:block'># {workname.work_name}</h1>
                            <div className='flex flex-col gap-3 mt-3 md:mt-0 '>
                                <WorkInfoSideMenu work_id={work_id} />
                            </div>
                        </div>
                        <div className='rounded w-full md:w-[80%] mb-5'>
                            <div className='rounded flex content-center items-center  justify-center bg-white '>
                                <div className='grid grid-cols-1 md:grid-cols-2  bg-white rounded gap-x-28 p-7'>
                                    <div>
                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'flex justify-center'} />
                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={(e) => { handleChange(e); getWingDetails(e) }}>
                                            <option value={''}>--- Choose Wing Name ---</option>
                                            {wing && wing.map((wingData, wingId) => {
                                                return (<Fragment key={wingId}>
                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                </Fragment>)
                                            })}
                                        </CustomSelect>
                                    </div>
                                    <div>
                                        <Button className={'w-60 mx-auto flex top-6'} onClick={openModal} >
                                            <PencilSquareIcon className='w-5 h-5' />
                                            <span className='ml-5'>Change Stage Status</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {wingData && wingData.length > 0 ? (
                                <div className='mt-4'>
                                    {wingData.map((tableData) => {
                                        return (
                                            <div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x p-4 text-center text-base  align-middle bg-default-color mb-4">
                                                    <div className='flex p-8'>
                                                        <h1 className='font-bold  w-40'>Wing</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>{tableData.wing_name}</h1>
                                                    </div>
                                                    <div>
                                                        {paymentstageData === null ?
                                                            (
                                                                <div className='flex p-8'>
                                                                    <h1 className='font-bold w-40'>Stages Name</h1>
                                                                    <span>:</span>
                                                                    <h1 className='pl-10'>{''}</h1>
                                                                </div>
                                                            ) :
                                                            (
                                                                <div className='flex p-8'>
                                                                    <h1 className='font-bold w-40'>Stages Name</h1>
                                                                    <span>:</span>
                                                                    <h1 className='pl-10'>{paymentstageData.stage_name}</h1>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x p-4 text-center text-base  align-middle bg-default-color mb-4">
                                                    <div className='flex p-8'>
                                                        <h1 className='font-bold w-40'>Wing Level</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>{tableData.no_of_levels}</h1>
                                                    </div>
                                                    <div className='flex p-8'>
                                                        <h1 className='font-bold w-40'>Wing Description</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>{tableData.wing_description}</h1>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x p-4 text-center text-base align-middle bg-default-color mb-4">
                                                    <div className='flex  p-8'>
                                                        <h1 className='font-bold w-40'>Budget Amount</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>Rs {Number(tableData.wing_budget).toLocaleString('en-IN')}/-</h1>
                                                    </div>
                                                    <div className='flex  p-8'>
                                                        <h1 className='font-bold w-40'>Completion Date</h1>
                                                        <span>:</span>
                                                        <h1 className='text-red-600 pl-10'>{moment(tableData.wing_completion_date).format('D-MM-YYYY')}</h1>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x p-4 text-center text-base  align-middle bg-default-color mb-4">
                                                    <div className='flex items-center p-8'>
                                                        <h1 className='font-bold w-40'>Work Status</h1>
                                                        <span>:</span>
                                                        <h1 className='ml-7 p-1 bg-primary-color text-white w-32 text-center px-3 rounded-lg'>{tableData.wing_work_status}</h1>
                                                    </div>
                                                    <div className='flex items-center p-8'>
                                                        <h1 className='font-bold w-40'>Start Date</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>{moment(tableData.wing_start_date).format('D-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center p-8'>
                                                        <h1 className='font-bold w-40'>Assigned Project Incharge</h1>
                                                        <span>:</span>
                                                        <h1 className='pl-10'>{tableData.assigned_project_incharge}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div>
                                </div>
                            )}
                            {
                                viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                                    <Dialog.Panel className="w-auto max-w-screen-md  transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                        {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                                            <Fragment>
                                                <div className='flex justify-between items-center gap-2'>
                                                    <h1 className='flex justify-between items-center text-center uppercase font-bold text-xl tracking-widest'>Stage Status</h1>
                                                </div>
                                                <hr className='my-4 border border-primary-color' />
                                                <div className='mt-4'>
                                                    {/* <div className='mb-3'>
                                                                    <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                                                    <Input id={'work_name'} type={'text'} name={'work_name'} value={workname.work_name} onChange={handleChange} disabled={true} />
                                                                </div> */}
                                                    <div className='mb-3'>
                                                        <Label htmlFor={'wing_name'} labelText={'Wing Name'} className={'mb-1'} />
                                                        <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} onChange={handleChange}>
                                                            <option value={''}>--- Choose Wing Name ---</option>
                                                            {wing && wing.map((wingData, wingId) => {
                                                                return (<Fragment key={wingId}>
                                                                    <option value={wingData.id}>{wingData.wing_name}</option>
                                                                </Fragment>)
                                                            })}
                                                        </CustomSelect>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Label htmlFor={'workpayment_stage'} labelText={'Payment Stages'} className={'mb-1'} />
                                                        <CustomSelect id={'workpayment_stage'} name={'workpayment_stage'} value={formInfo.workpayment_stage} onChange={handleChange}>
                                                            <option value={''}>--- Choose Payment Stages ---</option>
                                                            {paymentLists && paymentLists.map((paymentData, paymentId) => {
                                                                return (<Fragment key={paymentId}>
                                                                    <option value={paymentData.id}><p>{paymentData.stage_name}</p></option>
                                                                </Fragment>
                                                                )
                                                            })}
                                                        </CustomSelect>
                                                    </div>
                                                    <Button className={'w-60 mt-6 mx-auto'} onClick={handleSubmit}>
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                                        <span className='ml-5'>Save Now</span>
                                                    </Button>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Dialog.Panel>
                                </ViewModel>
                            }
                        </div>
                    </div>
                </PageComponent>
            )}
        </Fragment>
    )
}


export default WingInfo

