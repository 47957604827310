import React, { Fragment, useEffect, useRef, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, ForwardIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import ViewModel from '../helpers/ViewModel'
import { Link } from 'react-router-dom'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import { useStateContext } from '../../context/ContextProvider'
import { useReactToPrint } from 'react-to-print'
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination'
import PleaseWait from '../Common/PleaseWait'



function DealRegistration() {
    document.title = 'SiteSmart - Deal Registration'
    const tableHeading = ['Sr.No', 'Enquiry ID', 'Client Name', 'Work Name', 'Wing Name', 'Grand Total', 'Action']
    const feestableHeading = ['Sr.No', 'Fees Type', 'Fees Amount', 'Remark']

    const [loader, setLoader] = useState(false)

    const [viewModel, setViewModel] = useState(false)
    const [imgModel, setImgModel] = useState(false)
    const [modelImage, setModelImage] = useState(false)
    const [folderName, setFolderName] = useState('')
    const [modalLoader, setModalLoader] = useState(false)
    const [imgmodalLoader, setImgModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [feeList, setFeeList] = useState([])
    const [totalFeesAmount, setTotalFeesAmount] = useState()
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const [dealRegistrationList, setDealRegistrationList] = useState();
    const getDealRegistrationLists = () => {
        setLoader(true)
        axiosClient.get(`show_DealRegistration_list`)
            .then(({ data }) => {
                setLoader(false)
                setDealRegistrationList(data.lists)
            })
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleModelImage = (image, foldername) => {
        setImgModalLoader(true)
        setImgModel(true)
        setModelImage(image)
        setFolderName(foldername)
        setImgModalLoader(false)
    }



    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`single_deal_registration_data/${id}`)
            .then(({ data }) => {
                data.view_data ? setModalData(data.view_data) : setModalData(data.single_data)
            })
        axiosClient.get(`/DealRegistrationFees_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setFeeList(data.DealRegistrationFees)
                setTotalFeesAmount(data.total_sum)
            })
    }

    useEffect(() => {
        getDealRegistrationLists()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Client Deal Registration'}>

                    {dealRegistrationList && dealRegistrationList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {dealRegistrationList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.enq_id}</td>
                                        <td className="px-4 py-3">{tableData.client_name}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{tableData.wing_name}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.grandDealTotal).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton title={'View'} onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                {
                                                    tableData.grandDealTotal ?
                                                        <Link title='Forwarded' to={`/accounting/client-payment`}> <ActionButton className={'p-1.5 text-white bg-teal-600 rounded-lg'}><ForwardIcon className='w-5 h-5' /></ActionButton></Link> :
                                                        <Link title='Edit' to={`/post-sales/edit-deal-registration/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Deal Registration Data Not Found</p>
                        </div>
                    )}
                    {dealRegistrationList && dealRegistrationList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={dealRegistrationList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto ml-10 md:ml-0 max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='p-0 md:p-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Deal Registration Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4 '>
                                                <div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Client Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.client_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Work Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Wing Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.wing_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Level</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.level_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Flat No.</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.flat_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Nature of Flat</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.nature_of_flat}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Area</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Carpet Area</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.carpet_area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Flat Amount With GST</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{parseInt(modalData.flat_amount_with_gst).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Date</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{moment(modalData.created_at).format('DD-MM-YYYY')}</h1>
                                                    </div>

                                                </div>
                                                <div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Parking</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.parking_area}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Parking No.</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.parking_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Parking Amount With GST</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{parseInt(modalData.parking_amount_with_gst).toLocaleString('en-IN')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='text-[3vw] md:text-lg font-bold'>Owner Documents List</h1>
                                                        <h1>-</h1>
                                                    </div>
                                                    <div className='grid grid-cols-2 md:grid-cols-3 text-white text-xs text-center gap-x-3 mt-2 '>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_adharCard, 'owneradharCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Aadhar Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_panCard, 'ownerpanCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Pan Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_bankPassbook, 'ownerbankPassbook')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Bank Passbook</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_salarySlips, 'ownersalarySlips')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Salary Slips</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_idPhoto, 'owneridPhoto')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Id Photo</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_voterCard, 'ownervoterCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Voter's Id Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_bankLetter, 'ownerbankLetter')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Bank Letter</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.o_electricityBill, 'ownerelectricityBill')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Electricity Bill</h1>
                                                        </Link>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='text-[3vw] md:text-lg font-bold'>Co-Owner Documents List</h1>
                                                        <h1>-</h1>
                                                    </div>
                                                    <div className='grid grid-cols-2 md:grid-cols-3 text-white text-xs text-center gap-x-3 mt-2 '>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_adharCard, 'co_adharCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Aadhar Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_panCard, 'co_panCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Pan Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_bankPassbook, 'co_bankPassbook')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Bank Passbook</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_salarySlips, 'co_salarySlips')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Salary Slips</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_idPhoto, 'co_idPhoto')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Id Photo</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_voterCard, 'co_voterCard')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Voter's Id Card</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_bankLetter, 'co_bankLetter')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Bank Letter</h1>
                                                        </Link>
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-gray-50-500 duration-300' onClick={() => handleModelImage(modalData.co_electricityBill, 'co_electricityBill')}>
                                                            <h1 className='bg-primary-color p-2 mb-2 rounded-md'>Electricity Bill</h1>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4 w-full'>
                                                {feeList && feeList.length > 0 ? (
                                                    <Table tableHeading={feestableHeading}>
                                                        {feeList.map((tableData, tableDataID) => {
                                                            return (<Fragment key={tableDataID}>
                                                                <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                    <td>{tableDataID + 1}</td>
                                                                    <td className="px-4 py-3 w-[20%]">{tableData.fees_type}
                                                                    </td>
                                                                    <td className="px-4 py-3 w-[20%]">{parseInt(tableData.fees_amount).toLocaleString('en-IN')}
                                                                    </td>
                                                                    <td className="px-4 py-3 w-[20%]"> {tableData.fees_remark}
                                                                    </td>
                                                                </tr>
                                                            </Fragment>)
                                                        })}

                                                        <tr className="bg-default-color text-gray-900 border-b border-t-2 text-center">
                                                            <th className="px-4 py-3 whitespace-nowrap">Total</th>
                                                            <th className="px-4 py-3 whitespace-nowrap"></th>
                                                            <th className="px-4 py-3 whitespace-nowrap">{parseInt(totalFeesAmount).toLocaleString('en-IN')}</th>
                                                            <th className="px-4 py-3 whitespace-nowrap"></th>
                                                        </tr>
                                                    </Table>
                                                ) : (
                                                    <div className='flex justify-center items-center h-[20vh]'>
                                                        <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Fees Data Not Found</p>
                                                    </div>
                                                )}
                                            </div>
                                            <br />
                                            <div className='mb-3 grid grid-cols-1 md:grid-cols-3 gap-4'>
                                                <div ></div>
                                                <div ></div>
                                                <div className='flex justify-center text-[3vw] md:text-xl bg-gray-200 p-2 rounded-lg mb-4'>
                                                    <h1 className='font-extrabold'>Grand Total</h1>
                                                    <span className='font-extrabold mr-2 ml-2'>-</span>
                                                    <h1 className='font-extrabold'>{parseInt(modalData.grandDealTotal).toLocaleString('en-IN')}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                            <div ></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                    {
                        imgModel && <ViewModel viewModel={imgModel} setViewModel={setImgModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                                {imgmodalLoader ? <PleaseWait/> : (
                                    <Fragment>
                                        <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>View Full Image</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        {
                                            <img src={modelImage ? (
                                                modelImage.name ? URL.createObjectURL(modelImage) : IMG_URL + `/assets/images/${folderName}/` + modelImage
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-full h-full rounded' />
                                        }
                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setImgModel(false)}
                                            >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}

export default DealRegistration

