import React, { useRef, Fragment, useEffect, useState } from 'react'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axiosClient from '../../Axios'
import flasher from '@flasher/flasher'
import PageComponent from '../../components/PageComponent'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import ViewModel from '../helpers/ViewModel'
import Table from '../helpers/Table'
import { Dialog } from '@headlessui/react'
import moment from 'moment'
import ActionButton from '../helpers/ActionButton'
import { useReactToPrint } from "react-to-print";
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'

function EmployeeAdvance() {
    document.title = 'SiteSmart - View Salary And Payment Details'
    const tableHeading = ['Sr.No', 'Employee', 'Designation', 'Work Name', 'Salary per Month', 'Advanced Paid Amount', 'Advanced Month', 'Action']
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [modalData, setModalData] = useState([])
    const [advanceList, setAdvanceList] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const getAdvanceList = () => {
        setLoader(true)
        axiosClient.get('/Employee_Advance_list/')
            .then(({ data }) => {
                setLoader(false)
                setAdvanceList(data.lists)
            })
    }
    const deleteAdvance = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Employee_Advance/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getAdvanceList()
                    })
            }
        })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Employee_Advance_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAdvanceList()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Advance'} button={
                    <LinkButton to={'/advance-details/add-advance-details'}>
                        <PlusCircleIcon className='w-5 h-5 text-default-color' />
                        <span>Add Advance</span>
                    </LinkButton>}>
                    {advanceList && advanceList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {advanceList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{tableData.emp_name}</td>
                                        <td className="px-4 py-3">{tableData.designation}</td>
                                        <td className="px-4 py-3">{tableData.work_name}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.salary_per_month).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{parseInt(tableData.advanced_paid_amount).toLocaleString('en-IN')}</td>
                                        <td className="px-4 py-3">{moment(tableData.advanced_month).format('MM-YYYY')}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} className={'p-1.5'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/advance-details/edit-advance-details/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg '}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteAdvance(tableData.id)} title={'Delete'} className={'p-1.5  text-white bg-red-600 rounded-lg cursor-pointer '}><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Advance Details Not Found</p>
                        </div>
                    )}
                    {advanceList && advanceList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={advanceList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                      {
                        viewModel && modalData && <ViewModel viewModel={viewModel} setViewModel={setViewModel} >
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? <PleaseWait /> : (
                                    <Fragment>
                                        <div className='m-5' ref={componentRef} id='print'>
                                            <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-widest'>Employee Advance Information</h1>
                                            <hr className='my-4 border border-primary-color' />
                                            <div className='mb-1 grid grid-cols-1 md:grid-cols-2 gap-x-3'>
                                                
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Work Name</h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.work_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Employee</h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.emp_name}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Designation</h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.designation}</h1>
                                                </div>
                                                 <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Salary/Month</h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{Number(modalData.salary_per_month).toLocaleString('en-IN')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Advance Paid </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{Number(modalData.advanced_paid_amount).toLocaleString('en-IN')}</h1>
                                                </div>
                                                 <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Advance Month</h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.advanced_month}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Paid Date </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{moment(modalData.paid_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold whitespace-nowrap'>Paid from Account </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.paid_from_account}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>Payment Mode </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.payment_mode}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>TR Date </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{moment(modalData.Tr_date).format('DD-MM-YYYY')}</h1>
                                                </div>
                                                <div className='grid grid-cols-3 gap-1 mb-1 md:mb-4'>
                                                    <h1 className='font-bold '>TR Remark </h1>
                                                     <h1 className='justify-center flex'>-</h1>
                                                    <h1 >{modalData.Tr_remark}</h1>
                                                </div>
                                                </div>
                                            
                                        </div>
                                        <div className="mt-4  grid grid-cols-3">
                                            <button id='gotit'
                                                type="button"
                                                className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                            <div className=""></div>
                                            <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                        </div>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default EmployeeAdvance