import React, { Fragment, useEffect, useState } from 'react'
import { ArrowLeftCircleIcon, InboxArrowDownIcon } from '@heroicons/react/24/solid'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import PageComponent from '../../components/PageComponent'
import { useStateContext } from '../../context/ContextProvider'
import CurrencyInput from 'react-currency-input-field';

function TransactionDetailsAddEdit() {
    const { id } = useParams()
    document.title = 'SiteSmart - Add contractor Payment'
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [billUpload, setBillUpload] = useState('');
    const billImageChange = (e) => {
        setBillUpload(e.target.files[0]);
    }

    const [formInfo, setFormInfo] = useState({
        tax_amount: '',
        tds_amount: '',
        bill_payable_amount: '',
        bill_description: '',
        bill_remarks: '',
        bill_upload: '',
        paid_amount: '',
        paid_date: '',
        paid_from_acc: '',
        payment_mode: '',
        tr_reference_no: '',
        tr_reference_date: '',
        transasction_remark: '',
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('work_name', data.work_name)
        formData.append('contractor_name', data.contractor_name)
        formData.append('firm_name', data.firm_name)
        formData.append('wing', data.wing_name)
        formData.append('level_name', data.level_name)
        formData.append('bill_no', data.id)
        formData.append('bill_date', data.bill_date)
        formData.append('bill_gross_amount', data.total_amount)
        if (data.contract_type == "Fixed Rate") {
            formData.append('tax_amount', data.fixed_rate_tax_amount)
            formData.append('tds_amount', data.fixed_rate_TDS_amount)
            formData.append('bill_payable_amount', data.fixed_rate_grand_total)
        } else {
            formData.append('tax_amount', data.unit_based_rate_work_type)
            formData.append('tds_amount', data.unit_based_TDS_amount)
            formData.append('bill_payable_amount', data.unit_based_grand_total)
        }
        formData.append('bill_description', formInfo.bill_description)
        formData.append('bill_remarks', formInfo.bill_remarks)
        formData.append('bill_upload', billUpload)
        formData.append('paid_amount', formInfo.paid_amount)
        formData.append('paid_date', formInfo.paid_date)
        formData.append('paid_from_acc', formInfo.paid_from_acc)
        formData.append('payment_mode', formInfo.payment_mode)
        formData.append('tr_reference_no', formInfo.tr_reference_no)
        formData.append('tr_reference_date', formInfo.tr_reference_date)
        formData.append('transasction_remark', formInfo.transasction_remark)
        formData.append('bill_balance_amount', data.balance)
        if (data.contract_type == "Fixed Rate") {
            formData.append('work_type', data.fixed_rate_work_type)
        } else {
            formData.append('work_type', data.unit_based_rate_work_type)
        }
        axiosClient.post(`update_Contractorpayment/${id}`, formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/transactiondetails')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    const [data, setData] = useState("")
    const getSingleContractorData = () => {
        setLoader(true)
        axiosClient.get(`/single_Contractorpayment_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setBillUpload(data.single_data.upload_bill_copy)
                setFormInfo(data.single_data)
                setData(data.single_data)
            })
    }
    const [worktype, setWorktype] = useState('')
    const getWorktype = () => {
        setLoader(true)
        axiosClient.get(`/worktype_list`)
            .then(({ data }) => {
                setWorktype(data.lists)
            })
    }

    useEffect(() => {
        if (id) {
            getSingleContractorData()
        }
        getWorktype();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={id ? 'Edit Contractor Payment' : 'Contractor  Payment'} button={
                    <LinkButton to={'/transactiondetails'}>
                        <ArrowLeftCircleIcon className='w-6 h-6' />
                        <span>Back To List</span>
                    </LinkButton>
                }>
                    <div className='grid items-center justify-center '>
                        <form onSubmit={handleSubmit}>
                        <Card className={'w-full md:w-[70rem] p-6 mb-2 bg-default-color'}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-3'>
                                    <div>
                                        <Label htmlFor={'contractor_name'} labelText={'Contractor Name'} className={'mb-1'} />
                                        <Input id={'contractor_name'} type={'text'} name={'contractor_name'} value={data.contractor_name} readOnly={true} />
                                        <Validation error={errors.contractor_name} />
                                    </div>
                                    <div className='mb-3'>
                                        <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                                        <Input id={'work_name'} type={'text'} name={'work_name'} value={data.work_name} readOnly={true} />
                                        <Validation error={errors.work_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'wing'} labelText={'Wing Name'} className={'mb-1'} />
                                        <Input id={'wing'} type={'text'} name={'wing'} value={data.wing_name} readOnly={true} />
                                        <Validation error={errors.wing} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'level_name'} labelText={'Level Name'} className={'mb-1'} />
                                        <Input id={'level_name'} type={'text'} name={'level_name'} value={data.level_name} readOnly={true} />
                                        <Validation error={errors.level_name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'task_name'} labelText={'Task Name'} className={'mb-1'} />
                                        <Input id={'task_name'} type={'text'} name={'task_name'} value={data.task_name} readOnly={true} />
                                        <Validation error={errors.task_name} />
                                    </div>
                                    {data.contract_type === "Fixed Rate" ? (
                                        <div className='mb-3 '>
                                            <Label htmlFor={'work_type'} labelText={' Work Type'} className={'mb-1'} />
                                            {/* <Input id={'work_type'} type={'text'} name={'work_type'} value={data.fixed_rate_work_type} readOnly={true} /> */}
                                            <CustomSelect id={'work_type'} name={'work_type'} value={data.fixed_rate_work_type}>
                                                        <option value={''}>--- Select Work Type ---</option>
                                                        {worktype && worktype.map((worktypedata, worktypeID) => {
                                                            return (<Fragment key={worktypeID}>
                                                                <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                            <Validation error={errors.work_type} />
                                        </div>
                                    ) : (
                                        <div className='mb-3 '>
                                            <Label htmlFor={'work_type'} labelText={' Work Type'} className={'mb-1'} />
                                            {/* <Input id={'work_type'} type={'text'} name={'work_type'} value={data.unit_based_rate_work_type} readOnly={true} /> */}
                                            <CustomSelect id={'work_type'} name={'work_type'} value={data.unit_based_rate_work_type} >
                                                        <option value={''}>--- Select Work Type ---</option>
                                                        {worktype && worktype.map((worktypedata, worktypeID) => {
                                                            return (<Fragment key={worktypeID}>
                                                                <option value={worktypedata.id}>{worktypedata.work_type}</option>
                                                            </Fragment>)
                                                        })}
                                                    </CustomSelect>
                                            <Validation error={errors.work_type} />
                                        </div>
                                    )}
                                </div>

                            </Card>
                            <Card className={'w-full md:w-[70rem] p-6 mb-2 bg-default-color'}>
                                <div className='mb-4'>
                                    <span className='text-xl font-normal'>Bill details :</span>
                                </div>
                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>
                                <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
                                    <div className='col-start-1 col-span-3'>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 mb-4 '>
                                            <div>
                                                <Label htmlFor={'bill_no'} labelText={'Bill No.'} className={'mb-1'} />
                                                <Input id={'bill_no'} type={'text'} name={'bill_no'} value={data.id} readOnly={true} />
                                                <Validation error={errors.bill_no} />
                                            </div>
                                            <div>
                                                <Label htmlFor={'bill_date'} labelText={'Bill Date'} className={'mb-1'} />
                                                <Input id={'bill_date'} type={'date'} name={'bill_date'} value={data.bill_date} readOnly={true} />
                                                <Validation error={errors.bill_date} />
                                            </div>
                                            <div className='mt-4'>
                                                <Label htmlFor={'bill_gross_amount'} labelText={'Bill Gross Amount'} className={'mb-1'} />
                                                <CurrencyInput id={'bill_gross_amount'} type={'text'} name={'bill_gross_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                    value={data.fixed_rate_est_amount == null? ( data.unit_based_rate_est_amount): (data.fixed_rate_est_amount)} />
                                                <Validation error={errors.bill_gross_amount} readOnly={true} />
                                            </div>
                                            {data.contract_type === "Fixed Rate" ? (
                                                <div className='mt-4'>
                                                    <div>
                                                        <Label htmlFor={'tax_amount'} labelText={'Tax '} className={'mb-1'} />
                                                        <Input id={'tax_amount'} type={'text'} name={'tax_amount'} value={data.fixed_rate_tax_amount} readOnly={true} />
                                                        <Validation error={errors.tax_amount} />
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    <div className='mt-4'>
                                                        <div>
                                                            <Label htmlFor={'tax_amount'} labelText={'Tax '} className={'mb-1'} />
                                                            <Input id={'tax_amount'} type={'text'} name={'tax_amount'} value={data.unit_based_tax_amount} readOnly={true} />
                                                            <Validation error={errors.tax_amount} />
                                                        </div>
                                                    </div>
                                                )}
                                            {data.contract_type === "Fixed Rate" ? (
                                                <div className='mt-4'>
                                                    <div>
                                                        <Label htmlFor={'tds_amount'} labelText={'TDS '} className={'mb-1'} />
                                                        <Input id={'tds_amount'} type={'text'} name={'tds_amount'} value={data.fixed_rate_TDS_amount} readOnly={true} />
                                                        <Validation error={errors.tds_amount} />
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    <div className='mt-4'>
                                                        <div>
                                                            <Label htmlFor={'tds_amount'} labelText={'TDS '} className={'mb-1'} />
                                                            <Input id={'tds_amount'} type={'text'} name={'tds_amount'} value={data.unit_based_TDS_amount} readOnly={true} />
                                                            <Validation error={errors.tds_amount} />
                                                        </div>
                                                    </div>
                                                )}
                                            {data.contract_type === "Fixed Rate" ? (
                                                <div className='mt-4'>
                                                    <div>
                                                        <Label htmlFor={'bill_payable_amount'} labelText={'Bill Payable Amount'} className={'mb-1'} />
                                                        <CurrencyInput id={'bill_payable_amount'} type={'text'} name={'bill_payable_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                            value={data.fixed_rate_grand_total} readOnly={true} />
                                                        <Validation error={errors.bill_payable_amount} />
                                                    </div>
                                                </div>
                                            ) :
                                                (
                                                    <div className='mt-4'>
                                                        <div>
                                                            <Label htmlFor={'bill_payable_amount'} labelText={'Bill Payable Amount'} className={'mb-1'} />
                                                            <CurrencyInput id={'bill_payable_amount'} type={'text'} name={'bill_payable_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                                                value={data.unit_based_grand_total} readOnly={true} />
                                                            <Validation error={errors.bill_payable_amount} />
                                                            <Validation error={errors.bill_payable_amount} />
                                                        </div>
                                                    </div>
                                                )}
                                            <div className='mt-4'>
                                                <Label htmlFor={'bill_description'} labelText={'Bill Description'} className={'mb-1'} />
                                                <Input id={'bill_description'} type={'text'} name={'bill_description'} value={formInfo.bill_description} readOnly={true} />
                                                <Validation error={errors.bill_description} />
                                            </div>
                                            <div className='mt-4'>
                                                <Label htmlFor={'bill_remarks'} labelText={'Bill Remark'} className={'mb-1'} />
                                                <Input id={'bill_remarks'} type={'text'} name={'bill_remarks'} value={formInfo.bill_remark} readOnly={true} />
                                                <Validation error={errors.bill_remarks} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Label htmlFor={'bill_upload'} labelText={'Upload Invoice'} />
                                            <img src={billUpload ? (
                                                billUpload.name ? URL.createObjectURL(billUpload) : IMG_URL + "/assets/images/BillcopyFile/" + billUpload
                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="bill-img" className='md:w-96 w-80 md:h-80 h-70 rounded' />
                                            <Input id={'bill_upload'} type={'file'} name={'bill_upload'} onChange={billImageChange} className={'p-[0px!important] mt-2'} />
                                            <Validation error={errors.bill_upload} />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card className={'w-full md:w-[70rem] p-6  bg-default-color'}>
                                <div className='mb-4'>
                                    <span className='text-xl font-normal'>Transaction details :</span>
                                </div>
                                <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr>

                                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-3 mb-4'>
                                   <div className='mt-4'>
                                        <Label htmlFor={'paid_amount'} labelText={'Paid Amount'} className={'mb-1'} />
                                        <CurrencyInput id={'paid_amount'} type={'text'} name={'paid_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={formInfo.paid_amount} readOnly={true} />
                                        <Validation error={errors.paid_amount} />
                                    </div>
                                   <div className='mt-4'>
                                        <Label htmlFor={'paid_date'} labelText={'Paid Date'} className={'mb-1'} />
                                        <Input id={'paid_date'} type={'date'} name={'paid_date'} value={formInfo.paid_date} onChange={handleChange} />
                                        <Validation error={errors.paid_date} />
                                    </div>
                                   <div className='mt-4'>
                                        <Label htmlFor={'paid_from_acc'} labelText={'Paid From Acc'} className={'mb-1'} />
                                        <Input id={'paid_from_acc'} type={'text'} name={'paid_from_acc'} value={formInfo.paid_from_acc} onChange={handleChange} maxLength={16} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.paid_from_acc} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6 mb-4'>
                                   <div className='mt-4'>
                                        <Label htmlFor={'payment_mode'} labelText={'Payment Mode'} className={'mb-1'} />
                                        <CustomSelect id={'payment_mode'} name={'payment_mode'} value={formInfo.payment_mode} onChange={handleChange}>
                                            <option value={''}>--- Choose Payment Mode ---</option>
                                            <option value={'Online'}>Online</option>
                                            <option value={'Cash'}>Cash</option>
                                        </CustomSelect>
                                        <Validation error={errors.payment_mode} />
                                    </div>
                                   <div className='mt-4'>
                                        <Label htmlFor={'tr_reference_no'} labelText={'TR Reference No.'} className={'mb-1'} />
                                        <Input id={'tr_reference_no'} type={'text'} name={'tr_reference_no'} value={formInfo.tr_reference_no} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.tr_reference_no} />
                                    </div>
                                   <div className='mt-4'>
                                        <Label htmlFor={'tr_reference_date'} labelText={'TR Reference Date'} className={'mb-1'} />
                                        <Input id={'tr_reference_date'} type={'date'} name={'tr_reference_date'} value={formInfo.tr_reference_date} onChange={handleChange} />
                                        <Validation error={errors.tr_reference_date} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 mb-4'>
                                   <div className='mt-4'>
                                        <Label htmlFor={'transasction_remark'} labelText={'Transaction Remarks'} className={'mb-1'} />
                                        <Input id={'transasction_remark'} type={'text'} name={'transasction_remark'} value={formInfo.transasction_remark} onChange={handleChange} />
                                        <Validation error={errors.transasction_remark} />
                                    </div>
                                   <div className='mt-4'>
                                        <Label htmlFor={'bill_balance_amount'} labelText={'Remaining Balance'} className={'mb-1'} />
                                        <CurrencyInput id={'bill_balance_amount'} type={'text'} name={'bill_balance_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                                            value={data.balance} readOnly={true} />
                                        <Validation error={errors.bill_balance_amount} />
                                    </div>
                                </div>
                               <div >
                                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><InboxArrowDownIcon className="h-5 w-5 text-default-color" /></span>
                                        <span className='ml-5'>Save Now</span>
                                    </Button>
                                </div>
                            </Card>
                        </form>
                    </div>
                </PageComponent>
            )
            }
        </Fragment >
    )
}
export default TransactionDetailsAddEdit