import React, { Fragment, useEffect, useState } from 'react'
import PageComponent from '../../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import Validation from '../../helpers/Validation'
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment'

function Voucher() {
    document.title = 'SiteSmart - Account Voucher'
    const tableHeading = ['Sr.No', 'Date', 'Type Of Voucher', 'Ledger','group', 'Amount', 'Action']
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [modalLoader, setModalLoader] = useState(false)

    const [voucherLedgerLists, setVoucherLedgerLists] = useState('')
    const getVoucherLedgerLists = () => {
        setLoader(true)
        axiosClient.get('/VoucherTransaction_list')
            .then(({ data }) => {
                setLoader(false)
                setVoucherLedgerLists(data.lists)
            })
    }
    // api for Sort by Wing Name dropdown //
    const getvoucherSortDetails = () => {
        formInfo.type_of_voucher && setLoader(true)
        formInfo.type_of_voucher && axiosClient.get(`/voucher_wise_VoucherTransaction_list/${formInfo.type_of_voucher}`)
            .then(({ data }) => {
                setLoader(false)
                setVoucherLedgerLists(data.lists)
            })
    }
    const getLedgerSortDetails = () => {
        formInfo.ledger && setLoader(true)
        formInfo.ledger && axiosClient.get(`/ledger_wise_VoucherTransaction_list/${formInfo.ledger} `)
            .then(({ data }) => {
                setLoader(false)
                setVoucherLedgerLists(data.lists)
            })
    }
    const [voucherLists, setVoucherLists] = useState('')
    const getVoucherlist = () => {
        setLoader(true)
        axiosClient.get('/vouchermaster_list')
            .then(({ data }) => {
                setLoader(false)
                setVoucherLists(data.lists)
            })
    }
    const [accountLedgerLists, setAccountLedgerLists] = useState('')
    const getAccountLedgerLists = () => {
        setLoader(true)
        axiosClient.get('/Accountledger_lists')
            .then(({ data }) => {
                setLoader(false)
                setAccountLedgerLists(data.lists)
            })
    }

    const deleteAccountLedger = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_VoucherTransaction/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getVoucherLedgerLists()
                    })
            }
        })
    }
    const [isOpen, setIsOpen] = useState(false)
    const [modalData, setModalData] = useState([])
    function closeModal() { setIsOpen(false) }
    function openModal(id) {
        setIsOpen(true)
        setModalLoader(true)
        axiosClient.get(`/single_VoucherTransaction_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
            })
    }
    const [formInfo, setFormInfo] = useState({
    })
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    useEffect(() => {
        getVoucherLedgerLists();
        getVoucherlist();
        getAccountLedgerLists();
    }, [])

    useEffect(() => {
        getvoucherSortDetails();
    }, [formInfo.type_of_voucher])

    useEffect(() => {
        getLedgerSortDetails();
    }, [formInfo.ledger])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Voucher'} button={
                    <LinkButton to={'/accounting/account-voucher/add-account-voucher'}>
                        <PlusCircleIcon className='w-5 h-5' />
                        <span>Add New Voucher</span>
                    </LinkButton>
                }>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8">
                        <div className='mb-3'>
                            <Label htmlFor={'type_of_voucher'} labelText={'Type Of Voucher/Goods'} className={'mb-1'} />
                            <CustomSelect id={'type_of_voucher'} name={'type_of_voucher'} value={formInfo.type_of_voucher} onChange={handleChange}>
                                <option value={''}>--- Select Voucher Name ---</option>
                                {voucherLists && voucherLists.map((voucherdata, voucherID) => {
                                    return (<Fragment key={voucherID}>
                                        <option value={voucherdata.id}>{voucherdata.voucher_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                            <Validation error={errors.type_of_voucher} />
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor={'ledger'} labelText={'Ledger'} className={'mb-1'} />
                            <CustomSelect id={'ledger'} name={'ledger'} value={formInfo.ledger} onChange={handleChange}>
                                <option value={''}>--- Select Ledger Name ---</option>
                                {accountLedgerLists && accountLedgerLists.map((ledgerdata, ledgerID) => {
                                    return (<Fragment key={ledgerID}>
                                        <option value={ledgerdata.id}>{ledgerdata.ledger_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                            <Validation error={errors.ledger} />
                        </div>
                    </div>
                    {voucherLedgerLists && voucherLedgerLists.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {voucherLedgerLists.map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                        <td className="px-4 py-3">{moment(tableData.date).format('D-MM-YYYY')}</td>
                                        <td className="px-4 py-3">{tableData.voucher_name}</td>
                                        <td className="px-4 py-3">{tableData.ledger_name}</td>
                                        <td className="px-4 py-3">{tableData.group_name}</td>
                                        <td className="px-4 py-3">{tableData.amount}</td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 text-white bg-primary-color rounded-lg cursor-pointer'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                <Link title='Edit' to={`/accounting/account-voucher/edit-account-voucher/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                <ActionButton onClick={() => deleteAccountLedger(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'><TrashIcon className='w-5 h-5' /></ActionButton>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Voucher Data Not Found</p>
                        </div>
                    )}
                    <Transition appear show={isOpen} as={Fragment} >
                        <Dialog as="div" className="relative z-50" onClose={closeModal}>
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>
                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                        <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            {modalLoader ? (<h1 className='text-xl text-center font-bold'>PLEASE WAIT</h1>) : (
                                                <Fragment>
                                                    <h1 className='text-center uppercase font-bold text-2xl tracking-widest'>Voucher Information</h1>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Work Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.work_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Date</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{moment(modalData.date).format('D-MM-YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'> Bill No.</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.bill_no}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Voucher Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.voucher_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Leadger</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.ledger_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Group </h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.group_name}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Amount</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.amount}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Paid From</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.paid_from}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-4 w-full py-2'>
                                                        <h1 className='w-36 font-bold'>Bank Name</h1>
                                                        <h1>-</h1>
                                                        <h1 className='ml-6'>{modalData.bank_name}</h1>
                                                    </div>
                                                    <div className="mt-3">
                                                        <button
                                                            type="button"
                                                            className=" rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={closeModal} >
                                                            Got it, thanks!
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </PageComponent>
            )}
        </Fragment>
    )
}

export default Voucher
