import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { EyeIcon, PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import Button from '../helpers/Button'
import ViewModel from '../helpers/ViewModel'
import Validation from '../helpers/Validation'
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import { useReactToPrint } from "react-to-print";
import ActionButton from '../helpers/ActionButton'
import Pagination from '../helpers/Pagination';
import PleaseWait from '../Common/PleaseWait'
import moment from 'moment'

function ContractorWork() {
    document.title = 'SiteSmart - contractor Work '
    const tableHeading = ['Sr.No', 'Work', 'Wing', 'Level ', 'Task ', 'Contractor', 'Start', 'Due', 'Status', 'Actions']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [contractorWorkList, setContractorWorkList] = useState('')
    const [statusModel, setStatusModel] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [workName, setWorkName] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

    const handleChangeWorkName = (e) => {
        setWorkName(e.target.value)
    }
    const [updatestatus, setUpdatestatus] = useState({
        status: '',
    })
    const getContractorWorkList = () => {
        setLoader(true)
        axiosClient.get('/Contractor_work_lists')
            .then(({ data }) => {
                setContractorWorkList(data.lists)
                setLoader(false)
            })
    }

    const getWorkWiseData = () => {
        setLoader(true)
        axiosClient.get(`/workwise_Contractor_work_lists/${workName}`)
            .then(({ data }) => {
                setLoader(false)
                setContractorWorkList(data.lists)
            })
    }

    const handleSubmitStatus = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData2 = new FormData()
        formData2.append('status', updatestatus.status)
        formData2.append('work_name', updatestatus.work_name)
        axiosClient.post(`update_task_status/${taskID}`, formData2)
            .then(({ data }) => {
                setLoader(false)
                setStatusModel(false)
                getContractorWorkList();
                flasher.success(data.msg)
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }

    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setModalLoader(false)
            })
    }

    const contractorname = modalData.contractor_name
    const [taskID, setTaskID] = useState('')
    const [task, setTaskLists] = useState('')
    const getContractorWiseData = () => {
        setLoader(false)
        axiosClient.get(`/contractor_wise_taskdata/${contractorname}`)
            .then(({ data }) => {
                setLoader(false)
                setTaskLists(data.contractor_wise_taskdata)
            })
    }

    function openModal1(id) {
        setStatusModel(true)
        setModalLoader(false)
        axiosClient.get(`/single_Contractor_work_data/${id}`)
            .then(({ data }) => {
                setLoader(false)
                setUpdatestatus(data.single_data)
                setTaskID(data.single_data.taskID)
            })
    }

    const handleStatusChange = (e) => {
        setUpdatestatus(updatestatus => ({
            ...updatestatus,
            [e.target.name]: e.target.value
        }))
    }

    function filldata() {
        Swal.fire({
            title: 'You can not change status without filling Work Details',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        })
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [works, setWorks] = useState([]);
    const getWorkData = () => {
        setLoader(true)
        axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setLoader(false)
                setWorks(data.lists)
            })
    }

    const getAllData = () => {
        workName && setLoader(true)
        workName && workName == 'All' ? (getContractorWorkList()) : axiosClient.get(`/work_lists`)
            .then(({ data }) => {
                setWorks(data.lists)
                setLoader(false)
            })
    }

    useEffect(() => {
        getWorkWiseData()
    }, [workName])

    useEffect(() => {
        getAllData();
    }, [workName])

    useEffect(() => {
        getContractorWiseData();
    }, [contractorname])

    useEffect(() => {
        getContractorWorkList()
        getWorkData()
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'Contractor Work'} >
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-3'>
                        <div >
                            <Label htmlFor={'work_name'} labelText={'Work Name'} className={'mb-1'} />
                            <CustomSelect id={'work_name'} name={'work_name'} value={workName} onChange={handleChangeWorkName} className={'w-full'}>
                                <option disabled value={''}>--- Choose Work Name ---</option>
                                <option value={"All"}>All</option>
                                {works.map((userData, userId) => {
                                    return (<Fragment key={userId}>
                                        <option value={userData.id}>{userData.work_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {contractorWorkList && contractorWorkList.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                            {contractorWorkList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                return (<Fragment key={tableDataID}>
                                    <tr className="bg-default-color text-gray-900 border-b text-center">
                                        <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.work_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.wing_name}</td>
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.level_name}</td>
                                        <td className="px-4 py-3 ">{tableData.task_name}</td   >
                                        <td className="px-4 py-3 whitespace-nowrap">{tableData.contractor_name}</td>
                                        <td className="px-4 py-3 ">{moment(tableData.start_date).format('DD-MM-YYYY')}</td   >
                                        <td className="px-4 py-3 ">{moment(tableData.due_date).format('DD-MM-YYYY')}</td   >
                                        <td className="px-4 py-3">
                                            {tableData.contract_type === null ? (
                                                <div>
                                                    <div className='flex justify-center gap-3'>
                                                        <div> {tableData.status}</div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <button onClick={filldata}><div className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></div></button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className='flex justify-center gap-3'>
                                                        <div> {tableData.status}</div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <button onClick={() => openModal1(tableData.id)} title='View'><div className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></div></button>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className='flex justify-center gap-3'>
                                                {tableData.fixed_rate_grand_total || tableData.unit_based_grand_total !== null ? (
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                ) : (
                                                    <Link title='Add' to={`/contractorwork/edit-contractorwork/${tableData.id}`}><ActionButton className={'p-1.5 rounded-lg bg-yellow-400'}><PlusCircleIcon className='w-5 h-5 ' /></ActionButton></Link>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>)
                            })}
                        </Table>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Contractors Work Not Found</p>
                        </div>
                    )}
                    {contractorWorkList && contractorWorkList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={contractorWorkList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                    {
                        statusModel && <ViewModel viewModel={statusModel} setViewModel={setStatusModel}>
                            <Dialog.Panel className="w-[50%] md:max-w-[30%] transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-3 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <Fragment>
                                        <form>
                                            <div>
                                                <div>
                                                    <Label htmlFor={'status'} labelText={'Status'} className={'mb-1'} />
                                                    <CustomSelect id={'status'} name={'status'} value={updatestatus.status} onChange={handleStatusChange}>
                                                        <option value={''}>--- Choose Status ---</option>
                                                        <option value={'To-Do'}>To-Do</option>
                                                        <option value={'Ongoing'}>Ongoing</option>
                                                        <option value={'Completed'}>Completed</option>
                                                    </CustomSelect>
                                                    <Validation error={errors.status} />
                                                </div>
                                                <div className="grid grid-cols-2 md:grid-cols-2 gap-3 mt-4">
                                                    <Button
                                                        type="submit"
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 md:px-4 md:py-2 py-2 md:text-sm text-[2vw] font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={handleSubmitStatus} >
                                                        Save
                                                    </Button>
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center rounded-md border border-transparent bg-red-700 md:px-4 md:py-2 py-2 md:text-sm text-[2vw] font-medium text-white hover:bg-blue-200  hover:text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => setStatusModel(false)} >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </Fragment>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-2 md:py-4 px-2 md:px-6 text-left align-middle shadow-xl transition-all">
                                {modalLoader ? (<PleaseWait />) : (
                                    <div>
                                        {modalData.contract_type === "Fixed Rate" ? (
                                            <div>
                                                <div className='m-5' ref={componentRef} id='print'>
                                                    <h1 className='text-center uppercase font-bold md:text-xl text-[3vw] tracking-wide'>Contractor Work Details</h1>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4 '>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Work Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Wing Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.wing_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Level Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.task_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Task Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.task_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Task Status</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.status}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold whitespace-nowrap '>Contractor Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contractor_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Contractor Firm Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contractor_work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Contract Type</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contract_type}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Work Type</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.work_type}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Est Amount</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{parseInt(modalData.fixed_rate_est_amount).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Tax</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.fixed_rate_tax_amount}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>TDS</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.fixed_rate_TDS_amount}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Grand Total</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{parseInt(modalData.fixed_rate_grand_total).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Remark</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.fixed_rate_remarks}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div className="mt-4  grid grid-cols-3">
                                                    <button id='gotit'
                                                        type="button"
                                                        className="inline-flex justify-center items-center mr-3 rounded-md md:text-sm text-[2vw] border border-transparent bg-blue-100 md:px-4 md:py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => setViewModel(false)}>
                                                        Got it, thanks!
                                                    </button>
                                                    <div className=""></div>
                                                    <Link id='printButton' onClick={handlePrint} className="inline-flex md:text-sm text-[2vw] justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2">  Print </Link>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className='m-5' ref={componentRef} id='print'>
                                                    <h1 className='text-center uppercase font-bold text-xl tracking-wide'>Contractor Work Details</h1>
                                                    <hr className='my-4 border border-primary-color' />
                                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4'>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Work Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Wing Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.wing_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Level Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.level_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Contractor Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contractor_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Contractor Firm Name</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contractor_work_name}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Contract Type</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.contract_type}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Work Type</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.work_type}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Measuring Unit</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.unit_based_rate_measure_unit}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Total Measurements</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{parseInt(modalData.unit_based_rate_total_qty).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Est Amount</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{parseInt(modalData.unit_based_rate_est_amount).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Tax</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.unit_based_tax_amount}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>TDS</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.unit_based_TDS_amount}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Grand Total</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{parseInt(modalData.unit_based_grand_total).toLocaleString('en-IN')}</h1>
                                                        </div>
                                                        <div className='grid grid-cols-3 gap-4 mb-4'>
                                                            <h1 className='font-bold '>Remarks</h1>
                                                            <h1 className='justify-center flex'>-</h1>
                                                            <h1 >{modalData.unit_based_rate_remarks}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4 grid grid-cols-3 gap-4">
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                            onClick={() => setViewModel(false)} >
                                                            Got it, thanks!
                                                        </button>
                                                    </div>
                                                    <div></div>
                                                    <div className='flex justify-end'>
                                                        <Link id='printButton' onClick={handlePrint} className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-7 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-[50%]">  Print </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Dialog.Panel>
                        </ViewModel>
                    }

                </PageComponent >
            )
            }
        </Fragment >
    )
}
export default ContractorWork