
import React, { Fragment, useEffect, useState } from "react";
import { ArrowLeftCircleIcon, InboxArrowDownIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import flasher from "@flasher/flasher";
import { useNavigate, useParams } from "react-router-dom";
import PageComponent from "../../components/PageComponent";
import Label from "../helpers/Label";
import Card from "../helpers/Card";
import Input from "../helpers/Input";
import Button from "../helpers/Button";
import LinkButton from "../helpers/LinkButton";
import Loader from "../Common/Loader";
import Validation from "../helpers/Validation";
import TextArea from "../helpers/TextArea";
import axiosClient from "../../Axios";
import CustomSelect from "../helpers/CustomSelect";
import ViewModel from "../helpers/ViewModel";
import Table from "../helpers/Table";
import Swal from "sweetalert2";
import { Dialog } from "@headlessui/react";
import CurrencyInput from 'react-currency-input-field';
import PleaseWait from '../Common/PleaseWait'

function DailyWagesAddEdit() {
  const { id } = useParams();
  {
    id
      ? (document.title = "SiteSmart - Edit Wages")
      : (document.title = "SiteSmart - Add New Wages");
  }

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [viewModel, setViewModel] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const tableHeading = ["Sr No", "Labour Name", "Contact", "Fixed Wage", "Overtime Rate/Hour", "Overtime Hours", "Total Amount", "GST", "Final Amount", "Action",];
  const [labournamelist, setLabournamelist] = useState([]);
  const [labourcontactlist, setLabourcontactlist] = useState([]);
  const [labourfixedwagelist, setLabourfixedwagelist] = useState([]);
  const [lobourrateperhourslist, setLobourrateperhourslist] = useState([]);
  const [lobourovertimehourslist, setLobourovertimehourslist] = useState([]);
  const [laboutotalamountlist, setLaboutotalamountlist] = useState([]);
  const [labourfinalamountlist, setLabourfinalamountlist] = useState([]);
  const [labourgstlist, setLabourgstlist] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [tableid, setTableid] = useState('')

  const [formInfo, setFormInfo] = useState({
    month: '',
    team_leader: "",
    phone_no: "",
    work_name: "",
    wing_name: "",
    work_type: "",
    fixed_wage: "",
    gst: "",
    total_amount: "",
    overtime_rate_per_hours: "",
    overtime_hours: "",
    final_amount: "",
    total_payable: "",
    paid_amount: "",
    paid_date: "",
    paid_from_acount: "",
    payment_mode: "",
    tr_advanced_paid: "0",
    tr_balance: "",
    tr_reference_no: "",
    tr_reference_date: "",
    transaction_remarks: "",
    total_labours_worked: "",
    total_wage: "",
    wage_remarks: "",
    // labour_paid_amount: "",
    // labour_paid_date: "",
    // labour_paid_from_acount: "",
    // labour_payment_mode: "",
    // labour_tr_advanced_paid: "",
    // labour_tr_balance: "",
    // labour_tr_reference_no: "",
    // labour_tr_reference_date: "",
    // labour_transaction_remarks: "",
    total_paid_amount: '',
    grand_total: ''
  });

  const daily_wages_id = id;
  const [formDetail, setFormDetail] = useState({
    daily_wages_id: daily_wages_id,
    labour_name: "",
    labour_contact: "",
    labour_fixed_wage: "",
    rate_per_hours: "",
    labour_overtime_hours: "",
    labour_total_amount: "",
    labour_final_amount: "",
    labour_gst: "",
  });


  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFixedWage = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      fixed_wage: e,
    }))
  }

  const handleOvertimerate = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      overtime_rate_per_hours: e,
    }))
  }

  const handlePaidAmt = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      paid_amount: e,
    }))
  }

  const handleAdvPaid = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      tr_advanced_paid: e,
    }))
  }
  const handlelabourpaidamt = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      labour_paid_amount: e,
    }))
  }
  const handlelabouradvpaid = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      labour_tr_advanced_paid: e,
    }))
  }

  const handleChanges = (e) => {
    setFormDetail((formDetail) => ({
      ...formDetail,
      [e.target.name]: e.target.value,
    }));
  };

  const handlelabourfixedwage = (e) => {
    setFormDetail(formDetail => ({
      ...formDetail,
      labour_fixed_wage: e,
    }))
  }

  const handleTotalWage = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      total_wage: e,
    }))
  }

  const handlelabourrateperhr = (e) => {
    setFormDetail(formDetail => ({
      ...formDetail,
      rate_per_hours: e,
    }))
  }

  const openmodal = (e) => {
    setFormDetail({
      daily_wages_id: daily_wages_id,
      labour_name: "",
      labour_contact: "",
      labour_fixed_wage: "",
      rate_per_hours: "",
      labour_overtime_hours: "",
      labour_total_amount: "",
      labour_final_amount: "",
      labour_gst: "",
    });
    setViewModel(true)
  }

  const [count, setCount] = useState(0)
  const handleSubmitlabour = (e) => {
    e.preventDefault();
    if (!formDetail.labour_name || !formDetail.labour_contact || !formDetail.labour_fixed_wage || !formDetail.rate_per_hours || !formDetail.labour_overtime_hours || !formDetail.labour_gst) {
      flasher.error("All fields are mandatory")
      return;
    }
    if (isEditing && editingIndex !== null) {
      const updatedTableData = [...tableData];
      updatedTableData[editingIndex] = { ...formDetail };
      setTableData(updatedTableData);
    } else {
      setTableData([...tableData, { ...formDetail }]);
      countlabour()
    }
    setViewModel(false)
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleEdit = (index) => {
    setViewModel(true)
    const selectedSubtask = tableData[index];
    setFormDetail({
      daily_wages_id: selectedSubtask.daily_wages_id,
      labour_name: selectedSubtask.labour_name,
      labour_contact: selectedSubtask.labour_contact,
      labour_fixed_wage: selectedSubtask.labour_fixed_wage,
      rate_per_hours: selectedSubtask.rate_per_hours,
      labour_overtime_hours: selectedSubtask.labour_overtime_hours,
      labour_total_amount: selectedSubtask.labour_total_amount,
      labour_final_amount: selectedSubtask.labour_final_amount,
      labour_gst: selectedSubtask.labour_gst,
    });
    setIsEditing(true);
    setEditingIndex(index);
  };


  function countlabour() {
    for (let i = 0; i <= tableData.length; i++) {
      setCount(count + 1)
    }
  }
  const totalSum = tableData.reduce((sum, record) => sum + record.labour_final_amount, 0);


  const handleUpdatelabour = (e) => {
    e.preventDefault();
    axiosClient.post(tableid ? `update_Daily_wages_labour/${tableid}` : '/store_Daily_wages_labour', formDetail)
      .then(({ data }) => {
        setLoader(false)
        setViewModel(false)
        flasher.success(data.msg)
        navigate(`/daily-wages/edit-daily-wages/${id}`)
        getSingleWagesData()

      })
      .catch(({ response }) => {
        setLoader(false)
        setErrors(response.data.errors)
      });
    setViewModel(false)
  };

  const handlelabourdetails = (e) => {
    const labourname = tableData && tableData.map((data) => {
      return data.labour_name
    });
    setLabournamelist(labourname)

    const labourcontact = tableData && tableData.map((data) => {
      return data.labour_contact
    });
    setLabourcontactlist(labourcontact)

    const labourfixedwage = tableData && tableData.map((data) => {
      return data.labour_fixed_wage
    });
    setLabourfixedwagelist(labourfixedwage)

    const labourgstlist = tableData && tableData.map((data) => {
      return data.labour_gst
    });
    setLabourgstlist(labourgstlist)

    const labourrateperhours = tableData && tableData.map((data) => {
      return data.rate_per_hours
    });
    setLobourrateperhourslist(labourrateperhours)

    const labourovertimehours = tableData && tableData.map((data) => {
      return data.labour_overtime_hours
    });
    setLobourovertimehourslist(labourovertimehours)

    const labourtotalamount = tableData && tableData.map((data) => {
      return data.labour_total_amount
    });
    setLaboutotalamountlist(labourtotalamount)

    const labourfinalamount = tableData && tableData.map((data) => {
      return data.labour_final_amount
    });
    setLabourfinalamountlist(labourfinalamount)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      month: formInfo.month,
      team_leader: formInfo.team_leader,
      phone_no: formInfo.phone_no,
      work_name: formInfo.work_name,
      wing_name: formInfo.wing_name,
      work_type: formInfo.work_type,
      fixed_wage: formInfo.fixed_wage,
      gst: formInfo.gst,
      overtime_rate_per_hours: formInfo.overtime_rate_per_hours,
      overtime_hours: formInfo.overtime_hours,
      total_amount: formInfo.total_amount,
      final_amount: formInfo.final_amount,
      tr_advanced_paid: formInfo.tr_advanced_paid,
      total_payable: formInfo.total_payable,
      paid_amount: formInfo.paid_amount,
      paid_date: formInfo.paid_date,
      paid_from_acount: formInfo.paid_from_acount,
      payment_mode: formInfo.payment_mode,
      tr_balance: formInfo.tr_balance,
      tr_reference_no: formInfo.tr_reference_no,
      tr_reference_date: formInfo.tr_reference_date,
      transaction_remarks: formInfo.transaction_remarks,
      total_labours_worked: formInfo.total_labours_worked,
      total_wage: formInfo.total_wage,
      wage_remarks: formInfo.wage_remarks,
      // total_labours_worked: count,
      // total_wage: id ? sumfinalamount : totalSum,
      // labour_paid_amount: formInfo.labour_paid_amount,
      // labour_paid_date: formInfo.labour_paid_date,
      // labour_paid_from_acount: formInfo.labour_paid_from_acount,
      // labour_payment_mode: formInfo.labour_payment_mode,
      // labour_tr_advanced_paid: formInfo.labour_tr_advanced_paid,
      // labour_tr_balance: formInfo.labour_tr_balance,
      // labour_tr_reference_no: formInfo.labour_tr_reference_no,
      // labour_tr_reference_date: formInfo.labour_tr_reference_date,
      // labour_transaction_remarks: formInfo.labour_transaction_remarks,
      // total_paid_amount: formInfo.total_paid_amount,
      total_paid_amount: formInfo.paid_amount,
      grand_total: formInfo.grand_total,
      labournamelist: labournamelist,
      labourcontactlist: labourcontactlist,
      labourfixedwagelist: labourfixedwagelist,
      lobourrateperhourslist: lobourrateperhourslist,
      lobourovertimehourslist: lobourovertimehourslist,
      laboutotalamountlist: laboutotalamountlist,
      labourfinalamountlist: labourfinalamountlist,
      labourgstlist: labourgstlist,
    }
    axiosClient
      .post(
        id ? `update_addLabourGroup/${id}` : "/store_addLabourGroup",
        payload
      )
      .then(({ data }) => {
        setLoader(false);
        flasher.success(data.msg);
        navigate("/daily-wages");
      })
      .catch(({ response }) => {
        setLoader(false);
        setErrors(response.data.errors);
      });
  };


  const handleDelete = (index) => {
    const filterData = tableData.filter((item, i) => i !== index)
    setTableData(filterData)
    setCount(count - 1)
  };

  const deletelabour = (tableid) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient.delete(`/delete_Daily_wages_labour/${tableid}`)
          .then(({ data }) => {
            flasher.success(data.msg)
            getSingleWagesData()
          })
      }
    })
  }

  function getSingleLabourData(id) {
    setViewModel(true)
    axiosClient.get(`/single_Daily_wages_labour/${id}`)
      .then(({ data }) => {
        setFormDetail(data.single_data)
        setTableid(data.single_data.id)
      })
  }

  const [sumfinalamount, setSumfinalamount] = useState()
  const [modalData, setModalData] = useState([])
  const[balance, setBalance]=useState(null)
  const getSingleWagesData = () => {
    setLoader(true);
    axiosClient.get(`/single_addLabourGroup_data/${id}`)
      .then(({ data }) => {
        setLoader(false);
        setFormInfo(data.single_data);
        // setModalData(data.MulitiLabours)
        // setSumfinalamount(data.Sumof_final_amount)
        // setCount(data.MulitiLabours.length)
        setBalance(data.single_data.tr_balance)
      });
  };

  const [workNameLists, setWorkNameLists] = useState([])
  const getWorkData = () => {
    setLoader(true)
    axiosClient.get(`/work_lists`)
      .then(({ data }) => {
        setLoader(false)
        setWorkNameLists(data.lists)
      })
  }
  const [wing, setWing] = useState([])
  const getWingLists = () => {
    formInfo.work_name && axiosClient.get(`/work_wise_wings/${formInfo.work_name}`)
      .then(({ data }) => {
        setWing(data.work_wise_wingdata)
      })
  }

  const [worktype, setWorktype] = useState('')
  const getWorktype = () => {
    setLoader(true)
    axiosClient.get(`/worktype_list`)
      .then(({ data }) => {
        setWorktype(data.lists)
      })
  }

  const getAdvAmount = () => {
    // const TeamLeader = formInfo.team_leader.replace(/ /g, '_');
    formInfo.month && formInfo.team_leader && axiosClient.get(`/labour_advance_monthwise_data/${formInfo.month}/${formInfo.team_leader}`)
      .then(({ data }) => {
        setLoader(false)
        setFormInfo({
          ...formInfo,
          tr_advanced_paid: data.advanced_paid_amount
        })
      })
  }

  useEffect(() => {
    getWingLists()
  }, [formInfo.work_name])

  useEffect(() => {
    if (id) {
      getSingleWagesData()
    }
    getWorkData()
    getWorktype()
  }, []);

  useEffect(() => {
    getAdvAmount()
  }, [formInfo.month, formInfo.team_leader])

  useEffect(() => {
    handlelabourdetails()
  }, [tableData])
  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <PageComponent
          title={id ? "Edit wages" : "Add New Wage Slip"} button={
            <LinkButton to={"/daily-wages"}>
              <ArrowLeftCircleIcon className="w-5 h-5 text-default-color" />
              <span>Back To List</span>
            </LinkButton>}>
          <div className='flex items-center justify-center'>
            <Card className={'w-full md:w-[70rem] p-6  bg-default-color'}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"month"} labelText={"Month"} className={"mb-1"} />
                    <Input id={"month"} type={"month"} name={"month"} value={formInfo.month} onChange={handleChange} />
                    <Validation error={errors.month} />
                  </div>
                  <div>
                    <Label htmlFor={"team_leader"} labelText={"Team Leader"} className={"mb-1"} />
                    <Input id={"team_leader"} type={"text"} name={"team_leader"} value={formInfo.team_leader}  />
                    <Validation error={errors.team_leader} />
                  </div>
                  <div>
                    <Label htmlFor={"phone_no"} labelText={"Phone No."} className={"mb-1"} />
                    <Input id={"phone_no"} type={"text"} name={"phone_no"} value={formInfo.phone_no} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} maxLength={10} minLength={10}/>
                    <Validation error={errors.phone_no} />
                  </div>
                  <div>
                    <Label htmlFor={"work_name"} labelText={"Work Name"} className={"mb-1"} />
                    <CustomSelect id={'work_name'} name={'work_name'} className={''} value={formInfo.work_name}>
                      <option value={''}>--- Select Work ---</option>
                      {
                        workNameLists.map(data => {
                          return (
                            <option value={data.id}>{data.work_name}</option>
                          )
                        })
                      }
                    </CustomSelect>
                    <Validation error={errors.work_name} />
                  </div>
                  <div>
                    <Label htmlFor={"wing_name"} labelText={"Wing Name"} className={"mb-1"} />
                    <CustomSelect id={'wing_name'} name={'wing_name'} value={formInfo.wing_name} >
                      <option value={''}>--- Select Wing ---</option>
                      {wing && wing.map((wingData, wingId) => {
                        return (<Fragment key={wingId}>
                          <option value={wingData.id}>{wingData.wing_name}</option>
                        </Fragment>)
                      })}
                    </CustomSelect>
                    <Validation error={errors.wing_name} />
                  </div>

                  <div>
                    <Label htmlFor={"work_type"} labelText={"Work Type"} className={"mb-1"} />
                    <CustomSelect id={'work_type'} name={'work_type'} value={formInfo.work_type} >
                      <option value={''}>--- Select Work Type ---</option>
                      {worktype && worktype.map((worktypedata, worktypeID) => {
                        return (<Fragment key={worktypeID}>
                          <option value={worktypedata.id}>{worktypedata.work_type}</option>
                        </Fragment>)
                      })}
                    </CustomSelect>
                    <Validation error={errors.work_type} />
                  </div>
                  <div>
                    <Label htmlFor={"fixed_wage"} labelText={"Fixed Wage"} className={"mb-1"} />
                    <CurrencyInput id={'fixed_wage'} type={'text'} name={'fixed_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.fixed_wage}
                      />
                    <Validation error={errors.fixed_wage} />
                  </div>
                  <div>
                    <Label htmlFor={"overtime_rate_per_hours"} labelText={"Overtime Rate per Hour"} className={"mb-1"} />
                    <CurrencyInput id={'overtime_rate_per_hours'} type={'text'} name={'overtime_rate_per_hours'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.overtime_rate_per_hours}
                       />
                    <Validation error={errors.overtime_rate_per_hours} />
                  </div>
                  <div>
                    <Label htmlFor={"overtime_hours"} labelText={"Overtime Hours"} className={"mb-1"} />
                    <Input id={"overtime_hours"} type={"text"} name={"overtime_hours"} value={formInfo.overtime_hours} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.overtime_hours} />
                  </div>
                  <div>
                    <Label htmlFor={"total_amount"} labelText={"Amount Without GST"} className={"mb-1"} />
                    <CurrencyInput id={'total_amount'} type={'text'} name={'total_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.total_amount = parseInt(formInfo.fixed_wage) + (parseInt(formInfo.overtime_rate_per_hours * parseInt(formInfo.overtime_hours)))} />
                    <Validation error={errors.total_amount} />
                  </div>
                  <div> <Label htmlFor={"gst"} labelText={"GST"} className={"mb-1"} />
                    <CustomSelect id={"gst"} name={"gst"} value={formInfo.gst} >
                      <option value={""}>---GST ---</option>
                      <option value={"0%"}>0%</option>
                      <option value={"5%"}>5%</option>
                      <option value={"18%"}>18%</option>
                      <option value={"28%"}>28%</option>
                    </CustomSelect>
                    <Validation error={errors.gst} />
                  </div>
                  <div>
                    <Label htmlFor={"final_amount"} labelText={"Amount With GST"} className={"mb-1"} />
                    <CurrencyInput id={'final_amount'} type={'text'} name={'final_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.final_amount = parseInt(formInfo.total_amount) - parseInt(formInfo.total_amount * (parseInt(formInfo.gst) / 100))} />
                    <Validation error={errors.final_amount} />
                  </div>
                  <div>
                    <Label htmlFor={"total_labours_worked"} labelText={"Total Labours Worked"} className={"mb-1"} />
                    <Input id={"total_labours_worked"} type={"text"} name={"total_labours_worked"} value={formInfo.total_labours_worked} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.total_labours_worked} />
                  </div>
                  <div>
                    <Label htmlFor={"total_wage"} labelText={"Total Wage"} className={"mb-1"} />
                    <CurrencyInput id={'total_wage'} type={'text'} name={'total_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.total_wage}  />
                    <Validation error={errors.total_wage} />
                  </div>
                  <div>
                    <Label htmlFor={"wage_remarks"} labelText={"Wage Remarks"} className={"mb-1"} />
                    <Input id={"wage_remarks"} type={"text"} name={"wage_remarks"} value={formInfo.wage_remarks} />
                    <Validation error={errors.wage_remarks} />
                  </div>
                  <div>
                    <Label htmlFor={"grand_total"} labelText={"Grand Total"} className={"mb-1"} />
                    <Input id={"grand_total"} type={"text"} name={"grand_total"} value={parseInt((formInfo.grand_total) = parseInt(formInfo.final_amount) + parseInt(formInfo.total_wage)).toLocaleString('en-IN')} />
                    <Validation error={errors.grand_total} />
                  </div>

                  <div>
                    <Label htmlFor={"advanced_paid"} labelText={"Advance Paid"} className={"mb-1"} />
                    <CurrencyInput id={'tr_advanced_paid'} type={'text'} name={'tr_advanced_paid'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.tr_advanced_paid} />
                    <Validation error={errors.tr_advanced_paid} />
                  </div>
                  <div className='flex justify-center bg-gray-200 p-2 rounded-lg mt-5'>
                    <h1 className='text-xl font-extrabold'>Total Payable</h1>
                    <span className='text-xl font-extrabold mr-2 ml-2'>-</span>
                    <h1 className='text-xl font-extrabold'>{parseInt((formInfo.total_payable) = parseInt(formInfo.grand_total) - parseInt(formInfo.tr_advanced_paid)).toLocaleString('en-IN')}</h1>
                  </div>
                </div>
                {/* <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr> */}

                <div className="mb-4">
                  <span className="text-xl font-normal">
                    Transaction Details :
                  </span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"paid_amount"} labelText={" Paid Amount"} className={"mb-1"} />
                    <CurrencyInput id={'paid_amount'} type={'text'} name={'paid_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.paid_amount}
                      onValueChange={handlePaidAmt} />
                    <Validation error={errors.paid_amount} />
                  </div>
                  <div>
                    <Label htmlFor={"paid_date"} labelText={"Paid Date"} className={"mb-1"} />
                    <Input id={"paid_date"} type={"date"} name={"paid_date"} value={formInfo.paid_date}  onChange={handleChange}/>
                    <Validation error={errors.paid_date} />
                  </div>
                  <div>
                    <Label htmlFor={"paid_from_acount"} labelText={"Paid From Acc"} className={"mb-1"} />
                    <CustomSelect id={"paid_from_acount"} name={"paid_from_acount"} value={formInfo.paid_from_acount} onChange={handleChange} >
                      <option value={""}>--- Cash Account ---</option>
                      <option value={"cash"}>Cash</option>
                      <option value={"bank"}>Bank</option>
                    </CustomSelect>
                    <Validation error={errors.paid_from_acount} />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"payment_mode"} labelText={"Payment Mode"} className={"mb-1"} />
                    <CustomSelect id={"payment_mode"} name={"payment_mode"} value={formInfo.payment_mode} onChange={handleChange}>
                      <option value={""}>--- Cash Account ---</option>
                      <option value={"neft"}>NEFT</option>
                      <option value={"rtgs"}>RTGS</option>
                      <option value={"online"}>Online</option>
                      <option value={"cash"}>Cash</option>
                    </CustomSelect>
                    <Validation error={errors.payment_mode} />
                  </div>
                  {balance == null ?
                    (
                      <div>
                        <Label htmlFor={'tr_balance'} labelText={'Balance'} className={'mb-1'} />
                        <CurrencyInput id={'tr_balance'} type={'text'} name={'tr_balance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                          value={formInfo.paid_amount == null ? (formInfo.tr_balance = (formInfo.total_payable)) : formInfo.tr_balance = (formInfo.total_payable) - (formInfo.paid_amount)} />
                        <Validation error={errors.balance} />
                      </div>
                    ) :
                    (
                      <div>
                        <Label htmlFor={'tr_balance'} labelText={'Balance'} className={'mb-1'} />
                        <input id={'tr_balance'} type={'text'} name={'tr_balance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                          value={formInfo.paid_amount == null ? (formInfo.tr_balance = balance) : (formInfo.tr_balance = parseInt(balance) - parseInt(formInfo.paid_amount))} />
                        <Validation error={errors.balance} />
                      </div>
                    )
                  }
                  <div>
                    <Label htmlFor={"tr_reference_no"} labelText={"Transaction Reference No"} className={"mb-1"} />
                    <Input id={"tr_reference_no"} type={"text"} name={"tr_reference_no"} value={formInfo.tr_reference_no} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.tr_reference_no} />
                  </div>
                  <div>
                    <Label htmlFor={"tr_reference_date"} labelText={"Transaction Reference Date"} className={"mb-1"} />
                    <Input id={"tr_reference_date"} type={"date"} name={"tr_reference_date"} value={formInfo.tr_reference_date} onChange={handleChange} />
                    <Validation error={errors.tr_reference_date} />
                  </div>
                  <div>
                    <Label htmlFor={"transaction_remarks"} labelText={"Transaction Remarks"} className={"mb-1"} />
                    <TextArea id={"transaction_remarks"} type={"text"} rows={1} name={"transaction_remarks"} value={formInfo.transaction_remarks} onChange={handleChange} />
                    <Validation error={errors.transaction_remarks} />
                  </div>
                </div>
                {/* <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr> */}
                {/* <div className="grid grid-cols-2 md:grid-cols-2 gap-y-2.5 gap-x-[54%]">
                  <div className="font-normal text-lg ">
                    Add Labour Details :
                  </div>

                  <span
                    className="bg-blue-500 rounded-md p-2 w-10/12 text-center ml-10 text-white cursor-pointer"
                    onClick={() => openmodal()}>
                    Add Labour
                  </span>
                </div> */}
                {/* <div className="mt-4 w-full">
                  {
                    id ? (
                      modalData && modalData.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                          {modalData.map((item, i) => {
                            return (<Fragment key={i}>
                              <tr className="bg-default-color text-gray-900 border-b text-center">
                                <td className="px-4 py-3">{i + 1}</td>
                                <td className="px-4 py-3">{item.labour_name}</td>
                                <td className="px-4 py-3">{item.labour_contact}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_fixed_wage.toLocaleString('en-IN'))}</td>
                                <td className="px-4 py-3">{item.rate_per_hours}</td>
                                <td className="px-4 py-3">{item.labour_overtime_hours}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_total_amount).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">{item.labour_gst}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_final_amount).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">
                                  <div className='flex justify-center gap-3'>
                                    <button type='button' onClick={() => getSingleLabourData(item.id)} className={'group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color'}><PencilSquareIcon className='w-5 h-5' /></button>
                                    <button onClick={() => deletelabour(item.id)} className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><TrashIcon className='w-5 h-5' /></button>
                                  </div>
                                </td>
                              </tr>
                            </Fragment>)
                          })}
                        </Table>
                      ) : (
                        <div className='flex justify-center items-center h-[15vh]'>
                          <p className='text-3xl font-bold'>Sorry!Labour Data Not Found</p>
                        </div>
                      )
                    ) : (
                      tableData && tableData.length > 0 ? (
                        <Table tableHeading={tableHeading}>
                          {tableData.map((item, i) => {
                            return (<Fragment key={i}>
                              <tr className="bg-default-color text-gray-900 border-b text-center">
                                <td className="px-4 py-3">{i + 1}</td>
                                <td className="px-4 py-3">{item.labour_name}</td>
                                <td className="px-4 py-3">{item.labour_contact}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_fixed_wage).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">{parseInt(item.rate_per_hours).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">{item.labour_overtime_hours}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_total_amount).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">{item.labour_gst}</td>
                                <td className="px-4 py-3">{parseInt(item.labour_final_amount).toLocaleString('en-IN')}</td>
                                <td className="px-4 py-3">
                                  <td className='text-center text-lg'>
                                    <div className='flex justify-center gap-3'>
                                      <button onClick={() => handleEdit(i)} className={"group relative flex justify-center rounded-md bg-yellow-300 p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} type='button'><PencilSquareIcon className='w-5 h-5' /></button>
                                      <button type="button" onClick={() => handleDelete(i)} className={"group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "} ><TrashIcon className='w-5 h-5' /></button>
                                    </div>
                                  </td>
                                </td>
                              </tr>
                            </Fragment>)
                          })}
                        </Table>
                      ) : (
                        <div className='flex justify-center items-center h-[15vh]'>
                          <p className='text-3xl font-bold'>Sorry!Labour Data Not Found</p>
                        </div>
                      )

                    )
                  }
                </div> */}
                {/* <br></br> */}
                {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4"> */}
                {/* <div>
                    <Label htmlFor={"total_labours_worked"} labelText={"Total Labours Worked"} className={"mb-1"} />
                    <Input id={"total_labours_worked"} type={"text"} name={"total_labours_worked"} value={count} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.total_labours_worked} />
                  </div> */}
                {/* <div>
                    <Label htmlFor={"total_wage"} labelText={"Total Wage"} className={"mb-1"} />
                    <CurrencyInput id={'total_wage'} type={'text'} name={'total_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={id ? (sumfinalamount) : (totalSum)} onChange={handleChange} />
                    <Validation error={errors.total_wage} />
                  </div> */}
                {/* <div>
                    <Label htmlFor={"wage_remarks"} labelText={"Wage Remarks"} className={"mb-1"} />
                    <Input id={"wage_remarks"} type={"text"} name={"wage_remarks"} value={formInfo.wage_remarks} onChange={handleChange} />
                    <Validation error={errors.wage_remarks} />
                  </div> */}
                {/* </div> */}
                {/* <hr class="h-px my-7 bg-gray-300 border-0 dark:bg-gray-700"></hr> */}
                {/* <div className="mb-4">
                  <span className="text-xl font-normal">
                    Labour Transaction Details :
                  </span>
                </div> */}
                {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"labour_paid_amount"} labelText={" Paid Amount"} className={"mb-1"} />
                    <CurrencyInput id={'labour_paid_amount'} type={'text'} name={'labour_paid_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.labour_paid_amount}
                      onValueChange={handlelabourpaidamt} />
                    <Validation error={errors.labour_paid_amount} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_paid_date"} labelText={"Paid Date"} className={"mb-1"} />
                    <Input id={"labour_paid_date"} type={"date"} name={"labour_paid_date"} value={formInfo.labour_paid_date} onChange={handleChange} />
                    <Validation error={errors.labour_paid_date} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_paid_from_acount"} labelText={"Paid From Acc"} className={"mb-1"} />
                    <CustomSelect id={"labour_paid_from_acount"} name={"labour_paid_from_acount"} value={formInfo.labour_paid_from_acount} onChange={handleChange}>
                      <option value={""}>--- Cash Account ---</option>
                      <option value={"cash"}>Cash</option>
                      <option value={"bank"}>Bank</option>
                    </CustomSelect>
                    <Validation error={errors.labour_paid_from_acount} />
                  </div>
                </div> */}
                {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"labour_payment_mode"} labelText={"Payment Mode"} className={"mb-1"} />
                    <CustomSelect id={"labour_payment_mode"} name={"labour_payment_mode"} value={formInfo.labour_payment_mode} onChange={handleChange}>
                      <option value={""}>--- Cash Account ---</option>
                      <option value={"neft"}>NEFT</option>
                      <option value={"rtgs"}>RTGS</option>
                      <option value={"online"}>Online</option>
                      <option value={"cash"}>Cash</option>
                    </CustomSelect>
                    <Validation error={errors.labour_payment_mode} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_tr_advanced_paid"} labelText={"Advance Paid"} className={"mb-1"} />
                    <CurrencyInput id={'labour_tr_advanced_paid'} type={'text'} name={'labour_tr_advanced_paid'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.labour_tr_advanced_paid}
                      onValueChange={handlelabouradvpaid} />
                    <Validation error={errors.labour_tr_advanced_paid} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_tr_balance"} labelText={"Balance"} className={"mb-1"} />
                    <CurrencyInput id={'labour_tr_balance'} type={'text'} name={'labour_tr_balance'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                      value={formInfo.labour_tr_balance = (id ? ((sumfinalamount) - (parseInt(formInfo.labour_paid_amount) + parseInt(formInfo.labour_tr_advanced_paid))) : ((totalSum) - (parseInt(formInfo.labour_paid_amount) + parseInt(formInfo.labour_tr_advanced_paid))))} />
                    <Validation error={errors.labour_tr_balance} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_tr_reference_no"} labelText={"Transaction Reference No"} className={"mb-1"} />
                    <Input id={"labour_tr_reference_no"} type={"text"} name={"labour_tr_reference_no"} value={formInfo.labour_tr_reference_no} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <Validation error={errors.labour_tr_reference_no} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_tr_reference_date"} labelText={"Transaction Reference Date"} className={"mb-1"} />
                    <Input id={"labour_tr_reference_date"} type={"date"} name={"labour_tr_reference_date"} value={formInfo.labour_tr_reference_date} onChange={handleChange} />
                    <Validation error={errors.labour_tr_reference_date} />
                  </div>
                  <div>
                    <Label htmlFor={"labour_transaction_remarks"} labelText={"Transaction Remarks"} className={"mb-1"} />
                    <TextArea id={"labour_transaction_remarks"} type={"text"} rows={1} name={"labour_transaction_remarks"} value={formInfo.labour_transaction_remarks} onChange={handleChange} />
                    <Validation error={errors.labour_transaction_remarks} />
                  </div>
                </div> */}
                {/* <div className="grid grid-cols-1 md:grid-cols-3 mt-6">
                  <div className='flex justify-center bg-gray-200 p-2 rounded-lg mb-4'>
                    <h1 className='text-xl font-extrabold'>Total Paid Amount</h1>
                    <span className='text-xl font-extrabold mr-2 ml-2'>-</span>
                    {
                      totalSum == 0?(
                        <h1 className='text-xl font-extrabold'>{parseInt((formInfo.total_paid_amount) = parseInt(formInfo.paid_amount)).toLocaleString('en-IN')}</h1>
                      ):(
                        <h1 className='text-xl font-extrabold'>{parseInt((formInfo.total_paid_amount) = parseInt(formInfo.paid_amount) + parseInt(formInfo.labour_paid_amount)).toLocaleString('en-IN')}</h1>
                      )
                    }
                    </div>
                  <div></div> */}
                {/* <div className='flex justify-center bg-gray-200 p-2 rounded-lg mb-4'>
                    <h1 className='text-xl font-extrabold'>Grand Total</h1>
                    <span className='text-xl font-extrabold mr-2 ml-2'>-</span>
                    <h1 className='text-xl font-extrabold'>{parseInt((formInfo.grand_total) = parseInt(formInfo.final_amount) + totalSum).toLocaleString('en-IN')}</h1>
                  </div> */}
                {/* </div> */}
                <Button className={'w-full mt-6 md:mb-1 mb-5 mx-auto'}>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <InboxArrowDownIcon className="h-5 w-5 text-default-color" />
                  </span>
                  <span>Save Now</span>
                </Button>
              </form>
            </Card>
          </div>
          {/* {
            viewModel && (
              <ViewModel viewModel={viewModel} setViewModel={() => setViewModel()}>
                {modalLoader ? (<PleaseWait />) : (
                  <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">
                    <h1 className="text-center uppercase font-bold text-2xl tracking-widest">
                      Add Labour Details
                    </h1>
                    <hr className="my-4 border border-primary-color" />
                    <form >
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
                        <div className="mb-3">
                          <Label htmlFor={"labour_name"} labelText={"Labour Name"} className={"mb-1"} />
                          <Input id={"labour_name"} type={"text"} name={"labour_name"} value={formDetail.labour_name} onChange={handleChanges} />
                          <Validation error={errors.labour_name} />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor={"labour_contact"} labelText={"Phone No"} className={"mb-1"} />
                          <Input id={"labour_contact"} type={"text"} name={"labour_contact"} Input value={formDetail.labour_contact} onChange={handleChanges} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} maxLength={10} minLength={10} />
                          <Validation error={errors.labour_contact} />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor={"labour_fixed_wage"} labelText={"Fixed Wage"} className={"mb-1"} />
                          <CurrencyInput id={'labour_fixed_wage'} type={'text'} name={'labour_fixed_wage'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                            value={formDetail.labour_fixed_wage}
                            onValueChange={handlelabourfixedwage} />
                          <Validation error={errors.labour_fixed_wage} />
                        </div>
                        <div>
                          <Label htmlFor={"rate_per_hours"} labelText={"Overtime Rate Per Hours"} className={"mb-1"} />
                          <CurrencyInput id={'rate_per_hours'} type={'text'} name={'rate_per_hours'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                            value={formDetail.rate_per_hours}
                            onValueChange={handlelabourrateperhr} />
                          <Validation error={errors.rate_per_hours} />
                        </div>
                        <div>
                          <Label htmlFor={"labour_overtime_hours"} labelText={"Overtime Hours"} className={"mb-1"} />
                          <Input id={"labour_overtime_hours"} type={"text"} name={"labour_overtime_hours"} value={formDetail.labour_overtime_hours} onChange={handleChanges} />
                          <Validation error={errors.labour_overtime_hours} />
                        </div>
                        <div>
                          <Label htmlFor={"labour_total_amount"} labelText={"Total Amount"} className={"mb-1"} />
                          <CurrencyInput id={'labour_total_amount'} type={'text'} name={'labour_total_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                            value={formDetail.labour_total_amount = parseInt(formDetail.labour_fixed_wage) + (parseInt(formDetail.rate_per_hours * parseInt(formDetail.labour_overtime_hours)))} />
                          <Validation error={errors.labour_total_amount} />
                        </div>
                        <div>
                          <Label htmlFor={"labour_gst"} labelText={" GST"} className={"mb-1"} />
                          <CustomSelect id={"labour_gst"} name={"labour_gst"} value={formDetail.labour_gst} onChange={handleChanges}>
                            <option value={""}>---GST ---</option>
                            <option value={"0%"}>0%</option>
                            <option value={"5%"}>5%</option>
                            <option value={"18%"}>18%</option>
                            <option value={"28%"}>28%</option>
                          </CustomSelect>
                          <Validation error={errors.labour_gst} />
                        </div>
                        <div>
                          <Label htmlFor={"labour_final_amount"} labelText={"Final Amount"} className={"mb-1"} />
                          <CurrencyInput id={'labour_final_amount'} type={'text'} name={'labour_final_amount'} className={"block w-full  rounded-lg border-0 text-sm py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:leading-6 "}
                            value={formDetail.labour_final_amount = parseInt(formDetail.labour_total_amount) - parseInt(formDetail.labour_total_amount * (parseInt(formDetail.labour_gst) / 100))} />
                          <Validation error={errors.labour_final_amount} />
                        </div>
                      </div>
                      {
                        id ? (
                          <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'} onClick={handleUpdatelabour}>
                            <span>Save</span>
                          </Button>
                        ) : (
                          <Button className={'w-full mt-6 hover:bg-secondary-color hover:text-default-color transition delay-100'} onClick={handleSubmitlabour}>
                            <span> Save Now</span>
                          </Button>
                        )
                      }
                    </form>

                  </Dialog.Panel>
                )}
              </ViewModel>
            )} */}
        </PageComponent>
      )}
    </Fragment>
  )
}
export default DailyWagesAddEdit



