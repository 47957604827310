import React, { useRef, Fragment, useEffect, useState } from 'react'
import PageComponent from '../../components/PageComponent'
import { PlusCircleIcon, PrinterIcon } from '@heroicons/react/24/solid'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import axiosClient from '../../Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { Dialog } from '@headlessui/react'
import { useReactToPrint } from "react-to-print";
import Label from '../helpers/Label'
import CustomSelect from '../helpers/CustomSelect'
import ViewModel from '../helpers/ViewModel'
import moment from 'moment'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import PleaseWait from '../Common/PleaseWait'

function Dpr() {
    document.title = 'SiteSmart - DPR Report'
    const tableHeading = ['Sr.No', 'Date', 'Work Name', ' Contractor Name', 'Assignee Name', 'Todays Task', 'Action']
    const tableHeading1 = ['Sr.No', 'Material Name', 'Unit', 'Quantity']
    const [loader, setLoader] = useState(false)
    const [modalLoader, setModalLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [dprList, setDprList] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [contractorName, setContractorName] = useState('')
    const handleChangeContractor = (e) => {
        setContractorName(e.target.value)
    }
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    }

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    }

    const getDprList = () => {
        setLoader(true)
        axiosClient.get('/Dprreport_list')
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.lists)
            })
    }
    const deleteDpr = (id) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/delete_Dprreport/${id}`)
                    .then(({ data }) => {
                        flasher.success(data.msg)
                        getDprList()
                    })
            }
        })
    }
    const [tableData, setTableData] = useState([])
    const [modalData, setModalData] = useState([])
    function openModal(id) {
        setViewModel(true)
        setModalLoader(true)
        axiosClient.get(`/single_Dprreport_data/${id}`)
            .then(({ data }) => {
                setModalLoader(false)
                setModalData(data.view_data)
                setTableData(data.viewmultiDPR_Materials)
            })
    }

    const [contractor, setContractor] = useState([]);
    const getContractorData = () => {
        setLoader(true)
        axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setLoader(false)
                setContractor(data.lists)
            })
    }
    const getWorkWiseData = () => {
        setLoader(true)
        axiosClient.get(`/work_wise_taskdata/${contractorName}`)
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.work_wise_taskdata)
            })
    }

    const getAllData = () => {
        contractorName && setLoader(true)
        contractorName && contractorName === 'All' ? (getDprList()) : axiosClient.get(`/Contractormaster_lists`)
            .then(({ data }) => {
                setContractor(data.lists)
                setLoader(false)
            })
    }
    const getSortedData = () => {
        setLoader(true)
        const formData = new FormData()
        formData.append('contractorName', contractorName);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        axiosClient.post(`datewise_dprreport`, formData)
            .then(({ data }) => {
                setLoader(false)
                setDprList(data.lists)
                flasher.success(data.msg)
            })
    }

    const componentRef = useRef();
    const modalRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleModal = useReactToPrint({
        content: () => modalRef.current,
    });

    useEffect(() => {
        getWorkWiseData()
    }, [contractorName])

    useEffect(() => {
        getAllData();
    }, [contractorName])

    useEffect(() => {
        getDprList();
        getContractorData();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <PageComponent title={'DPR Report'} button={
                    <LinkButton to={'/task-activity/add-dprreport'}>
                        <PlusCircleIcon className='w-6 h-6' />
                        <span>Add DPR</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-5  gap-x-4 mt-4 mb-2 '>
                        <div >
                            <Label htmlFor={'contractorName'} labelText={'Contractor Name'} className={'mb-1'} />
                            <CustomSelect id={'contractorName'} name={'contractorName'} value={contractorName} onChange={handleChangeContractor} className={'w-full'}>
                                <option disabled value={''}>--- Choose Contractor ---</option>
                                <option value={"All"}>All</option>
                                {contractor.map((userData, userId) => {
                                    return (<Fragment key={userId}>
                                        <option value={userData.id}>{userData.contractor_name}</option>
                                    </Fragment>)
                                })}
                            </CustomSelect>
                        </div>
                        <div className='mb-3'>
                            <Label htmlFor={'date'} labelText={'From Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={startDate} onChange={handleStartDate} />
                        </div>
                        <div className="">
                            <Label htmlFor={'date'} labelText={'To Date'} className={'mb-1'} />
                            <Input id={'date'} type={'date'} name={'date'} value={endDate} onChange={handleEndDate} />
                        </div>
                        <div className="items-center flex justify-start"><Button className='px-10  mt-4' onClick={getSortedData}>Search</Button></div>
                        <div className="items-center flex justify-end"><Button className='px-10  mt-4' onClick={handlePrint}><PrinterIcon className='w-5 h-5' /></Button></div>
                    </div>
                    {/* {dprList && dprList.length > 0 ? (
                        <div ref={componentRef}>
                            <Table tableHeading={tableHeading}>
                                {dprList.map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr className="bg-default-color text-gray-900 border-b text-center">
                                            <td className="px-4 py-3 whitespace-nowrap">{tableDataID + 1}</td>
                                            <td className="px-4 py-3 font-bold whitespace-nowrap">{moment(tableData.date).format('DD-MM-YYYY')}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.work_name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.contractor_name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.name}</td>
                                            <td className="px-4 py-3 whitespace-nowrap">{tableData.summary_of_completed_work}</td>
                                            <td className="px-4 py-3">
                                                <div className='flex justify-center gap-3'>
                                                    <ActionButton onClick={() => openModal(tableData.id)} title='View' className={'p-1.5 bg-primary-color rounded-lg cursor-pointer text-white'}><EyeIcon className='w-5 h-5' /></ActionButton>
                                                    <Link title='Edit' to={`/task-activity/edit-task-details-dprreport/${tableData.id}`}><ActionButton className={'p-1.5 text-white bg-yellow-400 rounded-lg'}><PencilSquareIcon className='w-5 h-5' /></ActionButton></Link>
                                                    <ActionButton onClick={() => deleteDpr(tableData.id)} title={'Delete'} className={'p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'}><TrashIcon className='w-5 h-5' /></ActionButton>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </Table>
                        </div>
                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! DPR Data Not Found</p>
                        </div>
                    )} */}
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-1 w-full'>
                    <div>{
                        dprList && dprList.length > 0 ? (
                            <table className="border-collapse border border-slate-500 ... bg-white w-full">
                                {dprList.map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Date</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{moment(tableData.date).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Work Name</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.work_name)}</td>
                                        </tr>

                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Wing Name</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.wing_name)}</td>
                                        </tr>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Level Name</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.level)}</td>
                                        </tr>
                                    </Fragment>)
                                })}
                            </table>
                        ) : (<div></div>)
                    }</div>
                    <div>{
                        dprList && dprList.length > 0 ? (
                            <table className="border-collapse border border-slate-500 ... bg-white w-full">
                                {dprList.map((tableData, tableDataID) => {
                                    return (<Fragment key={tableDataID}>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3 font-bold" >Task Name</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.level)}</td>
                                        </tr>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Task Status</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.task_status)}</td>
                                        </tr>
                                        <tr >
                                            <td className="border border-slate-700 ...  px-4 py-3" >Assignee Name</td>
                                            <td className="border border-slate-700 ...  px-4 py-3">{(tableData.name)}</td>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </Fragment>)
                                })}
                            </table>
                        ) : (<div></div>)
                    }</div>
                    </div>
                    {
                        viewModel && <ViewModel viewModel={viewModel} setViewModel={setViewModel}>
                            <Dialog.Panel className="w-auto max-w-screen-md transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left align-middle shadow-xl transition-all">  
                            {modalLoader ? (<PleaseWait />) : (
                                <Fragment>
                                    <div ref={modalRef} className='m-5' id='print'>
                                        <h1 className='text-center uppercase font-bold text-xl tracking-wide'>Daily Progress Details</h1>
                                        <hr className='my-4 border border-primary-color' />
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 ga mt-4 '>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold '>Date</h1>
                                                <h1>-</h1>
                                                <h1 >{moment(modalData.date).format('DD-MM-YYYY')}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Report Time</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.time_of_report}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Work Name</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.work_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Wing Name</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.wing_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Level Name</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.level_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Contractor Name</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.contractor_name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Name</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.name}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Task Status</h1>
                                                <h1>-</h1>
                                                <h1 >{modalData.task_status}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Summary of Completed Work</h1>
                                                <h1>-</h1>
                                                <h1>{modalData.summary_of_completed_work}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Work Planned for Tommarow</h1>
                                                <h1>-</h1>
                                                <h1>{modalData.work_planned_for_tommarow}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Issues and Concerns</h1>
                                                <h1>-</h1>
                                                <h1>{modalData.issues_and_concerns}</h1>
                                            </div>
                                            <div className='grid grid-cols-3 gap-4 mb-4'>
                                                <h1 className='font-bold'>Safety Incidents</h1>
                                                <h1>-</h1>
                                                <h1>{modalData.safety_incidents}</h1>
                                            </div>
                                            <div className='flex items-center gap-2 w-full py-2 mt-3'>
                                                <h1 className='font-bold'>Material Used</h1>
                                                <h1>-</h1>
                                            </div>
                                        </div>
                                        <div>
                                            {tableData && tableData.length > 0 ? (
                                                <Table tableHeading={tableHeading1}>
                                                    {tableData.map((tableData, tableDataID) => {
                                                        return (<Fragment key={tableDataID}>
                                                            <tr className="bg-default-color text-gray-900 border-b text-center">
                                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                                <td className="px-4 py-3">{tableData.material_name}</td>
                                                                <td className="px-4 py-3">{tableData.unit}</td>
                                                                <td className="px-4 py-3">{tableData.quantity}</td>
                                                            </tr>
                                                        </Fragment>)
                                                    })}
                                                </Table>
                                            ) : (
                                                <div className='flex justify-center items-center h-[20vh]'>
                                                    <p className='text-[3vw] md:text-3xl font-bold'>Sorry! Sub Tasks Not Found</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-4 grid grid-cols-3 gap-4">
                                        <div>
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => setViewModel(false)} >
                                                Got it, thanks!
                                            </button>
                                        </div>
                                        <div></div>
                                        <div className='flex justify-end'>
                                            <Link id='printButton' onClick={handleModal} className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-7 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 w-[50%]">  Print </Link>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                            </Dialog.Panel>
                        </ViewModel>
                    }
                </PageComponent>
            )}
        </Fragment>
    )
}
export default Dpr
